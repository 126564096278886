export const Jowar = [
{ "Year : Month Week#":"Janwk1","Predicted Price(INR)/Quintal":2280,"Actual Price(INR)/Quintal":2350,"Percentage Difference":"2.98%","YEAR":2025},
{ "Year : Month Week#":"Janwk2","Predicted Price(INR)/Quintal":2302,"Actual Price(INR)/Quintal":2359,"Percentage Difference":"2.42%","YEAR":2025},
{ "Year : Month Week#":"Janwk3","Predicted Price(INR)/Quintal":2387,"Actual Price(INR)/Quintal":null,"Percentage Difference":null,"YEAR":2025},
{ "Year : Month Week#":"Janwk4","Predicted Price(INR)/Quintal":2406,"Actual Price(INR)/Quintal":null,"Percentage Difference":null,"YEAR":2025},
{ "Year : Month Week#":"Febwk1","Predicted Price(INR)/Quintal":2382,"Actual Price(INR)/Quintal":null,"Percentage Difference":null,"YEAR":2025},
{ "Year : Month Week#":"Febwk2","Predicted Price(INR)/Quintal":2274,"Actual Price(INR)/Quintal":null,"Percentage Difference":null,"YEAR":2025},
{ "Year : Month Week#":"Janwk1","Predicted Price(INR)/Quintal":3075,"Actual Price(INR)/Quintal":3087,"Percentage Difference":"0.39%","YEAR":2024},
{ "Year : Month Week#":"Janwk2","Predicted Price(INR)/Quintal":2865,"Actual Price(INR)/Quintal":2764,"Percentage Difference":"-3.65%","YEAR":2024},
{ "Year : Month Week#":"Janwk3","Predicted Price(INR)/Quintal":2688,"Actual Price(INR)/Quintal":2700,"Percentage Difference":"0.44%","YEAR":2024},
{ "Year : Month Week#":"Janwk4","Predicted Price(INR)/Quintal":2599,"Actual Price(INR)/Quintal":2617,"Percentage Difference":"0.69%","YEAR":2024},
{ "Year : Month Week#":"Febwk1","Predicted Price(INR)/Quintal":2570,"Actual Price(INR)/Quintal":2530,"Percentage Difference":"-1.58%","YEAR":2024},
{ "Year : Month Week#":"Febwk2","Predicted Price(INR)/Quintal":2558,"Actual Price(INR)/Quintal":2480,"Percentage Difference":"-3.15%","YEAR":2024},
{ "Year : Month Week#":"Febwk3","Predicted Price(INR)/Quintal":2368,"Actual Price(INR)/Quintal":2419,"Percentage Difference":"2.11%","YEAR":2024},
{ "Year : Month Week#":"Febwk4","Predicted Price(INR)/Quintal":2353,"Actual Price(INR)/Quintal":2423,"Percentage Difference":"2.89%","YEAR":2024},
{ "Year : Month Week#":"Marwk1","Predicted Price(INR)/Quintal":2375,"Actual Price(INR)/Quintal":2470,"Percentage Difference":"3.85%","YEAR":2024},
{ "Year : Month Week#":"Marwk2","Predicted Price(INR)/Quintal":2377,"Actual Price(INR)/Quintal":2395,"Percentage Difference":"0.75%","YEAR":2024},
{ "Year : Month Week#":"Marwk3","Predicted Price(INR)/Quintal":2418,"Actual Price(INR)/Quintal":2500,"Percentage Difference":"3.28%","YEAR":2024},
{ "Year : Month Week#":"Marwk4","Predicted Price(INR)/Quintal":2431,"Actual Price(INR)/Quintal":2525,"Percentage Difference":"3.72%","YEAR":2024},
{ "Year : Month Week#":"Aprwk1","Predicted Price(INR)/Quintal":2480,"Actual Price(INR)/Quintal":2600,"Percentage Difference":"4.62%","YEAR":2024},
{ "Year : Month Week#":"Aprwk2","Predicted Price(INR)/Quintal":2420,"Actual Price(INR)/Quintal":2573,"Percentage Difference":"5.95%","YEAR":2024},
{ "Year : Month Week#":"Aprwk3","Predicted Price(INR)/Quintal":2517,"Actual Price(INR)/Quintal":2545,"Percentage Difference":"1.10%","YEAR":2024},
{ "Year : Month Week#":"Aprwk4","Predicted Price(INR)/Quintal":2406,"Actual Price(INR)/Quintal":2511,"Percentage Difference":"4.18%","YEAR":2024},
{ "Year : Month Week#":"Maywk1","Predicted Price(INR)/Quintal":2582,"Actual Price(INR)/Quintal":2671,"Percentage Difference":"3.33%","YEAR":2024},
{ "Year : Month Week#":"Maywk2","Predicted Price(INR)/Quintal":2585,"Actual Price(INR)/Quintal":2620,"Percentage Difference":"1.34%","YEAR":2024},
{ "Year : Month Week#":"Maywk3","Predicted Price(INR)/Quintal":2584,"Actual Price(INR)/Quintal":2591,"Percentage Difference":"0.27%","YEAR":2024},
{ "Year : Month Week#":"Maywk4","Predicted Price(INR)/Quintal":2582,"Actual Price(INR)/Quintal":2573,"Percentage Difference":"-0.35%","YEAR":2024},
{ "Year : Month Week#":"Junwk1","Predicted Price(INR)/Quintal":2561,"Actual Price(INR)/Quintal":2660,"Percentage Difference":"3.72%","YEAR":2024},
{ "Year : Month Week#":"Junwk2","Predicted Price(INR)/Quintal":2603,"Actual Price(INR)/Quintal":2704,"Percentage Difference":"3.74%","YEAR":2024},
{ "Year : Month Week#":"Junwk3","Predicted Price(INR)/Quintal":2702,"Actual Price(INR)/Quintal":2775,"Percentage Difference":"2.63%","YEAR":2024},
{ "Year : Month Week#":"Junwk4","Predicted Price(INR)/Quintal":2687,"Actual Price(INR)/Quintal":2600,"Percentage Difference":"-3.35%","YEAR":2024},
{ "Year : Month Week#":"Julwk1","Predicted Price(INR)/Quintal":2612,"Actual Price(INR)/Quintal":2585,"Percentage Difference":"-1.04%","YEAR":2024},
{ "Year : Month Week#":"Julwk2","Predicted Price(INR)/Quintal":2641,"Actual Price(INR)/Quintal":2645,"Percentage Difference":"0.15%","YEAR":2024},
{ "Year : Month Week#":"Julwk3","Predicted Price(INR)/Quintal":2584,"Actual Price(INR)/Quintal":2600,"Percentage Difference":"0.62%","YEAR":2024},
{ "Year : Month Week#":"Julwk4","Predicted Price(INR)/Quintal":2586,"Actual Price(INR)/Quintal":2598,"Percentage Difference":"0.46%","YEAR":2024},
{ "Year : Month Week#":"Augwk1","Predicted Price(INR)/Quintal":2457,"Actual Price(INR)/Quintal":2462,"Percentage Difference":"0.20%","YEAR":2024},
{ "Year : Month Week#":"Augwk2","Predicted Price(INR)/Quintal":2413,"Actual Price(INR)/Quintal":2540,"Percentage Difference":"5.00%","YEAR":2024},
{ "Year : Month Week#":"Augwk3","Predicted Price(INR)/Quintal":2438,"Actual Price(INR)/Quintal":2535,"Percentage Difference":"3.83%","YEAR":2024},
{ "Year : Month Week#":"Augwk4","Predicted Price(INR)/Quintal":2499,"Actual Price(INR)/Quintal":2590,"Percentage Difference":"3.51%","YEAR":2024},
{ "Year : Month Week#":"Sepwk1","Predicted Price(INR)/Quintal":2480,"Actual Price(INR)/Quintal":2538,"Percentage Difference":"2.29%","YEAR":2024},
{ "Year : Month Week#":"Sepwk2","Predicted Price(INR)/Quintal":2546,"Actual Price(INR)/Quintal":2573,"Percentage Difference":"1.05%","YEAR":2024},
{ "Year : Month Week#":"Sepwk3","Predicted Price(INR)/Quintal":2305,"Actual Price(INR)/Quintal":2244,"Percentage Difference":"-2.72%","YEAR":2024},
{ "Year : Month Week#":"Sepwk4","Predicted Price(INR)/Quintal":2215,"Actual Price(INR)/Quintal":2283,"Percentage Difference":"2.98%","YEAR":2024},
{ "Year : Month Week#":"Octwk1","Predicted Price(INR)/Quintal":2268,"Actual Price(INR)/Quintal":2198,"Percentage Difference":"-3.18%","YEAR":2024},
{ "Year : Month Week#":"Octwk2","Predicted Price(INR)/Quintal":2193,"Actual Price(INR)/Quintal":2304,"Percentage Difference":"4.82%","YEAR":2024},
{ "Year : Month Week#":"Octwk3","Predicted Price(INR)/Quintal":2233,"Actual Price(INR)/Quintal":2292,"Percentage Difference":"2.57%","YEAR":2024},
{ "Year : Month Week#":"Octwk4","Predicted Price(INR)/Quintal":2302,"Actual Price(INR)/Quintal":2366,"Percentage Difference":"2.70%","YEAR":2024},
{ "Year : Month Week#":"Novwk1","Predicted Price(INR)/Quintal":2320,"Actual Price(INR)/Quintal":2252,"Percentage Difference":"-3.02%","YEAR":2024},
{ "Year : Month Week#":"Novwk2","Predicted Price(INR)/Quintal":2294,"Actual Price(INR)/Quintal":2330,"Percentage Difference":"1.55%","YEAR":2024},
{ "Year : Month Week#":"Novwk3","Predicted Price(INR)/Quintal":2284,"Actual Price(INR)/Quintal":2257,"Percentage Difference":"-1.20%","YEAR":2024},
{ "Year : Month Week#":"Novwk4","Predicted Price(INR)/Quintal":2327,"Actual Price(INR)/Quintal":2301,"Percentage Difference":"-1.13%","YEAR":2024},
{ "Year : Month Week#":"Decwk1","Predicted Price(INR)/Quintal":2325,"Actual Price(INR)/Quintal":2413,"Percentage Difference":"3.65%","YEAR":2024},
{ "Year : Month Week#":"Decwk2","Predicted Price(INR)/Quintal":2281,"Actual Price(INR)/Quintal":2328,"Percentage Difference":"2.02%","YEAR":2024},
{ "Year : Month Week#":"Decwk3","Predicted Price(INR)/Quintal":2255,"Actual Price(INR)/Quintal":2298,"Percentage Difference":"1.87%","YEAR":2024},
{ "Year : Month Week#":"Decwk4","Predicted Price(INR)/Quintal":2256,"Actual Price(INR)/Quintal":2289,"Percentage Difference":"1.44%","YEAR":2024},
{ "Year : Month Week#":"Janwk1","Predicted Price(INR)/Quintal":2136,"Actual Price(INR)/Quintal":2241,"Percentage Difference":"4.69%","YEAR":2023},
{ "Year : Month Week#":"Janwk2","Predicted Price(INR)/Quintal":2365,"Actual Price(INR)/Quintal":2300,"Percentage Difference":"-2.83%","YEAR":2023},
{ "Year : Month Week#":"Janwk3","Predicted Price(INR)/Quintal":2366,"Actual Price(INR)/Quintal":2331,"Percentage Difference":"-1.50%","YEAR":2023},
{ "Year : Month Week#":"Janwk4","Predicted Price(INR)/Quintal":2356,"Actual Price(INR)/Quintal":2409,"Percentage Difference":"2.20%","YEAR":2023},
{ "Year : Month Week#":"Febwk1","Predicted Price(INR)/Quintal":2409,"Actual Price(INR)/Quintal":2489,"Percentage Difference":"3.21%","YEAR":2023},
{ "Year : Month Week#":"Febwk2","Predicted Price(INR)/Quintal":2436,"Actual Price(INR)/Quintal":2544,"Percentage Difference":"4.25%","YEAR":2023},
{ "Year : Month Week#":"Febwk3","Predicted Price(INR)/Quintal":2457,"Actual Price(INR)/Quintal":2570,"Percentage Difference":"4.40%","YEAR":2023},
{ "Year : Month Week#":"Febwk4","Predicted Price(INR)/Quintal":2459,"Actual Price(INR)/Quintal":2550,"Percentage Difference":"3.57%","YEAR":2023},
{ "Year : Month Week#":"Marwk1","Predicted Price(INR)/Quintal":2480,"Actual Price(INR)/Quintal":2484,"Percentage Difference":"0.16%","YEAR":2023},
{ "Year : Month Week#":"Marwk2","Predicted Price(INR)/Quintal":2461,"Actual Price(INR)/Quintal":2467,"Percentage Difference":"0.24%","YEAR":2023},
{ "Year : Month Week#":"Marwk3","Predicted Price(INR)/Quintal":2489,"Actual Price(INR)/Quintal":2497,"Percentage Difference":"0.32%","YEAR":2023},
{ "Year : Month Week#":"Marwk4","Predicted Price(INR)/Quintal":2459,"Actual Price(INR)/Quintal":2490,"Percentage Difference":"1.24%","YEAR":2023},
{ "Year : Month Week#":"Aprwk1","Predicted Price(INR)/Quintal":2489,"Actual Price(INR)/Quintal":2517,"Percentage Difference":"1.11%","YEAR":2023},
{ "Year : Month Week#":"Aprwk2","Predicted Price(INR)/Quintal":2409,"Actual Price(INR)/Quintal":2447,"Percentage Difference":"1.55%","YEAR":2023},
{ "Year : Month Week#":"Aprwk3","Predicted Price(INR)/Quintal":2439,"Actual Price(INR)/Quintal":2471,"Percentage Difference":"1.30%","YEAR":2023},
{ "Year : Month Week#":"Aprwk4","Predicted Price(INR)/Quintal":2468,"Actual Price(INR)/Quintal":2450,"Percentage Difference":"-0.73%","YEAR":2023},
{ "Year : Month Week#":"Maywk1","Predicted Price(INR)/Quintal":2460,"Actual Price(INR)/Quintal":2500,"Percentage Difference":"1.60%","YEAR":2023},
{ "Year : Month Week#":"Maywk2","Predicted Price(INR)/Quintal":2491,"Actual Price(INR)/Quintal":2494,"Percentage Difference":"0.12%","YEAR":2023},
{ "Year : Month Week#":"Maywk3","Predicted Price(INR)/Quintal":2389,"Actual Price(INR)/Quintal":2399,"Percentage Difference":"0.42%","YEAR":2023},
{ "Year : Month Week#":"Maywk4","Predicted Price(INR)/Quintal":2288,"Actual Price(INR)/Quintal":2246,"Percentage Difference":"-1.87%","YEAR":2023},
{ "Year : Month Week#":"Junwk1","Predicted Price(INR)/Quintal":2298,"Actual Price(INR)/Quintal":2337,"Percentage Difference":"1.67%","YEAR":2023},
{ "Year : Month Week#":"Junwk2","Predicted Price(INR)/Quintal":2487,"Actual Price(INR)/Quintal":2528,"Percentage Difference":"1.62%","YEAR":2023},
{ "Year : Month Week#":"Junwk3","Predicted Price(INR)/Quintal":2411,"Actual Price(INR)/Quintal":2413,"Percentage Difference":"0.08%","YEAR":2023},
{ "Year : Month Week#":"Junwk4","Predicted Price(INR)/Quintal":2746,"Actual Price(INR)/Quintal":2864,"Percentage Difference":"4.12%","YEAR":2023},
{ "Year : Month Week#":"Julwk1","Predicted Price(INR)/Quintal":2838,"Actual Price(INR)/Quintal":2829,"Percentage Difference":"-0.32%","YEAR":2023},
{ "Year : Month Week#":"Julwk2","Predicted Price(INR)/Quintal":2817,"Actual Price(INR)/Quintal":2878,"Percentage Difference":"2.12%","YEAR":2023},
{ "Year : Month Week#":"Julwk3","Predicted Price(INR)/Quintal":2876,"Actual Price(INR)/Quintal":2900,"Percentage Difference":"0.83%","YEAR":2023},
{ "Year : Month Week#":"Julwk4","Predicted Price(INR)/Quintal":2740,"Actual Price(INR)/Quintal":2900,"Percentage Difference":"5.52%","YEAR":2023},
{ "Year : Month Week#":"Augwk1","Predicted Price(INR)/Quintal":2961,"Actual Price(INR)/Quintal":2990,"Percentage Difference":"0.97%","YEAR":2023},
{ "Year : Month Week#":"Augwk2","Predicted Price(INR)/Quintal":2947,"Actual Price(INR)/Quintal":2990,"Percentage Difference":"1.44%","YEAR":2023},
{ "Year : Month Week#":"Augwk3","Predicted Price(INR)/Quintal":2996,"Actual Price(INR)/Quintal":2876,"Percentage Difference":"-4.17%","YEAR":2023},
{ "Year : Month Week#":"Augwk4","Predicted Price(INR)/Quintal":2774,"Actual Price(INR)/Quintal":2789,"Percentage Difference":"0.54%","YEAR":2023},
{ "Year : Month Week#":"Sepwk1","Predicted Price(INR)/Quintal":2700,"Actual Price(INR)/Quintal":2800,"Percentage Difference":"3.57%","YEAR":2023},
{ "Year : Month Week#":"Sepwk2","Predicted Price(INR)/Quintal":2888,"Actual Price(INR)/Quintal":2779,"Percentage Difference":"-3.92%","YEAR":2023},
{ "Year : Month Week#":"Sepwk3","Predicted Price(INR)/Quintal":2674,"Actual Price(INR)/Quintal":2758,"Percentage Difference":"3.05%","YEAR":2023},
{ "Year : Month Week#":"Sepwk4","Predicted Price(INR)/Quintal":2709,"Actual Price(INR)/Quintal":2826,"Percentage Difference":"4.14%","YEAR":2023},
{ "Year : Month Week#":"Octwk1","Predicted Price(INR)/Quintal":2776,"Actual Price(INR)/Quintal":2850,"Percentage Difference":"2.60%","YEAR":2023},
{ "Year : Month Week#":"Octwk2","Predicted Price(INR)/Quintal":2725,"Actual Price(INR)/Quintal":2747,"Percentage Difference":"0.80%","YEAR":2023},
{ "Year : Month Week#":"Octwk3","Predicted Price(INR)/Quintal":2777,"Actual Price(INR)/Quintal":2864,"Percentage Difference":"3.04%","YEAR":2023},
{ "Year : Month Week#":"Octwk4","Predicted Price(INR)/Quintal":2817,"Actual Price(INR)/Quintal":2956,"Percentage Difference":"4.70%","YEAR":2023},
{ "Year : Month Week#":"Novwk1","Predicted Price(INR)/Quintal":2933,"Actual Price(INR)/Quintal":2973,"Percentage Difference":"1.35%","YEAR":2023},
{ "Year : Month Week#":"Novwk2","Predicted Price(INR)/Quintal":2921,"Actual Price(INR)/Quintal":3070,"Percentage Difference":"4.85%","YEAR":2023},
{ "Year : Month Week#":"Novwk3","Predicted Price(INR)/Quintal":2966,"Actual Price(INR)/Quintal":3092,"Percentage Difference":"4.08%","YEAR":2023},
{ "Year : Month Week#":"Novwk4","Predicted Price(INR)/Quintal":3021,"Actual Price(INR)/Quintal":3027,"Percentage Difference":"0.20%","YEAR":2023},
{ "Year : Month Week#":"Decwk1","Predicted Price(INR)/Quintal":2999,"Actual Price(INR)/Quintal":3039,"Percentage Difference":"1.32%","YEAR":2023},
{ "Year : Month Week#":"Decwk2","Predicted Price(INR)/Quintal":3166,"Actual Price(INR)/Quintal":3269,"Percentage Difference":"3.15%","YEAR":2023},
{ "Year : Month Week#":"Decwk3","Predicted Price(INR)/Quintal":3138,"Actual Price(INR)/Quintal":3169,"Percentage Difference":"0.98%","YEAR":2023},
{ "Year : Month Week#":"Decwk4","Predicted Price(INR)/Quintal":3127,"Actual Price(INR)/Quintal":3133,"Percentage Difference":"0.19%","YEAR":2023},
{ "Year : Month Week#":"Janwk1","Predicted Price(INR)/Quintal":1708,"Actual Price(INR)/Quintal":1776,"Percentage Difference":"3.83%","YEAR":2022},
{ "Year : Month Week#":"Janwk2","Predicted Price(INR)/Quintal":1684,"Actual Price(INR)/Quintal":1703,"Percentage Difference":"1.12%","YEAR":2022},
{ "Year : Month Week#":"Janwk3","Predicted Price(INR)/Quintal":1712,"Actual Price(INR)/Quintal":1745,"Percentage Difference":"1.89%","YEAR":2022},
{ "Year : Month Week#":"Janwk4","Predicted Price(INR)/Quintal":1720,"Actual Price(INR)/Quintal":1741,"Percentage Difference":"1.21%","YEAR":2022},
{ "Year : Month Week#":"Febwk1","Predicted Price(INR)/Quintal":1797,"Actual Price(INR)/Quintal":1889,"Percentage Difference":"4.87%","YEAR":2022},
{ "Year : Month Week#":"Febwk2","Predicted Price(INR)/Quintal":1899,"Actual Price(INR)/Quintal":1918,"Percentage Difference":"0.99%","YEAR":2022},
{ "Year : Month Week#":"Febwk3","Predicted Price(INR)/Quintal":1888,"Actual Price(INR)/Quintal":1910,"Percentage Difference":"1.15%","YEAR":2022},
{ "Year : Month Week#":"Febwk4","Predicted Price(INR)/Quintal":1904,"Actual Price(INR)/Quintal":1947,"Percentage Difference":"2.21%","YEAR":2022},
{ "Year : Month Week#":"Marwk1","Predicted Price(INR)/Quintal":1915,"Actual Price(INR)/Quintal":1966,"Percentage Difference":"2.59%","YEAR":2022},
{ "Year : Month Week#":"Marwk2","Predicted Price(INR)/Quintal":1945,"Actual Price(INR)/Quintal":1976,"Percentage Difference":"1.57%","YEAR":2022},
{ "Year : Month Week#":"Marwk3","Predicted Price(INR)/Quintal":2026,"Actual Price(INR)/Quintal":2060,"Percentage Difference":"1.65%","YEAR":2022},
{ "Year : Month Week#":"Marwk4","Predicted Price(INR)/Quintal":2130,"Actual Price(INR)/Quintal":2186,"Percentage Difference":"2.56%","YEAR":2022},
{ "Year : Month Week#":"Aprwk1","Predicted Price(INR)/Quintal":2134,"Actual Price(INR)/Quintal":2131,"Percentage Difference":"-0.14%","YEAR":2022},
{ "Year : Month Week#":"Aprwk2","Predicted Price(INR)/Quintal":2181,"Actual Price(INR)/Quintal":2133,"Percentage Difference":"-2.25%","YEAR":2022},
{ "Year : Month Week#":"Aprwk3","Predicted Price(INR)/Quintal":2131,"Actual Price(INR)/Quintal":2130,"Percentage Difference":"-0.05%","YEAR":2022},
{ "Year : Month Week#":"Aprwk4","Predicted Price(INR)/Quintal":2136,"Actual Price(INR)/Quintal":2119,"Percentage Difference":"-0.80%","YEAR":2022},
{ "Year : Month Week#":"Maywk1","Predicted Price(INR)/Quintal":2187,"Actual Price(INR)/Quintal":2147,"Percentage Difference":"-1.86%","YEAR":2022},
{ "Year : Month Week#":"Maywk2","Predicted Price(INR)/Quintal":2095,"Actual Price(INR)/Quintal":2064,"Percentage Difference":"-1.50%","YEAR":2022},
{ "Year : Month Week#":"Maywk3","Predicted Price(INR)/Quintal":2108,"Actual Price(INR)/Quintal":2072,"Percentage Difference":"-1.74%","YEAR":2022},
{ "Year : Month Week#":"Maywk4","Predicted Price(INR)/Quintal":2024,"Actual Price(INR)/Quintal":2031,"Percentage Difference":"0.34%","YEAR":2022},
{ "Year : Month Week#":"Junwk1","Predicted Price(INR)/Quintal":2010,"Actual Price(INR)/Quintal":2091,"Percentage Difference":"3.87%","YEAR":2022},
{ "Year : Month Week#":"Junwk2","Predicted Price(INR)/Quintal":2038,"Actual Price(INR)/Quintal":2088,"Percentage Difference":"2.39%","YEAR":2022},
{ "Year : Month Week#":"Junwk3","Predicted Price(INR)/Quintal":2100,"Actual Price(INR)/Quintal":2025,"Percentage Difference":"-3.70%","YEAR":2022},
{ "Year : Month Week#":"Junwk4","Predicted Price(INR)/Quintal":2022,"Actual Price(INR)/Quintal":2076,"Percentage Difference":"2.60%","YEAR":2022},
{ "Year : Month Week#":"Julwk1","Predicted Price(INR)/Quintal":2080,"Actual Price(INR)/Quintal":2150,"Percentage Difference":"3.26%","YEAR":2022},
{ "Year : Month Week#":"Julwk2","Predicted Price(INR)/Quintal":2225,"Actual Price(INR)/Quintal":2213,"Percentage Difference":"-0.54%","YEAR":2022},
{ "Year : Month Week#":"Julwk3","Predicted Price(INR)/Quintal":2204,"Actual Price(INR)/Quintal":2251,"Percentage Difference":"2.09%","YEAR":2022},
{ "Year : Month Week#":"Julwk4","Predicted Price(INR)/Quintal":2244,"Actual Price(INR)/Quintal":2311,"Percentage Difference":"2.90%","YEAR":2022},
{ "Year : Month Week#":"Augwk1","Predicted Price(INR)/Quintal":2269,"Actual Price(INR)/Quintal":2281,"Percentage Difference":"0.53%","YEAR":2022},
{ "Year : Month Week#":"Augwk2","Predicted Price(INR)/Quintal":2248,"Actual Price(INR)/Quintal":2262,"Percentage Difference":"0.62%","YEAR":2022},
{ "Year : Month Week#":"Augwk3","Predicted Price(INR)/Quintal":2220,"Actual Price(INR)/Quintal":2288,"Percentage Difference":"2.97%","YEAR":2022},
{ "Year : Month Week#":"Augwk4","Predicted Price(INR)/Quintal":2249,"Actual Price(INR)/Quintal":2233,"Percentage Difference":"-0.72%","YEAR":2022},
{ "Year : Month Week#":"Sepwk1","Predicted Price(INR)/Quintal":2253,"Actual Price(INR)/Quintal":2271,"Percentage Difference":"0.79%","YEAR":2022},
{ "Year : Month Week#":"Sepwk2","Predicted Price(INR)/Quintal":2231,"Actual Price(INR)/Quintal":2177,"Percentage Difference":"-2.48%","YEAR":2022},
{ "Year : Month Week#":"Sepwk3","Predicted Price(INR)/Quintal":2008,"Actual Price(INR)/Quintal":1937,"Percentage Difference":"-3.67%","YEAR":2022},
{ "Year : Month Week#":"Sepwk4","Predicted Price(INR)/Quintal":1939,"Actual Price(INR)/Quintal":1932,"Percentage Difference":"-0.36%","YEAR":2022},
{ "Year : Month Week#":"Octwk1","Predicted Price(INR)/Quintal":1976,"Actual Price(INR)/Quintal":1981,"Percentage Difference":"0.25%","YEAR":2022},
{ "Year : Month Week#":"Octwk2","Predicted Price(INR)/Quintal":1955,"Actual Price(INR)/Quintal":2036,"Percentage Difference":"3.98%","YEAR":2022},
{ "Year : Month Week#":"Octwk3","Predicted Price(INR)/Quintal":2043,"Actual Price(INR)/Quintal":2032,"Percentage Difference":"-0.54%","YEAR":2022},
{ "Year : Month Week#":"Octwk4","Predicted Price(INR)/Quintal":2023,"Actual Price(INR)/Quintal":2050,"Percentage Difference":"1.32%","YEAR":2022},
{ "Year : Month Week#":"Novwk1","Predicted Price(INR)/Quintal":2092,"Actual Price(INR)/Quintal":2025,"Percentage Difference":"-3.31%","YEAR":2022},
{ "Year : Month Week#":"Novwk2","Predicted Price(INR)/Quintal":2058,"Actual Price(INR)/Quintal":2160,"Percentage Difference":"4.72%","YEAR":2022},
{ "Year : Month Week#":"Novwk3","Predicted Price(INR)/Quintal":2084,"Actual Price(INR)/Quintal":2093,"Percentage Difference":"0.43%","YEAR":2022},
{ "Year : Month Week#":"Novwk4","Predicted Price(INR)/Quintal":2099,"Actual Price(INR)/Quintal":2191,"Percentage Difference":"4.20%","YEAR":2022},
{ "Year : Month Week#":"Decwk1","Predicted Price(INR)/Quintal":2176,"Actual Price(INR)/Quintal":2302,"Percentage Difference":"5.47%","YEAR":2022},
{ "Year : Month Week#":"Decwk2","Predicted Price(INR)/Quintal":2250,"Actual Price(INR)/Quintal":2200,"Percentage Difference":"-2.27%","YEAR":2022},
{ "Year : Month Week#":"Decwk3","Predicted Price(INR)/Quintal":2263,"Actual Price(INR)/Quintal":2232,"Percentage Difference":"-1.39%","YEAR":2022},
{ "Year : Month Week#":"Decwk4","Predicted Price(INR)/Quintal":2202,"Actual Price(INR)/Quintal":2141,"Percentage Difference":"-2.85%","YEAR":2022},
{ "Year : Month Week#":"JanWk1","Predicted Price(INR)/Quintal":1818,"Actual Price(INR)/Quintal":1749,"Percentage Difference":"-3.95%","YEAR":2021},
{ "Year : Month Week#":"JanWk2","Predicted Price(INR)/Quintal":1711,"Actual Price(INR)/Quintal":1721,"Percentage Difference":"0.58%","YEAR":2021},
{ "Year : Month Week#":"JanWk3","Predicted Price(INR)/Quintal":1701,"Actual Price(INR)/Quintal":1726,"Percentage Difference":"1.45%","YEAR":2021},
{ "Year : Month Week#":"JanWk4","Predicted Price(INR)/Quintal":1710,"Actual Price(INR)/Quintal":1730,"Percentage Difference":"1.16%","YEAR":2021},
{ "Year : Month Week#":"FebWk1","Predicted Price(INR)/Quintal":1805,"Actual Price(INR)/Quintal":1835,"Percentage Difference":"1.63%","YEAR":2021},
{ "Year : Month Week#":"FebWk2","Predicted Price(INR)/Quintal":1802,"Actual Price(INR)/Quintal":1762,"Percentage Difference":"-2.27%","YEAR":2021},
{ "Year : Month Week#":"FebWk3","Predicted Price(INR)/Quintal":1769,"Actual Price(INR)/Quintal":1800,"Percentage Difference":"1.72%","YEAR":2021},
{ "Year : Month Week#":"FebWk4","Predicted Price(INR)/Quintal":1842,"Actual Price(INR)/Quintal":1911,"Percentage Difference":"3.61%","YEAR":2021},
{ "Year : Month Week#":"MarWk1","Predicted Price(INR)/Quintal":1879,"Actual Price(INR)/Quintal":1921,"Percentage Difference":"2.19%","YEAR":2021},
{ "Year : Month Week#":"MarWk2","Predicted Price(INR)/Quintal":1877,"Actual Price(INR)/Quintal":1908,"Percentage Difference":"1.62%","YEAR":2021},
{ "Year : Month Week#":"MarWk3","Predicted Price(INR)/Quintal":1826,"Actual Price(INR)/Quintal":1862,"Percentage Difference":"1.93%","YEAR":2021},
{ "Year : Month Week#":"MarWk4","Predicted Price(INR)/Quintal":1879,"Actual Price(INR)/Quintal":1906,"Percentage Difference":"1.42%","YEAR":2021},
{ "Year : Month Week#":"AprWk1","Predicted Price(INR)/Quintal":1897,"Actual Price(INR)/Quintal":1899,"Percentage Difference":"0.11%","YEAR":2021},
{ "Year : Month Week#":"AprWk2","Predicted Price(INR)/Quintal":1881,"Actual Price(INR)/Quintal":1805,"Percentage Difference":"-4.21%","YEAR":2021},
{ "Year : Month Week#":"AprWk3","Predicted Price(INR)/Quintal":1842,"Actual Price(INR)/Quintal":1771,"Percentage Difference":"-4.01%","YEAR":2021},
{ "Year : Month Week#":"AprWk4","Predicted Price(INR)/Quintal":1799,"Actual Price(INR)/Quintal":1800,"Percentage Difference":"0.06%","YEAR":2021},
{ "Year : Month Week#":"Maywk1","Predicted Price(INR)/Quintal":1750,"Actual Price(INR)/Quintal":1803,"Percentage Difference":"2.94%","YEAR":2021},
{ "Year : Month Week#":"Maywk2","Predicted Price(INR)/Quintal":1762,"Actual Price(INR)/Quintal":1731,"Percentage Difference":"-1.79%","YEAR":2021},
{ "Year : Month Week#":"Maywk3","Predicted Price(INR)/Quintal":1756,"Actual Price(INR)/Quintal":1800,"Percentage Difference":"2.44%","YEAR":2021},
{ "Year : Month Week#":"Maywk4","Predicted Price(INR)/Quintal":1765,"Actual Price(INR)/Quintal":1796,"Percentage Difference":"1.73%","YEAR":2021},
{ "Year : Month Week#":"Junwk1","Predicted Price(INR)/Quintal":1984,"Actual Price(INR)/Quintal":2001,"Percentage Difference":"0.85%","YEAR":2021},
{ "Year : Month Week#":"Junwk2","Predicted Price(INR)/Quintal":1887,"Actual Price(INR)/Quintal":1854,"Percentage Difference":"-1.78%","YEAR":2021},
{ "Year : Month Week#":"Junwk3","Predicted Price(INR)/Quintal":1795,"Actual Price(INR)/Quintal":1850,"Percentage Difference":"2.97%","YEAR":2021},
{ "Year : Month Week#":"Junwk4","Predicted Price(INR)/Quintal":1796,"Actual Price(INR)/Quintal":1769,"Percentage Difference":"-1.53%","YEAR":2021},
{ "Year : Month Week#":"Julwk1","Predicted Price(INR)/Quintal":1794,"Actual Price(INR)/Quintal":1800,"Percentage Difference":"0.33%","YEAR":2021},
{ "Year : Month Week#":"Julwk2","Predicted Price(INR)/Quintal":1897,"Actual Price(INR)/Quintal":1950,"Percentage Difference":"2.72%","YEAR":2021},
{ "Year : Month Week#":"Julwk3","Predicted Price(INR)/Quintal":1902,"Actual Price(INR)/Quintal":1850,"Percentage Difference":"-2.81%","YEAR":2021},
{ "Year : Month Week#":"Julwk4","Predicted Price(INR)/Quintal":1807,"Actual Price(INR)/Quintal":1855,"Percentage Difference":"2.59%","YEAR":2021},
{ "Year : Month Week#":"Augwk1","Predicted Price(INR)/Quintal":1778,"Actual Price(INR)/Quintal":1777,"Percentage Difference":"-0.06%","YEAR":2021},
{ "Year : Month Week#":"Augwk2","Predicted Price(INR)/Quintal":1745,"Actual Price(INR)/Quintal":1701,"Percentage Difference":"-2.59%","YEAR":2021},
{ "Year : Month Week#":"Augwk3","Predicted Price(INR)/Quintal":1657,"Actual Price(INR)/Quintal":1690,"Percentage Difference":"1.95%","YEAR":2021},
{ "Year : Month Week#":"Augwk4","Predicted Price(INR)/Quintal":1555,"Actual Price(INR)/Quintal":1570,"Percentage Difference":"0.96%","YEAR":2021},
{ "Year : Month Week#":"Sepwk1","Predicted Price(INR)/Quintal":1616,"Actual Price(INR)/Quintal":1576,"Percentage Difference":"-2.54%","YEAR":2021},
{ "Year : Month Week#":"Sepwk2","Predicted Price(INR)/Quintal":1578,"Actual Price(INR)/Quintal":1602,"Percentage Difference":"1.50%","YEAR":2021},
{ "Year : Month Week#":"Sepwk3","Predicted Price(INR)/Quintal":1524,"Actual Price(INR)/Quintal":1515,"Percentage Difference":"-0.59%","YEAR":2021},
{ "Year : Month Week#":"Sepwk4","Predicted Price(INR)/Quintal":1529,"Actual Price(INR)/Quintal":1504,"Percentage Difference":"-1.66%","YEAR":2021},
{ "Year : Month Week#":"Octwk1","Predicted Price(INR)/Quintal":1524,"Actual Price(INR)/Quintal":1547,"Percentage Difference":"1.49%","YEAR":2021},
{ "Year : Month Week#":"Octwk2","Predicted Price(INR)/Quintal":1572,"Actual Price(INR)/Quintal":1510,"Percentage Difference":"-4.11%","YEAR":2021},
{ "Year : Month Week#":"Octwk3","Predicted Price(INR)/Quintal":1535,"Actual Price(INR)/Quintal":1466,"Percentage Difference":"-4.71%","YEAR":2021},
{ "Year : Month Week#":"Octwk4","Predicted Price(INR)/Quintal":1525,"Actual Price(INR)/Quintal":1517,"Percentage Difference":"-0.53%","YEAR":2021},
{ "Year : Month Week#":"Novwk1","Predicted Price(INR)/Quintal":1522,"Actual Price(INR)/Quintal":1590,"Percentage Difference":"4.28%","YEAR":2021},
{ "Year : Month Week#":"Novwk2","Predicted Price(INR)/Quintal":1534,"Actual Price(INR)/Quintal":1606,"Percentage Difference":"4.48%","YEAR":2021},
{ "Year : Month Week#":"Novwk3","Predicted Price(INR)/Quintal":1612,"Actual Price(INR)/Quintal":1590,"Percentage Difference":"-1.38%","YEAR":2021},
{ "Year : Month Week#":"Novwk4","Predicted Price(INR)/Quintal":1617,"Actual Price(INR)/Quintal":1650,"Percentage Difference":"2.00%","YEAR":2021},
{ "Year : Month Week#":"Decwk1","Predicted Price(INR)/Quintal":1625,"Actual Price(INR)/Quintal":1710,"Percentage Difference":"4.97%","YEAR":2021},
{ "Year : Month Week#":"Decwk2","Predicted Price(INR)/Quintal":1724,"Actual Price(INR)/Quintal":1682,"Percentage Difference":"-2.50%","YEAR":2021},
{ "Year : Month Week#":"Decwk3","Predicted Price(INR)/Quintal":1691,"Actual Price(INR)/Quintal":1750,"Percentage Difference":"3.37%","YEAR":2021},
{ "Year : Month Week#":"Decwk4","Predicted Price(INR)/Quintal":1693,"Actual Price(INR)/Quintal":1685,"Percentage Difference":"-0.47%","YEAR":2021},
{ "Year : Month Week#":"JanWk1","Predicted Price(INR)/Quintal":1904,"Actual Price(INR)/Quintal":2001,"Percentage Difference":"4.85%","YEAR":2020},
{ "Year : Month Week#":"JanWk2","Predicted Price(INR)/Quintal":2237,"Actual Price(INR)/Quintal":2347,"Percentage Difference":"4.69%","YEAR":2020},
{ "Year : Month Week#":"JanWk3","Predicted Price(INR)/Quintal":2102,"Actual Price(INR)/Quintal":2180,"Percentage Difference":"3.58%","YEAR":2020},
{ "Year : Month Week#":"JanWk4","Predicted Price(INR)/Quintal":2165,"Actual Price(INR)/Quintal":2117,"Percentage Difference":"-2.27%","YEAR":2020},
{ "Year : Month Week#":"FebWk1","Predicted Price(INR)/Quintal":2144,"Actual Price(INR)/Quintal":2096,"Percentage Difference":"-2.29%","YEAR":2020},
{ "Year : Month Week#":"FebWk2","Predicted Price(INR)/Quintal":2151,"Actual Price(INR)/Quintal":2162,"Percentage Difference":"0.51%","YEAR":2020},
{ "Year : Month Week#":"FebWk3","Predicted Price(INR)/Quintal":2160,"Actual Price(INR)/Quintal":1997,"Percentage Difference":"-8.16%","YEAR":2020},
{ "Year : Month Week#":"FebWk4","Predicted Price(INR)/Quintal":2053,"Actual Price(INR)/Quintal":2070,"Percentage Difference":"0.82%","YEAR":2020},
{ "Year : Month Week#":"MarWk1","Predicted Price(INR)/Quintal":2055,"Actual Price(INR)/Quintal":1946,"Percentage Difference":"-5.60%","YEAR":2020},
{ "Year : Month Week#":"MarWk2","Predicted Price(INR)/Quintal":1843,"Actual Price(INR)/Quintal":1855,"Percentage Difference":"0.65%","YEAR":2020},
{ "Year : Month Week#":"MarWk3","Predicted Price(INR)/Quintal":1872,"Actual Price(INR)/Quintal":1789,"Percentage Difference":"-4.64%","YEAR":2020},
{ "Year : Month Week#":"MarWk4","Predicted Price(INR)/Quintal":1793,"Actual Price(INR)/Quintal":1840,"Percentage Difference":"2.55%","YEAR":2020},
{ "Year : Month Week#":"AprWk1","Predicted Price(INR)/Quintal":1852,"Actual Price(INR)/Quintal":1864,"Percentage Difference":"0.64%","YEAR":2020},
{ "Year : Month Week#":"AprWk2","Predicted Price(INR)/Quintal":1877,"Actual Price(INR)/Quintal":1960,"Percentage Difference":"4.23%","YEAR":2020},
{ "Year : Month Week#":"AprWk3","Predicted Price(INR)/Quintal":1908,"Actual Price(INR)/Quintal":2002,"Percentage Difference":"4.70%","YEAR":2020},
{ "Year : Month Week#":"AprWk4","Predicted Price(INR)/Quintal":2065,"Actual Price(INR)/Quintal":2041,"Percentage Difference":"-1.18%","YEAR":2020},
{ "Year : Month Week#":"MayWk1","Predicted Price(INR)/Quintal":1949,"Actual Price(INR)/Quintal":2008,"Percentage Difference":"2.94%","YEAR":2020},
{ "Year : Month Week#":"MayWk2","Predicted Price(INR)/Quintal":1942,"Actual Price(INR)/Quintal":1994,"Percentage Difference":"2.61%","YEAR":2020},
{ "Year : Month Week#":"MayWk3","Predicted Price(INR)/Quintal":1959,"Actual Price(INR)/Quintal":2021,"Percentage Difference":"3.07%","YEAR":2020},
{ "Year : Month Week#":"MayWk4","Predicted Price(INR)/Quintal":1972,"Actual Price(INR)/Quintal":1890,"Percentage Difference":"-4.34%","YEAR":2020},
{ "Year : Month Week#":"JunWk1","Predicted Price(INR)/Quintal":1877,"Actual Price(INR)/Quintal":1883,"Percentage Difference":"0.32%","YEAR":2020},
{ "Year : Month Week#":"JunWk2","Predicted Price(INR)/Quintal":1855,"Actual Price(INR)/Quintal":1900,"Percentage Difference":"2.37%","YEAR":2020},
{ "Year : Month Week#":"JunWk3","Predicted Price(INR)/Quintal":1846,"Actual Price(INR)/Quintal":1899,"Percentage Difference":"2.79%","YEAR":2020},
{ "Year : Month Week#":"JunWk4","Predicted Price(INR)/Quintal":1965,"Actual Price(INR)/Quintal":2022,"Percentage Difference":"2.82%","YEAR":2020},
{ "Year : Month Week#":"JulWk1","Predicted Price(INR)/Quintal":1946,"Actual Price(INR)/Quintal":1987,"Percentage Difference":"2.06%","YEAR":2020},
{ "Year : Month Week#":"JulWk2","Predicted Price(INR)/Quintal":1949,"Actual Price(INR)/Quintal":1950,"Percentage Difference":"0.05%","YEAR":2020},
{ "Year : Month Week#":"JulWk3","Predicted Price(INR)/Quintal":1967,"Actual Price(INR)/Quintal":1955,"Percentage Difference":"-0.61%","YEAR":2020},
{ "Year : Month Week#":"JulWk4","Predicted Price(INR)/Quintal":1956,"Actual Price(INR)/Quintal":1986,"Percentage Difference":"1.51%","YEAR":2020},
{ "Year : Month Week#":"AugWk1","Predicted Price(INR)/Quintal":1954,"Actual Price(INR)/Quintal":1999,"Percentage Difference":"2.25%","YEAR":2020},
{ "Year : Month Week#":"AugWk2","Predicted Price(INR)/Quintal":1944,"Actual Price(INR)/Quintal":1882,"Percentage Difference":"-3.29%","YEAR":2020},
{ "Year : Month Week#":"AugWk3","Predicted Price(INR)/Quintal":1869,"Actual Price(INR)/Quintal":1808,"Percentage Difference":"-3.37%","YEAR":2020},
{ "Year : Month Week#":"AugWk4","Predicted Price(INR)/Quintal":1802,"Actual Price(INR)/Quintal":1840,"Percentage Difference":"2.07%","YEAR":2020},
{ "Year : Month Week#":"SepWk1","Predicted Price(INR)/Quintal":1801,"Actual Price(INR)/Quintal":1858,"Percentage Difference":"3.07%","YEAR":2020},
{ "Year : Month Week#":"SepWk2","Predicted Price(INR)/Quintal":1805,"Actual Price(INR)/Quintal":1815,"Percentage Difference":"0.55%","YEAR":2020},
{ "Year : Month Week#":"SepWk3","Predicted Price(INR)/Quintal":1780,"Actual Price(INR)/Quintal":1788,"Percentage Difference":"0.45%","YEAR":2020},
{ "Year : Month Week#":"SepWk4","Predicted Price(INR)/Quintal":1768,"Actual Price(INR)/Quintal":1782,"Percentage Difference":"0.79%","YEAR":2020},
{ "Year : Month Week#":"OctWk1","Predicted Price(INR)/Quintal":1790,"Actual Price(INR)/Quintal":1764,"Percentage Difference":"-1.47%","YEAR":2020},
{ "Year : Month Week#":"OctWk2","Predicted Price(INR)/Quintal":1693,"Actual Price(INR)/Quintal":1786,"Percentage Difference":"5.21%","YEAR":2020},
{ "Year : Month Week#":"OctWk3","Predicted Price(INR)/Quintal":1826,"Actual Price(INR)/Quintal":1907,"Percentage Difference":"4.25%","YEAR":2020},
{ "Year : Month Week#":"OctWk4","Predicted Price(INR)/Quintal":1909,"Actual Price(INR)/Quintal":2001,"Percentage Difference":"4.60%","YEAR":2020},
{ "Year : Month Week#":"NovWk1","Predicted Price(INR)/Quintal":2007,"Actual Price(INR)/Quintal":2109,"Percentage Difference":"4.84%","YEAR":2020},
{ "Year : Month Week#":"NovWk2","Predicted Price(INR)/Quintal":2106,"Actual Price(INR)/Quintal":2031,"Percentage Difference":"-3.69%","YEAR":2020},
{ "Year : Month Week#":"NovWk3","Predicted Price(INR)/Quintal":1927,"Actual Price(INR)/Quintal":1944,"Percentage Difference":"0.87%","YEAR":2020},
{ "Year : Month Week#":"NovWk4","Predicted Price(INR)/Quintal":1915,"Actual Price(INR)/Quintal":1897,"Percentage Difference":"-0.95%","YEAR":2020},
{ "Year : Month Week#":"DecWk1","Predicted Price(INR)/Quintal":1907,"Actual Price(INR)/Quintal":1975,"Percentage Difference":"3.44%","YEAR":2020},
{ "Year : Month Week#":"DecWk2","Predicted Price(INR)/Quintal":1904,"Actual Price(INR)/Quintal":1955,"Percentage Difference":"2.61%","YEAR":2020},
{ "Year : Month Week#":"DecWk3","Predicted Price(INR)/Quintal":1905,"Actual Price(INR)/Quintal":1880,"Percentage Difference":"-1.33%","YEAR":2020},
{ "Year : Month Week#":"DecWk4","Predicted Price(INR)/Quintal":1920,"Actual Price(INR)/Quintal":1970,"Percentage Difference":"2.54%","YEAR":2020},
  {
    "Year : Month Week#": "JanWk1",
    "Predicted Price(INR)/Quintal": 1637,
    "Actual Price(INR)/Quintal": 1650,
    "Percentage Difference": "0.79%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "JanWk2",
    "Predicted Price(INR)/Quintal": 1608,
    "Actual Price(INR)/Quintal": 1706,
    "Percentage Difference": "5.74%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "JanWk3",
    "Predicted Price(INR)/Quintal": 1713,
    "Actual Price(INR)/Quintal": 1787,
    "Percentage Difference": "4.14%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "JanWk4",
    "Predicted Price(INR)/Quintal": 1790,
    "Actual Price(INR)/Quintal": 1798,
    "Percentage Difference": "0.44%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "FebWk1",
    "Predicted Price(INR)/Quintal": 1770,
    "Actual Price(INR)/Quintal": 1738,
    "Percentage Difference": "-1.84%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "FebWk2",
    "Predicted Price(INR)/Quintal": 1725,
    "Actual Price(INR)/Quintal": 1809,
    "Percentage Difference": "4.64%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "FebWk3",
    "Predicted Price(INR)/Quintal": 1842,
    "Actual Price(INR)/Quintal": 1865,
    "Percentage Difference": "1.23%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "FebWk4",
    "Predicted Price(INR)/Quintal": 1836,
    "Actual Price(INR)/Quintal": 1878,
    "Percentage Difference": "2.24%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "MarWk1",
    "Predicted Price(INR)/Quintal": 1835,
    "Actual Price(INR)/Quintal": 1843,
    "Percentage Difference": "0.43%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "MarWk2",
    "Predicted Price(INR)/Quintal": 1826,
    "Actual Price(INR)/Quintal": 1873,
    "Percentage Difference": "2.51%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "MarWk3",
    "Predicted Price(INR)/Quintal": 1846,
    "Actual Price(INR)/Quintal": 1929,
    "Percentage Difference": "4.30%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "MarWk4",
    "Predicted Price(INR)/Quintal": 1948,
    "Actual Price(INR)/Quintal": 1951,
    "Percentage Difference": "0.15%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "AprWk1",
    "Predicted Price(INR)/Quintal": 1918,
    "Actual Price(INR)/Quintal": 1961,
    "Percentage Difference": "0.15%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "AprWk2",
    "Predicted Price(INR)/Quintal": 1919,
    "Actual Price(INR)/Quintal": 2002,
    "Percentage Difference": "4.15%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "AprWk3",
    "Predicted Price(INR)/Quintal": 1950,
    "Actual Price(INR)/Quintal": 1957,
    "Percentage Difference": "0.36%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "AprWk4",
    "Predicted Price(INR)/Quintal": 1955,
    "Actual Price(INR)/Quintal": 2049,
    "Percentage Difference": "4.59%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "MayWk1",
    "Predicted Price(INR)/Quintal": 2079,
    "Actual Price(INR)/Quintal": 2193,
    "Percentage Difference": "5.20%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "MayWk2",
    "Predicted Price(INR)/Quintal": 2108,
    "Actual Price(INR)/Quintal": 2035,
    "Percentage Difference": "-3.59%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "MayWk3",
    "Predicted Price(INR)/Quintal": 2111,
    "Actual Price(INR)/Quintal": 2119,
    "Percentage Difference": "0.38%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "MayWk4",
    "Predicted Price(INR)/Quintal": 2097,
    "Actual Price(INR)/Quintal": 2131,
    "Percentage Difference": "1.60%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "JunWk1",
    "Predicted Price(INR)/Quintal": 2134,
    "Actual Price(INR)/Quintal": 1995,
    "Percentage Difference": "-6.97%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "JunWk2",
    "Predicted Price(INR)/Quintal": 2036,
    "Actual Price(INR)/Quintal": 2136,
    "Percentage Difference": "4.68%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "JunWk3",
    "Predicted Price(INR)/Quintal": 2028,
    "Actual Price(INR)/Quintal": 2102,
    "Percentage Difference": "3.52%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "JunWk4",
    "Predicted Price(INR)/Quintal": 2129,
    "Actual Price(INR)/Quintal": 2186,
    "Percentage Difference": "2.61%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "JulWk1",
    "Predicted Price(INR)/Quintal": 2111,
    "Actual Price(INR)/Quintal": 2146,
    "Percentage Difference": "1.63%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "JulWk2",
    "Predicted Price(INR)/Quintal": 2113,
    "Actual Price(INR)/Quintal": 2140,
    "Percentage Difference": "1.26%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "JulWk3",
    "Predicted Price(INR)/Quintal": 2136,
    "Actual Price(INR)/Quintal": 2204,
    "Percentage Difference": "3.09%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "JulWk4",
    "Predicted Price(INR)/Quintal": 2232,
    "Actual Price(INR)/Quintal": 2303,
    "Percentage Difference": "3.08%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "AugWk1",
    "Predicted Price(INR)/Quintal": 2205,
    "Actual Price(INR)/Quintal": 2272,
    "Percentage Difference": "2.95%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "AugWk2",
    "Predicted Price(INR)/Quintal": 2283,
    "Actual Price(INR)/Quintal": 2250,
    "Percentage Difference": "-1.47%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "AugWk3",
    "Predicted Price(INR)/Quintal": 2186,
    "Actual Price(INR)/Quintal": 2115,
    "Percentage Difference": "-3.36%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "AugWk4",
    "Predicted Price(INR)/Quintal": 2105,
    "Actual Price(INR)/Quintal": 2051,
    "Percentage Difference": "-2.63%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "SepWk1",
    "Predicted Price(INR)/Quintal": 2033,
    "Actual Price(INR)/Quintal": 2030,
    "Percentage Difference": "-0.15%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "SepWk2",
    "Predicted Price(INR)/Quintal": 2031,
    "Actual Price(INR)/Quintal": 2130,
    "Percentage Difference": "4.65%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "SepWk3",
    "Predicted Price(INR)/Quintal": 2132,
    "Actual Price(INR)/Quintal": 2214,
    "Percentage Difference": "3.70%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "SepWk4",
    "Predicted Price(INR)/Quintal": 2130,
    "Actual Price(INR)/Quintal": 2050,
    "Percentage Difference": "-3.90%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "OctWk1",
    "Predicted Price(INR)/Quintal": 2023,
    "Actual Price(INR)/Quintal": 2025,
    "Percentage Difference": "0.10%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "OctWk2",
    "Predicted Price(INR)/Quintal": 2025,
    "Actual Price(INR)/Quintal": 1920,
    "Percentage Difference": "-5.47%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "OctWk3",
    "Predicted Price(INR)/Quintal": 2032,
    "Actual Price(INR)/Quintal": 1892,
    "Percentage Difference": "-7.40%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "OctWk4",
    "Predicted Price(INR)/Quintal": 1803,
    "Actual Price(INR)/Quintal": 1825,
    "Percentage Difference": "1.21%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "NovWk1",
    "Predicted Price(INR)/Quintal": 1839,
    "Actual Price(INR)/Quintal": 1850,
    "Percentage Difference": "0.59%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "NovWk2",
    "Predicted Price(INR)/Quintal": 1822,
    "Actual Price(INR)/Quintal": 1820,
    "Percentage Difference": "-0.11%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "NovWk3",
    "Predicted Price(INR)/Quintal": 1836,
    "Actual Price(INR)/Quintal": 1900,
    "Percentage Difference": "3.37%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "NovWk4",
    "Predicted Price(INR)/Quintal": 1870,
    "Actual Price(INR)/Quintal": 1900,
    "Percentage Difference": "1.58%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "DecWk1",
    "Predicted Price(INR)/Quintal": 1880,
    "Actual Price(INR)/Quintal": 1862,
    "Percentage Difference": "-0.97%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "DecWk2",
    "Predicted Price(INR)/Quintal": 1818,
    "Actual Price(INR)/Quintal": 1875,
    "Percentage Difference": "3.04%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "DecWk3",
    "Predicted Price(INR)/Quintal": 1828,
    "Actual Price(INR)/Quintal": 1896,
    "Percentage Difference": "3.59%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "DecWk4",
    "Predicted Price(INR)/Quintal": 1905,
    "Actual Price(INR)/Quintal": 1950,
    "Percentage Difference": "2.31%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "JanWk1",
    "Predicted Price(INR)/Quintal": 1164,
    "Actual Price(INR)/Quintal": 1222,
    "Percentage Difference": "4.75%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "JanWk2",
    "Predicted Price(INR)/Quintal": 1214,
    "Actual Price(INR)/Quintal": 1266,
    "Percentage Difference": "4.11%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "JanWk3",
    "Predicted Price(INR)/Quintal": 1298,
    "Actual Price(INR)/Quintal": 1293,
    "Percentage Difference": "-0.39%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "JanWk4",
    "Predicted Price(INR)/Quintal": 1294,
    "Actual Price(INR)/Quintal": 1304,
    "Percentage Difference": "0.77%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "FebWk1",
    "Predicted Price(INR)/Quintal": 1296,
    "Actual Price(INR)/Quintal": 1305,
    "Percentage Difference": "0.69%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "FebWk2",
    "Predicted Price(INR)/Quintal": 1316,
    "Actual Price(INR)/Quintal": 1289,
    "Percentage Difference": "-2.09%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "FebWk3",
    "Predicted Price(INR)/Quintal": 1290,
    "Actual Price(INR)/Quintal": 1311,
    "Percentage Difference": "1.60%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "FebWk4",
    "Predicted Price(INR)/Quintal": 1293,
    "Actual Price(INR)/Quintal": 1269,
    "Percentage Difference": "-1.89%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "MarWk1",
    "Predicted Price(INR)/Quintal": 1276,
    "Actual Price(INR)/Quintal": 1288,
    "Percentage Difference": "0.93%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "MarWk2",
    "Predicted Price(INR)/Quintal": 1293,
    "Actual Price(INR)/Quintal": 1290,
    "Percentage Difference": "-0.23%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "MarWk3",
    "Predicted Price(INR)/Quintal": 1307,
    "Actual Price(INR)/Quintal": 1304,
    "Percentage Difference": "-0.23%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "MarWk4",
    "Predicted Price(INR)/Quintal": 1324,
    "Actual Price(INR)/Quintal": 1343,
    "Percentage Difference": "1.41%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "AprWk1",
    "Predicted Price(INR)/Quintal": 1361,
    "Actual Price(INR)/Quintal": 1333,
    "Percentage Difference": "-2.10%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "AprWk2",
    "Predicted Price(INR)/Quintal": 1317,
    "Actual Price(INR)/Quintal": 1347,
    "Percentage Difference": "2.23%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "AprWk3",
    "Predicted Price(INR)/Quintal": 1343,
    "Actual Price(INR)/Quintal": 1335,
    "Percentage Difference": "-0.60%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "AprWk4",
    "Predicted Price(INR)/Quintal": 1265,
    "Actual Price(INR)/Quintal": 1278,
    "Percentage Difference": "1.02%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "MayWk1",
    "Predicted Price(INR)/Quintal": 1252,
    "Actual Price(INR)/Quintal": 1318,
    "Percentage Difference": "5.01%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "MayWk2",
    "Predicted Price(INR)/Quintal": 1305,
    "Actual Price(INR)/Quintal": 1300,
    "Percentage Difference": "-0.38%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "MayWk3",
    "Predicted Price(INR)/Quintal": 1304,
    "Actual Price(INR)/Quintal": 1251,
    "Percentage Difference": "-4.24%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "MayWk4",
    "Predicted Price(INR)/Quintal": 1302,
    "Actual Price(INR)/Quintal": 1270,
    "Percentage Difference": "-2.52%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "JunWk1",
    "Predicted Price(INR)/Quintal": 1202,
    "Actual Price(INR)/Quintal": 1269,
    "Percentage Difference": "5.28%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "JunWk2",
    "Predicted Price(INR)/Quintal": 1238,
    "Actual Price(INR)/Quintal": 1271,
    "Percentage Difference": "2.60%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "JunWk3",
    "Predicted Price(INR)/Quintal": 1242,
    "Actual Price(INR)/Quintal": 1246,
    "Percentage Difference": "0.32%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "JunWk4",
    "Predicted Price(INR)/Quintal": 1236,
    "Actual Price(INR)/Quintal": 1238,
    "Percentage Difference": "0.16%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "JulWk1",
    "Predicted Price(INR)/Quintal": 1249,
    "Actual Price(INR)/Quintal": 1271,
    "Percentage Difference": "1.73%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "JulWk2",
    "Predicted Price(INR)/Quintal": 1279,
    "Actual Price(INR)/Quintal": 1220,
    "Percentage Difference": "-4.84%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "JulWk3",
    "Predicted Price(INR)/Quintal": 1229,
    "Actual Price(INR)/Quintal": 1285,
    "Percentage Difference": "4.36%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "JulWk4",
    "Predicted Price(INR)/Quintal": 1265,
    "Actual Price(INR)/Quintal": 1307,
    "Percentage Difference": "3.21%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "AugWk1",
    "Predicted Price(INR)/Quintal": 1277,
    "Actual Price(INR)/Quintal": 1245,
    "Percentage Difference": "-2.57%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "AugWk2",
    "Predicted Price(INR)/Quintal": 1257,
    "Actual Price(INR)/Quintal": 1236,
    "Percentage Difference": "-1.70%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "AugWk3",
    "Predicted Price(INR)/Quintal": 1255,
    "Actual Price(INR)/Quintal": 1280,
    "Percentage Difference": "1.95%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "AugWk4",
    "Predicted Price(INR)/Quintal": 1275,
    "Actual Price(INR)/Quintal": 1289,
    "Percentage Difference": "1.09%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "SepWk1",
    "Predicted Price(INR)/Quintal": 1284,
    "Actual Price(INR)/Quintal": 1295,
    "Percentage Difference": "0.85%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "SepWk2",
    "Predicted Price(INR)/Quintal": 1294,
    "Actual Price(INR)/Quintal": 1306,
    "Percentage Difference": "0.92%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "SepWk3",
    "Predicted Price(INR)/Quintal": 1290,
    "Actual Price(INR)/Quintal": 1332,
    "Percentage Difference": "3.15%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "SepWk4",
    "Predicted Price(INR)/Quintal": 1334,
    "Actual Price(INR)/Quintal": 1343,
    "Percentage Difference": "0.67%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "OctWk1",
    "Predicted Price(INR)/Quintal": 1359,
    "Actual Price(INR)/Quintal": 1301,
    "Percentage Difference": "-4.46%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "OctWk2",
    "Predicted Price(INR)/Quintal": 1331,
    "Actual Price(INR)/Quintal": 1294,
    "Percentage Difference": "-2.86%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "OctWk3",
    "Predicted Price(INR)/Quintal": 1303,
    "Actual Price(INR)/Quintal": 1315,
    "Percentage Difference": "0.91%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "OctWk4",
    "Predicted Price(INR)/Quintal": 1301,
    "Actual Price(INR)/Quintal": 1368,
    "Percentage Difference": "4.90%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "NovWk1",
    "Predicted Price(INR)/Quintal": 1463,
    "Actual Price(INR)/Quintal": 1488,
    "Percentage Difference": "1.68%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "NovWk2",
    "Predicted Price(INR)/Quintal": 1471,
    "Actual Price(INR)/Quintal": 1516,
    "Percentage Difference": "2.97%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "NovWk3",
    "Predicted Price(INR)/Quintal": 1484,
    "Actual Price(INR)/Quintal": 1561,
    "Percentage Difference": "4.93%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "NovWk4",
    "Predicted Price(INR)/Quintal": 1676,
    "Actual Price(INR)/Quintal": 1761,
    "Percentage Difference": "4.83%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "DecWk1",
    "Predicted Price(INR)/Quintal": 1751,
    "Actual Price(INR)/Quintal": 1647,
    "Percentage Difference": "-6.31%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "DecWk2",
    "Predicted Price(INR)/Quintal": 1457,
    "Actual Price(INR)/Quintal": 1602,
    "Percentage Difference": "9.05%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "DecWk3",
    "Predicted Price(INR)/Quintal": 1664,
    "Actual Price(INR)/Quintal": 1745,
    "Percentage Difference": "4.64%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "DecWk4",
    "Predicted Price(INR)/Quintal": 1662,
    "Actual Price(INR)/Quintal": 1658,
    "Percentage Difference": "-0.24%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "Jan Wk1",
    "Predicted Price(INR)/Quintal": 1821,
    "Actual Price(INR)/Quintal": 1884,
    "Percentage Difference": "3.34%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Jan Wk2",
    "Predicted Price(INR)/Quintal": 1889,
    "Actual Price(INR)/Quintal": 1764,
    "Percentage Difference": "-7.09%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Jan Wk3",
    "Predicted Price(INR)/Quintal": 1650,
    "Actual Price(INR)/Quintal": 1695,
    "Percentage Difference": "2.65%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Jan Wk4",
    "Predicted Price(INR)/Quintal": 1682,
    "Actual Price(INR)/Quintal": 1690,
    "Percentage Difference": "0.47%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Feb Wk1",
    "Predicted Price(INR)/Quintal": 1693,
    "Actual Price(INR)/Quintal": 1630,
    "Percentage Difference": "-3.86%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Feb Wk2",
    "Predicted Price(INR)/Quintal": 1607,
    "Actual Price(INR)/Quintal": 1683,
    "Percentage Difference": "4.52%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Feb Wk3",
    "Predicted Price(INR)/Quintal": 1638,
    "Actual Price(INR)/Quintal": 1696,
    "Percentage Difference": "3.42%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Feb Wk4",
    "Predicted Price(INR)/Quintal": 1641,
    "Actual Price(INR)/Quintal": 1711,
    "Percentage Difference": "4.09%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Mar Wk1",
    "Predicted Price(INR)/Quintal": 1707,
    "Actual Price(INR)/Quintal": 1709,
    "Percentage Difference": "0.12%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Mar Wk2",
    "Predicted Price(INR)/Quintal": 1687,
    "Actual Price(INR)/Quintal": 1771,
    "Percentage Difference": "4.74%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Mar Wk3",
    "Predicted Price(INR)/Quintal": 1704,
    "Actual Price(INR)/Quintal": 1793,
    "Percentage Difference": "4.96%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Mar Wk4",
    "Predicted Price(INR)/Quintal": 1710,
    "Actual Price(INR)/Quintal": 1730,
    "Percentage Difference": "1.16%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Apr Wk1",
    "Predicted Price(INR)/Quintal": 1695,
    "Actual Price(INR)/Quintal": 1736,
    "Percentage Difference": "2.36%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Apr Wk2",
    "Predicted Price(INR)/Quintal": 1747,
    "Actual Price(INR)/Quintal": 1703,
    "Percentage Difference": "-2.58%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Apr Wk3",
    "Predicted Price(INR)/Quintal": 1709,
    "Actual Price(INR)/Quintal": 1721,
    "Percentage Difference": "0.70%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Apr Wk4",
    "Predicted Price(INR)/Quintal": 1704,
    "Actual Price(INR)/Quintal": 1715,
    "Percentage Difference": "0.64%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "May Wk1",
    "Predicted Price(INR)/Quintal": 1708,
    "Actual Price(INR)/Quintal": 1709,
    "Percentage Difference": "0.06%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "May Wk2",
    "Predicted Price(INR)/Quintal": 1710,
    "Actual Price(INR)/Quintal": 1712,
    "Percentage Difference": "0.12%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "May Wk3",
    "Predicted Price(INR)/Quintal": 1708,
    "Actual Price(INR)/Quintal": 1719,
    "Percentage Difference": "0.64%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "May Wk4",
    "Predicted Price(INR)/Quintal": 1702,
    "Actual Price(INR)/Quintal": 1687,
    "Percentage Difference": "-0.89%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Jun Wk1",
    "Predicted Price(INR)/Quintal": 1667,
    "Actual Price(INR)/Quintal": 1722,
    "Percentage Difference": "3.19%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Jun Wk2",
    "Predicted Price(INR)/Quintal": 1707,
    "Actual Price(INR)/Quintal": 1644,
    "Percentage Difference": "-3.83%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Jun Wk3",
    "Predicted Price(INR)/Quintal": 1650,
    "Actual Price(INR)/Quintal": 1656,
    "Percentage Difference": "0.36%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Jun Wk4",
    "Predicted Price(INR)/Quintal": 1651,
    "Actual Price(INR)/Quintal": 1652,
    "Percentage Difference": "0.06%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Jul Wk1",
    "Predicted Price(INR)/Quintal": 1607,
    "Actual Price(INR)/Quintal": 1637,
    "Percentage Difference": "1.83%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Jul Wk2",
    "Predicted Price(INR)/Quintal": 1601,
    "Actual Price(INR)/Quintal": 1649,
    "Percentage Difference": "2.91%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Jul Wk3",
    "Predicted Price(INR)/Quintal": 1653,
    "Actual Price(INR)/Quintal": 1611,
    "Percentage Difference": "-2.61%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Jul Wk4",
    "Predicted Price(INR)/Quintal": 1602,
    "Actual Price(INR)/Quintal": 1677,
    "Percentage Difference": "4.47%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Aug Wk1",
    "Predicted Price(INR)/Quintal": 1657,
    "Actual Price(INR)/Quintal": 1567,
    "Percentage Difference": "-5.74%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Aug Wk2",
    "Predicted Price(INR)/Quintal": 1560,
    "Actual Price(INR)/Quintal": 1498,
    "Percentage Difference": "-4.14%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Aug Wk3",
    "Predicted Price(INR)/Quintal": 1461,
    "Actual Price(INR)/Quintal": 1438,
    "Percentage Difference": "-1.60%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Aug Wk4",
    "Predicted Price(INR)/Quintal": 1424,
    "Actual Price(INR)/Quintal": 1503,
    "Percentage Difference": "5.26%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Sep Wk1",
    "Predicted Price(INR)/Quintal": 1471,
    "Actual Price(INR)/Quintal": 1559,
    "Percentage Difference": "5.64%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Sep Wk2",
    "Predicted Price(INR)/Quintal": 1578,
    "Actual Price(INR)/Quintal": 1589,
    "Percentage Difference": "0.69%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Sep Wk3",
    "Predicted Price(INR)/Quintal": 1569,
    "Actual Price(INR)/Quintal": 1594,
    "Percentage Difference": "1.57%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Sep Wk4",
    "Predicted Price(INR)/Quintal": 1587,
    "Actual Price(INR)/Quintal": 1535,
    "Percentage Difference": "-3.39%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Oct Wk1",
    "Predicted Price(INR)/Quintal": 1498,
    "Actual Price(INR)/Quintal": 1425,
    "Percentage Difference": "-5.12%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Oct Wk2",
    "Predicted Price(INR)/Quintal": 1391,
    "Actual Price(INR)/Quintal": 1453,
    "Percentage Difference": "4.27%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Oct Wk3",
    "Predicted Price(INR)/Quintal": 1397,
    "Actual Price(INR)/Quintal": 1328,
    "Percentage Difference": "-5.20%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Oct Wk4",
    "Predicted Price(INR)/Quintal": 1290,
    "Actual Price(INR)/Quintal": 1326,
    "Percentage Difference": "2.71%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Nov Wk1",
    "Predicted Price(INR)/Quintal": 1322,
    "Actual Price(INR)/Quintal": 1330,
    "Percentage Difference": "0.60%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Nov Wk2",
    "Predicted Price(INR)/Quintal": 1377,
    "Actual Price(INR)/Quintal": 1335,
    "Percentage Difference": "-3.15%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Nov Wk3",
    "Predicted Price(INR)/Quintal": 1324,
    "Actual Price(INR)/Quintal": 1293,
    "Percentage Difference": "-2.40%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Nov Wk4",
    "Predicted Price(INR)/Quintal": 1316,
    "Actual Price(INR)/Quintal": 1208,
    "Percentage Difference": "-8.94%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Dec Wk1",
    "Predicted Price(INR)/Quintal": 1204,
    "Actual Price(INR)/Quintal": 1243,
    "Percentage Difference": "3.14%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Dec Wk2",
    "Predicted Price(INR)/Quintal": 1202,
    "Actual Price(INR)/Quintal": 1231,
    "Percentage Difference": "2.36%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Dec Wk3",
    "Predicted Price(INR)/Quintal": 1239,
    "Actual Price(INR)/Quintal": 1194,
    "Percentage Difference": "-3.77%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Dec Wk4",
    "Predicted Price(INR)/Quintal": 1172,
    "Actual Price(INR)/Quintal": 1166,
    "Percentage Difference": "-0.51%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Jan Wk1",
    "Predicted Price(INR)/Quintal": 1409,
    "Actual Price(INR)/Quintal": 1465,
    "Percentage Difference": "3.82%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Jan Wk2",
    "Predicted Price(INR)/Quintal": 1428,
    "Actual Price(INR)/Quintal": 1468,
    "Percentage Difference": "2.72%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Jan Wk3",
    "Predicted Price(INR)/Quintal": 1405,
    "Actual Price(INR)/Quintal": 1408,
    "Percentage Difference": "0.21%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Jan Wk4",
    "Predicted Price(INR)/Quintal": 1426,
    "Actual Price(INR)/Quintal": 1376,
    "Percentage Difference": "-3.63%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Feb Wk1",
    "Predicted Price(INR)/Quintal": 1388,
    "Actual Price(INR)/Quintal": 1319,
    "Percentage Difference": "-5.23%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Feb Wk2",
    "Predicted Price(INR)/Quintal": 1451,
    "Actual Price(INR)/Quintal": 1396,
    "Percentage Difference": "-3.94%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Feb Wk3",
    "Predicted Price(INR)/Quintal": 1429,
    "Actual Price(INR)/Quintal": 1383,
    "Percentage Difference": "-0.55%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Feb Wk4",
    "Predicted Price(INR)/Quintal": 1427,
    "Actual Price(INR)/Quintal": 1438,
    "Percentage Difference": "0.76%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Mar Wk1",
    "Predicted Price(INR)/Quintal": 1404,
    "Actual Price(INR)/Quintal": 1419,
    "Percentage Difference": "1.06%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Mar Wk2",
    "Predicted Price(INR)/Quintal": 1429,
    "Actual Price(INR)/Quintal": 1560,
    "Percentage Difference": "8.40%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Mar Wk3",
    "Predicted Price(INR)/Quintal": 1534,
    "Actual Price(INR)/Quintal": 1557,
    "Percentage Difference": "1.48%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Mar Wk4",
    "Predicted Price(INR)/Quintal": 1556,
    "Actual Price(INR)/Quintal": 1549,
    "Percentage Difference": "-0.45%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Apr Wk1",
    "Predicted Price(INR)/Quintal": 1567,
    "Actual Price(INR)/Quintal": 1556,
    "Percentage Difference": "-0.71%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Apr Wk2",
    "Predicted Price(INR)/Quintal": 1551,
    "Actual Price(INR)/Quintal": 1525,
    "Percentage Difference": "-1.71%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Apr Wk3",
    "Predicted Price(INR)/Quintal": 1558,
    "Actual Price(INR)/Quintal": 1491,
    "Percentage Difference": "-4.49%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Apr Wk4",
    "Predicted Price(INR)/Quintal": 1476,
    "Actual Price(INR)/Quintal": 1494,
    "Percentage Difference": "1.20%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "May Wk1",
    "Predicted Price(INR)/Quintal": 1472,
    "Actual Price(INR)/Quintal": 1449,
    "Percentage Difference": "-1.59%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "May Wk2",
    "Predicted Price(INR)/Quintal": 1475,
    "Actual Price(INR)/Quintal": 1445,
    "Percentage Difference": "-2.08%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "May Wk3",
    "Predicted Price(INR)/Quintal": 1465,
    "Actual Price(INR)/Quintal": 1406,
    "Percentage Difference": "-4.20%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "May Wk4",
    "Predicted Price(INR)/Quintal": 1442,
    "Actual Price(INR)/Quintal": 1493,
    "Percentage Difference": "3.42%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Jun Wk1",
    "Predicted Price(INR)/Quintal": 1435,
    "Actual Price(INR)/Quintal": 1425,
    "Percentage Difference": "-0.70%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Jun Wk2",
    "Predicted Price(INR)/Quintal": 1477,
    "Actual Price(INR)/Quintal": 1399,
    "Percentage Difference": "-5.57%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Jun Wk3",
    "Predicted Price(INR)/Quintal": 1397,
    "Actual Price(INR)/Quintal": 1503,
    "Percentage Difference": "7.05%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Jun Wk4",
    "Predicted Price(INR)/Quintal": 1485,
    "Actual Price(INR)/Quintal": 1536,
    "Percentage Difference": "3.32%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Jul Wk1",
    "Predicted Price(INR)/Quintal": 1536,
    "Actual Price(INR)/Quintal": 1606,
    "Percentage Difference": "4.36%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Jul Wk2",
    "Predicted Price(INR)/Quintal": 1545,
    "Actual Price(INR)/Quintal": 1616,
    "Percentage Difference": "4.39%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Jul Wk3",
    "Predicted Price(INR)/Quintal": 1650,
    "Actual Price(INR)/Quintal": 1559,
    "Percentage Difference": "-5.84%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Jul Wk4",
    "Predicted Price(INR)/Quintal": 1568,
    "Actual Price(INR)/Quintal": 1647,
    "Percentage Difference": "4.80%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Aug Wk1",
    "Predicted Price(INR)/Quintal": 1618,
    "Actual Price(INR)/Quintal": 1625,
    "Percentage Difference": "0.43%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Aug Wk2",
    "Predicted Price(INR)/Quintal": 1605,
    "Actual Price(INR)/Quintal": 1677,
    "Percentage Difference": "4.29%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Aug Wk3",
    "Predicted Price(INR)/Quintal": 1665,
    "Actual Price(INR)/Quintal": 1640,
    "Percentage Difference": "-1.52%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Aug Wk4",
    "Predicted Price(INR)/Quintal": 1613,
    "Actual Price(INR)/Quintal": 1596,
    "Percentage Difference": "-1.07%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Sep Wk1",
    "Predicted Price(INR)/Quintal": 1606,
    "Actual Price(INR)/Quintal": 1583,
    "Percentage Difference": "-1.45%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Sep Wk2",
    "Predicted Price(INR)/Quintal": 1589,
    "Actual Price(INR)/Quintal": 1646,
    "Percentage Difference": "3.46%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Sep Wk3",
    "Predicted Price(INR)/Quintal": 1598,
    "Actual Price(INR)/Quintal": 1681,
    "Percentage Difference": "4.94%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Sep Wk4",
    "Predicted Price(INR)/Quintal": 1588,
    "Actual Price(INR)/Quintal": 1621,
    "Percentage Difference": "2.04%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Oct Wk1",
    "Predicted Price(INR)/Quintal": 1604,
    "Actual Price(INR)/Quintal": 1553,
    "Percentage Difference": "-3.28%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Oct Wk2",
    "Predicted Price(INR)/Quintal": 1555,
    "Actual Price(INR)/Quintal": 1504,
    "Percentage Difference": "-3.39%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Oct Wk3",
    "Predicted Price(INR)/Quintal": 1529,
    "Actual Price(INR)/Quintal": 1514,
    "Percentage Difference": "-0.99%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Oct Wk4",
    "Predicted Price(INR)/Quintal": 1524,
    "Actual Price(INR)/Quintal": 1605,
    "Percentage Difference": "5.05%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Now Wk1",
    "Predicted Price(INR)/Quintal": 1575,
    "Actual Price(INR)/Quintal": 1531,
    "Percentage Difference": "-2.87%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Now Wk2",
    "Predicted Price(INR)/Quintal": 1525,
    "Actual Price(INR)/Quintal": 1582,
    "Percentage Difference": "3.60%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Now Wk3",
    "Predicted Price(INR)/Quintal": 1611,
    "Actual Price(INR)/Quintal": 1640,
    "Percentage Difference": "1.77%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Now Wk4",
    "Predicted Price(INR)/Quintal": 1626,
    "Actual Price(INR)/Quintal": 1688,
    "Percentage Difference": "3.67%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Dec Wk1",
    "Predicted Price(INR)/Quintal": 1596,
    "Actual Price(INR)/Quintal": 1680,
    "Percentage Difference": "5.00%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Dec Wk2",
    "Predicted Price(INR)/Quintal": 1746,
    "Actual Price(INR)/Quintal": 1822,
    "Percentage Difference": "4.17%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Dec Wk3",
    "Predicted Price(INR)/Quintal": 1866,
    "Actual Price(INR)/Quintal": 1699,
    "Percentage Difference": "-9.89%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Dec Wk4",
    "Predicted Price(INR)/Quintal": 1605,
    "Actual Price(INR)/Quintal": 1752,
    "Percentage Difference": "8.38%",
    "YEAR": 2016
  }
];
