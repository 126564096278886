export const Copra = [
{ "Year : Month Week#":"Janwk1","Predicted Price(INR)/Quintal":13116,"Actual Price(INR)/Quintal":13399,"Percentage Difference":"2.11%","YEAR":2025},
{ "Year : Month Week#":"Janwk2","Predicted Price(INR)/Quintal":13217,"Actual Price(INR)/Quintal":13138,"Percentage Difference":"-0.60%","YEAR":2025},
{ "Year : Month Week#":"Janwk3","Predicted Price(INR)/Quintal":12950,"Actual Price(INR)/Quintal":null,"Percentage Difference":null,"YEAR":2025},
{ "Year : Month Week#":"Janwk4","Predicted Price(INR)/Quintal":13080,"Actual Price(INR)/Quintal":null,"Percentage Difference":null,"YEAR":2025},
{ "Year : Month Week#":"Febwk1","Predicted Price(INR)/Quintal":13188,"Actual Price(INR)/Quintal":null,"Percentage Difference":null,"YEAR":2025},
{ "Year : Month Week#":"Febwk2","Predicted Price(INR)/Quintal":13459,"Actual Price(INR)/Quintal":null,"Percentage Difference":null,"YEAR":2025},
{ "Year : Month Week#":"Janwk1","Predicted Price(INR)/Quintal":8099,"Actual Price(INR)/Quintal":8447,"Percentage Difference":"4.12%","YEAR":2024},
{ "Year : Month Week#":"Janwk2","Predicted Price(INR)/Quintal":8451,"Actual Price(INR)/Quintal":8907,"Percentage Difference":"5.12%","YEAR":2024},
{ "Year : Month Week#":"Janwk3","Predicted Price(INR)/Quintal":8966,"Actual Price(INR)/Quintal":9435,"Percentage Difference":"4.97%","YEAR":2024},
{ "Year : Month Week#":"Janwk4","Predicted Price(INR)/Quintal":9729,"Actual Price(INR)/Quintal":9348,"Percentage Difference":"-4.08%","YEAR":2024},
{ "Year : Month Week#":"Febwk1","Predicted Price(INR)/Quintal":9157,"Actual Price(INR)/Quintal":9210,"Percentage Difference":"0.58%","YEAR":2024},
{ "Year : Month Week#":"Febwk2","Predicted Price(INR)/Quintal":9218,"Actual Price(INR)/Quintal":9110,"Percentage Difference":"-1.19%","YEAR":2024},
{ "Year : Month Week#":"Febwk3","Predicted Price(INR)/Quintal":9011,"Actual Price(INR)/Quintal":9000,"Percentage Difference":"-0.12%","YEAR":2024},
{ "Year : Month Week#":"Febwk4","Predicted Price(INR)/Quintal":8690,"Actual Price(INR)/Quintal":8600,"Percentage Difference":"-1.05%","YEAR":2024},
{ "Year : Month Week#":"Marwk1","Predicted Price(INR)/Quintal":8666,"Actual Price(INR)/Quintal":8520,"Percentage Difference":"-1.71%","YEAR":2024},
{ "Year : Month Week#":"Marwk2","Predicted Price(INR)/Quintal":8643,"Actual Price(INR)/Quintal":8700,"Percentage Difference":"0.66%","YEAR":2024},
{ "Year : Month Week#":"Marwk3","Predicted Price(INR)/Quintal":8627,"Actual Price(INR)/Quintal":8580,"Percentage Difference":"-0.55%","YEAR":2024},
{ "Year : Month Week#":"Marwk4","Predicted Price(INR)/Quintal":8660,"Actual Price(INR)/Quintal":8700,"Percentage Difference":"0.46%","YEAR":2024},
{ "Year : Month Week#":"Aprwk1","Predicted Price(INR)/Quintal":8685,"Actual Price(INR)/Quintal":8592,"Percentage Difference":"-1.08%","YEAR":2024},
{ "Year : Month Week#":"Aprwk2","Predicted Price(INR)/Quintal":8573,"Actual Price(INR)/Quintal":8934,"Percentage Difference":"4.04%","YEAR":2024},
{ "Year : Month Week#":"Aprwk3","Predicted Price(INR)/Quintal":8595,"Actual Price(INR)/Quintal":8680,"Percentage Difference":"0.98%","YEAR":2024},
{ "Year : Month Week#":"Aprwk4","Predicted Price(INR)/Quintal":8506,"Actual Price(INR)/Quintal":8766,"Percentage Difference":"2.97%","YEAR":2024},
{ "Year : Month Week#":"Maywk1","Predicted Price(INR)/Quintal":8561,"Actual Price(INR)/Quintal":8616,"Percentage Difference":"0.64%","YEAR":2024},
{ "Year : Month Week#":"Maywk2","Predicted Price(INR)/Quintal":8737,"Actual Price(INR)/Quintal":8685,"Percentage Difference":"-0.60%","YEAR":2024},
{ "Year : Month Week#":"Maywk3","Predicted Price(INR)/Quintal":8656,"Actual Price(INR)/Quintal":8801,"Percentage Difference":"1.65%","YEAR":2024},
{ "Year : Month Week#":"Maywk4","Predicted Price(INR)/Quintal":8708,"Actual Price(INR)/Quintal":8682,"Percentage Difference":"-0.30%","YEAR":2024},
{ "Year : Month Week#":"Junwk1","Predicted Price(INR)/Quintal":8641,"Actual Price(INR)/Quintal":8950,"Percentage Difference":"3.45%","YEAR":2024},
{ "Year : Month Week#":"Junwk2","Predicted Price(INR)/Quintal":8887,"Actual Price(INR)/Quintal":8900,"Percentage Difference":"0.15%","YEAR":2024},
{ "Year : Month Week#":"Junwk3","Predicted Price(INR)/Quintal":8789,"Actual Price(INR)/Quintal":8775,"Percentage Difference":"-0.16%","YEAR":2024},
{ "Year : Month Week#":"Junwk4","Predicted Price(INR)/Quintal":8787,"Actual Price(INR)/Quintal":8880,"Percentage Difference":"1.05%","YEAR":2024},
{ "Year : Month Week#":"Julwk1","Predicted Price(INR)/Quintal":8809,"Actual Price(INR)/Quintal":8878,"Percentage Difference":"0.78%","YEAR":2024},
{ "Year : Month Week#":"Julwk2","Predicted Price(INR)/Quintal":8862,"Actual Price(INR)/Quintal":9075,"Percentage Difference":"2.35%","YEAR":2024},
{ "Year : Month Week#":"Julwk3","Predicted Price(INR)/Quintal":8991,"Actual Price(INR)/Quintal":9034,"Percentage Difference":"0.48%","YEAR":2024},
{ "Year : Month Week#":"Julwk4","Predicted Price(INR)/Quintal":8907,"Actual Price(INR)/Quintal":8900,"Percentage Difference":"-0.08%","YEAR":2024},
{ "Year : Month Week#":"Augwk1","Predicted Price(INR)/Quintal":8994,"Actual Price(INR)/Quintal":9183,"Percentage Difference":"2.06%","YEAR":2024},
{ "Year : Month Week#":"Augwk2","Predicted Price(INR)/Quintal":9177,"Actual Price(INR)/Quintal":9400,"Percentage Difference":"2.37%","YEAR":2024},
{ "Year : Month Week#":"Augwk3","Predicted Price(INR)/Quintal":9910,"Actual Price(INR)/Quintal":10400,"Percentage Difference":"4.71%","YEAR":2024},
{ "Year : Month Week#":"Augwk4","Predicted Price(INR)/Quintal":10406,"Actual Price(INR)/Quintal":10485,"Percentage Difference":"0.75%","YEAR":2024},
{ "Year : Month Week#":"Sepwk1","Predicted Price(INR)/Quintal":10404,"Actual Price(INR)/Quintal":10560,"Percentage Difference":"1.48%","YEAR":2024},
{ "Year : Month Week#":"Sepwk2","Predicted Price(INR)/Quintal":11985,"Actual Price(INR)/Quintal":12478,"Percentage Difference":"3.95%","YEAR":2024},
{ "Year : Month Week#":"Sepwk3","Predicted Price(INR)/Quintal":12602,"Actual Price(INR)/Quintal":12797,"Percentage Difference":"1.52%","YEAR":2024},
{ "Year : Month Week#":"Sepwk4","Predicted Price(INR)/Quintal":13804,"Actual Price(INR)/Quintal":14229,"Percentage Difference":"2.99%","YEAR":2024},
{ "Year : Month Week#":"Octwk1","Predicted Price(INR)/Quintal":14284,"Actual Price(INR)/Quintal":15001,"Percentage Difference":"4.78%","YEAR":2024},
{ "Year : Month Week#":"Octwk2","Predicted Price(INR)/Quintal":14989,"Actual Price(INR)/Quintal":15145,"Percentage Difference":"1.03%","YEAR":2024},
{ "Year : Month Week#":"Octwk3","Predicted Price(INR)/Quintal":14906,"Actual Price(INR)/Quintal":14542,"Percentage Difference":"-2.50%","YEAR":2024},
{ "Year : Month Week#":"Octwk4","Predicted Price(INR)/Quintal":13064,"Actual Price(INR)/Quintal":13316,"Percentage Difference":"1.89%","YEAR":2024},
{ "Year : Month Week#":"Novwk1","Predicted Price(INR)/Quintal":13008,"Actual Price(INR)/Quintal":13166,"Percentage Difference":"1.20%","YEAR":2024},
{ "Year : Month Week#":"Novwk2","Predicted Price(INR)/Quintal":13224,"Actual Price(INR)/Quintal":13543,"Percentage Difference":"2.36%","YEAR":2024},
{ "Year : Month Week#":"Novwk3","Predicted Price(INR)/Quintal":13506,"Actual Price(INR)/Quintal":13627,"Percentage Difference":"0.89%","YEAR":2024},
{ "Year : Month Week#":"Novwk4","Predicted Price(INR)/Quintal":13166,"Actual Price(INR)/Quintal":13003,"Percentage Difference":"-1.25%","YEAR":2024},
{ "Year : Month Week#":"Decwk1","Predicted Price(INR)/Quintal":12813,"Actual Price(INR)/Quintal":12900,"Percentage Difference":"0.67%","YEAR":2024},
{ "Year : Month Week#":"Decwk2","Predicted Price(INR)/Quintal":13294,"Actual Price(INR)/Quintal":12904,"Percentage Difference":"-3.02%","YEAR":2024},
{ "Year : Month Week#":"Decwk3","Predicted Price(INR)/Quintal":13079,"Actual Price(INR)/Quintal":13603,"Percentage Difference":"3.85%","YEAR":2024},
{ "Year : Month Week#":"Decwk4","Predicted Price(INR)/Quintal":13056,"Actual Price(INR)/Quintal":13001,"Percentage Difference":"-0.42%","YEAR":2024},
{ "Year : Month Week#":"Janwk1","Predicted Price(INR)/Quintal":11361,"Actual Price(INR)/Quintal":11487,"Percentage Difference":"1.10%","YEAR":2023},
{ "Year : Month Week#":"Janwk2","Predicted Price(INR)/Quintal":11756,"Actual Price(INR)/Quintal":11270,"Percentage Difference":"-4.31%","YEAR":2023},
{ "Year : Month Week#":"Janwk3","Predicted Price(INR)/Quintal":11267,"Actual Price(INR)/Quintal":11031,"Percentage Difference":"-2.14%","YEAR":2023},
{ "Year : Month Week#":"Janwk4","Predicted Price(INR)/Quintal":11051,"Actual Price(INR)/Quintal":10885,"Percentage Difference":"-1.53%","YEAR":2023},
{ "Year : Month Week#":"Febwk1","Predicted Price(INR)/Quintal":11085,"Actual Price(INR)/Quintal":10914,"Percentage Difference":"-1.57%","YEAR":2023},
{ "Year : Month Week#":"Febwk2","Predicted Price(INR)/Quintal":10214,"Actual Price(INR)/Quintal":10316,"Percentage Difference":"0.99%","YEAR":2023},
{ "Year : Month Week#":"Febwk3","Predicted Price(INR)/Quintal":10437,"Actual Price(INR)/Quintal":10400,"Percentage Difference":"-0.36%","YEAR":2023},
{ "Year : Month Week#":"Febwk4","Predicted Price(INR)/Quintal":10386,"Actual Price(INR)/Quintal":9700,"Percentage Difference":"-7.07%","YEAR":2023},
{ "Year : Month Week#":"Marwk1","Predicted Price(INR)/Quintal":9396,"Actual Price(INR)/Quintal":9350,"Percentage Difference":"-0.49%","YEAR":2023},
{ "Year : Month Week#":"Marwk2","Predicted Price(INR)/Quintal":9394,"Actual Price(INR)/Quintal":10255,"Percentage Difference":"8.40%","YEAR":2023},
{ "Year : Month Week#":"Marwk3","Predicted Price(INR)/Quintal":9707,"Actual Price(INR)/Quintal":10000,"Percentage Difference":"2.93%","YEAR":2023},
{ "Year : Month Week#":"Marwk4","Predicted Price(INR)/Quintal":9657,"Actual Price(INR)/Quintal":9611,"Percentage Difference":"-0.48%","YEAR":2023},
{ "Year : Month Week#":"Aprwk1","Predicted Price(INR)/Quintal":9552,"Actual Price(INR)/Quintal":9233,"Percentage Difference":"-3.45%","YEAR":2023},
{ "Year : Month Week#":"Aprwk2","Predicted Price(INR)/Quintal":9270,"Actual Price(INR)/Quintal":9111,"Percentage Difference":"-1.75%","YEAR":2023},
{ "Year : Month Week#":"Aprwk3","Predicted Price(INR)/Quintal":9138,"Actual Price(INR)/Quintal":8875,"Percentage Difference":"-2.96%","YEAR":2023},
{ "Year : Month Week#":"Aprwk4","Predicted Price(INR)/Quintal":8995,"Actual Price(INR)/Quintal":8900,"Percentage Difference":"-1.07%","YEAR":2023},
{ "Year : Month Week#":"Maywk1","Predicted Price(INR)/Quintal":8958,"Actual Price(INR)/Quintal":9332,"Percentage Difference":"4.01%","YEAR":2023},
{ "Year : Month Week#":"Maywk2","Predicted Price(INR)/Quintal":9249,"Actual Price(INR)/Quintal":9550,"Percentage Difference":"3.15%","YEAR":2023},
{ "Year : Month Week#":"Maywk3","Predicted Price(INR)/Quintal":9156,"Actual Price(INR)/Quintal":9100,"Percentage Difference":"-0.62%","YEAR":2023},
{ "Year : Month Week#":"Maywk4","Predicted Price(INR)/Quintal":9086,"Actual Price(INR)/Quintal":9025,"Percentage Difference":"-0.68%","YEAR":2023},
{ "Year : Month Week#":"Junwk1","Predicted Price(INR)/Quintal":9056,"Actual Price(INR)/Quintal":8850,"Percentage Difference":"-2.33%","YEAR":2023},
{ "Year : Month Week#":"Junwk2","Predicted Price(INR)/Quintal":8621,"Actual Price(INR)/Quintal":8688,"Percentage Difference":"0.77%","YEAR":2023},
{ "Year : Month Week#":"Junwk3","Predicted Price(INR)/Quintal":8050,"Actual Price(INR)/Quintal":8020,"Percentage Difference":"-0.37%","YEAR":2023},
{ "Year : Month Week#":"Junwk4","Predicted Price(INR)/Quintal":7876,"Actual Price(INR)/Quintal":7800,"Percentage Difference":"-0.97%","YEAR":2023},
{ "Year : Month Week#":"Julwk1","Predicted Price(INR)/Quintal":7872,"Actual Price(INR)/Quintal":7880,"Percentage Difference":"0.10%","YEAR":2023},
{ "Year : Month Week#":"Julwk2","Predicted Price(INR)/Quintal":7899,"Actual Price(INR)/Quintal":8033,"Percentage Difference":"1.67%","YEAR":2023},
{ "Year : Month Week#":"Julwk3","Predicted Price(INR)/Quintal":8028,"Actual Price(INR)/Quintal":8425,"Percentage Difference":"4.71%","YEAR":2023},
{ "Year : Month Week#":"Julwk4","Predicted Price(INR)/Quintal":8649,"Actual Price(INR)/Quintal":9100,"Percentage Difference":"4.96%","YEAR":2023},
{ "Year : Month Week#":"Augwk1","Predicted Price(INR)/Quintal":8943,"Actual Price(INR)/Quintal":9201,"Percentage Difference":"2.80%","YEAR":2023},
{ "Year : Month Week#":"Aug wk2","Predicted Price(INR)/Quintal":9278,"Actual Price(INR)/Quintal":9225,"Percentage Difference":"-0.57%","YEAR":2023},
{ "Year : Month Week#":"Augwk3","Predicted Price(INR)/Quintal":9241,"Actual Price(INR)/Quintal":8900,"Percentage Difference":"-3.83%","YEAR":2023},
{ "Year : Month Week#":"Augwk4","Predicted Price(INR)/Quintal":8895,"Actual Price(INR)/Quintal":8833,"Percentage Difference":"-0.70%","YEAR":2023},
{ "Year : Month Week#":"Sepwk1","Predicted Price(INR)/Quintal":8876,"Actual Price(INR)/Quintal":8492,"Percentage Difference":"-4.52%","YEAR":2023},
{ "Year : Month Week#":"Sepwk2","Predicted Price(INR)/Quintal":8462,"Actual Price(INR)/Quintal":8088,"Percentage Difference":"-4.62%","YEAR":2023},
{ "Year : Month Week#":"Sepwk3","Predicted Price(INR)/Quintal":8038,"Actual Price(INR)/Quintal":7983,"Percentage Difference":"-0.69%","YEAR":2023},
{ "Year : Month Week#":"Sepwk4","Predicted Price(INR)/Quintal":8077,"Actual Price(INR)/Quintal":7991,"Percentage Difference":"-1.08%","YEAR":2023},
{ "Year : Month Week#":"Octwk1","Predicted Price(INR)/Quintal":8151,"Actual Price(INR)/Quintal":8093,"Percentage Difference":"-0.72%","YEAR":2023},
{ "Year : Month Week#":"Octwk2","Predicted Price(INR)/Quintal":8193,"Actual Price(INR)/Quintal":8198,"Percentage Difference":"0.06%","YEAR":2023},
{ "Year : Month Week#":"Octwk3","Predicted Price(INR)/Quintal":8190,"Actual Price(INR)/Quintal":8650,"Percentage Difference":"5.32%","YEAR":2023},
{ "Year : Month Week#":"Octwk4","Predicted Price(INR)/Quintal":8500,"Actual Price(INR)/Quintal":8581,"Percentage Difference":"0.94%","YEAR":2023},
{ "Year : Month Week#":"Novwk1","Predicted Price(INR)/Quintal":8530,"Actual Price(INR)/Quintal":8383,"Percentage Difference":"-1.75%","YEAR":2023},
{ "Year : Month Week#":"Novwk2","Predicted Price(INR)/Quintal":8241,"Actual Price(INR)/Quintal":8045,"Percentage Difference":"-2.44%","YEAR":2023},
{ "Year : Month Week#":"Novwk3","Predicted Price(INR)/Quintal":8107,"Actual Price(INR)/Quintal":8018,"Percentage Difference":"-1.11%","YEAR":2023},
{ "Year : Month Week#":"Novwk4","Predicted Price(INR)/Quintal":8059,"Actual Price(INR)/Quintal":7961,"Percentage Difference":"-1.23%","YEAR":2023},
{ "Year : Month Week#":"Decwk1","Predicted Price(INR)/Quintal":8050,"Actual Price(INR)/Quintal":7768,"Percentage Difference":"-3.63%","YEAR":2023},
{ "Year : Month Week#":"Decwk2","Predicted Price(INR)/Quintal":7762,"Actual Price(INR)/Quintal":7932,"Percentage Difference":"2.14%","YEAR":2023},
{ "Year : Month Week#":"Decwk3","Predicted Price(INR)/Quintal":7782,"Actual Price(INR)/Quintal":7928,"Percentage Difference":"1.84%","YEAR":2023},
{ "Year : Month Week#":"Decwk4","Predicted Price(INR)/Quintal":7903,"Actual Price(INR)/Quintal":8053,"Percentage Difference":"1.86%","YEAR":2023},
{ "Year : Month Week#":"Janwk1","Predicted Price(INR)/Quintal":17476,"Actual Price(INR)/Quintal":17638,"Percentage Difference":"0.92%","YEAR":2022},
{ "Year : Month Week#":"Janwk2","Predicted Price(INR)/Quintal":17406,"Actual Price(INR)/Quintal":17002,"Percentage Difference":"-2.38%","YEAR":2022},
{ "Year : Month Week#":"Janwk3","Predicted Price(INR)/Quintal":17396,"Actual Price(INR)/Quintal":17200,"Percentage Difference":"-1.14%","YEAR":2022},
{ "Year : Month Week#":"Janwk4","Predicted Price(INR)/Quintal":17404,"Actual Price(INR)/Quintal":17075,"Percentage Difference":"-1.93%","YEAR":2022},
{ "Year : Month Week#":"Febwk1","Predicted Price(INR)/Quintal":17134,"Actual Price(INR)/Quintal":17350,"Percentage Difference":"1.24%","YEAR":2022},
{ "Year : Month Week#":"Febwk2","Predicted Price(INR)/Quintal":17109,"Actual Price(INR)/Quintal":17500,"Percentage Difference":"2.23%","YEAR":2022},
{ "Year : Month Week#":"Febwk3","Predicted Price(INR)/Quintal":17744,"Actual Price(INR)/Quintal":17800,"Percentage Difference":"0.31%","YEAR":2022},
{ "Year : Month Week#":"Febwk4","Predicted Price(INR)/Quintal":17631,"Actual Price(INR)/Quintal":17566,"Percentage Difference":"-0.37%","YEAR":2022},
{ "Year : Month Week#":"Marwk1","Predicted Price(INR)/Quintal":17662,"Actual Price(INR)/Quintal":17440,"Percentage Difference":"-1.27%","YEAR":2022},
{ "Year : Month Week#":"Marwk2","Predicted Price(INR)/Quintal":17486,"Actual Price(INR)/Quintal":17425,"Percentage Difference":"-0.35%","YEAR":2022},
{ "Year : Month Week#":"Marwk3","Predicted Price(INR)/Quintal":17383,"Actual Price(INR)/Quintal":17112,"Percentage Difference":"-1.58%","YEAR":2022},
{ "Year : Month Week#":"Marwk4","Predicted Price(INR)/Quintal":16339,"Actual Price(INR)/Quintal":16375,"Percentage Difference":"0.22%","YEAR":2022},
{ "Year : Month Week#":"Aprwk1","Predicted Price(INR)/Quintal":16408,"Actual Price(INR)/Quintal":16575,"Percentage Difference":"1.01%","YEAR":2022},
{ "Year : Month Week#":"Aprwk2","Predicted Price(INR)/Quintal":16497,"Actual Price(INR)/Quintal":16666,"Percentage Difference":"1.01%","YEAR":2022},
{ "Year : Month Week#":"Aprwk3","Predicted Price(INR)/Quintal":16331,"Actual Price(INR)/Quintal":16444,"Percentage Difference":"0.69%","YEAR":2022},
{ "Year : Month Week#":"Aprwk4","Predicted Price(INR)/Quintal":16452,"Actual Price(INR)/Quintal":16600,"Percentage Difference":"0.89%","YEAR":2022},
{ "Year : Month Week#":"Maywk1","Predicted Price(INR)/Quintal":16731,"Actual Price(INR)/Quintal":16350,"Percentage Difference":"-2.33%","YEAR":2022},
{ "Year : Month Week#":"Maywk2","Predicted Price(INR)/Quintal":16811,"Actual Price(INR)/Quintal":15950,"Percentage Difference":"-5.40%","YEAR":2022},
{ "Year : Month Week#":"Maywk3","Predicted Price(INR)/Quintal":15821,"Actual Price(INR)/Quintal":15245,"Percentage Difference":"-3.78%","YEAR":2022},
{ "Year : Month Week#":"Maywk4","Predicted Price(INR)/Quintal":14292,"Actual Price(INR)/Quintal":14275,"Percentage Difference":"-0.12%","YEAR":2022},
{ "Year : Month Week#":"Junwk1","Predicted Price(INR)/Quintal":14260,"Actual Price(INR)/Quintal":14184,"Percentage Difference":"-0.54%","YEAR":2022},
{ "Year : Month Week#":"Junwk2","Predicted Price(INR)/Quintal":14291,"Actual Price(INR)/Quintal":14312,"Percentage Difference":"0.15%","YEAR":2022},
{ "Year : Month Week#":"Junwk3","Predicted Price(INR)/Quintal":14320,"Actual Price(INR)/Quintal":13900,"Percentage Difference":"-3.02%","YEAR":2022},
{ "Year : Month Week#":"Junwk4","Predicted Price(INR)/Quintal":14383,"Actual Price(INR)/Quintal":14350,"Percentage Difference":"-0.23%","YEAR":2022},
{ "Year : Month Week#":"Julwk1","Predicted Price(INR)/Quintal":14193,"Actual Price(INR)/Quintal":13920,"Percentage Difference":"-1.96%","YEAR":2022},
{ "Year : Month Week#":"Julwk2","Predicted Price(INR)/Quintal":14032,"Actual Price(INR)/Quintal":14025,"Percentage Difference":"-0.05%","YEAR":2022},
{ "Year : Month Week#":"Julwk3","Predicted Price(INR)/Quintal":14026,"Actual Price(INR)/Quintal":14030,"Percentage Difference":"0.03%","YEAR":2022},
{ "Year : Month Week#":"Julwk4","Predicted Price(INR)/Quintal":14461,"Actual Price(INR)/Quintal":14575,"Percentage Difference":"0.78%","YEAR":2022},
{ "Year : Month Week#":"Augwk1","Predicted Price(INR)/Quintal":14147,"Actual Price(INR)/Quintal":14328,"Percentage Difference":"1.26%","YEAR":2022},
{ "Year : Month Week#":"Augwk2","Predicted Price(INR)/Quintal":14093,"Actual Price(INR)/Quintal":14275,"Percentage Difference":"1.27%","YEAR":2022},
{ "Year : Month Week#":"Augwk3","Predicted Price(INR)/Quintal":14277,"Actual Price(INR)/Quintal":13700,"Percentage Difference":"-4.21%","YEAR":2022},
{ "Year : Month Week#":"Augwk4","Predicted Price(INR)/Quintal":13457,"Actual Price(INR)/Quintal":13460,"Percentage Difference":"0.02%","YEAR":2022},
{ "Year : Month Week#":"Sepwk1","Predicted Price(INR)/Quintal":13408,"Actual Price(INR)/Quintal":13666,"Percentage Difference":"1.89%","YEAR":2022},
{ "Year : Month Week#":"Sepwk2","Predicted Price(INR)/Quintal":13449,"Actual Price(INR)/Quintal":13333,"Percentage Difference":"-0.87%","YEAR":2022},
{ "Year : Month Week#":"Sepwk3","Predicted Price(INR)/Quintal":13299,"Actual Price(INR)/Quintal":13500,"Percentage Difference":"1.49%","YEAR":2022},
{ "Year : Month Week#":"Sepwk4","Predicted Price(INR)/Quintal":13120,"Actual Price(INR)/Quintal":13720,"Percentage Difference":"4.37%","YEAR":2022},
{ "Year : Month Week#":"Octwk1","Predicted Price(INR)/Quintal":13161,"Actual Price(INR)/Quintal":13500,"Percentage Difference":"2.51%","YEAR":2022},
{ "Year : Month Week#":"Octwk2","Predicted Price(INR)/Quintal":13440,"Actual Price(INR)/Quintal":13550,"Percentage Difference":"0.81%","YEAR":2022},
{ "Year : Month Week#":"Octwk3","Predicted Price(INR)/Quintal":13474,"Actual Price(INR)/Quintal":13600,"Percentage Difference":"0.93%","YEAR":2022},
{ "Year : Month Week#":"Octwk4","Predicted Price(INR)/Quintal":13429,"Actual Price(INR)/Quintal":13475,"Percentage Difference":"0.34%","YEAR":2022},
{ "Year : Month Week#":"Novwk1","Predicted Price(INR)/Quintal":13597,"Actual Price(INR)/Quintal":12920,"Percentage Difference":"-5.24%","YEAR":2022},
{ "Year : Month Week#":"Novwk2","Predicted Price(INR)/Quintal":13041,"Actual Price(INR)/Quintal":12966,"Percentage Difference":"-0.58%","YEAR":2022},
{ "Year : Month Week#":"Novwk3","Predicted Price(INR)/Quintal":13012,"Actual Price(INR)/Quintal":12337,"Percentage Difference":"-5.47%","YEAR":2022},
{ "Year : Month Week#":"Novwk4","Predicted Price(INR)/Quintal":12244,"Actual Price(INR)/Quintal":12287,"Percentage Difference":"0.35%","YEAR":2022},
{ "Year : Month Week#":"Decwk1","Predicted Price(INR)/Quintal":12083,"Actual Price(INR)/Quintal":11980,"Percentage Difference":"-0.86%","YEAR":2022},
{ "Year : Month Week#":"Decwk2","Predicted Price(INR)/Quintal":12222,"Actual Price(INR)/Quintal":11643,"Percentage Difference":"-4.97%","YEAR":2022},
{ "Year : Month Week#":"Decwk3","Predicted Price(INR)/Quintal":11509,"Actual Price(INR)/Quintal":11614,"Percentage Difference":"0.90%","YEAR":2022},
{ "Year : Month Week#":"Decwk4","Predicted Price(INR)/Quintal":11169,"Actual Price(INR)/Quintal":11515,"Percentage Difference":"3.00%","YEAR":2022},
{ "Year : Month Week#":"JanWk1","Predicted Price(INR)/Quintal":14637,"Actual Price(INR)/Quintal":14383,"Percentage Difference":"-1.77%","YEAR":2021},
{ "Year : Month Week#":"JanWk2","Predicted Price(INR)/Quintal":14688,"Actual Price(INR)/Quintal":14437,"Percentage Difference":"-1.74%","YEAR":2021},
{ "Year : Month Week#":"JanWk3","Predicted Price(INR)/Quintal":14460,"Actual Price(INR)/Quintal":14330,"Percentage Difference":"-0.91%","YEAR":2021},
{ "Year : Month Week#":"JanWk4","Predicted Price(INR)/Quintal":14307,"Actual Price(INR)/Quintal":14050,"Percentage Difference":"-1.83%","YEAR":2021},
{ "Year : Month Week#":"FebWk1","Predicted Price(INR)/Quintal":14139,"Actual Price(INR)/Quintal":14200,"Percentage Difference":"0.43%","YEAR":2021},
{ "Year : Month Week#":"FebWk2","Predicted Price(INR)/Quintal":14163,"Actual Price(INR)/Quintal":14276,"Percentage Difference":"0.79%","YEAR":2021},
{ "Year : Month Week#":"FebWk3","Predicted Price(INR)/Quintal":14342,"Actual Price(INR)/Quintal":14540,"Percentage Difference":"1.36%","YEAR":2021},
{ "Year : Month Week#":"FebWk4","Predicted Price(INR)/Quintal":14341,"Actual Price(INR)/Quintal":14800,"Percentage Difference":"3.10%","YEAR":2021},
{ "Year : Month Week#":"MarWk1","Predicted Price(INR)/Quintal":14727,"Actual Price(INR)/Quintal":15100,"Percentage Difference":"2.47%","YEAR":2021},
{ "Year : Month Week#":"MarWk2","Predicted Price(INR)/Quintal":15714,"Actual Price(INR)/Quintal":15800,"Percentage Difference":"0.54%","YEAR":2021},
{ "Year : Month Week#":"MarWk3","Predicted Price(INR)/Quintal":15552,"Actual Price(INR)/Quintal":15475,"Percentage Difference":"-0.50%","YEAR":2021},
{ "Year : Month Week#":"MarWk4","Predicted Price(INR)/Quintal":15541,"Actual Price(INR)/Quintal":15100,"Percentage Difference":"-2.92%","YEAR":2021},
{ "Year : Month Week#":"AprWk1","Predicted Price(INR)/Quintal":15085,"Actual Price(INR)/Quintal":15266,"Percentage Difference":"1.19%","YEAR":2021},
{ "Year : Month Week#":"AprWk2","Predicted Price(INR)/Quintal":15294,"Actual Price(INR)/Quintal":15433,"Percentage Difference":"0.90%","YEAR":2021},
{ "Year : Month Week#":"AprWk3","Predicted Price(INR)/Quintal":15292,"Actual Price(INR)/Quintal":15920,"Percentage Difference":"3.94%","YEAR":2021},
{ "Year : Month Week#":"AprWk4","Predicted Price(INR)/Quintal":15887,"Actual Price(INR)/Quintal":15800,"Percentage Difference":"-0.55%","YEAR":2021},
{ "Year : Month Week#":"Maywk1","Predicted Price(INR)/Quintal":15833,"Actual Price(INR)/Quintal":15824,"Percentage Difference":"-0.06%","YEAR":2021},
{ "Year : Month Week#":"Maywk2","Predicted Price(INR)/Quintal":15632,"Actual Price(INR)/Quintal":15683,"Percentage Difference":"0.33%","YEAR":2021},
{ "Year : Month Week#":"Maywk3","Predicted Price(INR)/Quintal":15638,"Actual Price(INR)/Quintal":15874,"Percentage Difference":"1.49%","YEAR":2021},
{ "Year : Month Week#":"Maywk4","Predicted Price(INR)/Quintal":15611,"Actual Price(INR)/Quintal":16179,"Percentage Difference":"3.51%","YEAR":2021},
{ "Year : Month Week#":"Junwk1","Predicted Price(INR)/Quintal":15922,"Actual Price(INR)/Quintal":16200,"Percentage Difference":"1.72%","YEAR":2021},
{ "Year : Month Week#":"Junwk2","Predicted Price(INR)/Quintal":15459,"Actual Price(INR)/Quintal":15483,"Percentage Difference":"0.16%","YEAR":2021},
{ "Year : Month Week#":"Junwk3","Predicted Price(INR)/Quintal":15493,"Actual Price(INR)/Quintal":15825,"Percentage Difference":"2.10%","YEAR":2021},
{ "Year : Month Week#":"Junwk4","Predicted Price(INR)/Quintal":15690,"Actual Price(INR)/Quintal":15500,"Percentage Difference":"-1.23%","YEAR":2021},
{ "Year : Month Week#":"Julwk1","Predicted Price(INR)/Quintal":15668,"Actual Price(INR)/Quintal":15730,"Percentage Difference":"0.39%","YEAR":2021},
{ "Year : Month Week#":"Julwk2","Predicted Price(INR)/Quintal":15682,"Actual Price(INR)/Quintal":15685,"Percentage Difference":"0.02%","YEAR":2021},
{ "Year : Month Week#":"Julwk3","Predicted Price(INR)/Quintal":15906,"Actual Price(INR)/Quintal":15891,"Percentage Difference":"-0.09%","YEAR":2021},
{ "Year : Month Week#":"Julwk4","Predicted Price(INR)/Quintal":15932,"Actual Price(INR)/Quintal":16074,"Percentage Difference":"0.88%","YEAR":2021},
{ "Year : Month Week#":"Augwk1","Predicted Price(INR)/Quintal":15930,"Actual Price(INR)/Quintal":16166,"Percentage Difference":"1.46%","YEAR":2021},
{ "Year : Month Week#":"Augwk2","Predicted Price(INR)/Quintal":16268,"Actual Price(INR)/Quintal":16560,"Percentage Difference":"1.76%","YEAR":2021},
{ "Year : Month Week#":"Augwk3","Predicted Price(INR)/Quintal":16307,"Actual Price(INR)/Quintal":16390,"Percentage Difference":"0.51%","YEAR":2021},
{ "Year : Month Week#":"Augwk4","Predicted Price(INR)/Quintal":16309,"Actual Price(INR)/Quintal":16290,"Percentage Difference":"-0.12%","YEAR":2021},
{ "Year : Month Week#":"Sepwk1","Predicted Price(INR)/Quintal":16343,"Actual Price(INR)/Quintal":16300,"Percentage Difference":"-0.26%","YEAR":2021},
{ "Year : Month Week#":"Sepwk2","Predicted Price(INR)/Quintal":16395,"Actual Price(INR)/Quintal":16333,"Percentage Difference":"-0.38%","YEAR":2021},
{ "Year : Month Week#":"Sepwk3","Predicted Price(INR)/Quintal":16337,"Actual Price(INR)/Quintal":16500,"Percentage Difference":"0.99%","YEAR":2021},
{ "Year : Month Week#":"Sepwk4","Predicted Price(INR)/Quintal":16307,"Actual Price(INR)/Quintal":16370,"Percentage Difference":"0.38%","YEAR":2021},
{ "Year : Month Week#":"Octwk1","Predicted Price(INR)/Quintal":16282,"Actual Price(INR)/Quintal":16800,"Percentage Difference":"3.08%","YEAR":2021},
{ "Year : Month Week#":"Octwk2","Predicted Price(INR)/Quintal":16427,"Actual Price(INR)/Quintal":16550,"Percentage Difference":"0.74%","YEAR":2021},
{ "Year : Month Week#":"Octwk3","Predicted Price(INR)/Quintal":16402,"Actual Price(INR)/Quintal":16600,"Percentage Difference":"1.19%","YEAR":2021},
{ "Year : Month Week#":"Octwk4","Predicted Price(INR)/Quintal":16464,"Actual Price(INR)/Quintal":16700,"Percentage Difference":"1.41%","YEAR":2021},
{ "Year : Month Week#":"Novwk1","Predicted Price(INR)/Quintal":16503,"Actual Price(INR)/Quintal":16703,"Percentage Difference":"1.20%","YEAR":2021},
{ "Year : Month Week#":"Novwk2","Predicted Price(INR)/Quintal":16687,"Actual Price(INR)/Quintal":16713,"Percentage Difference":"0.16%","YEAR":2021},
{ "Year : Month Week#":"Novwk3","Predicted Price(INR)/Quintal":16759,"Actual Price(INR)/Quintal":17100,"Percentage Difference":"1.99%","YEAR":2021},
{ "Year : Month Week#":"Novwk4","Predicted Price(INR)/Quintal":16723,"Actual Price(INR)/Quintal":17300,"Percentage Difference":"3.34%","YEAR":2021},
{ "Year : Month Week#":"Decwk1","Predicted Price(INR)/Quintal":17194,"Actual Price(INR)/Quintal":17233,"Percentage Difference":"0.23%","YEAR":2021},
{ "Year : Month Week#":"Decwk2","Predicted Price(INR)/Quintal":17312,"Actual Price(INR)/Quintal":17400,"Percentage Difference":"0.51%","YEAR":2021},
{ "Year : Month Week#":"Decwk3","Predicted Price(INR)/Quintal":17298,"Actual Price(INR)/Quintal":17655,"Percentage Difference":"2.02%","YEAR":2021},
{ "Year : Month Week#":"Decwk4","Predicted Price(INR)/Quintal":17468,"Actual Price(INR)/Quintal":17566,"Percentage Difference":"0.56%","YEAR":2021},
{ "Year : Month Week#":"JanWk1","Predicted Price(INR)/Quintal":12063,"Actual Price(INR)/Quintal":11520,"Percentage Difference":"-4.71%","YEAR":2020},
{ "Year : Month Week#":"JanWk2","Predicted Price(INR)/Quintal":11777,"Actual Price(INR)/Quintal":11116,"Percentage Difference":"-5.95%","YEAR":2020},
{ "Year : Month Week#":"JanWk3","Predicted Price(INR)/Quintal":11288,"Actual Price(INR)/Quintal":11318,"Percentage Difference":"0.27%","YEAR":2020},
{ "Year : Month Week#":"JanWk4","Predicted Price(INR)/Quintal":11307,"Actual Price(INR)/Quintal":10952,"Percentage Difference":"-3.24%","YEAR":2020},
{ "Year : Month Week#":"FebWk1","Predicted Price(INR)/Quintal":11508,"Actual Price(INR)/Quintal":10914,"Percentage Difference":"-5.44%","YEAR":2020},
{ "Year : Month Week#":"FebWk2","Predicted Price(INR)/Quintal":11033,"Actual Price(INR)/Quintal":10816,"Percentage Difference":"-2.01%","YEAR":2020},
{ "Year : Month Week#":"FebWk3","Predicted Price(INR)/Quintal":11053,"Actual Price(INR)/Quintal":10510,"Percentage Difference":"-5.17%","YEAR":2020},
{ "Year : Month Week#":"FebWk4","Predicted Price(INR)/Quintal":10413,"Actual Price(INR)/Quintal":10175,"Percentage Difference":"-2.34%","YEAR":2020},
{ "Year : Month Week#":"MarWk1","Predicted Price(INR)/Quintal":10402,"Actual Price(INR)/Quintal":10283,"Percentage Difference":"-1.16%","YEAR":2020},
{ "Year : Month Week#":"MarWk2","Predicted Price(INR)/Quintal":10100,"Actual Price(INR)/Quintal":10116,"Percentage Difference":"0.16%","YEAR":2020},
{ "Year : Month Week#":"MarWk3","Predicted Price(INR)/Quintal":10185,"Actual Price(INR)/Quintal":10150,"Percentage Difference":"-0.34%","YEAR":2020},
{ "Year : Month Week#":"MarWk4","Predicted Price(INR)/Quintal":10190,"Actual Price(INR)/Quintal":10000,"Percentage Difference":"-1.90%","YEAR":2020},
{ "Year : Month Week#":"AprWk1","Predicted Price(INR)/Quintal":10037,"Actual Price(INR)/Quintal":10249,"Percentage Difference":"2.07%","YEAR":2020},
{ "Year : Month Week#":"AprWk2","Predicted Price(INR)/Quintal":10041,"Actual Price(INR)/Quintal":10375,"Percentage Difference":"3.22%","YEAR":2020},
{ "Year : Month Week#":"AprWk3","Predicted Price(INR)/Quintal":10344,"Actual Price(INR)/Quintal":10833,"Percentage Difference":"4.51%","YEAR":2020},
{ "Year : Month Week#":"AprWk4","Predicted Price(INR)/Quintal":10814,"Actual Price(INR)/Quintal":11000,"Percentage Difference":"1.69%","YEAR":2020},
{ "Year : Month Week#":"MayWk1","Predicted Price(INR)/Quintal":10933,"Actual Price(INR)/Quintal":10500,"Percentage Difference":"-4.12%","YEAR":2020},
{ "Year : Month Week#":"MayWk2","Predicted Price(INR)/Quintal":10512,"Actual Price(INR)/Quintal":10300,"Percentage Difference":"-2.06%","YEAR":2020},
{ "Year : Month Week#":"MayWk3","Predicted Price(INR)/Quintal":10077,"Actual Price(INR)/Quintal":10050,"Percentage Difference":"-0.27%","YEAR":2020},
{ "Year : Month Week#":"MayWk4","Predicted Price(INR)/Quintal":9988,"Actual Price(INR)/Quintal":9640,"Percentage Difference":"-3.61%","YEAR":2020},
{ "Year : Month Week#":"JunWk1","Predicted Price(INR)/Quintal":9079,"Actual Price(INR)/Quintal":9075,"Percentage Difference":"-0.04%","YEAR":2020},
{ "Year : Month Week#":"JunWk2","Predicted Price(INR)/Quintal":9070,"Actual Price(INR)/Quintal":9200,"Percentage Difference":"1.41%","YEAR":2020},
{ "Year : Month Week#":"JunWk3","Predicted Price(INR)/Quintal":9332,"Actual Price(INR)/Quintal":9660,"Percentage Difference":"3.40%","YEAR":2020},
{ "Year : Month Week#":"JunWk4","Predicted Price(INR)/Quintal":9670,"Actual Price(INR)/Quintal":9608,"Percentage Difference":"-0.65%","YEAR":2020},
{ "Year : Month Week#":"JulWk1","Predicted Price(INR)/Quintal":9671,"Actual Price(INR)/Quintal":9221,"Percentage Difference":"-4.88%","YEAR":2020},
{ "Year : Month Week#":"JulWk2","Predicted Price(INR)/Quintal":9216,"Actual Price(INR)/Quintal":9283,"Percentage Difference":"0.72%","YEAR":2020},
{ "Year : Month Week#":"JulWk3","Predicted Price(INR)/Quintal":9213,"Actual Price(INR)/Quintal":9258,"Percentage Difference":"0.49%","YEAR":2020},
{ "Year : Month Week#":"JulWk4","Predicted Price(INR)/Quintal":9268,"Actual Price(INR)/Quintal":9350,"Percentage Difference":"0.88%","YEAR":2020},
{ "Year : Month Week#":"AugWk1","Predicted Price(INR)/Quintal":9373,"Actual Price(INR)/Quintal":9494,"Percentage Difference":"1.27%","YEAR":2020},
{ "Year : Month Week#":"AugWk2","Predicted Price(INR)/Quintal":9366,"Actual Price(INR)/Quintal":9600,"Percentage Difference":"2.44%","YEAR":2020},
{ "Year : Month Week#":"AugWk3","Predicted Price(INR)/Quintal":9559,"Actual Price(INR)/Quintal":9800,"Percentage Difference":"2.46%","YEAR":2020},
{ "Year : Month Week#":"AugWk4","Predicted Price(INR)/Quintal":9862,"Actual Price(INR)/Quintal":9990,"Percentage Difference":"1.28%","YEAR":2020},
{ "Year : Month Week#":"SepWk1","Predicted Price(INR)/Quintal":9979,"Actual Price(INR)/Quintal":10159,"Percentage Difference":"1.77%","YEAR":2020},
{ "Year : Month Week#":"SepWk2","Predicted Price(INR)/Quintal":10105,"Actual Price(INR)/Quintal":10200,"Percentage Difference":"0.93%","YEAR":2020},
{ "Year : Month Week#":"SepWk3","Predicted Price(INR)/Quintal":10088,"Actual Price(INR)/Quintal":10350,"Percentage Difference":"2.53%","YEAR":2020},
{ "Year : Month Week#":"SepWk4","Predicted Price(INR)/Quintal":10323,"Actual Price(INR)/Quintal":10650,"Percentage Difference":"3.07%","YEAR":2020},
{ "Year : Month Week#":"OctWk1","Predicted Price(INR)/Quintal":10827,"Actual Price(INR)/Quintal":11166,"Percentage Difference":"3.04%","YEAR":2020},
{ "Year : Month Week#":"OctWk2","Predicted Price(INR)/Quintal":11132,"Actual Price(INR)/Quintal":11350,"Percentage Difference":"1.92%","YEAR":2020},
{ "Year : Month Week#":"OctWk3","Predicted Price(INR)/Quintal":11395,"Actual Price(INR)/Quintal":11590,"Percentage Difference":"1.68%","YEAR":2020},
{ "Year : Month Week#":"OctWk4","Predicted Price(INR)/Quintal":11669,"Actual Price(INR)/Quintal":11660,"Percentage Difference":"-0.08%","YEAR":2020},
{ "Year : Month Week#":"NovWk1","Predicted Price(INR)/Quintal":11691,"Actual Price(INR)/Quintal":12166,"Percentage Difference":"3.90%","YEAR":2020},
{ "Year : Month Week#":"NovWk2","Predicted Price(INR)/Quintal":12658,"Actual Price(INR)/Quintal":12309,"Percentage Difference":"-2.84%","YEAR":2020},
{ "Year : Month Week#":"NovWk3","Predicted Price(INR)/Quintal":12411,"Actual Price(INR)/Quintal":12426,"Percentage Difference":"0.12%","YEAR":2020},
{ "Year : Month Week#":"NovWk4","Predicted Price(INR)/Quintal":12487,"Actual Price(INR)/Quintal":12522,"Percentage Difference":"0.28%","YEAR":2020},
{ "Year : Month Week#":"DecWk1","Predicted Price(INR)/Quintal":13951,"Actual Price(INR)/Quintal":14415,"Percentage Difference":"3.22%","YEAR":2020},
{ "Year : Month Week#":"DecWk2","Predicted Price(INR)/Quintal":13491,"Actual Price(INR)/Quintal":14058,"Percentage Difference":"4.03%","YEAR":2020},
{ "Year : Month Week#":"DecWk3","Predicted Price(INR)/Quintal":14342,"Actual Price(INR)/Quintal":14708,"Percentage Difference":"2.49%","YEAR":2020},
{ "Year : Month Week#":"DecWk4","Predicted Price(INR)/Quintal":14850,"Actual Price(INR)/Quintal":15033,"Percentage Difference":"1.22%","YEAR":2020},
  {
    "Year : Month Week#": "JanWk1",
    "Predicted Price(INR)/Quintal": 16721,
    "Actual Price(INR)/Quintal": 16753,
    "Percentage Difference": "0.19%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "JanWk2",
    "Predicted Price(INR)/Quintal": 16391,
    "Actual Price(INR)/Quintal": 16596,
    "Percentage Difference": "1.24%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "JanWk3",
    "Predicted Price(INR)/Quintal": 16754,
    "Actual Price(INR)/Quintal": 16343,
    "Percentage Difference": "-2.51%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "JanWk4",
    "Predicted Price(INR)/Quintal": 16316,
    "Actual Price(INR)/Quintal": 16154,
    "Percentage Difference": "-1.00%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "FebWk1",
    "Predicted Price(INR)/Quintal": 16261,
    "Actual Price(INR)/Quintal": 15839,
    "Percentage Difference": "-2.66%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "FebWk2",
    "Predicted Price(INR)/Quintal": 16303,
    "Actual Price(INR)/Quintal": 16311,
    "Percentage Difference": "0.05%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "FebWk3",
    "Predicted Price(INR)/Quintal": 16320,
    "Actual Price(INR)/Quintal": 16412,
    "Percentage Difference": "0.56%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "FebWk4",
    "Predicted Price(INR)/Quintal": 16297,
    "Actual Price(INR)/Quintal": 16322,
    "Percentage Difference": "0.15%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "MarWk1",
    "Predicted Price(INR)/Quintal": 16314,
    "Actual Price(INR)/Quintal": 16491,
    "Percentage Difference": "1.07%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "MarWk2",
    "Predicted Price(INR)/Quintal": 16312,
    "Actual Price(INR)/Quintal": 16088,
    "Percentage Difference": "-1.39%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "MarWk3",
    "Predicted Price(INR)/Quintal": 16160,
    "Actual Price(INR)/Quintal": 15781,
    "Percentage Difference": "-2.40%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "MarWk4",
    "Predicted Price(INR)/Quintal": 15757,
    "Actual Price(INR)/Quintal": 15576,
    "Percentage Difference": "-1.16%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "AprWk1",
    "Predicted Price(INR)/Quintal": 15566,
    "Actual Price(INR)/Quintal": 16076,
    "Percentage Difference": "3.17%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "AprWk2",
    "Predicted Price(INR)/Quintal": 15818,
    "Actual Price(INR)/Quintal": 16076,
    "Percentage Difference": "1.60%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "AprWk3",
    "Predicted Price(INR)/Quintal": 16032,
    "Actual Price(INR)/Quintal": 16111,
    "Percentage Difference": "0.49%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "AprWk4",
    "Predicted Price(INR)/Quintal": 16015,
    "Actual Price(INR)/Quintal": 16112,
    "Percentage Difference": "0.60%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "MayWk1",
    "Predicted Price(INR)/Quintal": 16008,
    "Actual Price(INR)/Quintal": 16437,
    "Percentage Difference": "2.61%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "MayWk2",
    "Predicted Price(INR)/Quintal": 16330,
    "Actual Price(INR)/Quintal": 16133,
    "Percentage Difference": "-1.22%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "MayWk3",
    "Predicted Price(INR)/Quintal": 16103,
    "Actual Price(INR)/Quintal": 16200,
    "Percentage Difference": "0.60%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "MayWk4",
    "Predicted Price(INR)/Quintal": 16181,
    "Actual Price(INR)/Quintal": 16001,
    "Percentage Difference": "-1.12%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "JunWk1",
    "Predicted Price(INR)/Quintal": 16075,
    "Actual Price(INR)/Quintal": 15300,
    "Percentage Difference": "-5.07%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "JunWk2",
    "Predicted Price(INR)/Quintal": 15073,
    "Actual Price(INR)/Quintal": 14242,
    "Percentage Difference": "-5.83%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "JunWk3",
    "Predicted Price(INR)/Quintal": 14077,
    "Actual Price(INR)/Quintal": 14150,
    "Percentage Difference": "0.52%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "JunWk4",
    "Predicted Price(INR)/Quintal": 14181,
    "Actual Price(INR)/Quintal": 14043,
    "Percentage Difference": "-0.98%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "JulWk1",
    "Predicted Price(INR)/Quintal": 14078,
    "Actual Price(INR)/Quintal": 13642,
    "Percentage Difference": "-3.20%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "JulWk2",
    "Predicted Price(INR)/Quintal": 13600,
    "Actual Price(INR)/Quintal": 13650,
    "Percentage Difference": "0.37%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "JulWk3",
    "Predicted Price(INR)/Quintal": 13683,
    "Actual Price(INR)/Quintal": 13866,
    "Percentage Difference": "1.32%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "JulWk4",
    "Predicted Price(INR)/Quintal": 13874,
    "Actual Price(INR)/Quintal": 13800,
    "Percentage Difference": "-0.54%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "AugWk1",
    "Predicted Price(INR)/Quintal": 13868,
    "Actual Price(INR)/Quintal": 13750,
    "Percentage Difference": "-0.86%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "AugWk2",
    "Predicted Price(INR)/Quintal": 13656,
    "Actual Price(INR)/Quintal": 13366,
    "Percentage Difference": "-2.17%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "AugWk3",
    "Predicted Price(INR)/Quintal": 13368,
    "Actual Price(INR)/Quintal": 13400,
    "Percentage Difference": "0.24%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "AugWk4",
    "Predicted Price(INR)/Quintal": 13373,
    "Actual Price(INR)/Quintal": 12842,
    "Percentage Difference": "-4.13%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "SepWk1",
    "Predicted Price(INR)/Quintal": 12848,
    "Actual Price(INR)/Quintal": 12800,
    "Percentage Difference": "-0.38%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "SepWk2",
    "Predicted Price(INR)/Quintal": 12834,
    "Actual Price(INR)/Quintal": 12755,
    "Percentage Difference": "-0.62%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "SepWk3",
    "Predicted Price(INR)/Quintal": 12753,
    "Actual Price(INR)/Quintal": 12801,
    "Percentage Difference": "0.37%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "SepWk4",
    "Predicted Price(INR)/Quintal": 12734,
    "Actual Price(INR)/Quintal": 12802,
    "Percentage Difference": "0.53%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "OctWk1",
    "Predicted Price(INR)/Quintal": 12791,
    "Actual Price(INR)/Quintal": 12799,
    "Percentage Difference": "0.06%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "OctWk2",
    "Predicted Price(INR)/Quintal": 12746,
    "Actual Price(INR)/Quintal": 12933,
    "Percentage Difference": "1.45%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "OctWk3",
    "Predicted Price(INR)/Quintal": 12734,
    "Actual Price(INR)/Quintal": 12842,
    "Percentage Difference": "0.84%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "OctWk4",
    "Predicted Price(INR)/Quintal": 12913,
    "Actual Price(INR)/Quintal": 12753,
    "Percentage Difference": "-1.25%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "NovWk1",
    "Predicted Price(INR)/Quintal": 12084,
    "Actual Price(INR)/Quintal": 12700,
    "Percentage Difference": "4.85%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "NovWk2",
    "Predicted Price(INR)/Quintal": 12065,
    "Actual Price(INR)/Quintal": 12300,
    "Percentage Difference": "1.91%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "NovWk3",
    "Predicted Price(INR)/Quintal": 11538,
    "Actual Price(INR)/Quintal": 11680,
    "Percentage Difference": "1.22%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "NovWk4",
    "Predicted Price(INR)/Quintal": 12043,
    "Actual Price(INR)/Quintal": 11650,
    "Percentage Difference": "-3.37%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "DecWk1",
    "Predicted Price(INR)/Quintal": 11660,
    "Actual Price(INR)/Quintal": 11316,
    "Percentage Difference": "-3.04%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "DecWk2",
    "Predicted Price(INR)/Quintal": 11568,
    "Actual Price(INR)/Quintal": 11266,
    "Percentage Difference": "-2.68%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "DecWk3",
    "Predicted Price(INR)/Quintal": 11569,
    "Actual Price(INR)/Quintal": 11600,
    "Percentage Difference": "0.27%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "DecWk4",
    "Predicted Price(INR)/Quintal": 11549,
    "Actual Price(INR)/Quintal": 11520,
    "Percentage Difference": "-0.25%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "JanWk1",
    "Predicted Price(INR)/Quintal": 12936,
    "Actual Price(INR)/Quintal": 13100,
    "Percentage Difference": "1.25%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "JanWk2",
    "Predicted Price(INR)/Quintal": 12961,
    "Actual Price(INR)/Quintal": 12900,
    "Percentage Difference": "-0.47%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "JanWk3",
    "Predicted Price(INR)/Quintal": 12993,
    "Actual Price(INR)/Quintal": 13074,
    "Percentage Difference": "0.62%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "JanWk4",
    "Predicted Price(INR)/Quintal": 12917,
    "Actual Price(INR)/Quintal": 13189,
    "Percentage Difference": "2.06%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "FebWk1",
    "Predicted Price(INR)/Quintal": 13110,
    "Actual Price(INR)/Quintal": 13117,
    "Percentage Difference": "0.05%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "FebWk2",
    "Predicted Price(INR)/Quintal": 13132,
    "Actual Price(INR)/Quintal": 13083,
    "Percentage Difference": "-0.37%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "FebWk3",
    "Predicted Price(INR)/Quintal": 13093,
    "Actual Price(INR)/Quintal": 12994,
    "Percentage Difference": "-0.76%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "FebWk4",
    "Predicted Price(INR)/Quintal": 13094,
    "Actual Price(INR)/Quintal": 12728,
    "Percentage Difference": "-2.88%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "MarWk1",
    "Predicted Price(INR)/Quintal": 12730,
    "Actual Price(INR)/Quintal": 12500,
    "Percentage Difference": "-1.84%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "MarWk2",
    "Predicted Price(INR)/Quintal": 12442,
    "Actual Price(INR)/Quintal": 12200,
    "Percentage Difference": "-1.98%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "MarWk3",
    "Predicted Price(INR)/Quintal": 12027,
    "Actual Price(INR)/Quintal": 12169,
    "Percentage Difference": "1.17%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "MarWk4",
    "Predicted Price(INR)/Quintal": 12112,
    "Actual Price(INR)/Quintal": 12601,
    "Percentage Difference": "3.88%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "AprWk1",
    "Predicted Price(INR)/Quintal": 13151,
    "Actual Price(INR)/Quintal": 13291,
    "Percentage Difference": "1.05%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "AprWk2",
    "Predicted Price(INR)/Quintal": 13631,
    "Actual Price(INR)/Quintal": 13275,
    "Percentage Difference": "-2.68%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "AprWk3",
    "Predicted Price(INR)/Quintal": 13289,
    "Actual Price(INR)/Quintal": 13300,
    "Percentage Difference": "0.08%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "AprWk4",
    "Predicted Price(INR)/Quintal": 13259,
    "Actual Price(INR)/Quintal": 14000,
    "Percentage Difference": "5.29%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "MayWk1",
    "Predicted Price(INR)/Quintal": 13920,
    "Actual Price(INR)/Quintal": 14721,
    "Percentage Difference": "5.44%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "MayWk2",
    "Predicted Price(INR)/Quintal": 14739,
    "Actual Price(INR)/Quintal": 14868,
    "Percentage Difference": "0.87%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "MayWk3",
    "Predicted Price(INR)/Quintal": 14789,
    "Actual Price(INR)/Quintal": 15424,
    "Percentage Difference": "4.12%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "MayWk4",
    "Predicted Price(INR)/Quintal": 15473,
    "Actual Price(INR)/Quintal": 15704,
    "Percentage Difference": "1.47%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "JunWk1",
    "Predicted Price(INR)/Quintal": 15343,
    "Actual Price(INR)/Quintal": 15397,
    "Percentage Difference": "0.35%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "JunWk2",
    "Predicted Price(INR)/Quintal": 15477,
    "Actual Price(INR)/Quintal": 15589,
    "Percentage Difference": "0.72%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "JunWk3",
    "Predicted Price(INR)/Quintal": 15030,
    "Actual Price(INR)/Quintal": 15439,
    "Percentage Difference": "2.65%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "JunWk4",
    "Predicted Price(INR)/Quintal": 15375,
    "Actual Price(INR)/Quintal": 14983,
    "Percentage Difference": "-2.62%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "JulWk1",
    "Predicted Price(INR)/Quintal": 15197,
    "Actual Price(INR)/Quintal": 15661,
    "Percentage Difference": "2.96%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "JulWk2",
    "Predicted Price(INR)/Quintal": 15582,
    "Actual Price(INR)/Quintal": 15379,
    "Percentage Difference": "-1.32%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "JulWk3",
    "Predicted Price(INR)/Quintal": 15518,
    "Actual Price(INR)/Quintal": 15736,
    "Percentage Difference": "1.39%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "JulWk4",
    "Predicted Price(INR)/Quintal": 15537,
    "Actual Price(INR)/Quintal": 16248,
    "Percentage Difference": "4.38%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "AugWk1",
    "Predicted Price(INR)/Quintal": 15601,
    "Actual Price(INR)/Quintal": 16176,
    "Percentage Difference": "3.55%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "AugWk2",
    "Predicted Price(INR)/Quintal": 15708,
    "Actual Price(INR)/Quintal": 15788,
    "Percentage Difference": "0.51%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "AugWk3",
    "Predicted Price(INR)/Quintal": 15691,
    "Actual Price(INR)/Quintal": 15944,
    "Percentage Difference": "1.59%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "AugWk4",
    "Predicted Price(INR)/Quintal": 15783,
    "Actual Price(INR)/Quintal": 16140,
    "Percentage Difference": "2.21%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "SepWk1",
    "Predicted Price(INR)/Quintal": 15999,
    "Actual Price(INR)/Quintal": 15969,
    "Percentage Difference": "-0.19%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "SepWk2",
    "Predicted Price(INR)/Quintal": 15996,
    "Actual Price(INR)/Quintal": 16251,
    "Percentage Difference": "1.57%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "SepWk3",
    "Predicted Price(INR)/Quintal": 16100,
    "Actual Price(INR)/Quintal": 16175,
    "Percentage Difference": "0.46%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "SepWk4",
    "Predicted Price(INR)/Quintal": 16189,
    "Actual Price(INR)/Quintal": 16231,
    "Percentage Difference": "0.46%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "OctWk1",
    "Predicted Price(INR)/Quintal": 16421,
    "Actual Price(INR)/Quintal": 16433,
    "Percentage Difference": "0.07%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "OctWk2",
    "Predicted Price(INR)/Quintal": 16391,
    "Actual Price(INR)/Quintal": 15578,
    "Percentage Difference": "-5.22%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "OctWk3",
    "Predicted Price(INR)/Quintal": 15454,
    "Actual Price(INR)/Quintal": 15749,
    "Percentage Difference": "1.87%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "OctWk4",
    "Predicted Price(INR)/Quintal": 15516,
    "Actual Price(INR)/Quintal": 15915,
    "Percentage Difference": "2.51%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "NovWk1",
    "Predicted Price(INR)/Quintal": 15916,
    "Actual Price(INR)/Quintal": 15923,
    "Percentage Difference": "0.04%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "NovWk2",
    "Predicted Price(INR)/Quintal": 15903,
    "Actual Price(INR)/Quintal": 16004,
    "Percentage Difference": "0.63%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "NovWk3",
    "Predicted Price(INR)/Quintal": 15920,
    "Actual Price(INR)/Quintal": 16131,
    "Percentage Difference": "1.31%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "NovWk4",
    "Predicted Price(INR)/Quintal": 16097,
    "Actual Price(INR)/Quintal": 15984,
    "Percentage Difference": "-0.71%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "DecWk1",
    "Predicted Price(INR)/Quintal": 16014,
    "Actual Price(INR)/Quintal": 15995,
    "Percentage Difference": "-0.12%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "DecWk2",
    "Predicted Price(INR)/Quintal": 16012,
    "Actual Price(INR)/Quintal": 15664,
    "Percentage Difference": "-2.22%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "DecWk3",
    "Predicted Price(INR)/Quintal": 15660,
    "Actual Price(INR)/Quintal": 16586,
    "Percentage Difference": "5.58%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "DecWk4",
    "Predicted Price(INR)/Quintal": 16657,
    "Actual Price(INR)/Quintal": "",
    "Percentage Difference": "",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "Jan Wk1",
    "Predicted Price(INR)/Quintal": 6698,
    "Actual Price(INR)/Quintal": 6569,
    "Percentage Difference": "-1.96%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Jan Wk2",
    "Predicted Price(INR)/Quintal": 6449,
    "Actual Price(INR)/Quintal": 6620,
    "Percentage Difference": "2.58%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Jan Wk3",
    "Predicted Price(INR)/Quintal": 6494,
    "Actual Price(INR)/Quintal": 6627,
    "Percentage Difference": "2.01%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Jan Wk4",
    "Predicted Price(INR)/Quintal": 6612,
    "Actual Price(INR)/Quintal": 6984,
    "Percentage Difference": "5.33%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Feb Wk1",
    "Predicted Price(INR)/Quintal": 7080,
    "Actual Price(INR)/Quintal": 7695,
    "Percentage Difference": "7.99%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Feb Wk2",
    "Predicted Price(INR)/Quintal": 8111,
    "Actual Price(INR)/Quintal": 8237,
    "Percentage Difference": "1.53%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Feb Wk3",
    "Predicted Price(INR)/Quintal": 8122,
    "Actual Price(INR)/Quintal": 7732,
    "Percentage Difference": "-5.04%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Feb Wk4",
    "Predicted Price(INR)/Quintal": 7727,
    "Actual Price(INR)/Quintal": 7897,
    "Percentage Difference": "2.15%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Mar Wk1",
    "Predicted Price(INR)/Quintal": 7755,
    "Actual Price(INR)/Quintal": 7428,
    "Percentage Difference": "-4.40%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Mar Wk2",
    "Predicted Price(INR)/Quintal": 7426,
    "Actual Price(INR)/Quintal": 7356,
    "Percentage Difference": "-0.95%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Mar Wk3",
    "Predicted Price(INR)/Quintal": 7315,
    "Actual Price(INR)/Quintal": 7461,
    "Percentage Difference": "1.96%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Mar Wk4",
    "Predicted Price(INR)/Quintal": 7359,
    "Actual Price(INR)/Quintal": 7904,
    "Percentage Difference": "6.90%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Apr Wk1",
    "Predicted Price(INR)/Quintal": 7756,
    "Actual Price(INR)/Quintal": 8133,
    "Percentage Difference": "4.64%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Apr Wk2",
    "Predicted Price(INR)/Quintal": 7925,
    "Actual Price(INR)/Quintal": 8044,
    "Percentage Difference": "1.48%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Apr Wk3",
    "Predicted Price(INR)/Quintal": 7904,
    "Actual Price(INR)/Quintal": 7989,
    "Percentage Difference": "1.06%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Apr Wk4",
    "Predicted Price(INR)/Quintal": 7988,
    "Actual Price(INR)/Quintal": 8127,
    "Percentage Difference": "1.71%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "May Wk1",
    "Predicted Price(INR)/Quintal": 8169,
    "Actual Price(INR)/Quintal": 8334,
    "Percentage Difference": "1.98%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "May Wk2",
    "Predicted Price(INR)/Quintal": 8361,
    "Actual Price(INR)/Quintal": 8100,
    "Percentage Difference": "-3.22%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "May Wk3",
    "Predicted Price(INR)/Quintal": 8149,
    "Actual Price(INR)/Quintal": 7995,
    "Percentage Difference": "-1.93%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "May Wk4",
    "Predicted Price(INR)/Quintal": 8028,
    "Actual Price(INR)/Quintal": 8031,
    "Percentage Difference": "0.04%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Jun Wk1",
    "Predicted Price(INR)/Quintal": 8063,
    "Actual Price(INR)/Quintal": 8103,
    "Percentage Difference": "0.49%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Jun Wk2",
    "Predicted Price(INR)/Quintal": 8050,
    "Actual Price(INR)/Quintal": 7916,
    "Percentage Difference": "-1.69%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Jun Wk3",
    "Predicted Price(INR)/Quintal": 7935,
    "Actual Price(INR)/Quintal": 7830,
    "Percentage Difference": "-1.34%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Jun Wk4",
    "Predicted Price(INR)/Quintal": 7871,
    "Actual Price(INR)/Quintal": 7704,
    "Percentage Difference": "-2.17%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Jul Wk1",
    "Predicted Price(INR)/Quintal": 7622,
    "Actual Price(INR)/Quintal": 7867,
    "Percentage Difference": "3.11%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Jul Wk2",
    "Predicted Price(INR)/Quintal": 7860,
    "Actual Price(INR)/Quintal": 8001,
    "Percentage Difference": "1.76%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Jul Wk3",
    "Predicted Price(INR)/Quintal": 7949,
    "Actual Price(INR)/Quintal": 8292,
    "Percentage Difference": "4.14%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Jul Wk4",
    "Predicted Price(INR)/Quintal": 8159,
    "Actual Price(INR)/Quintal": 8689,
    "Percentage Difference": "6.10%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Aug Wk1",
    "Predicted Price(INR)/Quintal": 8529,
    "Actual Price(INR)/Quintal": 8677,
    "Percentage Difference": "1.71%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Aug Wk2",
    "Predicted Price(INR)/Quintal": 8510,
    "Actual Price(INR)/Quintal": 8983,
    "Percentage Difference": "5.27%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Aug Wk3",
    "Predicted Price(INR)/Quintal": 8911,
    "Actual Price(INR)/Quintal": 9236,
    "Percentage Difference": "3.52%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Aug Wk4",
    "Predicted Price(INR)/Quintal": 9793,
    "Actual Price(INR)/Quintal": 10867,
    "Percentage Difference": "9.88%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Sep Wk1",
    "Predicted Price(INR)/Quintal": 11867,
    "Actual Price(INR)/Quintal": 12618,
    "Percentage Difference": "5.95%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Sep Wk2",
    "Predicted Price(INR)/Quintal": 12871,
    "Actual Price(INR)/Quintal": 13609,
    "Percentage Difference": "5.42%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Sep Wk3",
    "Predicted Price(INR)/Quintal": 12905,
    "Actual Price(INR)/Quintal": 12913,
    "Percentage Difference": "0.06%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Sep Wk4",
    "Predicted Price(INR)/Quintal": 12919,
    "Actual Price(INR)/Quintal": 12946,
    "Percentage Difference": "0.21%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Oct Wk1",
    "Predicted Price(INR)/Quintal": 12808,
    "Actual Price(INR)/Quintal": 12867,
    "Percentage Difference": "0.46%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Oct Wk2",
    "Predicted Price(INR)/Quintal": 12809,
    "Actual Price(INR)/Quintal": 11884,
    "Percentage Difference": "-7.78%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Oct Wk3",
    "Predicted Price(INR)/Quintal": 11751,
    "Actual Price(INR)/Quintal": 11726,
    "Percentage Difference": "-0.21%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Oct Wk4",
    "Predicted Price(INR)/Quintal": 11788,
    "Actual Price(INR)/Quintal": 11949,
    "Percentage Difference": "1.35%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Nov Wk1",
    "Predicted Price(INR)/Quintal": 11794,
    "Actual Price(INR)/Quintal": 12418,
    "Percentage Difference": "5.02%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Nov Wk2",
    "Predicted Price(INR)/Quintal": 12665,
    "Actual Price(INR)/Quintal": 12030,
    "Percentage Difference": "-5.28%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Nov Wk3",
    "Predicted Price(INR)/Quintal": 11421,
    "Actual Price(INR)/Quintal": 12090,
    "Percentage Difference": "5.53%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Nov Wk4",
    "Predicted Price(INR)/Quintal": 11976,
    "Actual Price(INR)/Quintal": 12529,
    "Percentage Difference": "4.41%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Dec Wk1",
    "Predicted Price(INR)/Quintal": 12411,
    "Actual Price(INR)/Quintal": 12653,
    "Percentage Difference": "1.91%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Dec Wk2",
    "Predicted Price(INR)/Quintal": 12550,
    "Actual Price(INR)/Quintal": 12911,
    "Percentage Difference": "2.80%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Dec Wk3",
    "Predicted Price(INR)/Quintal": 12925,
    "Actual Price(INR)/Quintal": 12878,
    "Percentage Difference": "-0.36%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Dec Wk4",
    "Predicted Price(INR)/Quintal": 12904,
    "Actual Price(INR)/Quintal": 12920,
    "Percentage Difference": "0.12%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Jan Wk1",
    "Predicted Price(INR)/Quintal": 10808,
    "Actual Price(INR)/Quintal": 10245,
    "Percentage Difference": "-5.50%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Jan Wk2",
    "Predicted Price(INR)/Quintal": 9257,
    "Actual Price(INR)/Quintal": 9426,
    "Percentage Difference": "1.79%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Jan Wk3",
    "Predicted Price(INR)/Quintal": 9033,
    "Actual Price(INR)/Quintal": 9391,
    "Percentage Difference": "3.81%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Jan Wk4",
    "Predicted Price(INR)/Quintal": 9266,
    "Actual Price(INR)/Quintal": 9535,
    "Percentage Difference": "2.82%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Feb Wk1",
    "Predicted Price(INR)/Quintal": 8884,
    "Actual Price(INR)/Quintal": 8812,
    "Percentage Difference": "-0.82%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Feb Wk2",
    "Predicted Price(INR)/Quintal": 8754,
    "Actual Price(INR)/Quintal": 8936,
    "Percentage Difference": "2.04%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Feb Wk3",
    "Predicted Price(INR)/Quintal": 8249,
    "Actual Price(INR)/Quintal": 9129,
    "Percentage Difference": "9.64%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Feb Wk4",
    "Predicted Price(INR)/Quintal": 8961,
    "Actual Price(INR)/Quintal": 9000,
    "Percentage Difference": "0.43%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Mar Wk1",
    "Predicted Price(INR)/Quintal": 9073,
    "Actual Price(INR)/Quintal": 8532,
    "Percentage Difference": "-6.34%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Mar Wk2",
    "Predicted Price(INR)/Quintal": 8852,
    "Actual Price(INR)/Quintal": 8166,
    "Percentage Difference": "-8.40%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Mar Wk3",
    "Predicted Price(INR)/Quintal": 8223,
    "Actual Price(INR)/Quintal": 7588,
    "Percentage Difference": "-8.37%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Mar Wk4",
    "Predicted Price(INR)/Quintal": 7627,
    "Actual Price(INR)/Quintal": 7704,
    "Percentage Difference": "1.00%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Apr Wk1",
    "Predicted Price(INR)/Quintal": 7678,
    "Actual Price(INR)/Quintal": 8489,
    "Percentage Difference": "9.55%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Apr Wk2",
    "Predicted Price(INR)/Quintal": 8833,
    "Actual Price(INR)/Quintal": 8383,
    "Percentage Difference": "-5.37%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Apr Wk3",
    "Predicted Price(INR)/Quintal": 8837,
    "Actual Price(INR)/Quintal": 8179,
    "Percentage Difference": "-8.04%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Apr Wk4",
    "Predicted Price(INR)/Quintal": 8204,
    "Actual Price(INR)/Quintal": 7925,
    "Percentage Difference": "-3.51%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "May Wk1",
    "Predicted Price(INR)/Quintal": 7945,
    "Actual Price(INR)/Quintal": 7831,
    "Percentage Difference": "-1.46%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "May Wk2",
    "Predicted Price(INR)/Quintal": 8039,
    "Actual Price(INR)/Quintal": 7914,
    "Percentage Difference": "-1.58%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "May Wk3",
    "Predicted Price(INR)/Quintal": 8024,
    "Actual Price(INR)/Quintal": 7665,
    "Percentage Difference": "-4.68%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "May Wk4",
    "Predicted Price(INR)/Quintal": 7630,
    "Actual Price(INR)/Quintal": 7520,
    "Percentage Difference": "-1.46%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Jun Wk1",
    "Predicted Price(INR)/Quintal": 7528,
    "Actual Price(INR)/Quintal": 7541,
    "Percentage Difference": "0.00%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Jun Wk2",
    "Predicted Price(INR)/Quintal": 7526,
    "Actual Price(INR)/Quintal": 7351,
    "Percentage Difference": "-2.38%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Jun Wk3",
    "Predicted Price(INR)/Quintal": 7348,
    "Actual Price(INR)/Quintal": 7458,
    "Percentage Difference": "1.47%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Jun Wk4",
    "Predicted Price(INR)/Quintal": 7427,
    "Actual Price(INR)/Quintal": 7359,
    "Percentage Difference": "-0.01%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Jul Wk1",
    "Predicted Price(INR)/Quintal": 7376,
    "Actual Price(INR)/Quintal": 7431,
    "Percentage Difference": "0.74%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Jul Wk2",
    "Predicted Price(INR)/Quintal": 7357,
    "Actual Price(INR)/Quintal": 7314,
    "Percentage Difference": "-0.59%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Jul Wk3",
    "Predicted Price(INR)/Quintal": 7366,
    "Actual Price(INR)/Quintal": 7374,
    "Percentage Difference": "0.11%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Jul Wk4",
    "Predicted Price(INR)/Quintal": 7371,
    "Actual Price(INR)/Quintal": 7581,
    "Percentage Difference": "2.77%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Aug Wk1",
    "Predicted Price(INR)/Quintal": 7586,
    "Actual Price(INR)/Quintal": 7483,
    "Percentage Difference": "-1.34%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Aug Wk2",
    "Predicted Price(INR)/Quintal": 7538,
    "Actual Price(INR)/Quintal": 7344,
    "Percentage Difference": "-2.64%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Aug Wk3",
    "Predicted Price(INR)/Quintal": 7334,
    "Actual Price(INR)/Quintal": 7285,
    "Percentage Difference": "-0.67%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Aug Wk4",
    "Predicted Price(INR)/Quintal": 7336,
    "Actual Price(INR)/Quintal": 7263,
    "Percentage Difference": "-1.01%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Sep Wk1",
    "Predicted Price(INR)/Quintal": 7265,
    "Actual Price(INR)/Quintal": 7048,
    "Percentage Difference": "-3.08%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Sep Wk2",
    "Predicted Price(INR)/Quintal": 7013,
    "Actual Price(INR)/Quintal": 7056,
    "Percentage Difference": "0.60%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Sep Wk3",
    "Predicted Price(INR)/Quintal": 7033,
    "Actual Price(INR)/Quintal": 6844,
    "Percentage Difference": "-2.76%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Sep Wk4",
    "Predicted Price(INR)/Quintal": 6814,
    "Actual Price(INR)/Quintal": 6990,
    "Percentage Difference": "2.52%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Oct Wk1",
    "Predicted Price(INR)/Quintal": 6915,
    "Actual Price(INR)/Quintal": 6756,
    "Percentage Difference": "-2.35%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Oct Wk2",
    "Predicted Price(INR)/Quintal": 6841,
    "Actual Price(INR)/Quintal": 6763,
    "Percentage Difference": "-1.15%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Oct Wk3",
    "Predicted Price(INR)/Quintal": 6807,
    "Actual Price(INR)/Quintal": 6646,
    "Percentage Difference": "-2.42%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Oct Wk4",
    "Predicted Price(INR)/Quintal": 6640,
    "Actual Price(INR)/Quintal": 6602,
    "Percentage Difference": "-0.58%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Now Wk1",
    "Predicted Price(INR)/Quintal": 6707,
    "Actual Price(INR)/Quintal": 6449,
    "Percentage Difference": "-4.01%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Now Wk2",
    "Predicted Price(INR)/Quintal": 6551,
    "Actual Price(INR)/Quintal": 6232,
    "Percentage Difference": "-5.12%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Now Wk3",
    "Predicted Price(INR)/Quintal": 6223,
    "Actual Price(INR)/Quintal": 6242,
    "Percentage Difference": "0.30%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Now Wk4",
    "Predicted Price(INR)/Quintal": 6261,
    "Actual Price(INR)/Quintal": 6487,
    "Percentage Difference": "3.48%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Dec Wk1",
    "Predicted Price(INR)/Quintal": 6379,
    "Actual Price(INR)/Quintal": 6350,
    "Percentage Difference": "-0.46%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Dec Wk2",
    "Predicted Price(INR)/Quintal": 6365,
    "Actual Price(INR)/Quintal": 6367,
    "Percentage Difference": "0.03%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Dec Wk3",
    "Predicted Price(INR)/Quintal": 6326,
    "Actual Price(INR)/Quintal": 6684,
    "Percentage Difference": "5.35%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Dec Wk4",
    "Predicted Price(INR)/Quintal": 6570,
    "Actual Price(INR)/Quintal": 6580,
    "Percentage Difference": "0.15%",
    "YEAR": 2016
  }
];
