export const Maize = [
{ "Year : Month Week#":"Janwk1","Predicted Price(INR)/Quintal":2219,"Actual Price(INR)/Quintal":2238,"Percentage Difference":"0.85%","YEAR":2025},
{ "Year : Month Week#":"Janwk2","Predicted Price(INR)/Quintal":2250,"Actual Price(INR)/Quintal":2251,"Percentage Difference":"0.04%","YEAR":2025},
{ "Year : Month Week#":"Janwk3","Predicted Price(INR)/Quintal":2240,"Actual Price(INR)/Quintal":null,"Percentage Difference":null,"YEAR":2025},
{ "Year : Month Week#":"Janwk4","Predicted Price(INR)/Quintal":2265,"Actual Price(INR)/Quintal":null,"Percentage Difference":null,"YEAR":2025},
{ "Year : Month Week#":"Febwk1","Predicted Price(INR)/Quintal":2382,"Actual Price(INR)/Quintal":null,"Percentage Difference":null,"YEAR":2025},
{ "Year : Month Week#":"Febwk2","Predicted Price(INR)/Quintal":2240,"Actual Price(INR)/Quintal":null,"Percentage Difference":null,"YEAR":2025},
{ "Year : Month Week#":"Janwk1","Predicted Price(INR)/Quintal":2097,"Actual Price(INR)/Quintal":2172,"Percentage Difference":"3.45%","YEAR":2024},
{ "Year : Month Week#":"Janwk2","Predicted Price(INR)/Quintal":2120,"Actual Price(INR)/Quintal":2164,"Percentage Difference":"2.03%","YEAR":2024},
{ "Year : Month Week#":"Janwk3","Predicted Price(INR)/Quintal":2119,"Actual Price(INR)/Quintal":2203,"Percentage Difference":"3.81%","YEAR":2024},
{ "Year : Month Week#":"Janwk4","Predicted Price(INR)/Quintal":2146,"Actual Price(INR)/Quintal":2206,"Percentage Difference":"2.72%","YEAR":2024},
{ "Year : Month Week#":"Febwk1","Predicted Price(INR)/Quintal":2179,"Actual Price(INR)/Quintal":2188,"Percentage Difference":"0.41%","YEAR":2024},
{ "Year : Month Week#":"Febwk2","Predicted Price(INR)/Quintal":2140,"Actual Price(INR)/Quintal":2236,"Percentage Difference":"4.29%","YEAR":2024},
{ "Year : Month Week#":"Febwk3","Predicted Price(INR)/Quintal":2128,"Actual Price(INR)/Quintal":2209,"Percentage Difference":"3.67%","YEAR":2024},
{ "Year : Month Week#":"Febwk4","Predicted Price(INR)/Quintal":2218,"Actual Price(INR)/Quintal":2241,"Percentage Difference":"1.03%","YEAR":2024},
{ "Year : Month Week#":"Marwk1","Predicted Price(INR)/Quintal":2273,"Actual Price(INR)/Quintal":2266,"Percentage Difference":"-0.31%","YEAR":2024},
{ "Year : Month Week#":"Marwk2","Predicted Price(INR)/Quintal":2248,"Actual Price(INR)/Quintal":2224,"Percentage Difference":"-1.08%","YEAR":2024},
{ "Year : Month Week#":"Marwk3","Predicted Price(INR)/Quintal":2226,"Actual Price(INR)/Quintal":2229,"Percentage Difference":"0.13%","YEAR":2024},
{ "Year : Month Week#":"Marwk4","Predicted Price(INR)/Quintal":2282,"Actual Price(INR)/Quintal":2253,"Percentage Difference":"-1.29%","YEAR":2024},
{ "Year : Month Week#":"Aprw1","Predicted Price(INR)/Quintal":2278,"Actual Price(INR)/Quintal":2251,"Percentage Difference":"-1.20%","YEAR":2024},
{ "Year : Month Week#":"Aprw2","Predicted Price(INR)/Quintal":2220,"Actual Price(INR)/Quintal":2250,"Percentage Difference":"1.33%","YEAR":2024},
{ "Year : Month Week#":"Aprwk3","Predicted Price(INR)/Quintal":2260,"Actual Price(INR)/Quintal":2259,"Percentage Difference":"-0.04%","YEAR":2024},
{ "Year : Month Week#":"Aprwk4","Predicted Price(INR)/Quintal":2242,"Actual Price(INR)/Quintal":2269,"Percentage Difference":"1.19%","YEAR":2024},
{ "Year : Month Week#":"Maywk1","Predicted Price(INR)/Quintal":2256,"Actual Price(INR)/Quintal":2271,"Percentage Difference":"0.66%","YEAR":2024},
{ "Year : Month Week#":"Maywk2","Predicted Price(INR)/Quintal":2255,"Actual Price(INR)/Quintal":2288,"Percentage Difference":"1.44%","YEAR":2024},
{ "Year : Month Week#":"Maywk3","Predicted Price(INR)/Quintal":2261,"Actual Price(INR)/Quintal":2317,"Percentage Difference":"2.42%","YEAR":2024},
{ "Year : Month Week#":"Maywk4","Predicted Price(INR)/Quintal":2251,"Actual Price(INR)/Quintal":2323,"Percentage Difference":"3.10%","YEAR":2024},
{ "Year : Month Week#":"Junwk1","Predicted Price(INR)/Quintal":2357,"Actual Price(INR)/Quintal":2375,"Percentage Difference":"0.76%","YEAR":2024},
{ "Year : Month Week#":"Junwk2","Predicted Price(INR)/Quintal":2471,"Actual Price(INR)/Quintal":2485,"Percentage Difference":"0.56%","YEAR":2024},
{ "Year : Month Week#":"Junwk3","Predicted Price(INR)/Quintal":2395,"Actual Price(INR)/Quintal":2450,"Percentage Difference":"2.24%","YEAR":2024},
{ "Year : Month Week#":"Junwk4","Predicted Price(INR)/Quintal":2316,"Actual Price(INR)/Quintal":2395,"Percentage Difference":"3.30%","YEAR":2024},
{ "Year : Month Week#":"Julwk1","Predicted Price(INR)/Quintal":2471,"Actual Price(INR)/Quintal":2523,"Percentage Difference":"2.06%","YEAR":2024},
{ "Year : Month Week#":"Julwk2","Predicted Price(INR)/Quintal":2438,"Actual Price(INR)/Quintal":2533,"Percentage Difference":"3.75%","YEAR":2024},
{ "Year : Month Week#":"Julwk3","Predicted Price(INR)/Quintal":2468,"Actual Price(INR)/Quintal":2603,"Percentage Difference":"5.19%","YEAR":2024},
{ "Year : Month Week#":"Julwk4","Predicted Price(INR)/Quintal":2587,"Actual Price(INR)/Quintal":2646,"Percentage Difference":"2.23%","YEAR":2024},
{ "Year : Month Week#":"Augwk1","Predicted Price(INR)/Quintal":2543,"Actual Price(INR)/Quintal":2549,"Percentage Difference":"0.24%","YEAR":2024},
{ "Year : Month Week#":"Augwk2","Predicted Price(INR)/Quintal":2559,"Actual Price(INR)/Quintal":2601,"Percentage Difference":"1.61%","YEAR":2024},
{ "Year : Month Week#":"Augwk3","Predicted Price(INR)/Quintal":2576,"Actual Price(INR)/Quintal":2634,"Percentage Difference":"2.20%","YEAR":2024},
{ "Year : Month Week#":"Augwk4","Predicted Price(INR)/Quintal":2589,"Actual Price(INR)/Quintal":2602,"Percentage Difference":"0.50%","YEAR":2024},
{ "Year : Month Week#":"Sepwk1","Predicted Price(INR)/Quintal":2503,"Actual Price(INR)/Quintal":2576,"Percentage Difference":"2.83%","YEAR":2024},
{ "Year : Month Week#":"Sepwk2","Predicted Price(INR)/Quintal":2514,"Actual Price(INR)/Quintal":2567,"Percentage Difference":"2.06%","YEAR":2024},
{ "Year : Month Week#":"Sepwk3","Predicted Price(INR)/Quintal":2408,"Actual Price(INR)/Quintal":2339,"Percentage Difference":"-2.95%","YEAR":2024},
{ "Year : Month Week#":"Sepwk4","Predicted Price(INR)/Quintal":2253,"Actual Price(INR)/Quintal":2176,"Percentage Difference":"-3.54%","YEAR":2024},
{ "Year : Month Week#":"Octwk1","Predicted Price(INR)/Quintal":2139,"Actual Price(INR)/Quintal":2225,"Percentage Difference":"3.87%","YEAR":2024},
{ "Year : Month Week#":"Octwk2","Predicted Price(INR)/Quintal":2142,"Actual Price(INR)/Quintal":2185,"Percentage Difference":"1.97%","YEAR":2024},
{ "Year : Month Week#":"Octwk3","Predicted Price(INR)/Quintal":2133,"Actual Price(INR)/Quintal":2193,"Percentage Difference":"2.74%","YEAR":2024},
{ "Year : Month Week#":"Octwk4","Predicted Price(INR)/Quintal":2172,"Actual Price(INR)/Quintal":2208,"Percentage Difference":"1.63%","YEAR":2024},
{ "Year : Month Week#":"Novwk1","Predicted Price(INR)/Quintal":2145,"Actual Price(INR)/Quintal":2218,"Percentage Difference":"3.29%","YEAR":2024},
{ "Year : Month Week#":"Novwk2","Predicted Price(INR)/Quintal":2244,"Actual Price(INR)/Quintal":2213,"Percentage Difference":"-1.40%","YEAR":2024},
{ "Year : Month Week#":"Novwk3","Predicted Price(INR)/Quintal":2202,"Actual Price(INR)/Quintal":2177,"Percentage Difference":"-1.15%","YEAR":2024},
{ "Year : Month Week#":"Novwk4","Predicted Price(INR)/Quintal":2148,"Actual Price(INR)/Quintal":2203,"Percentage Difference":"2.50%","YEAR":2024},
{ "Year : Month Week#":"Decwk1","Predicted Price(INR)/Quintal":2144,"Actual Price(INR)/Quintal":2208,"Percentage Difference":"2.90%","YEAR":2024},
{ "Year : Month Week#":"Decwk2","Predicted Price(INR)/Quintal":2125,"Actual Price(INR)/Quintal":2207,"Percentage Difference":"3.72%","YEAR":2024},
{ "Year : Month Week#":"Decwk3","Predicted Price(INR)/Quintal":2148,"Actual Price(INR)/Quintal":2240,"Percentage Difference":"4.11%","YEAR":2024},
{ "Year : Month Week#":"Decwk4","Predicted Price(INR)/Quintal":2236,"Actual Price(INR)/Quintal":2247,"Percentage Difference":"0.49%","YEAR":2024},
{ "Year : Month Week#":"Janwk1","Predicted Price(INR)/Quintal":2097,"Actual Price(INR)/Quintal":2172,"Percentage Difference":"3.45%","YEAR":2023},
{ "Year : Month Week#":"Janwk2","Predicted Price(INR)/Quintal":2120,"Actual Price(INR)/Quintal":2130,"Percentage Difference":"0.47%","YEAR":2023},
{ "Year : Month Week#":"Janwk3","Predicted Price(INR)/Quintal":2119,"Actual Price(INR)/Quintal":2140,"Percentage Difference":"0.98%","YEAR":2023},
{ "Year : Month Week#":"Janwk4","Predicted Price(INR)/Quintal":2146,"Actual Price(INR)/Quintal":2203,"Percentage Difference":"2.59%","YEAR":2023},
{ "Year : Month Week#":"Febwk1","Predicted Price(INR)/Quintal":2179,"Actual Price(INR)/Quintal":2184,"Percentage Difference":"0.23%","YEAR":2023},
{ "Year : Month Week#":"Febwk2","Predicted Price(INR)/Quintal":2161,"Actual Price(INR)/Quintal":2174,"Percentage Difference":"0.60%","YEAR":2023},
{ "Year : Month Week#":"Febwk3","Predicted Price(INR)/Quintal":2167,"Actual Price(INR)/Quintal":2121,"Percentage Difference":"-2.17%","YEAR":2023},
{ "Year : Month Week#":"Febwk4","Predicted Price(INR)/Quintal":2177,"Actual Price(INR)/Quintal":2221,"Percentage Difference":"1.98%","YEAR":2023},
{ "Year : Month Week#":"Marwk1","Predicted Price(INR)/Quintal":2166,"Actual Price(INR)/Quintal":2140,"Percentage Difference":"-1.21%","YEAR":2023},
{ "Year : Month Week#":"Marwk2","Predicted Price(INR)/Quintal":2150,"Actual Price(INR)/Quintal":2116,"Percentage Difference":"-1.61%","YEAR":2023},
{ "Year : Month Week#":"Marwk3","Predicted Price(INR)/Quintal":2094,"Actual Price(INR)/Quintal":2124,"Percentage Difference":"1.41%","YEAR":2023},
{ "Year : Month Week#":"Marwk4","Predicted Price(INR)/Quintal":2113,"Actual Price(INR)/Quintal":2093,"Percentage Difference":"-0.96%","YEAR":2023},
{ "Year : Month Week#":"Aprwk1","Predicted Price(INR)/Quintal":2127,"Actual Price(INR)/Quintal":2123,"Percentage Difference":"-0.19%","YEAR":2023},
{ "Year : Month Week#":"Aprwk2","Predicted Price(INR)/Quintal":1941,"Actual Price(INR)/Quintal":2018,"Percentage Difference":"3.82%","YEAR":2023},
{ "Year : Month Week#":"Aprwk3","Predicted Price(INR)/Quintal":1954,"Actual Price(INR)/Quintal":1961,"Percentage Difference":"0.36%","YEAR":2023},
{ "Year : Month Week#":"Aprwk4","Predicted Price(INR)/Quintal":1878,"Actual Price(INR)/Quintal":1857,"Percentage Difference":"-1.13%","YEAR":2023},
{ "Year : Month Week#":"Maywk1","Predicted Price(INR)/Quintal":1857,"Actual Price(INR)/Quintal":1862,"Percentage Difference":"0.27%","YEAR":2023},
{ "Year : Month Week#":"Maywk2","Predicted Price(INR)/Quintal":1923,"Actual Price(INR)/Quintal":1947,"Percentage Difference":"1.23%","YEAR":2023},
{ "Year : Month Week#":"Maywk3","Predicted Price(INR)/Quintal":1920,"Actual Price(INR)/Quintal":1910,"Percentage Difference":"-0.52%","YEAR":2023},
{ "Year : Month Week#":"Maywk4","Predicted Price(INR)/Quintal":1888,"Actual Price(INR)/Quintal":1905,"Percentage Difference":"0.89%","YEAR":2023},
{ "Year : Month Week#":"Junwk1","Predicted Price(INR)/Quintal":1900,"Actual Price(INR)/Quintal":1978,"Percentage Difference":"3.94%","YEAR":2023},
{ "Year : Month Week#":"Junwk2","Predicted Price(INR)/Quintal":1992,"Actual Price(INR)/Quintal":1942,"Percentage Difference":"-2.57%","YEAR":2023},
{ "Year : Month Week#":"Junwk3","Predicted Price(INR)/Quintal":1952,"Actual Price(INR)/Quintal":1978,"Percentage Difference":"1.31%","YEAR":2023},
{ "Year : Month Week#":"Junwk4","Predicted Price(INR)/Quintal":1958,"Actual Price(INR)/Quintal":2008,"Percentage Difference":"2.49%","YEAR":2023},
{ "Year : Month Week#":"Julwk1","Predicted Price(INR)/Quintal":2052,"Actual Price(INR)/Quintal":2098,"Percentage Difference":"2.19%","YEAR":2023},
{ "Year : Month Week#":"Julwk2","Predicted Price(INR)/Quintal":2044,"Actual Price(INR)/Quintal":2133,"Percentage Difference":"4.17%","YEAR":2023},
{ "Year : Month Week#":"Julwk3","Predicted Price(INR)/Quintal":2052,"Actual Price(INR)/Quintal":2118,"Percentage Difference":"3.12%","YEAR":2023},
{ "Year : Month Week#":"Julwk4","Predicted Price(INR)/Quintal":2158,"Actual Price(INR)/Quintal":2160,"Percentage Difference":"0.09%","YEAR":2023},
{ "Year : Month Week#":"Augwk1","Predicted Price(INR)/Quintal":2144,"Actual Price(INR)/Quintal":2249,"Percentage Difference":"4.67%","YEAR":2023},
{ "Year : Month Week#":"Augwk2","Predicted Price(INR)/Quintal":2083,"Actual Price(INR)/Quintal":2160,"Percentage Difference":"3.56%","YEAR":2023},
{ "Year : Month Week#":"Augwk3","Predicted Price(INR)/Quintal":2098,"Actual Price(INR)/Quintal":2134,"Percentage Difference":"1.69%","YEAR":2023},
{ "Year : Month Week#":"Augwk4","Predicted Price(INR)/Quintal":2150,"Actual Price(INR)/Quintal":2190,"Percentage Difference":"1.83%","YEAR":2023},
{ "Year : Month Week#":"Sepwk1","Predicted Price(INR)/Quintal":2189,"Actual Price(INR)/Quintal":2298,"Percentage Difference":"4.74%","YEAR":2023},
{ "Year : Month Week#":"Sepwk2","Predicted Price(INR)/Quintal":2223,"Actual Price(INR)/Quintal":2201,"Percentage Difference":"-1.00%","YEAR":2023},
{ "Year : Month Week#":"Sepwk3","Predicted Price(INR)/Quintal":2229,"Actual Price(INR)/Quintal":2214,"Percentage Difference":"-0.68%","YEAR":2023},
{ "Year : Month Week#":"Sepwk4","Predicted Price(INR)/Quintal":2148,"Actual Price(INR)/Quintal":2130,"Percentage Difference":"-0.85%","YEAR":2023},
{ "Year : Month Week#":"Octwk1","Predicted Price(INR)/Quintal":2198,"Actual Price(INR)/Quintal":2165,"Percentage Difference":"-1.52%","YEAR":2023},
{ "Year : Month Week#":"Octwk2","Predicted Price(INR)/Quintal":2160,"Actual Price(INR)/Quintal":2142,"Percentage Difference":"-0.84%","YEAR":2023},
{ "Year : Month Week#":"Octwk3","Predicted Price(INR)/Quintal":2170,"Actual Price(INR)/Quintal":2132,"Percentage Difference":"-1.78%","YEAR":2023},
{ "Year : Month Week#":"Octwk4","Predicted Price(INR)/Quintal":2155,"Actual Price(INR)/Quintal":2159,"Percentage Difference":"0.19%","YEAR":2023},
{ "Year : Month Week#":"Novwk1","Predicted Price(INR)/Quintal":2169,"Actual Price(INR)/Quintal":2196,"Percentage Difference":"1.23%","YEAR":2023},
{ "Year : Month Week#":"Novwk2","Predicted Price(INR)/Quintal":2171,"Actual Price(INR)/Quintal":2173,"Percentage Difference":"0.09%","YEAR":2023},
{ "Year : Month Week#":"Novwk3","Predicted Price(INR)/Quintal":2149,"Actual Price(INR)/Quintal":2226,"Percentage Difference":"3.46%","YEAR":2023},
{ "Year : Month Week#":"Novwk4","Predicted Price(INR)/Quintal":2184,"Actual Price(INR)/Quintal":2230,"Percentage Difference":"2.06%","YEAR":2023},
{ "Year : Month Week#":"Decwk1","Predicted Price(INR)/Quintal":2131,"Actual Price(INR)/Quintal":2212,"Percentage Difference":"3.66%","YEAR":2023},
{ "Year : Month Week#":"Decwk2","Predicted Price(INR)/Quintal":2161,"Actual Price(INR)/Quintal":2187,"Percentage Difference":"1.19%","YEAR":2023},
{ "Year : Month Week#":"Decwk3","Predicted Price(INR)/Quintal":2132,"Actual Price(INR)/Quintal":2137,"Percentage Difference":"0.23%","YEAR":2023},
{ "Year : Month Week#":"Decwk4","Predicted Price(INR)/Quintal":2139,"Actual Price(INR)/Quintal":2147,"Percentage Difference":"0.37%","YEAR":2023},
{ "Year : Month Week#":"Janwk1","Predicted Price(INR)/Quintal":1609,"Actual Price(INR)/Quintal":1655,"Percentage Difference":"2.78%","YEAR":2022},
{ "Year : Month Week#":"Janwk2","Predicted Price(INR)/Quintal":1614,"Actual Price(INR)/Quintal":1696,"Percentage Difference":"4.83%","YEAR":2022},
{ "Year : Month Week#":"Janwk3","Predicted Price(INR)/Quintal":1716,"Actual Price(INR)/Quintal":1718,"Percentage Difference":"0.12%","YEAR":2022},
{ "Year : Month Week#":"Janwk4","Predicted Price(INR)/Quintal":1751,"Actual Price(INR)/Quintal":1813,"Percentage Difference":"3.42%","YEAR":2022},
{ "Year : Month Week#":"Febwk1","Predicted Price(INR)/Quintal":1902,"Actual Price(INR)/Quintal":1973,"Percentage Difference":"3.60%","YEAR":2022},
{ "Year : Month Week#":"Febwk2","Predicted Price(INR)/Quintal":1907,"Actual Price(INR)/Quintal":1910,"Percentage Difference":"0.16%","YEAR":2022},
{ "Year : Month Week#":"Febwk3","Predicted Price(INR)/Quintal":1924,"Actual Price(INR)/Quintal":1940,"Percentage Difference":"0.82%","YEAR":2022},
{ "Year : Month Week#":"Febwk4","Predicted Price(INR)/Quintal":1916,"Actual Price(INR)/Quintal":1965,"Percentage Difference":"2.49%","YEAR":2022},
{ "Year : Month Week#":"Marwk1","Predicted Price(INR)/Quintal":2034,"Actual Price(INR)/Quintal":2037,"Percentage Difference":"0.15%","YEAR":2022},
{ "Year : Month Week#":"Marwk2","Predicted Price(INR)/Quintal":2144,"Actual Price(INR)/Quintal":2218,"Percentage Difference":"3.34%","YEAR":2022},
{ "Year : Month Week#":"Marwk3","Predicted Price(INR)/Quintal":2166,"Actual Price(INR)/Quintal":2167,"Percentage Difference":"0.05%","YEAR":2022},
{ "Year : Month Week#":"Marwk4","Predicted Price(INR)/Quintal":2143,"Actual Price(INR)/Quintal":2165,"Percentage Difference":"1.02%","YEAR":2022},
{ "Year : Month Week#":"Aprwk1","Predicted Price(INR)/Quintal":2161,"Actual Price(INR)/Quintal":2177,"Percentage Difference":"0.73%","YEAR":2022},
{ "Year : Month Week#":"Aprwk2","Predicted Price(INR)/Quintal":2156,"Actual Price(INR)/Quintal":2186,"Percentage Difference":"1.37%","YEAR":2022},
{ "Year : Month Week#":"Aprwk3","Predicted Price(INR)/Quintal":2102,"Actual Price(INR)/Quintal":2207,"Percentage Difference":"4.76%","YEAR":2022},
{ "Year : Month Week#":"Aprwk4","Predicted Price(INR)/Quintal":2120,"Actual Price(INR)/Quintal":2217,"Percentage Difference":"4.38%","YEAR":2022},
{ "Year : Month Week#":"Maywk1","Predicted Price(INR)/Quintal":2165,"Actual Price(INR)/Quintal":2180,"Percentage Difference":"0.69%","YEAR":2022},
{ "Year : Month Week#":"Maywk2","Predicted Price(INR)/Quintal":2138,"Actual Price(INR)/Quintal":2187,"Percentage Difference":"2.24%","YEAR":2022},
{ "Year : Month Week#":"Maywk3","Predicted Price(INR)/Quintal":2130,"Actual Price(INR)/Quintal":2181,"Percentage Difference":"2.34%","YEAR":2022},
{ "Year : Month Week#":"Maywk4","Predicted Price(INR)/Quintal":2131,"Actual Price(INR)/Quintal":2144,"Percentage Difference":"0.61%","YEAR":2022},
{ "Year : Month Week#":"Junwk1","Predicted Price(INR)/Quintal":2140,"Actual Price(INR)/Quintal":2203,"Percentage Difference":"2.86%","YEAR":2022},
{ "Year : Month Week#":"Junwk2","Predicted Price(INR)/Quintal":2133,"Actual Price(INR)/Quintal":2198,"Percentage Difference":"2.96%","YEAR":2022},
{ "Year : Month Week#":"Junwk3","Predicted Price(INR)/Quintal":2151,"Actual Price(INR)/Quintal":2168,"Percentage Difference":"0.78%","YEAR":2022},
{ "Year : Month Week#":"Junwk4","Predicted Price(INR)/Quintal":2144,"Actual Price(INR)/Quintal":2186,"Percentage Difference":"1.92%","YEAR":2022},
{ "Year : Month Week#":"Julwk1","Predicted Price(INR)/Quintal":2185,"Actual Price(INR)/Quintal":2160,"Percentage Difference":"-1.16%","YEAR":2022},
{ "Year : Month Week#":"Julwk2","Predicted Price(INR)/Quintal":2192,"Actual Price(INR)/Quintal":2199,"Percentage Difference":"0.32%","YEAR":2022},
{ "Year : Month Week#":"Julwk3","Predicted Price(INR)/Quintal":2190,"Actual Price(INR)/Quintal":2212,"Percentage Difference":"0.99%","YEAR":2022},
{ "Year : Month Week#":"Julwk4","Predicted Price(INR)/Quintal":2178,"Actual Price(INR)/Quintal":2238,"Percentage Difference":"2.68%","YEAR":2022},
{ "Year : Month Week#":"Augwk1","Predicted Price(INR)/Quintal":2169,"Actual Price(INR)/Quintal":2212,"Percentage Difference":"1.94%","YEAR":2022},
{ "Year : Month Week#":"Augwk2","Predicted Price(INR)/Quintal":2144,"Actual Price(INR)/Quintal":2238,"Percentage Difference":"4.20%","YEAR":2022},
{ "Year : Month Week#":"Augwk3","Predicted Price(INR)/Quintal":2162,"Actual Price(INR)/Quintal":2220,"Percentage Difference":"2.61%","YEAR":2022},
{ "Year : Month Week#":"Augwk4","Predicted Price(INR)/Quintal":2155,"Actual Price(INR)/Quintal":2230,"Percentage Difference":"3.36%","YEAR":2022},
{ "Year : Month Week#":"Sepwk1","Predicted Price(INR)/Quintal":2237,"Actual Price(INR)/Quintal":2183,"Percentage Difference":"-2.47%","YEAR":2022},
{ "Year : Month Week#":"Sepwk2","Predicted Price(INR)/Quintal":2206,"Actual Price(INR)/Quintal":2100,"Percentage Difference":"-5.05%","YEAR":2022},
{ "Year : Month Week#":"Sepwk3","Predicted Price(INR)/Quintal":2244,"Actual Price(INR)/Quintal":2192,"Percentage Difference":"-2.37%","YEAR":2022},
{ "Year : Month Week#":"Sepwk4","Predicted Price(INR)/Quintal":1957,"Actual Price(INR)/Quintal":1953,"Percentage Difference":"-0.20%","YEAR":2022},
{ "Year : Month Week#":"Octwk1","Predicted Price(INR)/Quintal":1995,"Actual Price(INR)/Quintal":1989,"Percentage Difference":"-0.30%","YEAR":2022},
{ "Year : Month Week#":"Octwk2","Predicted Price(INR)/Quintal":1929,"Actual Price(INR)/Quintal":1985,"Percentage Difference":"2.82%","YEAR":2022},
{ "Year : Month Week#":"Octwk3","Predicted Price(INR)/Quintal":1988,"Actual Price(INR)/Quintal":2075,"Percentage Difference":"4.19%","YEAR":2022},
{ "Year : Month Week#":"Octwk4","Predicted Price(INR)/Quintal":2135,"Actual Price(INR)/Quintal":2040,"Percentage Difference":"-4.66%","YEAR":2022},
{ "Year : Month Week#":"Novwk1","Predicted Price(INR)/Quintal":2011,"Actual Price(INR)/Quintal":1998,"Percentage Difference":"-0.65%","YEAR":2022},
{ "Year : Month Week#":"Novwk2","Predicted Price(INR)/Quintal":2019,"Actual Price(INR)/Quintal":2052,"Percentage Difference":"1.61%","YEAR":2022},
{ "Year : Month Week#":"Novwk3","Predicted Price(INR)/Quintal":2029,"Actual Price(INR)/Quintal":2087,"Percentage Difference":"2.78%","YEAR":2022},
{ "Year : Month Week#":"Novwk4","Predicted Price(INR)/Quintal":2009,"Actual Price(INR)/Quintal":2022,"Percentage Difference":"0.64%","YEAR":2022},
{ "Year : Month Week#":"Decwk1","Predicted Price(INR)/Quintal":2031,"Actual Price(INR)/Quintal":2122,"Percentage Difference":"4.29%","YEAR":2022},
{ "Year : Month Week#":"Decwk2","Predicted Price(INR)/Quintal":2135,"Actual Price(INR)/Quintal":2186,"Percentage Difference":"2.33%","YEAR":2022},
{ "Year : Month Week#":"Decwk3","Predicted Price(INR)/Quintal":2144,"Actual Price(INR)/Quintal":2150,"Percentage Difference":"0.28%","YEAR":2022},
{ "Year : Month Week#":"Decwk4","Predicted Price(INR)/Quintal":2141,"Actual Price(INR)/Quintal":2192,"Percentage Difference":"2.33%","YEAR":2022},
{ "Year : Month Week#":"JanWk1","Predicted Price(INR)/Quintal":1347,"Actual Price(INR)/Quintal":1331,"Percentage Difference":"-1.20%","YEAR":2021},
{ "Year : Month Week#":"JanWk2","Predicted Price(INR)/Quintal":1305,"Actual Price(INR)/Quintal":1310,"Percentage Difference":"0.38%","YEAR":2021},
{ "Year : Month Week#":"JanWk3","Predicted Price(INR)/Quintal":1317,"Actual Price(INR)/Quintal":1332,"Percentage Difference":"1.13%","YEAR":2021},
{ "Year : Month Week#":"JanWk4","Predicted Price(INR)/Quintal":1321,"Actual Price(INR)/Quintal":1336,"Percentage Difference":"1.12%","YEAR":2021},
{ "Year : Month Week#":"FebWk1","Predicted Price(INR)/Quintal":1349,"Actual Price(INR)/Quintal":1359,"Percentage Difference":"0.74%","YEAR":2021},
{ "Year : Month Week#":"FebWk2","Predicted Price(INR)/Quintal":1345,"Actual Price(INR)/Quintal":1362,"Percentage Difference":"1.25%","YEAR":2021},
{ "Year : Month Week#":"FebWk3","Predicted Price(INR)/Quintal":1386,"Actual Price(INR)/Quintal":1412,"Percentage Difference":"1.84%","YEAR":2021},
{ "Year : Month Week#":"FebWk4","Predicted Price(INR)/Quintal":1488,"Actual Price(INR)/Quintal":1510,"Percentage Difference":"1.46%","YEAR":2021},
{ "Year : Month Week#":"MarWk1","Predicted Price(INR)/Quintal":1485,"Actual Price(INR)/Quintal":1495,"Percentage Difference":"0.67%","YEAR":2021},
{ "Year : Month Week#":"MarWk2","Predicted Price(INR)/Quintal":1498,"Actual Price(INR)/Quintal":1513,"Percentage Difference":"0.99%","YEAR":2021},
{ "Year : Month Week#":"MarWk3","Predicted Price(INR)/Quintal":1497,"Actual Price(INR)/Quintal":1469,"Percentage Difference":"-1.91%","YEAR":2021},
{ "Year : Month Week#":"MarWk4","Predicted Price(INR)/Quintal":1506,"Actual Price(INR)/Quintal":1508,"Percentage Difference":"0.13%","YEAR":2021},
{ "Year : Month Week#":"AprWk1","Predicted Price(INR)/Quintal":1475,"Actual Price(INR)/Quintal":1510,"Percentage Difference":"2.32%","YEAR":2021},
{ "Year : Month Week#":"AprWk2","Predicted Price(INR)/Quintal":1492,"Actual Price(INR)/Quintal":1525,"Percentage Difference":"2.16%","YEAR":2021},
{ "Year : Month Week#":"AprWk3","Predicted Price(INR)/Quintal":1529,"Actual Price(INR)/Quintal":1551,"Percentage Difference":"1.42%","YEAR":2021},
{ "Year : Month Week#":"AprWk4","Predicted Price(INR)/Quintal":1531,"Actual Price(INR)/Quintal":1593,"Percentage Difference":"3.89%","YEAR":2021},
{ "Year : Month Week#":"Maywk1","Predicted Price(INR)/Quintal":1541,"Actual Price(INR)/Quintal":1565,"Percentage Difference":"1.53%","YEAR":2021},
{ "Year : Month Week#":"Maywk2","Predicted Price(INR)/Quintal":1554,"Actual Price(INR)/Quintal":1582,"Percentage Difference":"1.77%","YEAR":2021},
{ "Year : Month Week#":"Maywk3","Predicted Price(INR)/Quintal":1581,"Actual Price(INR)/Quintal":1587,"Percentage Difference":"0.38%","YEAR":2021},
{ "Year : Month Week#":"Maywk4","Predicted Price(INR)/Quintal":1585,"Actual Price(INR)/Quintal":1585,"Percentage Difference":"0.00%","YEAR":2021},
{ "Year : Month Week#":"Junwk1","Predicted Price(INR)/Quintal":1599,"Actual Price(INR)/Quintal":1615,"Percentage Difference":"0.99%","YEAR":2021},
{ "Year : Month Week#":"Junwk2","Predicted Price(INR)/Quintal":1585,"Actual Price(INR)/Quintal":1602,"Percentage Difference":"1.06%","YEAR":2021},
{ "Year : Month Week#":"Junwk3","Predicted Price(INR)/Quintal":1563,"Actual Price(INR)/Quintal":1605,"Percentage Difference":"2.62%","YEAR":2021},
{ "Year : Month Week#":"Junwk4","Predicted Price(INR)/Quintal":1794,"Actual Price(INR)/Quintal":1830,"Percentage Difference":"1.97%","YEAR":2021},
{ "Year : Month Week#":"Julwk1","Predicted Price(INR)/Quintal":1792,"Actual Price(INR)/Quintal":1828,"Percentage Difference":"1.97%","YEAR":2021},
{ "Year : Month Week#":"Julwk2","Predicted Price(INR)/Quintal":1895,"Actual Price(INR)/Quintal":1946,"Percentage Difference":"2.62%","YEAR":2021},
{ "Year : Month Week#":"Julwk3","Predicted Price(INR)/Quintal":1921,"Actual Price(INR)/Quintal":1962,"Percentage Difference":"2.09%","YEAR":2021},
{ "Year : Month Week#":"Julwk4","Predicted Price(INR)/Quintal":1939,"Actual Price(INR)/Quintal":1975,"Percentage Difference":"1.82%","YEAR":2021},
{ "Year : Month Week#":"Augwk1","Predicted Price(INR)/Quintal":1938,"Actual Price(INR)/Quintal":1973,"Percentage Difference":"1.77%","YEAR":2021},
{ "Year : Month Week#":"Augwk2","Predicted Price(INR)/Quintal":1964,"Actual Price(INR)/Quintal":1965,"Percentage Difference":"0.05%","YEAR":2021},
{ "Year : Month Week#":"Augwk3","Predicted Price(INR)/Quintal":1949,"Actual Price(INR)/Quintal":1957,"Percentage Difference":"0.41%","YEAR":2021},
{ "Year : Month Week#":"Augwk4","Predicted Price(INR)/Quintal":1923,"Actual Price(INR)/Quintal":1928,"Percentage Difference":"0.26%","YEAR":2021},
{ "Year : Month Week#":"Sepwk1","Predicted Price(INR)/Quintal":1982,"Actual Price(INR)/Quintal":1966,"Percentage Difference":"-0.81%","YEAR":2021},
{ "Year : Month Week#":"Sepwk2","Predicted Price(INR)/Quintal":1968,"Actual Price(INR)/Quintal":1970,"Percentage Difference":"0.10%","YEAR":2021},
{ "Year : Month Week#":"Sepwk3","Predicted Price(INR)/Quintal":1848,"Actual Price(INR)/Quintal":1870,"Percentage Difference":"1.18%","YEAR":2021},
{ "Year : Month Week#":"Sepwk4","Predicted Price(INR)/Quintal":1849,"Actual Price(INR)/Quintal":1800,"Percentage Difference":"-2.72%","YEAR":2021},
{ "Year : Month Week#":"Octwk1","Predicted Price(INR)/Quintal":1765,"Actual Price(INR)/Quintal":1701,"Percentage Difference":"-3.76%","YEAR":2021},
{ "Year : Month Week#":"Octwk2","Predicted Price(INR)/Quintal":1656,"Actual Price(INR)/Quintal":1632,"Percentage Difference":"-1.47%","YEAR":2021},
{ "Year : Month Week#":"Octwk3","Predicted Price(INR)/Quintal":1658,"Actual Price(INR)/Quintal":1656,"Percentage Difference":"-0.12%","YEAR":2021},
{ "Year : Month Week#":"Octwk4","Predicted Price(INR)/Quintal":1665,"Actual Price(INR)/Quintal":1667,"Percentage Difference":"0.12%","YEAR":2021},
{ "Year : Month Week#":"Novwk1","Predicted Price(INR)/Quintal":1648,"Actual Price(INR)/Quintal":1638,"Percentage Difference":"-0.61%","YEAR":2021},
{ "Year : Month Week#":"Novwk2","Predicted Price(INR)/Quintal":1618,"Actual Price(INR)/Quintal":1629,"Percentage Difference":"0.68%","YEAR":2021},
{ "Year : Month Week#":"Novwk3","Predicted Price(INR)/Quintal":1622,"Actual Price(INR)/Quintal":1628,"Percentage Difference":"0.37%","YEAR":2021},
{ "Year : Month Week#":"Novwk4","Predicted Price(INR)/Quintal":1630,"Actual Price(INR)/Quintal":1637,"Percentage Difference":"0.43%","YEAR":2021},
{ "Year : Month Week#":"Decwk1","Predicted Price(INR)/Quintal":1633,"Actual Price(INR)/Quintal":1641,"Percentage Difference":"0.49%","YEAR":2021},
{ "Year : Month Week#":"Decwk2","Predicted Price(INR)/Quintal":1669,"Actual Price(INR)/Quintal":1639,"Percentage Difference":"-1.83%","YEAR":2021},
{ "Year : Month Week#":"Decwk3","Predicted Price(INR)/Quintal":1629,"Actual Price(INR)/Quintal":1644,"Percentage Difference":"0.91%","YEAR":2021},
{ "Year : Month Week#":"Decwk4","Predicted Price(INR)/Quintal":1632,"Actual Price(INR)/Quintal":1604,"Percentage Difference":"-1.75%","YEAR":2021},
{ "Year : Month Week#":"JanWk1","Predicted Price(INR)/Quintal":1829,"Actual Price(INR)/Quintal":1898,"Percentage Difference":"3.64%","YEAR":2020},
{ "Year : Month Week#":"JanWk2","Predicted Price(INR)/Quintal":1844,"Actual Price(INR)/Quintal":1862,"Percentage Difference":"0.97%","YEAR":2020},
{ "Year : Month Week#":"JanWk3","Predicted Price(INR)/Quintal":1866,"Actual Price(INR)/Quintal":1858,"Percentage Difference":"-0.43%","YEAR":2020},
{ "Year : Month Week#":"JanWk4","Predicted Price(INR)/Quintal":1881,"Actual Price(INR)/Quintal":1863,"Percentage Difference":"-0.97%","YEAR":2020},
{ "Year : Month Week#":"FebWk1","Predicted Price(INR)/Quintal":1882,"Actual Price(INR)/Quintal":1784,"Percentage Difference":"-5.49%","YEAR":2020},
{ "Year : Month Week#":"FebWk2","Predicted Price(INR)/Quintal":1885,"Actual Price(INR)/Quintal":1800,"Percentage Difference":"-4.72%","YEAR":2020},
{ "Year : Month Week#":"FebWk3","Predicted Price(INR)/Quintal":1808,"Actual Price(INR)/Quintal":1786,"Percentage Difference":"-1.23%","YEAR":2020},
{ "Year : Month Week#":"FebWk4","Predicted Price(INR)/Quintal":1806,"Actual Price(INR)/Quintal":1801,"Percentage Difference":"-0.28%","YEAR":2020},
{ "Year : Month Week#":"MarWk1","Predicted Price(INR)/Quintal":1802,"Actual Price(INR)/Quintal":1730,"Percentage Difference":"-4.16%","YEAR":2020},
{ "Year : Month Week#":"MarWk2","Predicted Price(INR)/Quintal":1720,"Actual Price(INR)/Quintal":1697,"Percentage Difference":"-1.36%","YEAR":2020},
{ "Year : Month Week#":"MarWk3","Predicted Price(INR)/Quintal":1493,"Actual Price(INR)/Quintal":1450,"Percentage Difference":"-2.97%","YEAR":2020},
{ "Year : Month Week#":"MarWk4","Predicted Price(INR)/Quintal":1401,"Actual Price(INR)/Quintal":1406,"Percentage Difference":"0.36%","YEAR":2020},
{ "Year : Month Week#":"AprWk1","Predicted Price(INR)/Quintal":1306,"Actual Price(INR)/Quintal":1368,"Percentage Difference":"6.58%","YEAR":2020},
{ "Year : Month Week#":"AprWk2","Predicted Price(INR)/Quintal":1472,"Actual Price(INR)/Quintal":1458,"Percentage Difference":"-0.96%","YEAR":2020},
{ "Year : Month Week#":"AprWk3","Predicted Price(INR)/Quintal":1466,"Actual Price(INR)/Quintal":1445,"Percentage Difference":"-1.45%","YEAR":2020},
{ "Year : Month Week#":"AprWk4","Predicted Price(INR)/Quintal":1407,"Actual Price(INR)/Quintal":1409,"Percentage Difference":"0.14%","YEAR":2020},
{ "Year : Month Week#":"MayWk1","Predicted Price(INR)/Quintal":1411,"Actual Price(INR)/Quintal":1412,"Percentage Difference":"0.07%","YEAR":2020},
{ "Year : Month Week#":"MayWk2","Predicted Price(INR)/Quintal":1405,"Actual Price(INR)/Quintal":1363,"Percentage Difference":"-3.08%","YEAR":2020},
{ "Year : Month Week#":"MayWk3","Predicted Price(INR)/Quintal":1339,"Actual Price(INR)/Quintal":1316,"Percentage Difference":"-1.75%","YEAR":2020},
{ "Year : Month Week#":"MayWk4","Predicted Price(INR)/Quintal":1322,"Actual Price(INR)/Quintal":1331,"Percentage Difference":"0.68%","YEAR":2020},
{ "Year : Month Week#":"JunWk1","Predicted Price(INR)/Quintal":1328,"Actual Price(INR)/Quintal":1334,"Percentage Difference":"0.45%","YEAR":2020},
{ "Year : Month Week#":"JunWk2","Predicted Price(INR)/Quintal":1323,"Actual Price(INR)/Quintal":1355,"Percentage Difference":"2.36%","YEAR":2020},
{ "Year : Month Week#":"JunWk3","Predicted Price(INR)/Quintal":1369,"Actual Price(INR)/Quintal":1404,"Percentage Difference":"2.49%","YEAR":2020},
{ "Year : Month Week#":"JunWk4","Predicted Price(INR)/Quintal":1391,"Actual Price(INR)/Quintal":1384,"Percentage Difference":"-0.51%","YEAR":2020},
{ "Year : Month Week#":"JulWk1","Predicted Price(INR)/Quintal":1389,"Actual Price(INR)/Quintal":1390,"Percentage Difference":"0.07%","YEAR":2020},
{ "Year : Month Week#":"JulWk2","Predicted Price(INR)/Quintal":1387,"Actual Price(INR)/Quintal":1382,"Percentage Difference":"-0.36%","YEAR":2020},
{ "Year : Month Week#":"JulWk3","Predicted Price(INR)/Quintal":1374,"Actual Price(INR)/Quintal":1351,"Percentage Difference":"-1.70%","YEAR":2020},
{ "Year : Month Week#":"JulWk4","Predicted Price(INR)/Quintal":1356,"Actual Price(INR)/Quintal":1345,"Percentage Difference":"-0.82%","YEAR":2020},
{ "Year : Month Week#":"AugWk1","Predicted Price(INR)/Quintal":1344,"Actual Price(INR)/Quintal":1364,"Percentage Difference":"1.47%","YEAR":2020},
{ "Year : Month Week#":"AugWk2","Predicted Price(INR)/Quintal":1375,"Actual Price(INR)/Quintal":1354,"Percentage Difference":"-1.55%","YEAR":2020},
{ "Year : Month Week#":"AugWk3","Predicted Price(INR)/Quintal":1337,"Actual Price(INR)/Quintal":1320,"Percentage Difference":"-1.29%","YEAR":2020},
{ "Year : Month Week#":"AugWk4","Predicted Price(INR)/Quintal":1327,"Actual Price(INR)/Quintal":1300,"Percentage Difference":"-2.08%","YEAR":2020},
{ "Year : Month Week#":"SepWk1","Predicted Price(INR)/Quintal":1288,"Actual Price(INR)/Quintal":1301,"Percentage Difference":"1.00%","YEAR":2020},
{ "Year : Month Week#":"SepWk2","Predicted Price(INR)/Quintal":1291,"Actual Price(INR)/Quintal":1252,"Percentage Difference":"-3.12%","YEAR":2020},
{ "Year : Month Week#":"SepWk3","Predicted Price(INR)/Quintal":1190,"Actual Price(INR)/Quintal":1229,"Percentage Difference":"3.17%","YEAR":2020},
{ "Year : Month Week#":"SepWk4","Predicted Price(INR)/Quintal":1205,"Actual Price(INR)/Quintal":1238,"Percentage Difference":"2.67%","YEAR":2020},
{ "Year : Month Week#":"OctWk1","Predicted Price(INR)/Quintal":1239,"Actual Price(INR)/Quintal":1201,"Percentage Difference":"-3.16%","YEAR":2020},
{ "Year : Month Week#":"OctWk2","Predicted Price(INR)/Quintal":1203,"Actual Price(INR)/Quintal":1227,"Percentage Difference":"1.96%","YEAR":2020},
{ "Year : Month Week#":"OctWk3","Predicted Price(INR)/Quintal":1226,"Actual Price(INR)/Quintal":1217,"Percentage Difference":"-0.74%","YEAR":2020},
{ "Year : Month Week#":"OctWk4","Predicted Price(INR)/Quintal":1227,"Actual Price(INR)/Quintal":1223,"Percentage Difference":"-0.33%","YEAR":2020},
{ "Year : Month Week#":"NovWk1","Predicted Price(INR)/Quintal":1221,"Actual Price(INR)/Quintal":1268,"Percentage Difference":"3.71%","YEAR":2020},
{ "Year : Month Week#":"NovWk2","Predicted Price(INR)/Quintal":1252,"Actual Price(INR)/Quintal":1311,"Percentage Difference":"4.50%","YEAR":2020},
{ "Year : Month Week#":"NovWk3","Predicted Price(INR)/Quintal":1335,"Actual Price(INR)/Quintal":1327,"Percentage Difference":"-0.60%","YEAR":2020},
{ "Year : Month Week#":"NovWk4","Predicted Price(INR)/Quintal":1343,"Actual Price(INR)/Quintal":1355,"Percentage Difference":"0.89%","YEAR":2020},
{ "Year : Month Week#":"DecWk1","Predicted Price(INR)/Quintal":1350,"Actual Price(INR)/Quintal":1382,"Percentage Difference":"2.32%","YEAR":2020},
{ "Year : Month Week#":"DecWk2","Predicted Price(INR)/Quintal":1353,"Actual Price(INR)/Quintal":1347,"Percentage Difference":"-0.45%","YEAR":2020},
{ "Year : Month Week#":"DecWk3","Predicted Price(INR)/Quintal":1366,"Actual Price(INR)/Quintal":1375,"Percentage Difference":"0.65%","YEAR":2020},
{ "Year : Month Week#":"DecWk4","Predicted Price(INR)/Quintal":1332,"Actual Price(INR)/Quintal":1345,"Percentage Difference":"0.97%","YEAR":2020},
  {
    "Year : Month Week#": "JanWk1",
    "Predicted Price(INR)/Quintal": 1566,
    "Actual Price(INR)/Quintal": 1613,
    "Percentage Difference": "2.91%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "JanWk2",
    "Predicted Price(INR)/Quintal": 1567,
    "Actual Price(INR)/Quintal": 1677,
    "Percentage Difference": "6.56%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "JanWk3",
    "Predicted Price(INR)/Quintal": 1670,
    "Actual Price(INR)/Quintal": 1704,
    "Percentage Difference": "2.00%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "JanWk4",
    "Predicted Price(INR)/Quintal": 1693,
    "Actual Price(INR)/Quintal": 1779,
    "Percentage Difference": "4.83%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "FebWk1",
    "Predicted Price(INR)/Quintal": 1695,
    "Actual Price(INR)/Quintal": 1816,
    "Percentage Difference": "6.66%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "FebWk2",
    "Predicted Price(INR)/Quintal": 1771,
    "Actual Price(INR)/Quintal": 1821,
    "Percentage Difference": "2.75%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "FebWk3",
    "Predicted Price(INR)/Quintal": 1869,
    "Actual Price(INR)/Quintal": 1921,
    "Percentage Difference": "2.71%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "FebWk4",
    "Predicted Price(INR)/Quintal": 1857,
    "Actual Price(INR)/Quintal": 1862,
    "Percentage Difference": "0.27%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "MarWk1",
    "Predicted Price(INR)/Quintal": 1870,
    "Actual Price(INR)/Quintal": 1918,
    "Percentage Difference": "2.50%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "MarWk2",
    "Predicted Price(INR)/Quintal": 1869,
    "Actual Price(INR)/Quintal": 1935,
    "Percentage Difference": "3.41%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "MarWk3",
    "Predicted Price(INR)/Quintal": 1874,
    "Actual Price(INR)/Quintal": 1955,
    "Percentage Difference": "4.14%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "MarWk4",
    "Predicted Price(INR)/Quintal": 1968,
    "Actual Price(INR)/Quintal": 1971,
    "Percentage Difference": "0.15%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "AprWk1",
    "Predicted Price(INR)/Quintal": 1919,
    "Actual Price(INR)/Quintal": 1966,
    "Percentage Difference": "2.39%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "AprWk2",
    "Predicted Price(INR)/Quintal": 1975,
    "Actual Price(INR)/Quintal": 1994,
    "Percentage Difference": "0.95%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "AprWk3",
    "Predicted Price(INR)/Quintal": 1943,
    "Actual Price(INR)/Quintal": 1963,
    "Percentage Difference": "1.02%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "AprWk4",
    "Predicted Price(INR)/Quintal": 1886,
    "Actual Price(INR)/Quintal": 2008,
    "Percentage Difference": "6.08%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "MayWk1",
    "Predicted Price(INR)/Quintal": 2071,
    "Actual Price(INR)/Quintal": 2072,
    "Percentage Difference": "0.05%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "MayWk2",
    "Predicted Price(INR)/Quintal": 2081,
    "Actual Price(INR)/Quintal": 2088,
    "Percentage Difference": "0.34%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "MayWk3",
    "Predicted Price(INR)/Quintal": 2063,
    "Actual Price(INR)/Quintal": 2088,
    "Percentage Difference": "1.20%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "MayWk4",
    "Predicted Price(INR)/Quintal": 2086,
    "Actual Price(INR)/Quintal": 2123,
    "Percentage Difference": "1.74%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "JunWk1",
    "Predicted Price(INR)/Quintal": 2073,
    "Actual Price(INR)/Quintal": 2083,
    "Percentage Difference": "0.48%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "JunWk2",
    "Predicted Price(INR)/Quintal": 2069,
    "Actual Price(INR)/Quintal": 2095,
    "Percentage Difference": "1.24%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "JunWk2",
    "Predicted Price(INR)/Quintal": 2077,
    "Actual Price(INR)/Quintal": 2137,
    "Percentage Difference": "2.81%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "JunWk2",
    "Predicted Price(INR)/Quintal": 2066,
    "Actual Price(INR)/Quintal": 2120,
    "Percentage Difference": "2.55%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "JulWk1",
    "Predicted Price(INR)/Quintal": 2077,
    "Actual Price(INR)/Quintal": 2133,
    "Percentage Difference": "2.63%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "JulWk2",
    "Predicted Price(INR)/Quintal": 2172,
    "Actual Price(INR)/Quintal": 2203,
    "Percentage Difference": "1.41%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "JulWk3",
    "Predicted Price(INR)/Quintal": 2276,
    "Actual Price(INR)/Quintal": 2280,
    "Percentage Difference": "0.18%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "JulWk4",
    "Predicted Price(INR)/Quintal": 2271,
    "Actual Price(INR)/Quintal": 2280,
    "Percentage Difference": "0.39%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "AugWk1",
    "Predicted Price(INR)/Quintal": 2205,
    "Actual Price(INR)/Quintal": 2125,
    "Percentage Difference": "-3.76%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "AugWk2",
    "Predicted Price(INR)/Quintal": 2171,
    "Actual Price(INR)/Quintal": 2215,
    "Percentage Difference": "1.99%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "AugWk3",
    "Predicted Price(INR)/Quintal": 2192,
    "Actual Price(INR)/Quintal": 2194,
    "Percentage Difference": "0.09%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "AugWk4",
    "Predicted Price(INR)/Quintal": 2189,
    "Actual Price(INR)/Quintal": 2280,
    "Percentage Difference": "3.99%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "SepWk1",
    "Predicted Price(INR)/Quintal": 2233,
    "Actual Price(INR)/Quintal": 2276,
    "Percentage Difference": "1.89%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "SepWk2",
    "Predicted Price(INR)/Quintal": 2224,
    "Actual Price(INR)/Quintal": 2333,
    "Percentage Difference": "4.67%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "SepWk3",
    "Predicted Price(INR)/Quintal": 2227,
    "Actual Price(INR)/Quintal": 2236,
    "Percentage Difference": "0.40%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "SepWk4",
    "Predicted Price(INR)/Quintal": 2234,
    "Actual Price(INR)/Quintal": 2300,
    "Percentage Difference": "2.87%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "OctWk1",
    "Predicted Price(INR)/Quintal": 2207,
    "Actual Price(INR)/Quintal": 2212,
    "Percentage Difference": "0.23%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "OctWk2",
    "Predicted Price(INR)/Quintal": 2108,
    "Actual Price(INR)/Quintal": 2164,
    "Percentage Difference": "2.59%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "OctWk3",
    "Predicted Price(INR)/Quintal": 2146,
    "Actual Price(INR)/Quintal": 2079,
    "Percentage Difference": "-3.22%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "OctWk4",
    "Predicted Price(INR)/Quintal": 2010,
    "Actual Price(INR)/Quintal": 1997,
    "Percentage Difference": "-0.65%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "NovWk1",
    "Predicted Price(INR)/Quintal": 1931,
    "Actual Price(INR)/Quintal": 1968,
    "Percentage Difference": "1.88%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "NovWk2",
    "Predicted Price(INR)/Quintal": 1839,
    "Actual Price(INR)/Quintal": 1830,
    "Percentage Difference": "-0.49%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "NovWk3",
    "Predicted Price(INR)/Quintal": 1844,
    "Actual Price(INR)/Quintal": 1815,
    "Percentage Difference": "-1.60%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "NovWk4",
    "Predicted Price(INR)/Quintal": 1868,
    "Actual Price(INR)/Quintal": 1834,
    "Percentage Difference": "-1.85%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "DecWk1",
    "Predicted Price(INR)/Quintal": 1853,
    "Actual Price(INR)/Quintal": 1895,
    "Percentage Difference": "2.22%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "DecWk2",
    "Predicted Price(INR)/Quintal": 1841,
    "Actual Price(INR)/Quintal": 1845,
    "Percentage Difference": "0.22%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "DecWk3",
    "Predicted Price(INR)/Quintal": 1832,
    "Actual Price(INR)/Quintal": 1840,
    "Percentage Difference": "0.43%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "DecWk4",
    "Predicted Price(INR)/Quintal": 1830,
    "Actual Price(INR)/Quintal": 1929,
    "Percentage Difference": "5.13%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "JanWk1",
    "Predicted Price(INR)/Quintal": 1193,
    "Actual Price(INR)/Quintal": 1204,
    "Percentage Difference": "0.91%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "JanWk2",
    "Predicted Price(INR)/Quintal": 1202,
    "Actual Price(INR)/Quintal": 1201,
    "Percentage Difference": "-0.08%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "JanWk3",
    "Predicted Price(INR)/Quintal": 1231,
    "Actual Price(INR)/Quintal": 1198,
    "Percentage Difference": "-2.75%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "JanWk4",
    "Predicted Price(INR)/Quintal": 1217,
    "Actual Price(INR)/Quintal": 1180,
    "Percentage Difference": "-3.14%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "FebWk1",
    "Predicted Price(INR)/Quintal": 1176,
    "Actual Price(INR)/Quintal": 1196,
    "Percentage Difference": "1.67%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "FebWk2",
    "Predicted Price(INR)/Quintal": 1206,
    "Actual Price(INR)/Quintal": 1191,
    "Percentage Difference": "-1.26%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "FebWk3",
    "Predicted Price(INR)/Quintal": 1197,
    "Actual Price(INR)/Quintal": 1238,
    "Percentage Difference": "3.31%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "FebWk4",
    "Predicted Price(INR)/Quintal": 1233,
    "Actual Price(INR)/Quintal": 1263,
    "Percentage Difference": "2.38%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "MarWk1",
    "Predicted Price(INR)/Quintal": 1243,
    "Actual Price(INR)/Quintal": 1244,
    "Percentage Difference": "0.08%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "MarWk2",
    "Predicted Price(INR)/Quintal": 1248,
    "Actual Price(INR)/Quintal": 1233,
    "Percentage Difference": "-1.22%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "MarWk3",
    "Predicted Price(INR)/Quintal": 1224,
    "Actual Price(INR)/Quintal": 1231,
    "Percentage Difference": "0.57%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "MarWk4",
    "Predicted Price(INR)/Quintal": 1229,
    "Actual Price(INR)/Quintal": 1164,
    "Percentage Difference": "-5.58%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "AprWk1",
    "Predicted Price(INR)/Quintal": 1177,
    "Actual Price(INR)/Quintal": 1147,
    "Percentage Difference": "-2.62%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "AprWk2",
    "Predicted Price(INR)/Quintal": 1137,
    "Actual Price(INR)/Quintal": 1167,
    "Percentage Difference": "2.57%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "AprWk3",
    "Predicted Price(INR)/Quintal": 1163,
    "Actual Price(INR)/Quintal": 1172,
    "Percentage Difference": "0.77%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "AprWk4",
    "Predicted Price(INR)/Quintal": 1165,
    "Actual Price(INR)/Quintal": 1156,
    "Percentage Difference": "-0.78%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "MayWk1",
    "Predicted Price(INR)/Quintal": 1163,
    "Actual Price(INR)/Quintal": 1165,
    "Percentage Difference": "0.17%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "MayWk2",
    "Predicted Price(INR)/Quintal": 1165,
    "Actual Price(INR)/Quintal": 1210,
    "Percentage Difference": "3.72%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "MayWk3",
    "Predicted Price(INR)/Quintal": 1202,
    "Actual Price(INR)/Quintal": 1215,
    "Percentage Difference": "1.07%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "MayWk4",
    "Predicted Price(INR)/Quintal": 1205,
    "Actual Price(INR)/Quintal": 1219,
    "Percentage Difference": "1.15%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "JunWk1",
    "Predicted Price(INR)/Quintal": 1212,
    "Actual Price(INR)/Quintal": 1226,
    "Percentage Difference": "1.14%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "JunWk2",
    "Predicted Price(INR)/Quintal": 1229,
    "Actual Price(INR)/Quintal": 1234,
    "Percentage Difference": "0.41%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "JunWk3",
    "Predicted Price(INR)/Quintal": 1237,
    "Actual Price(INR)/Quintal": 1266,
    "Percentage Difference": "2.29%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "JunWk4",
    "Predicted Price(INR)/Quintal": 1265,
    "Actual Price(INR)/Quintal": 1266,
    "Percentage Difference": "0.08%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "JulWk1",
    "Predicted Price(INR)/Quintal": 1267,
    "Actual Price(INR)/Quintal": 1271,
    "Percentage Difference": "0.31%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "JulWk2",
    "Predicted Price(INR)/Quintal": 1260,
    "Actual Price(INR)/Quintal": 1252,
    "Percentage Difference": "-0.64%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "JulWk3",
    "Predicted Price(INR)/Quintal": 1259,
    "Actual Price(INR)/Quintal": 1256,
    "Percentage Difference": "-0.24%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "JulWk4",
    "Predicted Price(INR)/Quintal": 1249,
    "Actual Price(INR)/Quintal": 1300,
    "Percentage Difference": "3.92%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "AugWk1",
    "Predicted Price(INR)/Quintal": 1251,
    "Actual Price(INR)/Quintal": 1282,
    "Percentage Difference": "2.42%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "AugWk2",
    "Predicted Price(INR)/Quintal": 1274,
    "Actual Price(INR)/Quintal": 1266,
    "Percentage Difference": "-0.63%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "AugWk3",
    "Predicted Price(INR)/Quintal": 1284,
    "Actual Price(INR)/Quintal": 1288,
    "Percentage Difference": "0.31%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "AugWk4",
    "Predicted Price(INR)/Quintal": 1285,
    "Actual Price(INR)/Quintal": 1346,
    "Percentage Difference": "4.53%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "SepWk1",
    "Predicted Price(INR)/Quintal": 1267,
    "Actual Price(INR)/Quintal": 1268,
    "Percentage Difference": "0.08%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "SepWk2",
    "Predicted Price(INR)/Quintal": 1276,
    "Actual Price(INR)/Quintal": 1308,
    "Percentage Difference": "2.45%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "SepWk3",
    "Predicted Price(INR)/Quintal": 1278,
    "Actual Price(INR)/Quintal": 1295,
    "Percentage Difference": "1.31%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "SepWk4",
    "Predicted Price(INR)/Quintal": 1285,
    "Actual Price(INR)/Quintal": 1337,
    "Percentage Difference": "3.89%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "OctWK1",
    "Predicted Price(INR)/Quintal": 1298,
    "Actual Price(INR)/Quintal": 1305,
    "Percentage Difference": "0.54%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "OctWk2",
    "Predicted Price(INR)/Quintal": 1302,
    "Actual Price(INR)/Quintal": 1324,
    "Percentage Difference": "1.66%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "OctWK3",
    "Predicted Price(INR)/Quintal": 1306,
    "Actual Price(INR)/Quintal": 1355,
    "Percentage Difference": "3.62%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "OctWK4",
    "Predicted Price(INR)/Quintal": 1357,
    "Actual Price(INR)/Quintal": 1363,
    "Percentage Difference": "0.44%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "NovWk1",
    "Predicted Price(INR)/Quintal": 1363,
    "Actual Price(INR)/Quintal": 1395,
    "Percentage Difference": "2.29%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "NovWk2",
    "Predicted Price(INR)/Quintal": 1374,
    "Actual Price(INR)/Quintal": 1386,
    "Percentage Difference": "0.87%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "NovWk3",
    "Predicted Price(INR)/Quintal": 1369,
    "Actual Price(INR)/Quintal": 1393,
    "Percentage Difference": "1.72%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "NovWk4",
    "Predicted Price(INR)/Quintal": 1401,
    "Actual Price(INR)/Quintal": 1433,
    "Percentage Difference": "2.23%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "DecWk1",
    "Predicted Price(INR)/Quintal": 1479,
    "Actual Price(INR)/Quintal": 1518,
    "Percentage Difference": "2.57%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "DecWk2",
    "Predicted Price(INR)/Quintal": 1462,
    "Actual Price(INR)/Quintal": 1561,
    "Percentage Difference": "6.34%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "DecWk3",
    "Predicted Price(INR)/Quintal": 1508,
    "Actual Price(INR)/Quintal": 1606,
    "Percentage Difference": "6.10%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "DecWk4",
    "Predicted Price(INR)/Quintal": 1628,
    "Actual Price(INR)/Quintal": 1607,
    "Percentage Difference": "-1.31%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "Jan Wk1",
    "Predicted Price(INR)/Quintal": 1499,
    "Actual Price(INR)/Quintal": 1425,
    "Percentage Difference": "-5.19%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Jan Wk2",
    "Predicted Price(INR)/Quintal": 1431,
    "Actual Price(INR)/Quintal": 1424,
    "Percentage Difference": "-0.49%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Jan Wk3",
    "Predicted Price(INR)/Quintal": 1432,
    "Actual Price(INR)/Quintal": 1422,
    "Percentage Difference": "-0.70%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Jan Wk4",
    "Predicted Price(INR)/Quintal": 1412,
    "Actual Price(INR)/Quintal": 1459,
    "Percentage Difference": "3.22%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Feb Wk1",
    "Predicted Price(INR)/Quintal": 1455,
    "Actual Price(INR)/Quintal": 1489,
    "Percentage Difference": "2.28%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Feb Wk2",
    "Predicted Price(INR)/Quintal": 1441,
    "Actual Price(INR)/Quintal": 1507,
    "Percentage Difference": "4.38%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Feb Wk3",
    "Predicted Price(INR)/Quintal": 1491,
    "Actual Price(INR)/Quintal": 1511,
    "Percentage Difference": "1.32%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Feb Wk4",
    "Predicted Price(INR)/Quintal": 1498,
    "Actual Price(INR)/Quintal": 1477,
    "Percentage Difference": "-1.42%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Mar Wk1",
    "Predicted Price(INR)/Quintal": 1469,
    "Actual Price(INR)/Quintal": 1479,
    "Percentage Difference": "0.68%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Mar Wk2",
    "Predicted Price(INR)/Quintal": 1458,
    "Actual Price(INR)/Quintal": 1509,
    "Percentage Difference": "3.38%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Mar Wk3",
    "Predicted Price(INR)/Quintal": 1506,
    "Actual Price(INR)/Quintal": 1512,
    "Percentage Difference": "0.40%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Mar Wk4",
    "Predicted Price(INR)/Quintal": 1505,
    "Actual Price(INR)/Quintal": 1507,
    "Percentage Difference": "0.13%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Apr Wk1",
    "Predicted Price(INR)/Quintal": 1501,
    "Actual Price(INR)/Quintal": 1511,
    "Percentage Difference": "0.66%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Apr Wk2",
    "Predicted Price(INR)/Quintal": 1504,
    "Actual Price(INR)/Quintal": 1520,
    "Percentage Difference": "1.05%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Apr Wk3",
    "Predicted Price(INR)/Quintal": 1502,
    "Actual Price(INR)/Quintal": 1523,
    "Percentage Difference": "1.38%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Apr Wk4",
    "Predicted Price(INR)/Quintal": 1510,
    "Actual Price(INR)/Quintal": 1517,
    "Percentage Difference": "0.46%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "May Wk1",
    "Predicted Price(INR)/Quintal": 1506,
    "Actual Price(INR)/Quintal": 1509,
    "Percentage Difference": "0.20%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "May Wk2",
    "Predicted Price(INR)/Quintal": 1505,
    "Actual Price(INR)/Quintal": 1520,
    "Percentage Difference": "0.99%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "May Wk3",
    "Predicted Price(INR)/Quintal": 1504,
    "Actual Price(INR)/Quintal": 1534,
    "Percentage Difference": "1.96%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "May Wk4",
    "Predicted Price(INR)/Quintal": 1523,
    "Actual Price(INR)/Quintal": 1544,
    "Percentage Difference": "1.36%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Jun Wk1",
    "Predicted Price(INR)/Quintal": 1538,
    "Actual Price(INR)/Quintal": 1542,
    "Percentage Difference": "0.26%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Jun Wk2",
    "Predicted Price(INR)/Quintal": 1535,
    "Actual Price(INR)/Quintal": 1541,
    "Percentage Difference": "0.39%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Jun Wk3",
    "Predicted Price(INR)/Quintal": 1538,
    "Actual Price(INR)/Quintal": 1543,
    "Percentage Difference": "0.39%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Jun Wk4",
    "Predicted Price(INR)/Quintal": 1542,
    "Actual Price(INR)/Quintal": 1556,
    "Percentage Difference": "0.90%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Jul Wk1",
    "Predicted Price(INR)/Quintal": 1537,
    "Actual Price(INR)/Quintal": 1557,
    "Percentage Difference": "1.28%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Jul Wk2",
    "Predicted Price(INR)/Quintal": 1540,
    "Actual Price(INR)/Quintal": 1556,
    "Percentage Difference": "1.03%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Jul Wk3",
    "Predicted Price(INR)/Quintal": 1548,
    "Actual Price(INR)/Quintal": 1574,
    "Percentage Difference": "1.65%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Jul Wk4",
    "Predicted Price(INR)/Quintal": 1540,
    "Actual Price(INR)/Quintal": 1580,
    "Percentage Difference": "2.53%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Aug Wk1",
    "Predicted Price(INR)/Quintal": 1549,
    "Actual Price(INR)/Quintal": 1559,
    "Percentage Difference": "0.64%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Aug Wk2",
    "Predicted Price(INR)/Quintal": 1545,
    "Actual Price(INR)/Quintal": 1611,
    "Percentage Difference": "4.10%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Aug Wk3",
    "Predicted Price(INR)/Quintal": 1586,
    "Actual Price(INR)/Quintal": 1525,
    "Percentage Difference": "-4.00%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Aug Wk4",
    "Predicted Price(INR)/Quintal": 1467,
    "Actual Price(INR)/Quintal": 1481,
    "Percentage Difference": "0.95%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Sep Wk1",
    "Predicted Price(INR)/Quintal": 1456,
    "Actual Price(INR)/Quintal": 1461,
    "Percentage Difference": "0.34%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Sep Wk2",
    "Predicted Price(INR)/Quintal": 1446,
    "Actual Price(INR)/Quintal": 1402,
    "Percentage Difference": "-3.14%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Sep Wk3",
    "Predicted Price(INR)/Quintal": 1411,
    "Actual Price(INR)/Quintal": 1352,
    "Percentage Difference": "-4.36%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Sep Wk4",
    "Predicted Price(INR)/Quintal": 1326,
    "Actual Price(INR)/Quintal": 1290,
    "Percentage Difference": "-2.79%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Oct Wk1",
    "Predicted Price(INR)/Quintal": 1296,
    "Actual Price(INR)/Quintal": 1309,
    "Percentage Difference": "0.99%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Oct Wk2",
    "Predicted Price(INR)/Quintal": 1285,
    "Actual Price(INR)/Quintal": 1297,
    "Percentage Difference": "0.93%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Oct Wk3",
    "Predicted Price(INR)/Quintal": 1284,
    "Actual Price(INR)/Quintal": 1292,
    "Percentage Difference": "0.62%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Oct Wk4",
    "Predicted Price(INR)/Quintal": 1264,
    "Actual Price(INR)/Quintal": 1240,
    "Percentage Difference": "-1.94%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Nov Wk1",
    "Predicted Price(INR)/Quintal": 1226,
    "Actual Price(INR)/Quintal": 1197,
    "Percentage Difference": "-2.42%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Nov Wk2",
    "Predicted Price(INR)/Quintal": 1168,
    "Actual Price(INR)/Quintal": 1180,
    "Percentage Difference": "1.02%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Nov Wk3",
    "Predicted Price(INR)/Quintal": 1187,
    "Actual Price(INR)/Quintal": 1165,
    "Percentage Difference": "-1.89%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Nov Wk4",
    "Predicted Price(INR)/Quintal": 1166,
    "Actual Price(INR)/Quintal": 1167,
    "Percentage Difference": "0.09%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Dec Wk1",
    "Predicted Price(INR)/Quintal": 1163,
    "Actual Price(INR)/Quintal": 1168,
    "Percentage Difference": "0.43%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Dec Wk2",
    "Predicted Price(INR)/Quintal": 1173,
    "Actual Price(INR)/Quintal": 1204,
    "Percentage Difference": "2.57%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Dec Wk3",
    "Predicted Price(INR)/Quintal": 1217,
    "Actual Price(INR)/Quintal": 1224,
    "Percentage Difference": "0.57%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Dec Wk4",
    "Predicted Price(INR)/Quintal": 1237,
    "Actual Price(INR)/Quintal": 1196,
    "Percentage Difference": "-3.43%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Jan Wk1",
    "Predicted Price(INR)/Quintal": 1372,
    "Actual Price(INR)/Quintal": 1405,
    "Percentage Difference": "2.35%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Jan Wk2",
    "Predicted Price(INR)/Quintal": 1391,
    "Actual Price(INR)/Quintal": 1431,
    "Percentage Difference": "2.80%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Jan Wk3",
    "Predicted Price(INR)/Quintal": 1367,
    "Actual Price(INR)/Quintal": 1411,
    "Percentage Difference": "3.12%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Jan Wk4",
    "Predicted Price(INR)/Quintal": 1390,
    "Actual Price(INR)/Quintal": 1425,
    "Percentage Difference": "2.46%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Feb Wk1",
    "Predicted Price(INR)/Quintal": 1344,
    "Actual Price(INR)/Quintal": 1378,
    "Percentage Difference": "2.47%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Feb Wk2",
    "Predicted Price(INR)/Quintal": 1398,
    "Actual Price(INR)/Quintal": 1372,
    "Percentage Difference": "-1.90%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Feb Wk3",
    "Predicted Price(INR)/Quintal": 1392,
    "Actual Price(INR)/Quintal": 1390,
    "Percentage Difference": "-0.02%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Feb Wk4",
    "Predicted Price(INR)/Quintal": 1391,
    "Actual Price(INR)/Quintal": 1435,
    "Percentage Difference": "3.07%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Mar Wk1",
    "Predicted Price(INR)/Quintal": 1366,
    "Actual Price(INR)/Quintal": 1438,
    "Percentage Difference": "5.01%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Mar Wk2",
    "Predicted Price(INR)/Quintal": 1392,
    "Actual Price(INR)/Quintal": 1428,
    "Percentage Difference": "2.52%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Mar Wk3",
    "Predicted Price(INR)/Quintal": 1419,
    "Actual Price(INR)/Quintal": 1437,
    "Percentage Difference": "1.25%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Mar Wk4",
    "Predicted Price(INR)/Quintal": 1427,
    "Actual Price(INR)/Quintal": 1392,
    "Percentage Difference": "-2.51%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Apr Wk1",
    "Predicted Price(INR)/Quintal": 1425,
    "Actual Price(INR)/Quintal": 1390,
    "Percentage Difference": "-2.52%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Apr Wk2",
    "Predicted Price(INR)/Quintal": 1436,
    "Actual Price(INR)/Quintal": 1389,
    "Percentage Difference": "-3.38%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Apr Wk3",
    "Predicted Price(INR)/Quintal": 1386,
    "Actual Price(INR)/Quintal": 1405,
    "Percentage Difference": "1.35%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Apr Wk4",
    "Predicted Price(INR)/Quintal": 1397,
    "Actual Price(INR)/Quintal": 1415,
    "Percentage Difference": "1.27%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "May Wk1",
    "Predicted Price(INR)/Quintal": 1414,
    "Actual Price(INR)/Quintal": 1426,
    "Percentage Difference": "0.84%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "May Wk2",
    "Predicted Price(INR)/Quintal": 1405,
    "Actual Price(INR)/Quintal": 1467,
    "Percentage Difference": "4.23%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "May Wk3",
    "Predicted Price(INR)/Quintal": 1468,
    "Actual Price(INR)/Quintal": 1455,
    "Percentage Difference": "-0.89%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "May Wk4",
    "Predicted Price(INR)/Quintal": 1485,
    "Actual Price(INR)/Quintal": 1460,
    "Percentage Difference": "-1.71%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Jun Wk1",
    "Predicted Price(INR)/Quintal": 1474,
    "Actual Price(INR)/Quintal": 1479,
    "Percentage Difference": "0.34%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Jun Wk2",
    "Predicted Price(INR)/Quintal": 1456,
    "Actual Price(INR)/Quintal": 1487,
    "Percentage Difference": "2.08%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Jun Wk3",
    "Predicted Price(INR)/Quintal": 1482,
    "Actual Price(INR)/Quintal": 1513,
    "Percentage Difference": "2.05%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Jun Wk4",
    "Predicted Price(INR)/Quintal": 1495,
    "Actual Price(INR)/Quintal": 1555,
    "Percentage Difference": "3.86%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Jul Wk1",
    "Predicted Price(INR)/Quintal": 1514,
    "Actual Price(INR)/Quintal": 1632,
    "Percentage Difference": "7.23%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Jul Wk2",
    "Predicted Price(INR)/Quintal": 1610,
    "Actual Price(INR)/Quintal": 1627,
    "Percentage Difference": "1.04%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Jul Wk3",
    "Predicted Price(INR)/Quintal": 1608,
    "Actual Price(INR)/Quintal": 1608,
    "Percentage Difference": "0.00%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Jul Wk4",
    "Predicted Price(INR)/Quintal": 1609,
    "Actual Price(INR)/Quintal": 1684,
    "Percentage Difference": "4.45%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Aug Wk1",
    "Predicted Price(INR)/Quintal": 1664,
    "Actual Price(INR)/Quintal": 1695,
    "Percentage Difference": "1.83%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Aug Wk2",
    "Predicted Price(INR)/Quintal": 1666,
    "Actual Price(INR)/Quintal": 1642,
    "Percentage Difference": "-1.46%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Aug Wk3",
    "Predicted Price(INR)/Quintal": 1673,
    "Actual Price(INR)/Quintal": 1712,
    "Percentage Difference": "2.28%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Aug Wk4",
    "Predicted Price(INR)/Quintal": 1669,
    "Actual Price(INR)/Quintal": 1684,
    "Percentage Difference": "0.89%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Sep Wk1",
    "Predicted Price(INR)/Quintal": 1681,
    "Actual Price(INR)/Quintal": 1661,
    "Percentage Difference": "-1.20%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Sep Wk2",
    "Predicted Price(INR)/Quintal": 1668,
    "Actual Price(INR)/Quintal": 1675,
    "Percentage Difference": "0.42%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Sep Wk3",
    "Predicted Price(INR)/Quintal": 1607,
    "Actual Price(INR)/Quintal": 1568,
    "Percentage Difference": "-2.49%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Sep Wk4",
    "Predicted Price(INR)/Quintal": 1562,
    "Actual Price(INR)/Quintal": 1662,
    "Percentage Difference": "6.02%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Oct Wk1",
    "Predicted Price(INR)/Quintal": 1643,
    "Actual Price(INR)/Quintal": 1606,
    "Percentage Difference": "-2.30%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Oct Wk2",
    "Predicted Price(INR)/Quintal": 1616,
    "Actual Price(INR)/Quintal": 1516,
    "Percentage Difference": "-6.96%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Oct Wk3",
    "Predicted Price(INR)/Quintal": 1505,
    "Actual Price(INR)/Quintal": 1468,
    "Percentage Difference": "-2.52%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Oct Wk4",
    "Predicted Price(INR)/Quintal": 1524,
    "Actual Price(INR)/Quintal": 1427,
    "Percentage Difference": "-6.80%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Now Wk1",
    "Predicted Price(INR)/Quintal": 1443,
    "Actual Price(INR)/Quintal": 1382,
    "Percentage Difference": "-4.41%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Now Wk2",
    "Predicted Price(INR)/Quintal": 1402,
    "Actual Price(INR)/Quintal": 1367,
    "Percentage Difference": "-2.56%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Now Wk3",
    "Predicted Price(INR)/Quintal": 1352,
    "Actual Price(INR)/Quintal": 1409,
    "Percentage Difference": "4.05%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Now Wk4",
    "Predicted Price(INR)/Quintal": 1411,
    "Actual Price(INR)/Quintal": 1418,
    "Percentage Difference": "0.49%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Dec Wk1",
    "Predicted Price(INR)/Quintal": 1425,
    "Actual Price(INR)/Quintal": 1415,
    "Percentage Difference": "-0.71%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Dec Wk2",
    "Predicted Price(INR)/Quintal": 1455,
    "Actual Price(INR)/Quintal": 1451,
    "Percentage Difference": "-0.28%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Dec Wk3",
    "Predicted Price(INR)/Quintal": 1462,
    "Actual Price(INR)/Quintal": 1475,
    "Percentage Difference": "0.88%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Dec Wk4",
    "Predicted Price(INR)/Quintal": 1447,
    "Actual Price(INR)/Quintal": 1473,
    "Percentage Difference": "1.76%",
    "YEAR": 2016
  }
];
