export const Tur = [
{ "Year : Month Week#":"Janwk1","Predicted Price(INR)/Quintal":8091,"Actual Price(INR)/Quintal":8074,"Percentage Difference":"-0.21%","YEAR":2025},
{ "Year : Month Week#":"Janwk2","Predicted Price(INR)/Quintal":8095,"Actual Price(INR)/Quintal":7650,"Percentage Difference":"-5.82%","YEAR":2025},
{ "Year : Month Week#":"Janwk3","Predicted Price(INR)/Quintal":7488,"Actual Price(INR)/Quintal":null,"Percentage Difference":null,"YEAR":2025},
{ "Year : Month Week#":"Janwk4","Predicted Price(INR)/Quintal":7462,"Actual Price(INR)/Quintal":null,"Percentage Difference":null,"YEAR":2025},
{ "Year : Month Week#":"Febwk1","Predicted Price(INR)/Quintal":7477,"Actual Price(INR)/Quintal":null,"Percentage Difference":null,"YEAR":2025},
{ "Year : Month Week#":"Febwk2","Predicted Price(INR)/Quintal":7541,"Actual Price(INR)/Quintal":null,"Percentage Difference":null,"YEAR":2025},
{ "Year : Month Week#":"Janwk1","Predicted Price(INR)/Quintal":9372,"Actual Price(INR)/Quintal":8628,"Percentage Difference":"-8.62%","YEAR":2024},
{ "Year : Month Week#":"Janwk2","Predicted Price(INR)/Quintal":8050,"Actual Price(INR)/Quintal":8826,"Percentage Difference":"8.79%","YEAR":2024},
{ "Year : Month Week#":"Janwk3","Predicted Price(INR)/Quintal":9085,"Actual Price(INR)/Quintal":9154,"Percentage Difference":"0.75%","YEAR":2024},
{ "Year : Month Week#":"Janwk4","Predicted Price(INR)/Quintal":9056,"Actual Price(INR)/Quintal":9472,"Percentage Difference":"4.39%","YEAR":2024},
{ "Year : Month Week#":"Febwk1","Predicted Price(INR)/Quintal":9579,"Actual Price(INR)/Quintal":10105,"Percentage Difference":"5.21%","YEAR":2024},
{ "Year : Month Week#":"Febwk2","Predicted Price(INR)/Quintal":9982,"Actual Price(INR)/Quintal":10126,"Percentage Difference":"1.42%","YEAR":2024},
{ "Year : Month Week#":"Febwk3","Predicted Price(INR)/Quintal":10101,"Actual Price(INR)/Quintal":10120,"Percentage Difference":"0.19%","YEAR":2024},
{ "Year : Month Week#":"Febwk4","Predicted Price(INR)/Quintal":10080,"Actual Price(INR)/Quintal":10285,"Percentage Difference":"1.99%","YEAR":2024},
{ "Year : Month Week#":"Marwk1","Predicted Price(INR)/Quintal":10101,"Actual Price(INR)/Quintal":10065,"Percentage Difference":"-0.36%","YEAR":2024},
{ "Year : Month Week#":"Marwk2","Predicted Price(INR)/Quintal":10280,"Actual Price(INR)/Quintal":10288,"Percentage Difference":"0.08%","YEAR":2024},
{ "Year : Month Week#":"Marwk3","Predicted Price(INR)/Quintal":10180,"Actual Price(INR)/Quintal":10291,"Percentage Difference":"1.08%","YEAR":2024},
{ "Year : Month Week#":"Marwk4","Predicted Price(INR)/Quintal":10230,"Actual Price(INR)/Quintal":10251,"Percentage Difference":"0.20%","YEAR":2024},
{ "Year : Month Week#":"Aprwk1","Predicted Price(INR)/Quintal":10666,"Actual Price(INR)/Quintal":10681,"Percentage Difference":"0.14%","YEAR":2024},
{ "Year : Month Week#":"Aprwk2","Predicted Price(INR)/Quintal":11152,"Actual Price(INR)/Quintal":11536,"Percentage Difference":"3.33%","YEAR":2024},
{ "Year : Month Week#":"Aprwk3","Predicted Price(INR)/Quintal":11145,"Actual Price(INR)/Quintal":11344,"Percentage Difference":"1.75%","YEAR":2024},
{ "Year : Month Week#":"Aprwk4","Predicted Price(INR)/Quintal":11081,"Actual Price(INR)/Quintal":11522,"Percentage Difference":"3.83%","YEAR":2024},
{ "Year : Month Week#":"Maywk1","Predicted Price(INR)/Quintal":11034,"Actual Price(INR)/Quintal":11560,"Percentage Difference":"4.55%","YEAR":2024},
{ "Year : Month Week#":"Maywk2","Predicted Price(INR)/Quintal":11235,"Actual Price(INR)/Quintal":11810,"Percentage Difference":"4.87%","YEAR":2024},
{ "Year : Month Week#":"Maywk3","Predicted Price(INR)/Quintal":11743,"Actual Price(INR)/Quintal":11812,"Percentage Difference":"0.58%","YEAR":2024},
{ "Year : Month Week#":"Maywk4","Predicted Price(INR)/Quintal":11626,"Actual Price(INR)/Quintal":12087,"Percentage Difference":"3.81%","YEAR":2024},
{ "Year : Month Week#":"Junwk1","Predicted Price(INR)/Quintal":11893,"Actual Price(INR)/Quintal":12074,"Percentage Difference":"1.50%","YEAR":2024},
{ "Year : Month Week#":"Junwk2","Predicted Price(INR)/Quintal":11888,"Actual Price(INR)/Quintal":11902,"Percentage Difference":"0.12%","YEAR":2024},
{ "Year : Month Week#":"Junwk3","Predicted Price(INR)/Quintal":11976,"Actual Price(INR)/Quintal":11644,"Percentage Difference":"-2.85%","YEAR":2024},
{ "Year : Month Week#":"Junwk4","Predicted Price(INR)/Quintal":11951,"Actual Price(INR)/Quintal":11623,"Percentage Difference":"-2.82%","YEAR":2024},
{ "Year : Month Week#":"Julwk1","Predicted Price(INR)/Quintal":11665,"Actual Price(INR)/Quintal":11723,"Percentage Difference":"0.49%","YEAR":2024},
{ "Year : Month Week#":"Julwk2","Predicted Price(INR)/Quintal":11769,"Actual Price(INR)/Quintal":11683,"Percentage Difference":"-0.74%","YEAR":2024},
{ "Year : Month Week#":"Julwk3","Predicted Price(INR)/Quintal":12020,"Actual Price(INR)/Quintal":11589,"Percentage Difference":"-3.72%","YEAR":2024},
{ "Year : Month Week#":"Julwk4","Predicted Price(INR)/Quintal":11899,"Actual Price(INR)/Quintal":11774,"Percentage Difference":"-1.06%","YEAR":2024},
{ "Year : Month Week#":"Augwk1","Predicted Price(INR)/Quintal":11064,"Actual Price(INR)/Quintal":11149,"Percentage Difference":"0.76%","YEAR":2024},
{ "Year : Month Week#":"Augwk2","Predicted Price(INR)/Quintal":11322,"Actual Price(INR)/Quintal":11401,"Percentage Difference":"0.69%","YEAR":2024},
{ "Year : Month Week#":"Augwk3","Predicted Price(INR)/Quintal":11216,"Actual Price(INR)/Quintal":11076,"Percentage Difference":"-1.26%","YEAR":2024},
{ "Year : Month Week#":"Augwk4","Predicted Price(INR)/Quintal":10139,"Actual Price(INR)/Quintal":10175,"Percentage Difference":"0.35%","YEAR":2024},
{ "Year : Month Week#":"Sepwk1","Predicted Price(INR)/Quintal":10137,"Actual Price(INR)/Quintal":10453,"Percentage Difference":"3.02%","YEAR":2024},
{ "Year : Month Week#":"Sepwk2","Predicted Price(INR)/Quintal":10541,"Actual Price(INR)/Quintal":10524,"Percentage Difference":"-0.16%","YEAR":2024},
{ "Year : Month Week#":"Sepwk3","Predicted Price(INR)/Quintal":10802,"Actual Price(INR)/Quintal":10592,"Percentage Difference":"-1.98%","YEAR":2024},
{ "Year : Month Week#":"Sepwk4","Predicted Price(INR)/Quintal":10865,"Actual Price(INR)/Quintal":10869,"Percentage Difference":"0.04%","YEAR":2024},
{ "Year : Month Week#":"Octwk1","Predicted Price(INR)/Quintal":11119,"Actual Price(INR)/Quintal":10819,"Percentage Difference":"-2.77%","YEAR":2024},
{ "Year : Month Week#":"Octwk2","Predicted Price(INR)/Quintal":10434,"Actual Price(INR)/Quintal":10539,"Percentage Difference":"1.00%","YEAR":2024},
{ "Year : Month Week#":"Octwk3","Predicted Price(INR)/Quintal":10799,"Actual Price(INR)/Quintal":10298,"Percentage Difference":"-4.87%","YEAR":2024},
{ "Year : Month Week#":"Octwk4","Predicted Price(INR)/Quintal":10427,"Actual Price(INR)/Quintal":10728,"Percentage Difference":"2.81%","YEAR":2024},
{ "Year : Month Week#":"Novwk1","Predicted Price(INR)/Quintal":10859,"Actual Price(INR)/Quintal":11116,"Percentage Difference":"2.31%","YEAR":2024},
{ "Year : Month Week#":"Novwk2","Predicted Price(INR)/Quintal":10580,"Actual Price(INR)/Quintal":10641,"Percentage Difference":"0.57%","YEAR":2024},
{ "Year : Month Week#":"Novwk3","Predicted Price(INR)/Quintal":10245,"Actual Price(INR)/Quintal":10385,"Percentage Difference":"1.35%","YEAR":2024},
{ "Year : Month Week#":"Novwk4","Predicted Price(INR)/Quintal":10428,"Actual Price(INR)/Quintal":10015,"Percentage Difference":"-4.12%","YEAR":2024},
{ "Year : Month Week#":"Decwk1","Predicted Price(INR)/Quintal":10188,"Actual Price(INR)/Quintal":9990,"Percentage Difference":"-1.98%","YEAR":2024},
{ "Year : Month Week#":"Decwk2","Predicted Price(INR)/Quintal":9920,"Actual Price(INR)/Quintal":9782,"Percentage Difference":"-1.41%","YEAR":2024},
{ "Year : Month Week#":"Decwk3","Predicted Price(INR)/Quintal":9691,"Actual Price(INR)/Quintal":8943,"Percentage Difference":"-8.36%","YEAR":2024},
{ "Year : Month Week#":"Decwk4","Predicted Price(INR)/Quintal":8005,"Actual Price(INR)/Quintal":8079,"Percentage Difference":"0.92%","YEAR":2024},
{ "Year : Month Week#":"Janwk1","Predicted Price(INR)/Quintal":6841,"Actual Price(INR)/Quintal":7032,"Percentage Difference":"2.72%","YEAR":2023},
{ "Year : Month Week#":"Janwk2","Predicted Price(INR)/Quintal":6835,"Actual Price(INR)/Quintal":7056,"Percentage Difference":"3.13%","YEAR":2023},
{ "Year : Month Week#":"Janwk3","Predicted Price(INR)/Quintal":7078,"Actual Price(INR)/Quintal":7024,"Percentage Difference":"-0.77%","YEAR":2023},
{ "Year : Month Week#":"Janwk4","Predicted Price(INR)/Quintal":7087,"Actual Price(INR)/Quintal":6979,"Percentage Difference":"-1.55%","YEAR":2023},
{ "Year : Month Week#":"Febwk1","Predicted Price(INR)/Quintal":6957,"Actual Price(INR)/Quintal":7021,"Percentage Difference":"0.91%","YEAR":2023},
{ "Year : Month Week#":"Febwk2","Predicted Price(INR)/Quintal":7062,"Actual Price(INR)/Quintal":7103,"Percentage Difference":"0.58%","YEAR":2023},
{ "Year : Month Week#":"Febwk3","Predicted Price(INR)/Quintal":7023,"Actual Price(INR)/Quintal":7375,"Percentage Difference":"4.77%","YEAR":2023},
{ "Year : Month Week#":"Febwk4","Predicted Price(INR)/Quintal":7495,"Actual Price(INR)/Quintal":7866,"Percentage Difference":"4.72%","YEAR":2023},
{ "Year : Month Week#":"Marwk1","Predicted Price(INR)/Quintal":7898,"Actual Price(INR)/Quintal":7945,"Percentage Difference":"0.59%","YEAR":2023},
{ "Year : Month Week#":"Marwk2","Predicted Price(INR)/Quintal":7821,"Actual Price(INR)/Quintal":7977,"Percentage Difference":"1.96%","YEAR":2023},
{ "Year : Month Week#":"Marwk3","Predicted Price(INR)/Quintal":7913,"Actual Price(INR)/Quintal":8020,"Percentage Difference":"1.33%","YEAR":2023},
{ "Year : Month Week#":"Marwk4","Predicted Price(INR)/Quintal":8074,"Actual Price(INR)/Quintal":8204,"Percentage Difference":"1.58%","YEAR":2023},
{ "Year : Month Week#":"Aprwk1","Predicted Price(INR)/Quintal":8166,"Actual Price(INR)/Quintal":8207,"Percentage Difference":"0.50%","YEAR":2023},
{ "Year : Month Week#":"Aprwk2","Predicted Price(INR)/Quintal":8156,"Actual Price(INR)/Quintal":8184,"Percentage Difference":"0.34%","YEAR":2023},
{ "Year : Month Week#":"Aprwk3","Predicted Price(INR)/Quintal":8184,"Actual Price(INR)/Quintal":8127,"Percentage Difference":"-0.70%","YEAR":2023},
{ "Year : Month Week#":"Aprwk4","Predicted Price(INR)/Quintal":8112,"Actual Price(INR)/Quintal":8231,"Percentage Difference":"1.45%","YEAR":2023},
{ "Year : Month Week#":"Maywk1","Predicted Price(INR)/Quintal":8133,"Actual Price(INR)/Quintal":8439,"Percentage Difference":"3.63%","YEAR":2023},
{ "Year : Month Week#":"Maywk2","Predicted Price(INR)/Quintal":8628,"Actual Price(INR)/Quintal":8719,"Percentage Difference":"1.04%","YEAR":2023},
{ "Year : Month Week#":"Maywk3","Predicted Price(INR)/Quintal":8847,"Actual Price(INR)/Quintal":9142,"Percentage Difference":"3.23%","YEAR":2023},
{ "Year : Month Week#":"Maywk4","Predicted Price(INR)/Quintal":9211,"Actual Price(INR)/Quintal":9482,"Percentage Difference":"2.86%","YEAR":2023},
{ "Year : Month Week#":"Junwk1","Predicted Price(INR)/Quintal":9802,"Actual Price(INR)/Quintal":9992,"Percentage Difference":"1.90%","YEAR":2023},
{ "Year : Month Week#":"Junwk2","Predicted Price(INR)/Quintal":9905,"Actual Price(INR)/Quintal":10125,"Percentage Difference":"2.17%","YEAR":2023},
{ "Year : Month Week#":"Junwk3","Predicted Price(INR)/Quintal":9953,"Actual Price(INR)/Quintal":10002,"Percentage Difference":"0.49%","YEAR":2023},
{ "Year : Month Week#":"Junwk4","Predicted Price(INR)/Quintal":9966,"Actual Price(INR)/Quintal":9653,"Percentage Difference":"-3.24%","YEAR":2023},
{ "Year : Month Week#":"Julwk1","Predicted Price(INR)/Quintal":9722,"Actual Price(INR)/Quintal":9947,"Percentage Difference":"2.26%","YEAR":2023},
{ "Year : Month Week#":"Julwk2","Predicted Price(INR)/Quintal":9798,"Actual Price(INR)/Quintal":9952,"Percentage Difference":"1.55%","YEAR":2023},
{ "Year : Month Week#":"Julwk3","Predicted Price(INR)/Quintal":9899,"Actual Price(INR)/Quintal":9918,"Percentage Difference":"0.19%","YEAR":2023},
{ "Year : Month Week#":"Julwk4","Predicted Price(INR)/Quintal":9852,"Actual Price(INR)/Quintal":9911,"Percentage Difference":"0.60%","YEAR":2023},
{ "Year : Month Week#":"Augwk1","Predicted Price(INR)/Quintal":9877,"Actual Price(INR)/Quintal":10156,"Percentage Difference":"2.75%","YEAR":2023},
{ "Year : Month Week#":"Augwk2","Predicted Price(INR)/Quintal":10063,"Actual Price(INR)/Quintal":10285,"Percentage Difference":"2.16%","YEAR":2023},
{ "Year : Month Week#":"Augwk3","Predicted Price(INR)/Quintal":10085,"Actual Price(INR)/Quintal":10285,"Percentage Difference":"1.94%","YEAR":2023},
{ "Year : Month Week#":"Augwk4","Predicted Price(INR)/Quintal":10045,"Actual Price(INR)/Quintal":10362,"Percentage Difference":"3.06%","YEAR":2023},
{ "Year : Month Week#":"Sepwk1","Predicted Price(INR)/Quintal":11089,"Actual Price(INR)/Quintal":11851,"Percentage Difference":"6.43%","YEAR":2023},
{ "Year : Month Week#":"Sepwk2","Predicted Price(INR)/Quintal":11105,"Actual Price(INR)/Quintal":11246,"Percentage Difference":"1.25%","YEAR":2023},
{ "Year : Month Week#":"Sepwk3","Predicted Price(INR)/Quintal":11457,"Actual Price(INR)/Quintal":11925,"Percentage Difference":"3.92%","YEAR":2023},
{ "Year : Month Week#":"Sepwk4","Predicted Price(INR)/Quintal":11270,"Actual Price(INR)/Quintal":11583,"Percentage Difference":"2.70%","YEAR":2023},
{ "Year : Month Week#":"Octwk1","Predicted Price(INR)/Quintal":11670,"Actual Price(INR)/Quintal":11326,"Percentage Difference":"-3.04%","YEAR":2023},
{ "Year : Month Week#":"Octwk2","Predicted Price(INR)/Quintal":11925,"Actual Price(INR)/Quintal":11787,"Percentage Difference":"-1.17%","YEAR":2023},
{ "Year : Month Week#":"Octwk3","Predicted Price(INR)/Quintal":12044,"Actual Price(INR)/Quintal":11878,"Percentage Difference":"-1.40%","YEAR":2023},
{ "Year : Month Week#":"Octwk4","Predicted Price(INR)/Quintal":12112,"Actual Price(INR)/Quintal":12216,"Percentage Difference":"0.85%","YEAR":2023},
{ "Year : Month Week#":"Novwk1","Predicted Price(INR)/Quintal":11538,"Actual Price(INR)/Quintal":11903,"Percentage Difference":"3.07%","YEAR":2023},
{ "Year : Month Week#":"Novwk2","Predicted Price(INR)/Quintal":11585,"Actual Price(INR)/Quintal":11638,"Percentage Difference":"0.46%","YEAR":2023},
{ "Year : Month Week#":"Novwk3","Predicted Price(INR)/Quintal":11418,"Actual Price(INR)/Quintal":10999,"Percentage Difference":"-3.81%","YEAR":2023},
{ "Year : Month Week#":"Novwk4","Predicted Price(INR)/Quintal":9649,"Actual Price(INR)/Quintal":9467,"Percentage Difference":"-1.92%","YEAR":2023},
{ "Year : Month Week#":"Decwk1","Predicted Price(INR)/Quintal":9663,"Actual Price(INR)/Quintal":10016,"Percentage Difference":"3.52%","YEAR":2023},
{ "Year : Month Week#":"Decwk2","Predicted Price(INR)/Quintal":9808,"Actual Price(INR)/Quintal":9556,"Percentage Difference":"-2.64%","YEAR":2023},
{ "Year : Month Week#":"Decwk3","Predicted Price(INR)/Quintal":9579,"Actual Price(INR)/Quintal":9596,"Percentage Difference":"0.18%","YEAR":2023},
{ "Year : Month Week#":"Decwk4","Predicted Price(INR)/Quintal":9583,"Actual Price(INR)/Quintal":9329,"Percentage Difference":"-2.72%","YEAR":2023},
{ "Year : Month Week#":"Janwk1","Predicted Price(INR)/Quintal":5808,"Actual Price(INR)/Quintal":5832,"Percentage Difference":"0.41%","YEAR":2022},
{ "Year : Month Week#":"Janwk2","Predicted Price(INR)/Quintal":5820,"Actual Price(INR)/Quintal":5984,"Percentage Difference":"2.74%","YEAR":2022},
{ "Year : Month Week#":"Janwk3","Predicted Price(INR)/Quintal":5836,"Actual Price(INR)/Quintal":6035,"Percentage Difference":"3.30%","YEAR":2022},
{ "Year : Month Week#":"Janwk4","Predicted Price(INR)/Quintal":5977,"Actual Price(INR)/Quintal":6047,"Percentage Difference":"1.16%","YEAR":2022},
{ "Year : Month Week#":"Febwk1","Predicted Price(INR)/Quintal":5973,"Actual Price(INR)/Quintal":6098,"Percentage Difference":"2.05%","YEAR":2022},
{ "Year : Month Week#":"Febwk2","Predicted Price(INR)/Quintal":5978,"Actual Price(INR)/Quintal":6019,"Percentage Difference":"0.68%","YEAR":2022},
{ "Year : Month Week#":"Febwk3","Predicted Price(INR)/Quintal":5982,"Actual Price(INR)/Quintal":6083,"Percentage Difference":"1.66%","YEAR":2022},
{ "Year : Month Week#":"Febwk4","Predicted Price(INR)/Quintal":5995,"Actual Price(INR)/Quintal":6057,"Percentage Difference":"1.02%","YEAR":2022},
{ "Year : Month Week#":"Marwk1","Predicted Price(INR)/Quintal":6085,"Actual Price(INR)/Quintal":6152,"Percentage Difference":"1.09%","YEAR":2022},
{ "Year : Month Week#":"Marwk2","Predicted Price(INR)/Quintal":6242,"Actual Price(INR)/Quintal":6265,"Percentage Difference":"0.37%","YEAR":2022},
{ "Year : Month Week#":"Marwk3","Predicted Price(INR)/Quintal":6173,"Actual Price(INR)/Quintal":6081,"Percentage Difference":"-1.51%","YEAR":2022},
{ "Year : Month Week#":"Marwk4","Predicted Price(INR)/Quintal":6033,"Actual Price(INR)/Quintal":6130,"Percentage Difference":"1.58%","YEAR":2022},
{ "Year : Month Week#":"Aprwk1","Predicted Price(INR)/Quintal":6082,"Actual Price(INR)/Quintal":6180,"Percentage Difference":"1.59%","YEAR":2022},
{ "Year : Month Week#":"Aprwk2","Predicted Price(INR)/Quintal":6175,"Actual Price(INR)/Quintal":6209,"Percentage Difference":"0.55%","YEAR":2022},
{ "Year : Month Week#":"Aprw3","Predicted Price(INR)/Quintal":6112,"Actual Price(INR)/Quintal":6185,"Percentage Difference":"1.18%","YEAR":2022},
{ "Year : Month Week#":"Aprwk4","Predicted Price(INR)/Quintal":6158,"Actual Price(INR)/Quintal":6102,"Percentage Difference":"-0.92%","YEAR":2022},
{ "Year : Month Week#":"Maywk1","Predicted Price(INR)/Quintal":6111,"Actual Price(INR)/Quintal":6048,"Percentage Difference":"-1.04%","YEAR":2022},
{ "Year : Month Week#":"Maywk2","Predicted Price(INR)/Quintal":6106,"Actual Price(INR)/Quintal":5905,"Percentage Difference":"-3.40%","YEAR":2022},
{ "Year : Month Week#":"Maywk3","Predicted Price(INR)/Quintal":5921,"Actual Price(INR)/Quintal":5951,"Percentage Difference":"0.50%","YEAR":2022},
{ "Year : Month Week#":"Maywk4","Predicted Price(INR)/Quintal":5915,"Actual Price(INR)/Quintal":5884,"Percentage Difference":"-0.53%","YEAR":2022},
{ "Year : Month Week#":"Junwk1","Predicted Price(INR)/Quintal":5898,"Actual Price(INR)/Quintal":5867,"Percentage Difference":"-0.53%","YEAR":2022},
{ "Year : Month Week#":"Junwk2","Predicted Price(INR)/Quintal":5896,"Actual Price(INR)/Quintal":5880,"Percentage Difference":"-0.27%","YEAR":2022},
{ "Year : Month Week#":"Junwk3","Predicted Price(INR)/Quintal":5890,"Actual Price(INR)/Quintal":5940,"Percentage Difference":"0.84%","YEAR":2022},
{ "Year : Month Week#":"Junwk4","Predicted Price(INR)/Quintal":5871,"Actual Price(INR)/Quintal":6014,"Percentage Difference":"2.38%","YEAR":2022},
{ "Year : Month Week#":"Julwk1","Predicted Price(INR)/Quintal":6206,"Actual Price(INR)/Quintal":6217,"Percentage Difference":"0.18%","YEAR":2022},
{ "Year : Month Week#":"Julwk2","Predicted Price(INR)/Quintal":6485,"Actual Price(INR)/Quintal":6730,"Percentage Difference":"3.64%","YEAR":2022},
{ "Year : Month Week#":"Julwk3","Predicted Price(INR)/Quintal":6591,"Actual Price(INR)/Quintal":6710,"Percentage Difference":"1.77%","YEAR":2022},
{ "Year : Month Week#":"Julwk4","Predicted Price(INR)/Quintal":6966,"Actual Price(INR)/Quintal":7097,"Percentage Difference":"1.85%","YEAR":2022},
{ "Year : Month Week#":"Augwk1","Predicted Price(INR)/Quintal":7560,"Actual Price(INR)/Quintal":7663,"Percentage Difference":"1.34%","YEAR":2022},
{ "Year : Month Week#":"Augwk2","Predicted Price(INR)/Quintal":7547,"Actual Price(INR)/Quintal":7804,"Percentage Difference":"1.85%","YEAR":2022},
{ "Year : Month Week#":"Augwk3","Predicted Price(INR)/Quintal":7553,"Actual Price(INR)/Quintal":7680,"Percentage Difference":"1.65%","YEAR":2022},
{ "Year : Month Week#":"Augwk4","Predicted Price(INR)/Quintal":7653,"Actual Price(INR)/Quintal":7409,"Percentage Difference":"-3.29%","YEAR":2022},
{ "Year : Month Week#":"Sepwk1","Predicted Price(INR)/Quintal":7159,"Actual Price(INR)/Quintal":7013,"Percentage Difference":"-2.08%","YEAR":2022},
{ "Year : Month Week#":"Sepwk2","Predicted Price(INR)/Quintal":7154,"Actual Price(INR)/Quintal":7091,"Percentage Difference":"-0.89%","YEAR":2022},
{ "Year : Month Week#":"Sepwk3","Predicted Price(INR)/Quintal":7169,"Actual Price(INR)/Quintal":7300,"Percentage Difference":"1.79%","YEAR":2022},
{ "Year : Month Week#":"Sepwk4","Predicted Price(INR)/Quintal":7155,"Actual Price(INR)/Quintal":7105,"Percentage Difference":"-0.70%","YEAR":2022},
{ "Year : Month Week#":"Octwk1","Predicted Price(INR)/Quintal":7184,"Actual Price(INR)/Quintal":7335,"Percentage Difference":"2.06%","YEAR":2022},
{ "Year : Month Week#":"Octwk2","Predicted Price(INR)/Quintal":7210,"Actual Price(INR)/Quintal":7237,"Percentage Difference":"0.37%","YEAR":2022},
{ "Year : Month Week#":"Octwk3","Predicted Price(INR)/Quintal":7313,"Actual Price(INR)/Quintal":7315,"Percentage Difference":"0.03%","YEAR":2022},
{ "Year : Month Week#":"Octwk4","Predicted Price(INR)/Quintal":7306,"Actual Price(INR)/Quintal":7400,"Percentage Difference":"1.27%","YEAR":2022},
{ "Year : Month Week#":"Novwk1","Predicted Price(INR)/Quintal":7343,"Actual Price(INR)/Quintal":7579,"Percentage Difference":"3.11%","YEAR":2022},
{ "Year : Month Week#":"Novwk2","Predicted Price(INR)/Quintal":7538,"Actual Price(INR)/Quintal":7509,"Percentage Difference":"-0.39%","YEAR":2022},
{ "Year : Month Week#":"Novwk3","Predicted Price(INR)/Quintal":7531,"Actual Price(INR)/Quintal":7296,"Percentage Difference":"-3.22%","YEAR":2022},
{ "Year : Month Week#":"Novwk4","Predicted Price(INR)/Quintal":7023,"Actual Price(INR)/Quintal":6903,"Percentage Difference":"-1.74%","YEAR":2022},
{ "Year : Month Week#":"Decwk1","Predicted Price(INR)/Quintal":6882,"Actual Price(INR)/Quintal":6799,"Percentage Difference":"-1.22%","YEAR":2022},
{ "Year : Month Week#":"Decwk2","Predicted Price(INR)/Quintal":6690,"Actual Price(INR)/Quintal":6840,"Percentage Difference":"2.19%","YEAR":2022},
{ "Year : Month Week#":"Decwk3","Predicted Price(INR)/Quintal":6766,"Actual Price(INR)/Quintal":6971,"Percentage Difference":"2.94%","YEAR":2022},
{ "Year : Month Week#":"Decwk4","Predicted Price(INR)/Quintal":6711,"Actual Price(INR)/Quintal":6936,"Percentage Difference":"3.24%","YEAR":2022},
{ "Year : Month Week#":"JanWk1","Predicted Price(INR)/Quintal":5654,"Actual Price(INR)/Quintal":5697,"Percentage Difference":"0.75%","YEAR":2021},
{ "Year : Month Week#":"JanWk2","Predicted Price(INR)/Quintal":5665,"Actual Price(INR)/Quintal":5659,"Percentage Difference":"-0.11%","YEAR":2021},
{ "Year : Month Week#":"JanWk3","Predicted Price(INR)/Quintal":5623,"Actual Price(INR)/Quintal":5737,"Percentage Difference":"1.99%","YEAR":2021},
{ "Year : Month Week#":"JanWk4","Predicted Price(INR)/Quintal":5718,"Actual Price(INR)/Quintal":5716,"Percentage Difference":"-0.03%","YEAR":2021},
{ "Year : Month Week#":"FebWk1","Predicted Price(INR)/Quintal":5951,"Actual Price(INR)/Quintal":6070,"Percentage Difference":"1.96%","YEAR":2021},
{ "Year : Month Week#":"FebWk2","Predicted Price(INR)/Quintal":6159,"Actual Price(INR)/Quintal":6302,"Percentage Difference":"2.27%","YEAR":2021},
{ "Year : Month Week#":"FebWk3","Predicted Price(INR)/Quintal":6351,"Actual Price(INR)/Quintal":6535,"Percentage Difference":"2.82%","YEAR":2021},
{ "Year : Month Week#":"FebWk4","Predicted Price(INR)/Quintal":6583,"Actual Price(INR)/Quintal":6563,"Percentage Difference":"-0.30%","YEAR":2021},
{ "Year : Month Week#":"MarWk1","Predicted Price(INR)/Quintal":6570,"Actual Price(INR)/Quintal":6573,"Percentage Difference":"0.05%","YEAR":2021},
{ "Year : Month Week#":"MarWk2","Predicted Price(INR)/Quintal":6327,"Actual Price(INR)/Quintal":6269,"Percentage Difference":"-0.93%","YEAR":2021},
{ "Year : Month Week#":"MarWk3","Predicted Price(INR)/Quintal":6380,"Actual Price(INR)/Quintal":6344,"Percentage Difference":"-0.57%","YEAR":2021},
{ "Year : Month Week#":"MarWk3","Predicted Price(INR)/Quintal":6380,"Actual Price(INR)/Quintal":6344,"Percentage Difference":"-0.57%","YEAR":2021},
{ "Year : Month Week#":"MarWk4","Predicted Price(INR)/Quintal":6343,"Actual Price(INR)/Quintal":6369,"Percentage Difference":"0.41%","YEAR":2021},
{ "Year : Month Week#":"AprWk1","Predicted Price(INR)/Quintal":6413,"Actual Price(INR)/Quintal":6565,"Percentage Difference":"2.32%","YEAR":2021},
{ "Year : Month Week#":"AprWk2","Predicted Price(INR)/Quintal":6537,"Actual Price(INR)/Quintal":6584,"Percentage Difference":"0.71%","YEAR":2021},
{ "Year : Month Week#":"AprWk3","Predicted Price(INR)/Quintal":6565,"Actual Price(INR)/Quintal":6561,"Percentage Difference":"-0.06%","YEAR":2021},
{ "Year : Month Week#":"AprWk4","Predicted Price(INR)/Quintal":6542,"Actual Price(INR)/Quintal":6610,"Percentage Difference":"1.03%","YEAR":2021},
{ "Year : Month Week#":"Maywk1","Predicted Price(INR)/Quintal":6496,"Actual Price(INR)/Quintal":6555,"Percentage Difference":"0.90%","YEAR":2021},
{ "Year : Month Week#":"Maywk2","Predicted Price(INR)/Quintal":6514,"Actual Price(INR)/Quintal":6662,"Percentage Difference":"2.22%","YEAR":2021},
{ "Year : Month Week#":"Maywk3","Predicted Price(INR)/Quintal":6578,"Actual Price(INR)/Quintal":6600,"Percentage Difference":"0.33%","YEAR":2021},
{ "Year : Month Week#":"Maywk4","Predicted Price(INR)/Quintal":6601,"Actual Price(INR)/Quintal":6454,"Percentage Difference":"-2.28%","YEAR":2021},
{ "Year : Month Week#":"Junwk1","Predicted Price(INR)/Quintal":6412,"Actual Price(INR)/Quintal":6444,"Percentage Difference":"0.50%","YEAR":2021},
{ "Year : Month Week#":"Junwk2","Predicted Price(INR)/Quintal":6480,"Actual Price(INR)/Quintal":6398,"Percentage Difference":"-1.28%","YEAR":2021},
{ "Year : Month Week#":"Junwk3","Predicted Price(INR)/Quintal":6388,"Actual Price(INR)/Quintal":6470,"Percentage Difference":"1.27%","YEAR":2021},
{ "Year : Month Week#":"Junwk4","Predicted Price(INR)/Quintal":6377,"Actual Price(INR)/Quintal":6383,"Percentage Difference":"0.09%","YEAR":2021},
{ "Year : Month Week#":"Julwk1","Predicted Price(INR)/Quintal":6116,"Actual Price(INR)/Quintal":6180,"Percentage Difference":"1.04%","YEAR":2021},
{ "Year : Month Week#":"Julwk2","Predicted Price(INR)/Quintal":6051,"Actual Price(INR)/Quintal":6087,"Percentage Difference":"0.59%","YEAR":2021},
{ "Year : Month Week#":"Julwk3","Predicted Price(INR)/Quintal":6009,"Actual Price(INR)/Quintal":6030,"Percentage Difference":"0.35%","YEAR":2021},
{ "Year : Month Week#":"Julwk4","Predicted Price(INR)/Quintal":5991,"Actual Price(INR)/Quintal":6127,"Percentage Difference":"2.22%","YEAR":2021},
{ "Year : Month Week#":"Augwk1","Predicted Price(INR)/Quintal":6124,"Actual Price(INR)/Quintal":6273,"Percentage Difference":"2.38%","YEAR":2021},
{ "Year : Month Week#":"Augwk2","Predicted Price(INR)/Quintal":6188,"Actual Price(INR)/Quintal":6253,"Percentage Difference":"1.04%","YEAR":2021},
{ "Year : Month Week#":"Augwk3","Predicted Price(INR)/Quintal":6227,"Actual Price(INR)/Quintal":6456,"Percentage Difference":"3.55%","YEAR":2021},
{ "Year : Month Week#":"Augwk4","Predicted Price(INR)/Quintal":6393,"Actual Price(INR)/Quintal":6490,"Percentage Difference":"1.49%","YEAR":2021},
{ "Year : Month Week#":"Sepwk1","Predicted Price(INR)/Quintal":6394,"Actual Price(INR)/Quintal":6533,"Percentage Difference":"2.13%","YEAR":2021},
{ "Year : Month Week#":"Sepwk2","Predicted Price(INR)/Quintal":6424,"Actual Price(INR)/Quintal":6522,"Percentage Difference":"1.50%","YEAR":2021},
{ "Year : Month Week#":"Sepwk3","Predicted Price(INR)/Quintal":6318,"Actual Price(INR)/Quintal":6357,"Percentage Difference":"0.61%","YEAR":2021},
{ "Year : Month Week#":"Sepwk4","Predicted Price(INR)/Quintal":6441,"Actual Price(INR)/Quintal":6451,"Percentage Difference":"0.16%","YEAR":2021},
{ "Year : Month Week#":"Octwk1","Predicted Price(INR)/Quintal":6555,"Actual Price(INR)/Quintal":6602,"Percentage Difference":"0.71%","YEAR":2021},
{ "Year : Month Week#":"Octwk2","Predicted Price(INR)/Quintal":6454,"Actual Price(INR)/Quintal":6502,"Percentage Difference":"0.74%","YEAR":2021},
{ "Year : Month Week#":"Octwk3","Predicted Price(INR)/Quintal":6442,"Actual Price(INR)/Quintal":6368,"Percentage Difference":"-1.16%","YEAR":2021},
{ "Year : Month Week#":"Octwk4","Predicted Price(INR)/Quintal":6129,"Actual Price(INR)/Quintal":6161,"Percentage Difference":"0.52%","YEAR":2021},
{ "Year : Month Week#":"Novwk1","Predicted Price(INR)/Quintal":6231,"Actual Price(INR)/Quintal":6187,"Percentage Difference":"-0.71%","YEAR":2021},
{ "Year : Month Week#":"Novwk2","Predicted Price(INR)/Quintal":6240,"Actual Price(INR)/Quintal":6129,"Percentage Difference":"-1.81%","YEAR":2021},
{ "Year : Month Week#":"Novwk3","Predicted Price(INR)/Quintal":6090,"Actual Price(INR)/Quintal":5866,"Percentage Difference":"-3.82%","YEAR":2021},
{ "Year : Month Week#":"Novwk4","Predicted Price(INR)/Quintal":5898,"Actual Price(INR)/Quintal":5900,"Percentage Difference":"0.03%","YEAR":2021},
{ "Year : Month Week#":"Decwk1","Predicted Price(INR)/Quintal":5851,"Actual Price(INR)/Quintal":5974,"Percentage Difference":"2.06%","YEAR":2021},
{ "Year : Month Week#":"Decwk2","Predicted Price(INR)/Quintal":5958,"Actual Price(INR)/Quintal":6050,"Percentage Difference":"1.52%","YEAR":2021},
{ "Year : Month Week#":"Decwk3","Predicted Price(INR)/Quintal":5906,"Actual Price(INR)/Quintal":6090,"Percentage Difference":"3.02%","YEAR":2021},
{ "Year : Month Week#":"Decwk4","Predicted Price(INR)/Quintal":5974,"Actual Price(INR)/Quintal":5953,"Percentage Difference":"-0.35%","YEAR":2021},
{ "Year : Month Week#":"JanWk1","Predicted Price(INR)/Quintal":5029,"Actual Price(INR)/Quintal":5087,"Percentage Difference":"1.14%","YEAR":2020},
{ "Year : Month Week#":"JanWk2","Predicted Price(INR)/Quintal":5089,"Actual Price(INR)/Quintal":5083,"Percentage Difference":"-0.12%","YEAR":2020},
{ "Year : Month Week#":"JanWk3","Predicted Price(INR)/Quintal":5103,"Actual Price(INR)/Quintal":4908,"Percentage Difference":"-3.97%","YEAR":2020},
{ "Year : Month Week#":"JanWk4","Predicted Price(INR)/Quintal":5024,"Actual Price(INR)/Quintal":4858,"Percentage Difference":"-3.42%","YEAR":2020},
{ "Year : Month Week#":"FebWk1","Predicted Price(INR)/Quintal":4953,"Actual Price(INR)/Quintal":4897,"Percentage Difference":"-1.14%","YEAR":2020},
{ "Year : Month Week#":"FebWk2","Predicted Price(INR)/Quintal":4909,"Actual Price(INR)/Quintal":4862,"Percentage Difference":"-0.97%","YEAR":2020},
{ "Year : Month Week#":"FebWk3","Predicted Price(INR)/Quintal":4978,"Actual Price(INR)/Quintal":4927,"Percentage Difference":"-1.04%","YEAR":2020},
{ "Year : Month Week#":"FebWk4","Predicted Price(INR)/Quintal":4926,"Actual Price(INR)/Quintal":4855,"Percentage Difference":"-1.46%","YEAR":2020},
{ "Year : Month Week#":"MarWk1","Predicted Price(INR)/Quintal":4906,"Actual Price(INR)/Quintal":4828,"Percentage Difference":"-1.62%","YEAR":2020},
{ "Year : Month Week#":"MarWk2","Predicted Price(INR)/Quintal":4803,"Actual Price(INR)/Quintal":4773,"Percentage Difference":"-0.63%","YEAR":2020},
{ "Year : Month Week#":"MarWk3","Predicted Price(INR)/Quintal":4803,"Actual Price(INR)/Quintal":4860,"Percentage Difference":"1.17%","YEAR":2020},
{ "Year : Month Week#":"MarWk4","Predicted Price(INR)/Quintal":4820,"Actual Price(INR)/Quintal":5113,"Percentage Difference":"5.73%","YEAR":2020},
{ "Year : Month Week#":"AprWk1","Predicted Price(INR)/Quintal":4983,"Actual Price(INR)/Quintal":5107,"Percentage Difference":"2.43%","YEAR":2020},
{ "Year : Month Week#":"AprWk2","Predicted Price(INR)/Quintal":5081,"Actual Price(INR)/Quintal":5233,"Percentage Difference":"2.90%","YEAR":2020},
{ "Year : Month Week#":"AprWk3","Predicted Price(INR)/Quintal":5188,"Actual Price(INR)/Quintal":5196,"Percentage Difference":"0.15%","YEAR":2020},
{ "Year : Month Week#":"AprWk4","Predicted Price(INR)/Quintal":5179,"Actual Price(INR)/Quintal":5186,"Percentage Difference":"0.13%","YEAR":2020},
{ "Year : Month Week#":"MayWk1","Predicted Price(INR)/Quintal":5177,"Actual Price(INR)/Quintal":5092,"Percentage Difference":"-1.67%","YEAR":2020},
{ "Year : Month Week#":"MayWk2","Predicted Price(INR)/Quintal":5097,"Actual Price(INR)/Quintal":5102,"Percentage Difference":"0.10%","YEAR":2020},
{ "Year : Month Week#":"MayWk3","Predicted Price(INR)/Quintal":5116,"Actual Price(INR)/Quintal":5112,"Percentage Difference":"-0.08%","YEAR":2020},
{ "Year : Month Week#":"MayWk4","Predicted Price(INR)/Quintal":5141,"Actual Price(INR)/Quintal":5099,"Percentage Difference":"-0.82%","YEAR":2020},
{ "Year : Month Week#":"JunWk1","Predicted Price(INR)/Quintal":5153,"Actual Price(INR)/Quintal":5161,"Percentage Difference":"0.16%","YEAR":2020},
{ "Year : Month Week#":"JunWk2","Predicted Price(INR)/Quintal":5143,"Actual Price(INR)/Quintal":5217,"Percentage Difference":"1.42%","YEAR":2020},
{ "Year : Month Week#":"JunWk3","Predicted Price(INR)/Quintal":5130,"Actual Price(INR)/Quintal":5289,"Percentage Difference":"3.01%","YEAR":2020},
{ "Year : Month Week#":"JunWk4","Predicted Price(INR)/Quintal":5228,"Actual Price(INR)/Quintal":5364,"Percentage Difference":"2.54%","YEAR":2020},
{ "Year : Month Week#":"JulWk1","Predicted Price(INR)/Quintal":5335,"Actual Price(INR)/Quintal":5550,"Percentage Difference":"3.87%","YEAR":2020},
{ "Year : Month Week#":"JulWk2","Predicted Price(INR)/Quintal":5530,"Actual Price(INR)/Quintal":5576,"Percentage Difference":"0.82%","YEAR":2020},
{ "Year : Month Week#":"JulWk3","Predicted Price(INR)/Quintal":5523,"Actual Price(INR)/Quintal":5398,"Percentage Difference":"-2.32%","YEAR":2020},
{ "Year : Month Week#":"JulWk4","Predicted Price(INR)/Quintal":5345,"Actual Price(INR)/Quintal":5604,"Percentage Difference":"4.62%","YEAR":2020},
{ "Year : Month Week#":"AugWk1","Predicted Price(INR)/Quintal":5423,"Actual Price(INR)/Quintal":5426,"Percentage Difference":"0.06%","YEAR":2020},
{ "Year : Month Week#":"AugWk2","Predicted Price(INR)/Quintal":5389,"Actual Price(INR)/Quintal":5554,"Percentage Difference":"2.97%","YEAR":2020},
{ "Year : Month Week#":"AugWk3","Predicted Price(INR)/Quintal":5412,"Actual Price(INR)/Quintal":5535,"Percentage Difference":"2.22%","YEAR":2020},
{ "Year : Month Week#":"AugWk4","Predicted Price(INR)/Quintal":5747,"Actual Price(INR)/Quintal":5803,"Percentage Difference":"0.97%","YEAR":2020},
{ "Year : Month Week#":"SepWk1","Predicted Price(INR)/Quintal":5742,"Actual Price(INR)/Quintal":5852,"Percentage Difference":"1.88%","YEAR":2020},
{ "Year : Month Week#":"SepWk2","Predicted Price(INR)/Quintal":5804,"Actual Price(INR)/Quintal":5806,"Percentage Difference":"0.03%","YEAR":2020},
{ "Year : Month Week#":"SepWk3","Predicted Price(INR)/Quintal":5881,"Actual Price(INR)/Quintal":6069,"Percentage Difference":"3.10%","YEAR":2020},
{ "Year : Month Week#":"SepWk4","Predicted Price(INR)/Quintal":6592,"Actual Price(INR)/Quintal":6673,"Percentage Difference":"1.21%","YEAR":2020},
{ "Year : Month Week#":"OctWk1","Predicted Price(INR)/Quintal":6772,"Actual Price(INR)/Quintal":6833,"Percentage Difference":"0.89%","YEAR":2020},
{ "Year : Month Week#":"OctWk2","Predicted Price(INR)/Quintal":7298,"Actual Price(INR)/Quintal":7314,"Percentage Difference":"0.22%","YEAR":2020},
{ "Year : Month Week#":"OctWk3","Predicted Price(INR)/Quintal":7255,"Actual Price(INR)/Quintal":7314,"Percentage Difference":"0.81%","YEAR":2020},
{ "Year : Month Week#":"OctWk4","Predicted Price(INR)/Quintal":7099,"Actual Price(INR)/Quintal":7010,"Percentage Difference":"-1.27%","YEAR":2020},
{ "Year : Month Week#":"NovWk1","Predicted Price(INR)/Quintal":7077,"Actual Price(INR)/Quintal":6749,"Percentage Difference":"-4.86%","YEAR":2020},
{ "Year : Month Week#":"NovWk2","Predicted Price(INR)/Quintal":6722,"Actual Price(INR)/Quintal":6400,"Percentage Difference":"-5.03%","YEAR":2020},
{ "Year : Month Week#":"NovWk3","Predicted Price(INR)/Quintal":6319,"Actual Price(INR)/Quintal":6255,"Percentage Difference":"-1.02%","YEAR":2020},
{ "Year : Month Week#":"NovWk4","Predicted Price(INR)/Quintal":6107,"Actual Price(INR)/Quintal":6188,"Percentage Difference":"1.31%","YEAR":2020},
{ "Year : Month Week#":"DecWk1","Predicted Price(INR)/Quintal":6035,"Actual Price(INR)/Quintal":6036,"Percentage Difference":"0.02%","YEAR":2020},
{ "Year : Month Week#":"DecWk2","Predicted Price(INR)/Quintal":6019,"Actual Price(INR)/Quintal":5961,"Percentage Difference":"-0.97%","YEAR":2020},
{ "Year : Month Week#":"DecWk3","Predicted Price(INR)/Quintal":5741,"Actual Price(INR)/Quintal":5638,"Percentage Difference":"-1.83%","YEAR":2020},
{ "Year : Month Week#":"DecWk4","Predicted Price(INR)/Quintal":5630,"Actual Price(INR)/Quintal":5629,"Percentage Difference":"-0.02%","YEAR":2020},
  {
    "Year : Month Week#": "JanWk1",
    "Predicted Price(INR)/Quintal": 4699,
    "Actual Price(INR)/Quintal": 4729,
    "Percentage Difference": "0.63%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "JanWk2",
    "Predicted Price(INR)/Quintal": 4714,
    "Actual Price(INR)/Quintal": 4880,
    "Percentage Difference": "3.40%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "JanWk3",
    "Predicted Price(INR)/Quintal": 4817,
    "Actual Price(INR)/Quintal": 5088,
    "Percentage Difference": "5.33%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "JanWk4",
    "Predicted Price(INR)/Quintal": 5009,
    "Actual Price(INR)/Quintal": 5248,
    "Percentage Difference": "4.55%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "FebWk1",
    "Predicted Price(INR)/Quintal": 5114,
    "Actual Price(INR)/Quintal": 5288,
    "Percentage Difference": "3.29%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "FebWk2",
    "Predicted Price(INR)/Quintal": 5210,
    "Actual Price(INR)/Quintal": 5296,
    "Percentage Difference": "1.62%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "FebWk3",
    "Predicted Price(INR)/Quintal": 5272,
    "Actual Price(INR)/Quintal": 5080,
    "Percentage Difference": "-3.78%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "FebWk4",
    "Predicted Price(INR)/Quintal": 5090,
    "Actual Price(INR)/Quintal": 4950,
    "Percentage Difference": "-2.83%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "MarWk1",
    "Predicted Price(INR)/Quintal": 4988,
    "Actual Price(INR)/Quintal": 4961,
    "Percentage Difference": "-0.54%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "MarWk2",
    "Predicted Price(INR)/Quintal": 4962,
    "Actual Price(INR)/Quintal": 5001,
    "Percentage Difference": "0.78%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "MarWk3",
    "Predicted Price(INR)/Quintal": 4944,
    "Actual Price(INR)/Quintal": 4882,
    "Percentage Difference": "-1.27%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "MarWk4",
    "Predicted Price(INR)/Quintal": 4845,
    "Actual Price(INR)/Quintal": 4902,
    "Percentage Difference": "1.16%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "AprWk1",
    "Predicted Price(INR)/Quintal": 4895,
    "Actual Price(INR)/Quintal": 5029,
    "Percentage Difference": "2.66%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "AprWk2",
    "Predicted Price(INR)/Quintal": 4980,
    "Actual Price(INR)/Quintal": 4987,
    "Percentage Difference": "0.14%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "AprWk3",
    "Predicted Price(INR)/Quintal": 4977,
    "Actual Price(INR)/Quintal": 4984,
    "Percentage Difference": "0.14%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "AprWk4",
    "Predicted Price(INR)/Quintal": 4981,
    "Actual Price(INR)/Quintal": 5063,
    "Percentage Difference": "1.62%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "MayWk1",
    "Predicted Price(INR)/Quintal": 5093,
    "Actual Price(INR)/Quintal": 5305,
    "Percentage Difference": "4.00%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "MayWk2",
    "Predicted Price(INR)/Quintal": 5234,
    "Actual Price(INR)/Quintal": 5508,
    "Percentage Difference": "4.97%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "MayWk3",
    "Predicted Price(INR)/Quintal": 5497,
    "Actual Price(INR)/Quintal": 5766,
    "Percentage Difference": "4.67%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "MayWk4",
    "Predicted Price(INR)/Quintal": 5835,
    "Actual Price(INR)/Quintal": 5840,
    "Percentage Difference": "0.09%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "JunWk1",
    "Predicted Price(INR)/Quintal": 5824,
    "Actual Price(INR)/Quintal": 5717,
    "Percentage Difference": "-1.87%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "JunWk2",
    "Predicted Price(INR)/Quintal": 5763,
    "Actual Price(INR)/Quintal": 5640,
    "Percentage Difference": "-2.18%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "JunWk3",
    "Predicted Price(INR)/Quintal": 5603,
    "Actual Price(INR)/Quintal": 5618,
    "Percentage Difference": "0.27%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "JunWk4",
    "Predicted Price(INR)/Quintal": 5624,
    "Actual Price(INR)/Quintal": 5560,
    "Percentage Difference": "-1.15%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "JulWk1",
    "Predicted Price(INR)/Quintal": 5590,
    "Actual Price(INR)/Quintal": 5591,
    "Percentage Difference": "0.02%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "JulWk2",
    "Predicted Price(INR)/Quintal": 5591,
    "Actual Price(INR)/Quintal": 5606,
    "Percentage Difference": "0.27%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "JulWk3",
    "Predicted Price(INR)/Quintal": 5597,
    "Actual Price(INR)/Quintal": 5706,
    "Percentage Difference": "1.91%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "JulWk4",
    "Predicted Price(INR)/Quintal": 5698,
    "Actual Price(INR)/Quintal": 5765,
    "Percentage Difference": "1.16%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "AugWk1",
    "Predicted Price(INR)/Quintal": 5655,
    "Actual Price(INR)/Quintal": 5662,
    "Percentage Difference": "0.12%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "AugWk2",
    "Predicted Price(INR)/Quintal": 5601,
    "Actual Price(INR)/Quintal": 5665,
    "Percentage Difference": "1.13%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "AugWk3",
    "Predicted Price(INR)/Quintal": 5607,
    "Actual Price(INR)/Quintal": 5668,
    "Percentage Difference": "1.08%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "AugWk4",
    "Predicted Price(INR)/Quintal": 5605,
    "Actual Price(INR)/Quintal": 5612,
    "Percentage Difference": "0.12%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "SepWk1",
    "Predicted Price(INR)/Quintal": 5670,
    "Actual Price(INR)/Quintal": 5602,
    "Percentage Difference": "-1.21%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "SepWk2",
    "Predicted Price(INR)/Quintal": 5582,
    "Actual Price(INR)/Quintal": 5519,
    "Percentage Difference": "-1.14%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "SepWk3",
    "Predicted Price(INR)/Quintal": 5588,
    "Actual Price(INR)/Quintal": 5602,
    "Percentage Difference": "0.25%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "SepWk4",
    "Predicted Price(INR)/Quintal": 5570,
    "Actual Price(INR)/Quintal": 5579,
    "Percentage Difference": "0.16%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "OctWk1",
    "Predicted Price(INR)/Quintal": 5575,
    "Actual Price(INR)/Quintal": 5475,
    "Percentage Difference": "-1.83%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "OctWk2",
    "Predicted Price(INR)/Quintal": 5473,
    "Actual Price(INR)/Quintal": 5319,
    "Percentage Difference": "-2.90%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "OctWk3",
    "Predicted Price(INR)/Quintal": 5488,
    "Actual Price(INR)/Quintal": 5312,
    "Percentage Difference": "-3.31%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "OctWk4",
    "Predicted Price(INR)/Quintal": 5374,
    "Actual Price(INR)/Quintal": 5357,
    "Percentage Difference": "-0.32%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "NovWk1",
    "Predicted Price(INR)/Quintal": 5675,
    "Actual Price(INR)/Quintal": 5673,
    "Percentage Difference": "-0.04%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "NovWk2",
    "Predicted Price(INR)/Quintal": 5671,
    "Actual Price(INR)/Quintal": 5639,
    "Percentage Difference": "-0.57%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "NovWk3",
    "Predicted Price(INR)/Quintal": 5506,
    "Actual Price(INR)/Quintal": 5476,
    "Percentage Difference": "-0.55%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "NovWk4",
    "Predicted Price(INR)/Quintal": 5474,
    "Actual Price(INR)/Quintal": 5308,
    "Percentage Difference": "-3.13%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "DecWk1",
    "Predicted Price(INR)/Quintal": 5442,
    "Actual Price(INR)/Quintal": 5439,
    "Percentage Difference": "-0.06%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "DecWk2",
    "Predicted Price(INR)/Quintal": 5304,
    "Actual Price(INR)/Quintal": 5313,
    "Percentage Difference": "0.17%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "DecWk3",
    "Predicted Price(INR)/Quintal": 5204,
    "Actual Price(INR)/Quintal": 5200,
    "Percentage Difference": "-0.08%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "DecWk4",
    "Predicted Price(INR)/Quintal": 5252,
    "Actual Price(INR)/Quintal": 5030,
    "Percentage Difference": "-4.41%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "JanWk1",
    "Predicted Price(INR)/Quintal": 4186,
    "Actual Price(INR)/Quintal": 4203,
    "Percentage Difference": "0.40%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "JanWk2",
    "Predicted Price(INR)/Quintal": 4191,
    "Actual Price(INR)/Quintal": 4146,
    "Percentage Difference": "-1.09%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "JanWk3",
    "Predicted Price(INR)/Quintal": 4108,
    "Actual Price(INR)/Quintal": 4096,
    "Percentage Difference": "-0.29%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "JanWk4",
    "Predicted Price(INR)/Quintal": 4051,
    "Actual Price(INR)/Quintal": 4106,
    "Percentage Difference": "1.34%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "FebWk1",
    "Predicted Price(INR)/Quintal": 4111,
    "Actual Price(INR)/Quintal": 4169,
    "Percentage Difference": "1.39%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "FebWk2",
    "Predicted Price(INR)/Quintal": 4180,
    "Actual Price(INR)/Quintal": 4103,
    "Percentage Difference": "-1.88%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "FebWk3",
    "Predicted Price(INR)/Quintal": 4031,
    "Actual Price(INR)/Quintal": 4170,
    "Percentage Difference": "3.33%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "FebWk4",
    "Predicted Price(INR)/Quintal": 4145,
    "Actual Price(INR)/Quintal": 3975,
    "Percentage Difference": "-4.28%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "MarWk1",
    "Predicted Price(INR)/Quintal": 3990,
    "Actual Price(INR)/Quintal": 3938,
    "Percentage Difference": "-1.32%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "MarWk2",
    "Predicted Price(INR)/Quintal": 3961,
    "Actual Price(INR)/Quintal": 3962,
    "Percentage Difference": "0.03%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "MarWk3",
    "Predicted Price(INR)/Quintal": 3948,
    "Actual Price(INR)/Quintal": 3828,
    "Percentage Difference": "-3.13%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "MarWk4",
    "Predicted Price(INR)/Quintal": 3942,
    "Actual Price(INR)/Quintal": 3822,
    "Percentage Difference": "-3.14%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "AprWk1",
    "Predicted Price(INR)/Quintal": 3894,
    "Actual Price(INR)/Quintal": 3882,
    "Percentage Difference": "-0.31%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "AprWk2",
    "Predicted Price(INR)/Quintal": 3862,
    "Actual Price(INR)/Quintal": 3813,
    "Percentage Difference": "-1.29%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "AprWk3",
    "Predicted Price(INR)/Quintal": 3872,
    "Actual Price(INR)/Quintal": 3707,
    "Percentage Difference": "-4.45%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "AprWk4",
    "Predicted Price(INR)/Quintal": 3725,
    "Actual Price(INR)/Quintal": 3758,
    "Percentage Difference": "0.88%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "MayWk1",
    "Predicted Price(INR)/Quintal": 3717,
    "Actual Price(INR)/Quintal": 3853,
    "Percentage Difference": "3.53%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "MayWk2",
    "Predicted Price(INR)/Quintal": 3706,
    "Actual Price(INR)/Quintal": 3890,
    "Percentage Difference": "4.73%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "MayWk3",
    "Predicted Price(INR)/Quintal": 3920,
    "Actual Price(INR)/Quintal": 3977,
    "Percentage Difference": "1.43%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "MayWk4",
    "Predicted Price(INR)/Quintal": 3902,
    "Actual Price(INR)/Quintal": 3922,
    "Percentage Difference": "0.51%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "JunWk1",
    "Predicted Price(INR)/Quintal": 3925,
    "Actual Price(INR)/Quintal": 3889,
    "Percentage Difference": "-0.93%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "JunWk2",
    "Predicted Price(INR)/Quintal": 3836,
    "Actual Price(INR)/Quintal": 3631,
    "Percentage Difference": "-5.65%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "JunWk3",
    "Predicted Price(INR)/Quintal": 3634,
    "Actual Price(INR)/Quintal": 3837,
    "Percentage Difference": "5.29%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "JunWk4",
    "Predicted Price(INR)/Quintal": 3733,
    "Actual Price(INR)/Quintal": 3737,
    "Percentage Difference": "0.11%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "JulWk1",
    "Predicted Price(INR)/Quintal": 3762,
    "Actual Price(INR)/Quintal": 3774,
    "Percentage Difference": "0.32%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "JulWk2",
    "Predicted Price(INR)/Quintal": 3751,
    "Actual Price(INR)/Quintal": 3737,
    "Percentage Difference": "-0.37%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "JulWk3",
    "Predicted Price(INR)/Quintal": 3740,
    "Actual Price(INR)/Quintal": 3811,
    "Percentage Difference": "1.86%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "JulWk4",
    "Predicted Price(INR)/Quintal": 3832,
    "Actual Price(INR)/Quintal": 3766,
    "Percentage Difference": "-1.75%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "AugWk1",
    "Predicted Price(INR)/Quintal": 3827,
    "Actual Price(INR)/Quintal": 3815,
    "Percentage Difference": "-0.31%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "AugWk2",
    "Predicted Price(INR)/Quintal": 3829,
    "Actual Price(INR)/Quintal": 3812,
    "Percentage Difference": "-0.45%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "AugWk3",
    "Predicted Price(INR)/Quintal": 3824,
    "Actual Price(INR)/Quintal": 3796,
    "Percentage Difference": "-0.74%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "AugWk4",
    "Predicted Price(INR)/Quintal": 3823,
    "Actual Price(INR)/Quintal": 3706,
    "Percentage Difference": "-3.16%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "SepWk1",
    "Predicted Price(INR)/Quintal": 3718,
    "Actual Price(INR)/Quintal": 3655,
    "Percentage Difference": "-1.72%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "SepWk2",
    "Predicted Price(INR)/Quintal": 3695,
    "Actual Price(INR)/Quintal": 3638,
    "Percentage Difference": "-1.57%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "SepWk3",
    "Predicted Price(INR)/Quintal": 3698,
    "Actual Price(INR)/Quintal": 3633,
    "Percentage Difference": "-1.79%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "SepWk4",
    "Predicted Price(INR)/Quintal": 3689,
    "Actual Price(INR)/Quintal": 3647,
    "Percentage Difference": "-1.15%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "OctWk1",
    "Predicted Price(INR)/Quintal": 3599,
    "Actual Price(INR)/Quintal": 3574,
    "Percentage Difference": "-0.70%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "OctWk2",
    "Predicted Price(INR)/Quintal": 3544,
    "Actual Price(INR)/Quintal": 3519,
    "Percentage Difference": "-0.71%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "OctWk3",
    "Predicted Price(INR)/Quintal": 3517,
    "Actual Price(INR)/Quintal": 3546,
    "Percentage Difference": "0.82%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "OctWk4",
    "Predicted Price(INR)/Quintal": 3509,
    "Actual Price(INR)/Quintal": 3556,
    "Percentage Difference": "1.32%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "NovWk1",
    "Predicted Price(INR)/Quintal": 3514,
    "Actual Price(INR)/Quintal": 3707,
    "Percentage Difference": "5.21%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "NovWk2",
    "Predicted Price(INR)/Quintal": 3810,
    "Actual Price(INR)/Quintal": 4087,
    "Percentage Difference": "6.78%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "NovWk3",
    "Predicted Price(INR)/Quintal": 4172,
    "Actual Price(INR)/Quintal": 4304,
    "Percentage Difference": "3.07%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "NovWk4",
    "Predicted Price(INR)/Quintal": 4290,
    "Actual Price(INR)/Quintal": 4508,
    "Percentage Difference": "4.84%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "DecWk1",
    "Predicted Price(INR)/Quintal": 4688,
    "Actual Price(INR)/Quintal": 4831,
    "Percentage Difference": "2.96%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "DecWk2",
    "Predicted Price(INR)/Quintal": 4862,
    "Actual Price(INR)/Quintal": 4788,
    "Percentage Difference": "-1.55%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "DecWk3",
    "Predicted Price(INR)/Quintal": 4644,
    "Actual Price(INR)/Quintal": 4772,
    "Percentage Difference": "2.68%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "DecWk4",
    "Predicted Price(INR)/Quintal": 4745,
    "Actual Price(INR)/Quintal": 4730,
    "Percentage Difference": "-0.32%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "Jan Wk1",
    "Predicted Price(INR)/Quintal": 5201,
    "Actual Price(INR)/Quintal": 4901,
    "Percentage Difference": "-6.12%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Jan Wk2",
    "Predicted Price(INR)/Quintal": 4991,
    "Actual Price(INR)/Quintal": 4816,
    "Percentage Difference": "-3.63%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Jan Wk3",
    "Predicted Price(INR)/Quintal": 4831,
    "Actual Price(INR)/Quintal": 4936,
    "Percentage Difference": "2.13%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Jan Wk4",
    "Predicted Price(INR)/Quintal": 4906,
    "Actual Price(INR)/Quintal": 4933,
    "Percentage Difference": "0.54%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Feb Wk1",
    "Predicted Price(INR)/Quintal": 4905,
    "Actual Price(INR)/Quintal": 4824,
    "Percentage Difference": "-1.68%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Feb Wk2",
    "Predicted Price(INR)/Quintal": 4898,
    "Actual Price(INR)/Quintal": 4777,
    "Percentage Difference": "-2.53%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Feb Wk3",
    "Predicted Price(INR)/Quintal": 4792,
    "Actual Price(INR)/Quintal": 4723,
    "Percentage Difference": "-1.46%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Feb Wk4",
    "Predicted Price(INR)/Quintal": 4688,
    "Actual Price(INR)/Quintal": 4659,
    "Percentage Difference": "-0.62%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Mar Wk1",
    "Predicted Price(INR)/Quintal": 4633,
    "Actual Price(INR)/Quintal": 4834,
    "Percentage Difference": "4.16%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Mar Wk2",
    "Predicted Price(INR)/Quintal": 4876,
    "Actual Price(INR)/Quintal": 4942,
    "Percentage Difference": "1.34%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Mar Wk3",
    "Predicted Price(INR)/Quintal": 4863,
    "Actual Price(INR)/Quintal": 4737,
    "Percentage Difference": "-2.66%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Mar Wk4",
    "Predicted Price(INR)/Quintal": 4889,
    "Actual Price(INR)/Quintal": 4890,
    "Percentage Difference": "0.02%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Apr Wk1",
    "Predicted Price(INR)/Quintal": 4834,
    "Actual Price(INR)/Quintal": 4620,
    "Percentage Difference": "-4.63%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Apr Wk2",
    "Predicted Price(INR)/Quintal": 4620,
    "Actual Price(INR)/Quintal": 4532,
    "Percentage Difference": "-1.94%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Apr Wk3",
    "Predicted Price(INR)/Quintal": 4542,
    "Actual Price(INR)/Quintal": 4581,
    "Percentage Difference": "0.85%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Apr Wk4",
    "Predicted Price(INR)/Quintal": 4556,
    "Actual Price(INR)/Quintal": 4409,
    "Percentage Difference": "-3.33%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "May Wk1",
    "Predicted Price(INR)/Quintal": 4443,
    "Actual Price(INR)/Quintal": 4432,
    "Percentage Difference": "-0.25%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "May Wk2",
    "Predicted Price(INR)/Quintal": 4397,
    "Actual Price(INR)/Quintal": 4382,
    "Percentage Difference": "-0.34%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "May Wk3",
    "Predicted Price(INR)/Quintal": 4398,
    "Actual Price(INR)/Quintal": 4475,
    "Percentage Difference": "1.72%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "May Wk4",
    "Predicted Price(INR)/Quintal": 4412,
    "Actual Price(INR)/Quintal": 4219,
    "Percentage Difference": "-4.57%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Jun Wk1",
    "Predicted Price(INR)/Quintal": 4152,
    "Actual Price(INR)/Quintal": 4305,
    "Percentage Difference": "3.55%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Jun Wk2",
    "Predicted Price(INR)/Quintal": 4203,
    "Actual Price(INR)/Quintal": 4331,
    "Percentage Difference": "2.96%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Jun Wk3",
    "Predicted Price(INR)/Quintal": 4279,
    "Actual Price(INR)/Quintal": 4206,
    "Percentage Difference": "-1.74%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Jun Wk4",
    "Predicted Price(INR)/Quintal": 4164,
    "Actual Price(INR)/Quintal": 4166,
    "Percentage Difference": "0.05%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Jul Wk1",
    "Predicted Price(INR)/Quintal": 4140,
    "Actual Price(INR)/Quintal": 4213,
    "Percentage Difference": "1.73%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Jul Wk2",
    "Predicted Price(INR)/Quintal": 4104,
    "Actual Price(INR)/Quintal": 4230,
    "Percentage Difference": "2.98%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Jul Wk3",
    "Predicted Price(INR)/Quintal": 4250,
    "Actual Price(INR)/Quintal": 4123,
    "Percentage Difference": "-3.08%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Jul Wk4",
    "Predicted Price(INR)/Quintal": 4057,
    "Actual Price(INR)/Quintal": 4265,
    "Percentage Difference": "4.88%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Aug Wk1",
    "Predicted Price(INR)/Quintal": 4198,
    "Actual Price(INR)/Quintal": 4396,
    "Percentage Difference": "4.50%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Aug Wk2",
    "Predicted Price(INR)/Quintal": 4404,
    "Actual Price(INR)/Quintal": 4342,
    "Percentage Difference": "-1.43%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Aug Wk3",
    "Predicted Price(INR)/Quintal": 4406,
    "Actual Price(INR)/Quintal": 4609,
    "Percentage Difference": "4.40%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Aug Wk4",
    "Predicted Price(INR)/Quintal": 4498,
    "Actual Price(INR)/Quintal": 4817,
    "Percentage Difference": "6.62%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Sep Wk1",
    "Predicted Price(INR)/Quintal": 4765,
    "Actual Price(INR)/Quintal": 4539,
    "Percentage Difference": "-4.98%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Sep Wk2",
    "Predicted Price(INR)/Quintal": 4570,
    "Actual Price(INR)/Quintal": 4603,
    "Percentage Difference": "0.72%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Sep Wk3",
    "Predicted Price(INR)/Quintal": 4505,
    "Actual Price(INR)/Quintal": 4725,
    "Percentage Difference": "4.66%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Sep Wk4",
    "Predicted Price(INR)/Quintal": 4746,
    "Actual Price(INR)/Quintal": 4494,
    "Percentage Difference": "-5.61%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Oct Wk1",
    "Predicted Price(INR)/Quintal": 4430,
    "Actual Price(INR)/Quintal": 4909,
    "Percentage Difference": "9.76%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Oct Wk2",
    "Predicted Price(INR)/Quintal": 4585,
    "Actual Price(INR)/Quintal": 4840,
    "Percentage Difference": "5.27%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Oct Wk3",
    "Predicted Price(INR)/Quintal": 4699,
    "Actual Price(INR)/Quintal": 4884,
    "Percentage Difference": "3.79%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Oct Wk4",
    "Predicted Price(INR)/Quintal": 4816,
    "Actual Price(INR)/Quintal": 4591,
    "Percentage Difference": "-4.90%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Nov Wk1",
    "Predicted Price(INR)/Quintal": 4584,
    "Actual Price(INR)/Quintal": 4396,
    "Percentage Difference": "-4.28%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Nov Wk2",
    "Predicted Price(INR)/Quintal": 4413,
    "Actual Price(INR)/Quintal": 3975,
    "Percentage Difference": "-11.02%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Nov Wk3",
    "Predicted Price(INR)/Quintal": 3763,
    "Actual Price(INR)/Quintal": 3731,
    "Percentage Difference": "-0.86%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Nov Wk4",
    "Predicted Price(INR)/Quintal": 3794,
    "Actual Price(INR)/Quintal": 3823,
    "Percentage Difference": "0.76%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Dec Wk1",
    "Predicted Price(INR)/Quintal": 3824,
    "Actual Price(INR)/Quintal": 3858,
    "Percentage Difference": "0.88%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Dec Wk2",
    "Predicted Price(INR)/Quintal": 3777,
    "Actual Price(INR)/Quintal": 3958,
    "Percentage Difference": "4.57%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Dec Wk3",
    "Predicted Price(INR)/Quintal": 3908,
    "Actual Price(INR)/Quintal": 4109,
    "Percentage Difference": "4.89%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Dec Wk4",
    "Predicted Price(INR)/Quintal": 4030,
    "Actual Price(INR)/Quintal": 4182,
    "Percentage Difference": "3.63%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Jan Wk1",
    "Predicted Price(INR)/Quintal": 9680,
    "Actual Price(INR)/Quintal": 9828,
    "Percentage Difference": "1.51%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Jan Wk2",
    "Predicted Price(INR)/Quintal": 9638,
    "Actual Price(INR)/Quintal": 9990,
    "Percentage Difference": "3.52%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Jan Wk3",
    "Predicted Price(INR)/Quintal": 9185,
    "Actual Price(INR)/Quintal": 9248,
    "Percentage Difference": "0.68%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Jan Wk4",
    "Predicted Price(INR)/Quintal": 8681,
    "Actual Price(INR)/Quintal": 9150,
    "Percentage Difference": "5.13%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Feb Wk1",
    "Predicted Price(INR)/Quintal": 9985,
    "Actual Price(INR)/Quintal": 12345,
    "Percentage Difference": "19.12%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Feb Wk2",
    "Predicted Price(INR)/Quintal": 9114,
    "Actual Price(INR)/Quintal": 9239,
    "Percentage Difference": "1.35%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Feb Wk3",
    "Predicted Price(INR)/Quintal": 8004,
    "Actual Price(INR)/Quintal": 7940,
    "Percentage Difference": "-0.81%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Feb Wk4",
    "Predicted Price(INR)/Quintal": 8055,
    "Actual Price(INR)/Quintal": 9001,
    "Percentage Difference": "10.51%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Mar Wk1",
    "Predicted Price(INR)/Quintal": 8204,
    "Actual Price(INR)/Quintal": 7994,
    "Percentage Difference": "-2.63%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Mar Wk2",
    "Predicted Price(INR)/Quintal": 8015,
    "Actual Price(INR)/Quintal": 8568,
    "Percentage Difference": "6.45%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Mar Wk3",
    "Predicted Price(INR)/Quintal": 8575,
    "Actual Price(INR)/Quintal": 8634,
    "Percentage Difference": "0.68%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Mar Wk4",
    "Predicted Price(INR)/Quintal": 8543,
    "Actual Price(INR)/Quintal": 8683,
    "Percentage Difference": "1.61%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Apr Wk1",
    "Predicted Price(INR)/Quintal": 8683,
    "Actual Price(INR)/Quintal": 8908,
    "Percentage Difference": "2.52%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Apr Wk2",
    "Predicted Price(INR)/Quintal": 8916,
    "Actual Price(INR)/Quintal": 9348,
    "Percentage Difference": "4.62%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Apr Wk3",
    "Predicted Price(INR)/Quintal": 9403,
    "Actual Price(INR)/Quintal": 9535,
    "Percentage Difference": "1.38%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Apr Wk4",
    "Predicted Price(INR)/Quintal": 9414,
    "Actual Price(INR)/Quintal": 8994,
    "Percentage Difference": "-4.67%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "May Wk1",
    "Predicted Price(INR)/Quintal": 9012,
    "Actual Price(INR)/Quintal": 9017,
    "Percentage Difference": "0.00%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "May Wk2",
    "Predicted Price(INR)/Quintal": 9122,
    "Actual Price(INR)/Quintal": 9163,
    "Percentage Difference": "0.01%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "May Wk3",
    "Predicted Price(INR)/Quintal": 9134,
    "Actual Price(INR)/Quintal": 9033,
    "Percentage Difference": "-1.12%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "May Wk4",
    "Predicted Price(INR)/Quintal": 9016,
    "Actual Price(INR)/Quintal": 8990,
    "Percentage Difference": "-0.01%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Jun Wk1",
    "Predicted Price(INR)/Quintal": 9052,
    "Actual Price(INR)/Quintal": 8920,
    "Percentage Difference": "-1.49%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Jun Wk2",
    "Predicted Price(INR)/Quintal": 9016,
    "Actual Price(INR)/Quintal": 9086,
    "Percentage Difference": "-0.77%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Jun Wk3",
    "Predicted Price(INR)/Quintal": 9253,
    "Actual Price(INR)/Quintal": 8898,
    "Percentage Difference": "-3.99%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Jun Wk4",
    "Predicted Price(INR)/Quintal": 8918,
    "Actual Price(INR)/Quintal": 8938,
    "Percentage Difference": "0.22%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Jul Wk1",
    "Predicted Price(INR)/Quintal": 8984,
    "Actual Price(INR)/Quintal": 9040,
    "Percentage Difference": "0.62%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Jul Wk2",
    "Predicted Price(INR)/Quintal": 9018,
    "Actual Price(INR)/Quintal": 8995,
    "Percentage Difference": "-0.26%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Jul Wk3",
    "Predicted Price(INR)/Quintal": 9060,
    "Actual Price(INR)/Quintal": 8432,
    "Percentage Difference": "-7.45%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Jul Wk4",
    "Predicted Price(INR)/Quintal": 8456,
    "Actual Price(INR)/Quintal": 8229,
    "Percentage Difference": "-2.76%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Aug Wk1",
    "Predicted Price(INR)/Quintal": 8303,
    "Actual Price(INR)/Quintal": 7804,
    "Percentage Difference": "-6.39%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Aug Wk2",
    "Predicted Price(INR)/Quintal": 7322,
    "Actual Price(INR)/Quintal": 6786,
    "Percentage Difference": "-7.90%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Aug Wk3",
    "Predicted Price(INR)/Quintal": 6427,
    "Actual Price(INR)/Quintal": 6219,
    "Percentage Difference": "-3.35%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Aug Wk4",
    "Predicted Price(INR)/Quintal": 6416,
    "Actual Price(INR)/Quintal": 6118,
    "Percentage Difference": "-4.87%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Sep Wk1",
    "Predicted Price(INR)/Quintal": 6108,
    "Actual Price(INR)/Quintal": 6435,
    "Percentage Difference": "5.08%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Sep Wk2",
    "Predicted Price(INR)/Quintal": 6648,
    "Actual Price(INR)/Quintal": 6519,
    "Percentage Difference": "-1.98%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Sep Wk3",
    "Predicted Price(INR)/Quintal": 6510,
    "Actual Price(INR)/Quintal": 6682,
    "Percentage Difference": "2.57%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Sep Wk4",
    "Predicted Price(INR)/Quintal": 6594,
    "Actual Price(INR)/Quintal": 6569,
    "Percentage Difference": "-0.38%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Oct Wk1",
    "Predicted Price(INR)/Quintal": 6527,
    "Actual Price(INR)/Quintal": 6809,
    "Percentage Difference": "4.14%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Oct Wk2",
    "Predicted Price(INR)/Quintal": 6725,
    "Actual Price(INR)/Quintal": 6236,
    "Percentage Difference": "-7.82%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Oct Wk3",
    "Predicted Price(INR)/Quintal": 6247,
    "Actual Price(INR)/Quintal": 6274,
    "Percentage Difference": "0.43%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Oct Wk4",
    "Predicted Price(INR)/Quintal": 6255,
    "Actual Price(INR)/Quintal": 5836,
    "Percentage Difference": "-7.18%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Now Wk1",
    "Predicted Price(INR)/Quintal": 5658,
    "Actual Price(INR)/Quintal": 5379,
    "Percentage Difference": "-5.17%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Now Wk2",
    "Predicted Price(INR)/Quintal": 5377,
    "Actual Price(INR)/Quintal": 5778,
    "Percentage Difference": "6.94%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Now Wk3",
    "Predicted Price(INR)/Quintal": 5757,
    "Actual Price(INR)/Quintal": 6009,
    "Percentage Difference": "4.19%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Now Wk4",
    "Predicted Price(INR)/Quintal": 5972,
    "Actual Price(INR)/Quintal": 6176,
    "Percentage Difference": "3.30%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Dec Wk1",
    "Predicted Price(INR)/Quintal": 6118,
    "Actual Price(INR)/Quintal": 5671,
    "Percentage Difference": "-7.88%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Dec Wk2",
    "Predicted Price(INR)/Quintal": 5611,
    "Actual Price(INR)/Quintal": 5681,
    "Percentage Difference": "1.23%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Dec Wk3",
    "Predicted Price(INR)/Quintal": 5622,
    "Actual Price(INR)/Quintal": 5342,
    "Percentage Difference": "-5.24%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Dec Wk4",
    "Predicted Price(INR)/Quintal": 5033,
    "Actual Price(INR)/Quintal": 5379,
    "Percentage Difference": "6.43%",
    "YEAR": 2016
  }
];
