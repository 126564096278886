export const Ragi = [
{ "Year : Month Week#":"Janwk1","Predicted Price(INR)/Quintal":3088,"Actual Price(INR)/Quintal":3200,"Percentage Difference":"3.50%","YEAR":2025},
{ "Year : Month Week#":"Janwk2","Predicted Price(INR)/Quintal":3188,"Actual Price(INR)/Quintal":3202,"Percentage Difference":"0.44%","YEAR":2025},
{ "Year : Month Week#":"Janwk3","Predicted Price(INR)/Quintal":3125,"Actual Price(INR)/Quintal":null,"Percentage Difference":null,"YEAR":2025},
{ "Year : Month Week#":"Janwk4","Predicted Price(INR)/Quintal":3155,"Actual Price(INR)/Quintal":null,"Percentage Difference":null,"YEAR":2025},
{ "Year : Month Week#":"Febwk1","Predicted Price(INR)/Quintal":3174,"Actual Price(INR)/Quintal":null,"Percentage Difference":null,"YEAR":2025},
{ "Year : Month Week#":"Febwk2","Predicted Price(INR)/Quintal":3121,"Actual Price(INR)/Quintal":null,"Percentage Difference":null,"YEAR":2025},
{ "Year : Month Week#":"Janwk1","Predicted Price(INR)/Quintal":3120,"Actual Price(INR)/Quintal":3129,"Percentage Difference":"0.29%","YEAR":2024},
{ "Year : Month Week#":"Janwk2","Predicted Price(INR)/Quintal":3156,"Actual Price(INR)/Quintal":3170,"Percentage Difference":"0.44%","YEAR":2024},
{ "Year : Month Week#":"Janwk3","Predicted Price(INR)/Quintal":3064,"Actual Price(INR)/Quintal":3100,"Percentage Difference":"1.16%","YEAR":2024},
{ "Year : Month Week#":"Janwk4","Predicted Price(INR)/Quintal":3122,"Actual Price(INR)/Quintal":3123,"Percentage Difference":"0.03%","YEAR":2024},
{ "Year : Month Week#":"Febwk1","Predicted Price(INR)/Quintal":3140,"Actual Price(INR)/Quintal":3127,"Percentage Difference":"-0.42%","YEAR":2024},
{ "Year : Month Week#":"Febwk2","Predicted Price(INR)/Quintal":3169,"Actual Price(INR)/Quintal":3060,"Percentage Difference":"-3.56%","YEAR":2024},
{ "Year : Month Week#":"Febwk3","Predicted Price(INR)/Quintal":3030,"Actual Price(INR)/Quintal":3033,"Percentage Difference":"0.10%","YEAR":2024},
{ "Year : Month Week#":"Febwk4","Predicted Price(INR)/Quintal":3032,"Actual Price(INR)/Quintal":3061,"Percentage Difference":"0.95%","YEAR":2024},
{ "Year : Month Week#":"Marwk1","Predicted Price(INR)/Quintal":3001,"Actual Price(INR)/Quintal":3072,"Percentage Difference":"2.31%","YEAR":2024},
{ "Year : Month Week#":"Marwk2","Predicted Price(INR)/Quintal":3037,"Actual Price(INR)/Quintal":2941,"Percentage Difference":"-3.26%","YEAR":2024},
{ "Year : Month Week#":"Marwk3","Predicted Price(INR)/Quintal":2970,"Actual Price(INR)/Quintal":3094,"Percentage Difference":"4.01%","YEAR":2024},
{ "Year : Month Week#":"Marwk4","Predicted Price(INR)/Quintal":2956,"Actual Price(INR)/Quintal":3009,"Percentage Difference":"1.76%","YEAR":2024},
{ "Year : Month Week#":"Aprwk1","Predicted Price(INR)/Quintal":2944,"Actual Price(INR)/Quintal":3016,"Percentage Difference":"2.39%","YEAR":2024},
{ "Year : Month Week#":"Aprwk2","Predicted Price(INR)/Quintal":2922,"Actual Price(INR)/Quintal":3037,"Percentage Difference":"3.79%","YEAR":2024},
{ "Year : Month Week#":"Aprwk3","Predicted Price(INR)/Quintal":3048,"Actual Price(INR)/Quintal":3050,"Percentage Difference":"0.07%","YEAR":2024},
{ "Year : Month Week#":"Aprwk4","Predicted Price(INR)/Quintal":3040,"Actual Price(INR)/Quintal":2986,"Percentage Difference":"-1.81%","YEAR":2024},
{ "Year : Month Week#":"Maywk1","Predicted Price(INR)/Quintal":3030,"Actual Price(INR)/Quintal":3042,"Percentage Difference":"0.39%","YEAR":2024},
{ "Year : Month Week#":"Maywk2","Predicted Price(INR)/Quintal":3049,"Actual Price(INR)/Quintal":3125,"Percentage Difference":"2.43%","YEAR":2024},
{ "Year : Month Week#":"Maywk3","Predicted Price(INR)/Quintal":3007,"Actual Price(INR)/Quintal":3033,"Percentage Difference":"0.86%","YEAR":2024},
{ "Year : Month Week#":"Maywk4","Predicted Price(INR)/Quintal":3015,"Actual Price(INR)/Quintal":3062,"Percentage Difference":"1.53%","YEAR":2024},
{ "Year : Month Week#":"Junwk1","Predicted Price(INR)/Quintal":3018,"Actual Price(INR)/Quintal":3060,"Percentage Difference":"1.37%","YEAR":2024},
{ "Year : Month Week#":"Junwk2","Predicted Price(INR)/Quintal":3099,"Actual Price(INR)/Quintal":3046,"Percentage Difference":"-1.74%","YEAR":2024},
{ "Year : Month Week#":"Junwk3","Predicted Price(INR)/Quintal":3032,"Actual Price(INR)/Quintal":3080,"Percentage Difference":"1.56%","YEAR":2024},
{ "Year : Month Week#":"Junwk4","Predicted Price(INR)/Quintal":3121,"Actual Price(INR)/Quintal":3180,"Percentage Difference":"1.86%","YEAR":2024},
{ "Year : Month Week#":"Julwk1","Predicted Price(INR)/Quintal":3133,"Actual Price(INR)/Quintal":3139,"Percentage Difference":"0.19%","YEAR":2024},
{ "Year : Month Week#":"Julwk2","Predicted Price(INR)/Quintal":3165,"Actual Price(INR)/Quintal":3112,"Percentage Difference":"-1.70%","YEAR":2024},
{ "Year : Month Week#":"Julwk3","Predicted Price(INR)/Quintal":3149,"Actual Price(INR)/Quintal":3175,"Percentage Difference":"0.82%","YEAR":2024},
{ "Year : Month Week#":"Julwk4","Predicted Price(INR)/Quintal":3123,"Actual Price(INR)/Quintal":3110,"Percentage Difference":"-0.42%","YEAR":2024},
{ "Year : Month Week#":"Augwk1","Predicted Price(INR)/Quintal":3096,"Actual Price(INR)/Quintal":3195,"Percentage Difference":"3.10%","YEAR":2024},
{ "Year : Month Week#":"Augwk2","Predicted Price(INR)/Quintal":3114,"Actual Price(INR)/Quintal":3190,"Percentage Difference":"2.38%","YEAR":2024},
{ "Year : Month Week#":"Augwk3","Predicted Price(INR)/Quintal":3194,"Actual Price(INR)/Quintal":3233,"Percentage Difference":"1.21%","YEAR":2024},
{ "Year : Month Week#":"Augwk4","Predicted Price(INR)/Quintal":3112,"Actual Price(INR)/Quintal":3170,"Percentage Difference":"1.83%","YEAR":2024},
{ "Year : Month Week#":"Sepwk1","Predicted Price(INR)/Quintal":3169,"Actual Price(INR)/Quintal":3090,"Percentage Difference":"-2.56%","YEAR":2024},
{ "Year : Month Week#":"Sepwk2","Predicted Price(INR)/Quintal":3104,"Actual Price(INR)/Quintal":3096,"Percentage Difference":"-0.26%","YEAR":2024},
{ "Year : Month Week#":"Sepwk3","Predicted Price(INR)/Quintal":3179,"Actual Price(INR)/Quintal":3189,"Percentage Difference":"0.31%","YEAR":2024},
{ "Year : Month Week#":"Sepwk4","Predicted Price(INR)/Quintal":3209,"Actual Price(INR)/Quintal":3218,"Percentage Difference":"0.28%","YEAR":2024},
{ "Year : Month Week#":"Octwk1","Predicted Price(INR)/Quintal":3138,"Actual Price(INR)/Quintal":3087,"Percentage Difference":"-1.65%","YEAR":2024},
{ "Year : Month Week#":"Octwk2","Predicted Price(INR)/Quintal":3192,"Actual Price(INR)/Quintal":3150,"Percentage Difference":"-1.33%","YEAR":2024},
{ "Year : Month Week#":"Octwk3","Predicted Price(INR)/Quintal":3180,"Actual Price(INR)/Quintal":3214,"Percentage Difference":"1.06%","YEAR":2024},
{ "Year : Month Week#":"Octwk4","Predicted Price(INR)/Quintal":3195,"Actual Price(INR)/Quintal":3157,"Percentage Difference":"-1.20%","YEAR":2024},
{ "Year : Month Week#":"Novwk1","Predicted Price(INR)/Quintal":3068,"Actual Price(INR)/Quintal":3120,"Percentage Difference":"1.67%","YEAR":2024},
{ "Year : Month Week#":"Novwk2","Predicted Price(INR)/Quintal":3074,"Actual Price(INR)/Quintal":3145,"Percentage Difference":"2.26%","YEAR":2024},
{ "Year : Month Week#":"Novwk3","Predicted Price(INR)/Quintal":3082,"Actual Price(INR)/Quintal":3117,"Percentage Difference":"1.12%","YEAR":2024},
{ "Year : Month Week#":"Novwk4","Predicted Price(INR)/Quintal":3099,"Actual Price(INR)/Quintal":3111,"Percentage Difference":"0.39%","YEAR":2024},
{ "Year : Month Week#":"Decwk1","Predicted Price(INR)/Quintal":3159,"Actual Price(INR)/Quintal":3153,"Percentage Difference":"-0.19%","YEAR":2024},
{ "Year : Month Week#":"Decwk2","Predicted Price(INR)/Quintal":3128,"Actual Price(INR)/Quintal":2997,"Percentage Difference":"-4.37%","YEAR":2024},
{ "Year : Month Week#":"Decwk3","Predicted Price(INR)/Quintal":3036,"Actual Price(INR)/Quintal":3206,"Percentage Difference":"5.30%","YEAR":2024},
{ "Year : Month Week#":"Decwk4","Predicted Price(INR)/Quintal":3044,"Actual Price(INR)/Quintal":3150,"Percentage Difference":"3.37%","YEAR":2024},
{ "Year : Month Week#":"Janwk1","Predicted Price(INR)/Quintal":2111,"Actual Price(INR)/Quintal":2133,"Percentage Difference":"1.03%","YEAR":2023},
{ "Year : Month Week#":"Janwk2","Predicted Price(INR)/Quintal":2148,"Actual Price(INR)/Quintal":2200,"Percentage Difference":"2.36%","YEAR":2023},
{ "Year : Month Week#":"Janwk3","Predicted Price(INR)/Quintal":2166,"Actual Price(INR)/Quintal":2231,"Percentage Difference":"2.91%","YEAR":2023},
{ "Year : Month Week#":"Janwk4","Predicted Price(INR)/Quintal":2211,"Actual Price(INR)/Quintal":2225,"Percentage Difference":"0.63%","YEAR":2023},
{ "Year : Month Week#":"Febwk1","Predicted Price(INR)/Quintal":2219,"Actual Price(INR)/Quintal":2250,"Percentage Difference":"1.38%","YEAR":2023},
{ "Year : Month Week#":"Febwk2","Predicted Price(INR)/Quintal":2195,"Actual Price(INR)/Quintal":2200,"Percentage Difference":"0.23%","YEAR":2023},
{ "Year : Month Week#":"Febwk3","Predicted Price(INR)/Quintal":2201,"Actual Price(INR)/Quintal":2283,"Percentage Difference":"3.59%","YEAR":2023},
{ "Year : Month Week#":"Febwk4","Predicted Price(INR)/Quintal":2288,"Actual Price(INR)/Quintal":2315,"Percentage Difference":"1.17%","YEAR":2023},
{ "Year : Month Week#":"Marwk1","Predicted Price(INR)/Quintal":2285,"Actual Price(INR)/Quintal":2343,"Percentage Difference":"2.48%","YEAR":2023},
{ "Year : Month Week#":"Marwk2","Predicted Price(INR)/Quintal":2252,"Actual Price(INR)/Quintal":2363,"Percentage Difference":"4.70%","YEAR":2023},
{ "Year : Month Week#":"Marwk3","Predicted Price(INR)/Quintal":2384,"Actual Price(INR)/Quintal":2452,"Percentage Difference":"2.77%","YEAR":2023},
{ "Year : Month Week#":"Marwk4","Predicted Price(INR)/Quintal":2404,"Actual Price(INR)/Quintal":2429,"Percentage Difference":"1.03%","YEAR":2023},
{ "Year : Month Week#":"Aprwk1","Predicted Price(INR)/Quintal":2465,"Actual Price(INR)/Quintal":2402,"Percentage Difference":"-2.62%","YEAR":2023},
{ "Year : Month Week#":"Aprwk2","Predicted Price(INR)/Quintal":2443,"Actual Price(INR)/Quintal":2453,"Percentage Difference":"0.41%","YEAR":2023},
{ "Year : Month Week#":"Aprwk3","Predicted Price(INR)/Quintal":2413,"Actual Price(INR)/Quintal":2384,"Percentage Difference":"-1.22%","YEAR":2023},
{ "Year : Month Week#":"Aprwk4","Predicted Price(INR)/Quintal":2450,"Actual Price(INR)/Quintal":2491,"Percentage Difference":"1.65%","YEAR":2023},
{ "Year : Month Week#":"Maywk1","Predicted Price(INR)/Quintal":2434,"Actual Price(INR)/Quintal":2454,"Percentage Difference":"0.81%","YEAR":2023},
{ "Year : Month Week#":"Maywk2","Predicted Price(INR)/Quintal":2418,"Actual Price(INR)/Quintal":2365,"Percentage Difference":"-2.24%","YEAR":2023},
{ "Year : Month Week#":"Maywk3","Predicted Price(INR)/Quintal":2446,"Actual Price(INR)/Quintal":2416,"Percentage Difference":"-1.24%","YEAR":2023},
{ "Year : Month Week#":"Maywk4","Predicted Price(INR)/Quintal":2444,"Actual Price(INR)/Quintal":2449,"Percentage Difference":"0.20%","YEAR":2023},
{ "Year : Month Week#":"Junwk1","Predicted Price(INR)/Quintal":2400,"Actual Price(INR)/Quintal":2372,"Percentage Difference":"-1.18%","YEAR":2023},
{ "Year : Month Week#":"Junwk2","Predicted Price(INR)/Quintal":2324,"Actual Price(INR)/Quintal":2343,"Percentage Difference":"0.81%","YEAR":2023},
{ "Year : Month Week#":"Junwk3","Predicted Price(INR)/Quintal":2332,"Actual Price(INR)/Quintal":2366,"Percentage Difference":"1.44%","YEAR":2023},
{ "Year : Month Week#":"Junwk4","Predicted Price(INR)/Quintal":2336,"Actual Price(INR)/Quintal":2339,"Percentage Difference":"0.13%","YEAR":2023},
{ "Year : Month Week#":"Julwk1","Predicted Price(INR)/Quintal":2432,"Actual Price(INR)/Quintal":2460,"Percentage Difference":"1.14%","YEAR":2023},
{ "Year : Month Week#":"Julwk2","Predicted Price(INR)/Quintal":2410,"Actual Price(INR)/Quintal":2465,"Percentage Difference":"2.23%","YEAR":2023},
{ "Year : Month Week#":"Julwk3","Predicted Price(INR)/Quintal":2428,"Actual Price(INR)/Quintal":2500,"Percentage Difference":"2.88%","YEAR":2023},
{ "Year : Month Week#":"Julwk4","Predicted Price(INR)/Quintal":2531,"Actual Price(INR)/Quintal":2638,"Percentage Difference":"4.06%","YEAR":2023},
{ "Year : Month Week#":"Augwk1","Predicted Price(INR)/Quintal":2572,"Actual Price(INR)/Quintal":2653,"Percentage Difference":"3.05%","YEAR":2023},
{ "Year : Month Week#":"Augwk2","Predicted Price(INR)/Quintal":2646,"Actual Price(INR)/Quintal":2640,"Percentage Difference":"-0.23%","YEAR":2023},
{ "Year : Month Week#":"Augwk3","Predicted Price(INR)/Quintal":2602,"Actual Price(INR)/Quintal":2650,"Percentage Difference":"1.81%","YEAR":2023},
{ "Year : Month Week#":"Augwk4","Predicted Price(INR)/Quintal":2594,"Actual Price(INR)/Quintal":2600,"Percentage Difference":"0.23%","YEAR":2023},
{ "Year : Month Week#":"Sepwk1","Predicted Price(INR)/Quintal":2591,"Actual Price(INR)/Quintal":2620,"Percentage Difference":"1.11%","YEAR":2023},
{ "Year : Month Week#":"Sepwk2","Predicted Price(INR)/Quintal":2610,"Actual Price(INR)/Quintal":2650,"Percentage Difference":"1.51%","YEAR":2023},
{ "Year : Month Week#":"Sepwk3","Predicted Price(INR)/Quintal":2618,"Actual Price(INR)/Quintal":2600,"Percentage Difference":"-0.69%","YEAR":2023},
{ "Year : Month Week#":"Sepwk4","Predicted Price(INR)/Quintal":2621,"Actual Price(INR)/Quintal":2611,"Percentage Difference":"-0.38%","YEAR":2023},
{ "Year : Month Week#":"Octwk1","Predicted Price(INR)/Quintal":2643,"Actual Price(INR)/Quintal":2655,"Percentage Difference":"0.45%","YEAR":2023},
{ "Year : Month Week#":"Octwk2","Predicted Price(INR)/Quintal":2658,"Actual Price(INR)/Quintal":2657,"Percentage Difference":"-0.04%","YEAR":2023},
{ "Year : Month Week#":"Octwk3","Predicted Price(INR)/Quintal":2594,"Actual Price(INR)/Quintal":2700,"Percentage Difference":"3.93%","YEAR":2023},
{ "Year : Month Week#":"Octwk4","Predicted Price(INR)/Quintal":2575,"Actual Price(INR)/Quintal":2615,"Percentage Difference":"1.53%","YEAR":2023},
{ "Year : Month Week#":"Novwk1","Predicted Price(INR)/Quintal":2673,"Actual Price(INR)/Quintal":2705,"Percentage Difference":"1.18%","YEAR":2023},
{ "Year : Month Week#":"Novwk2","Predicted Price(INR)/Quintal":2659,"Actual Price(INR)/Quintal":2761,"Percentage Difference":"3.69%","YEAR":2023},
{ "Year : Month Week#":"Novwk3","Predicted Price(INR)/Quintal":2768,"Actual Price(INR)/Quintal":2817,"Percentage Difference":"1.74%","YEAR":2023},
{ "Year : Month Week#":"Novwk4","Predicted Price(INR)/Quintal":2749,"Actual Price(INR)/Quintal":2850,"Percentage Difference":"3.54%","YEAR":2023},
{ "Year : Month Week#":"Decwk1","Predicted Price(INR)/Quintal":2940,"Actual Price(INR)/Quintal":2999,"Percentage Difference":"1.97%","YEAR":2023},
{ "Year : Month Week#":"Decwk2","Predicted Price(INR)/Quintal":3268,"Actual Price(INR)/Quintal":3318,"Percentage Difference":"1.51%","YEAR":2023},
{ "Year : Month Week#":"Decwk3","Predicted Price(INR)/Quintal":3221,"Actual Price(INR)/Quintal":3225,"Percentage Difference":"0.12%","YEAR":2023},
{ "Year : Month Week#":"Decwk4","Predicted Price(INR)/Quintal":3184,"Actual Price(INR)/Quintal":3112,"Percentage Difference":"-2.31%","YEAR":2023},
{ "Year : Month Week#":"Janwk1","Predicted Price(INR)/Quintal":1999,"Actual Price(INR)/Quintal":2056,"Percentage Difference":"2.77%","YEAR":2022},
{ "Year : Month Week#":"Janwk2","Predicted Price(INR)/Quintal":2078,"Actual Price(INR)/Quintal":2083,"Percentage Difference":"0.24%","YEAR":2022},
{ "Year : Month Week#":"Janwk3","Predicted Price(INR)/Quintal":2021,"Actual Price(INR)/Quintal":2084,"Percentage Difference":"3.02%","YEAR":2022},
{ "Year : Month Week#":"Janwk4","Predicted Price(INR)/Quintal":2023,"Actual Price(INR)/Quintal":2002,"Percentage Difference":"-1.05%","YEAR":2022},
{ "Year : Month Week#":"Febwk1","Predicted Price(INR)/Quintal":1908,"Actual Price(INR)/Quintal":1966,"Percentage Difference":"2.95%","YEAR":2022},
{ "Year : Month Week#":"Febwk2","Predicted Price(INR)/Quintal":2001,"Actual Price(INR)/Quintal":2050,"Percentage Difference":"2.39%","YEAR":2022},
{ "Year : Month Week#":"Febwk3","Predicted Price(INR)/Quintal":1992,"Actual Price(INR)/Quintal":1993,"Percentage Difference":"0.05%","YEAR":2022},
{ "Year : Month Week#":"Febwk4","Predicted Price(INR)/Quintal":1998,"Actual Price(INR)/Quintal":1950,"Percentage Difference":"-2.46%","YEAR":2022},
{ "Year : Month Week#":"Marwk1","Predicted Price(INR)/Quintal":1951,"Actual Price(INR)/Quintal":1975,"Percentage Difference":"1.22%","YEAR":2022},
{ "Year : Month Week#":"Marwk2","Predicted Price(INR)/Quintal":1979,"Actual Price(INR)/Quintal":1918,"Percentage Difference":"-3.18%","YEAR":2022},
{ "Year : Month Week#":"Marwk3","Predicted Price(INR)/Quintal":1905,"Actual Price(INR)/Quintal":1863,"Percentage Difference":"-2.25%","YEAR":2022},
{ "Year : Month Week#":"Marwk4","Predicted Price(INR)/Quintal":1806,"Actual Price(INR)/Quintal":1823,"Percentage Difference":"0.93%","YEAR":2022},
{ "Year : Month Week#":"Aprwk1","Predicted Price(INR)/Quintal":1804,"Actual Price(INR)/Quintal":1834,"Percentage Difference":"1.64%","YEAR":2022},
{ "Year : Month Week#":"Aprwk2","Predicted Price(INR)/Quintal":1811,"Actual Price(INR)/Quintal":1833,"Percentage Difference":"1.20%","YEAR":2022},
{ "Year : Month Week#":"Aprwk3","Predicted Price(INR)/Quintal":1803,"Actual Price(INR)/Quintal":1831,"Percentage Difference":"1.53%","YEAR":2022},
{ "Year : Month Week#":"Aprwk4","Predicted Price(INR)/Quintal":1876,"Actual Price(INR)/Quintal":1857,"Percentage Difference":"-1.02%","YEAR":2022},
{ "Year : Month Week#":"Maywk1","Predicted Price(INR)/Quintal":1789,"Actual Price(INR)/Quintal":1754,"Percentage Difference":"-2.00%","YEAR":2022},
{ "Year : Month Week#":"Maywk2","Predicted Price(INR)/Quintal":1756,"Actual Price(INR)/Quintal":1806,"Percentage Difference":"2.77%","YEAR":2022},
{ "Year : Month Week#":"Maywk3","Predicted Price(INR)/Quintal":1796,"Actual Price(INR)/Quintal":1807,"Percentage Difference":"0.61%","YEAR":2022},
{ "Year : Month Week#":"Maywk4","Predicted Price(INR)/Quintal":1769,"Actual Price(INR)/Quintal":1796,"Percentage Difference":"1.50%","YEAR":2022},
{ "Year : Month Week#":"Junwk1","Predicted Price(INR)/Quintal":1788,"Actual Price(INR)/Quintal":1806,"Percentage Difference":"1.00%","YEAR":2022},
{ "Year : Month Week#":"Junwk2","Predicted Price(INR)/Quintal":1724,"Actual Price(INR)/Quintal":1748,"Percentage Difference":"1.37%","YEAR":2022},
{ "Year : Month Week#":"Junwk3","Predicted Price(INR)/Quintal":1791,"Actual Price(INR)/Quintal":1758,"Percentage Difference":"-1.88%","YEAR":2022},
{ "Year : Month Week#":"Junwk4","Predicted Price(INR)/Quintal":1808,"Actual Price(INR)/Quintal":1835,"Percentage Difference":"1.47%","YEAR":2022},
{ "Year : Month Week#":"Julwk1","Predicted Price(INR)/Quintal":1790,"Actual Price(INR)/Quintal":1805,"Percentage Difference":"0.83%","YEAR":2022},
{ "Year : Month Week#":"Julwk22","Predicted Price(INR)/Quintal":1783,"Actual Price(INR)/Quintal":1845,"Percentage Difference":"3.36%","YEAR":2022},
{ "Year : Month Week#":"Julwk3","Predicted Price(INR)/Quintal":1885,"Actual Price(INR)/Quintal":1835,"Percentage Difference":"-2.72%","YEAR":2022},
{ "Year : Month Week#":"Julwk4","Predicted Price(INR)/Quintal":1877,"Actual Price(INR)/Quintal":1855,"Percentage Difference":"-1.19%","YEAR":2022},
{ "Year : Month Week#":"Augwk1","Predicted Price(INR)/Quintal":1867,"Actual Price(INR)/Quintal":1877,"Percentage Difference":"0.53%","YEAR":2022},
{ "Year : Month Week#":"Augwk2","Predicted Price(INR)/Quintal":1809,"Actual Price(INR)/Quintal":1853,"Percentage Difference":"2.37%","YEAR":2022},
{ "Year : Month Week#":"Augwk3","Predicted Price(INR)/Quintal":1806,"Actual Price(INR)/Quintal":1808,"Percentage Difference":"0.11%","YEAR":2022},
{ "Year : Month Week#":"Augwk4","Predicted Price(INR)/Quintal":1808,"Actual Price(INR)/Quintal":1771,"Percentage Difference":"-2.09%","YEAR":2022},
{ "Year : Month Week#":"Sepwk1","Predicted Price(INR)/Quintal":1758,"Actual Price(INR)/Quintal":1781,"Percentage Difference":"1.29%","YEAR":2022},
{ "Year : Month Week#":"Sepwk2","Predicted Price(INR)/Quintal":1883,"Actual Price(INR)/Quintal":1901,"Percentage Difference":"0.95%","YEAR":2022},
{ "Year : Month Week#":"Sepwk3","Predicted Price(INR)/Quintal":1931,"Actual Price(INR)/Quintal":1971,"Percentage Difference":"2.03%","YEAR":2022},
{ "Year : Month Week#":"Sepwk4","Predicted Price(INR)/Quintal":1849,"Actual Price(INR)/Quintal":1809,"Percentage Difference":"-2.21%","YEAR":2022},
{ "Year : Month Week#":"Octwk1","Predicted Price(INR)/Quintal":1842,"Actual Price(INR)/Quintal":1850,"Percentage Difference":"0.43%","YEAR":2022},
{ "Year : Month Week#":"Octwk2","Predicted Price(INR)/Quintal":1853,"Actual Price(INR)/Quintal":1933,"Percentage Difference":"4.14%","YEAR":2022},
{ "Year : Month Week#":"Octwk3","Predicted Price(INR)/Quintal":1909,"Actual Price(INR)/Quintal":1911,"Percentage Difference":"0.10%","YEAR":2022},
{ "Year : Month Week#":"Octwk4","Predicted Price(INR)/Quintal":1899,"Actual Price(INR)/Quintal":1900,"Percentage Difference":"0.05%","YEAR":2022},
{ "Year : Month Week#":"Novwk1","Predicted Price(INR)/Quintal":1895,"Actual Price(INR)/Quintal":1851,"Percentage Difference":"-2.38%","YEAR":2022},
{ "Year : Month Week#":"Novwk2","Predicted Price(INR)/Quintal":1898,"Actual Price(INR)/Quintal":1929,"Percentage Difference":"1.61%","YEAR":2022},
{ "Year : Month Week#":"Novwk3","Predicted Price(INR)/Quintal":1892,"Actual Price(INR)/Quintal":1935,"Percentage Difference":"2.22%","YEAR":2022},
{ "Year : Month Week#":"Novwk4","Predicted Price(INR)/Quintal":1986,"Actual Price(INR)/Quintal":2069,"Percentage Difference":"4.01%","YEAR":2022},
{ "Year : Month Week#":"Decwk1","Predicted Price(INR)/Quintal":2045,"Actual Price(INR)/Quintal":2145,"Percentage Difference":"4.66%","YEAR":2022},
{ "Year : Month Week#":"Decwk2","Predicted Price(INR)/Quintal":2096,"Actual Price(INR)/Quintal":2140,"Percentage Difference":"2.06%","YEAR":2022},
{ "Year : Month Week#":"Decwk3","Predicted Price(INR)/Quintal":2087,"Actual Price(INR)/Quintal":2108,"Percentage Difference":"1.00%","YEAR":2022},
{ "Year : Month Week#":"Decwk4","Predicted Price(INR)/Quintal":2076,"Actual Price(INR)/Quintal":2180,"Percentage Difference":"4.77%","YEAR":2022},
{ "Year : Month Week#":"JanWk1","Predicted Price(INR)/Quintal":2125,"Actual Price(INR)/Quintal":2180,"Percentage Difference":"2.52%","YEAR":2021},
{ "Year : Month Week#":"JanWk2","Predicted Price(INR)/Quintal":2108,"Actual Price(INR)/Quintal":2178,"Percentage Difference":"3.21%","YEAR":2021},
{ "Year : Month Week#":"JanWk3","Predicted Price(INR)/Quintal":2105,"Actual Price(INR)/Quintal":2154,"Percentage Difference":"2.27%","YEAR":2021},
{ "Year : Month Week#":"JanWk4","Predicted Price(INR)/Quintal":2170,"Actual Price(INR)/Quintal":2175,"Percentage Difference":"0.23%","YEAR":2021},
{ "Year : Month Week#":"FebWk1","Predicted Price(INR)/Quintal":2164,"Actual Price(INR)/Quintal":2200,"Percentage Difference":"1.64%","YEAR":2021},
{ "Year : Month Week#":"FebWk2","Predicted Price(INR)/Quintal":2153,"Actual Price(INR)/Quintal":2190,"Percentage Difference":"1.69%","YEAR":2021},
{ "Year : Month Week#":"FebWk3","Predicted Price(INR)/Quintal":2278,"Actual Price(INR)/Quintal":2302,"Percentage Difference":"1.04%","YEAR":2021},
{ "Year : Month Week#":"FebWk4","Predicted Price(INR)/Quintal":2283,"Actual Price(INR)/Quintal":2330,"Percentage Difference":"2.02%","YEAR":2021},
{ "Year : Month Week#":"MarWk1","Predicted Price(INR)/Quintal":2278,"Actual Price(INR)/Quintal":2280,"Percentage Difference":"0.09%","YEAR":2021},
{ "Year : Month Week#":"MarWk2","Predicted Price(INR)/Quintal":2292,"Actual Price(INR)/Quintal":2356,"Percentage Difference":"2.72%","YEAR":2021},
{ "Year : Month Week#":"MarWk3","Predicted Price(INR)/Quintal":2388,"Actual Price(INR)/Quintal":2501,"Percentage Difference":"4.52%","YEAR":2021},
{ "Year : Month Week#":"MarWk4","Predicted Price(INR)/Quintal":2366,"Actual Price(INR)/Quintal":2398,"Percentage Difference":"1.33%","YEAR":2021},
{ "Year : Month Week#":"AprWk1","Predicted Price(INR)/Quintal":2371,"Actual Price(INR)/Quintal":2481,"Percentage Difference":"4.43%","YEAR":2021},
{ "Year : Month Week#":"AprWk2","Predicted Price(INR)/Quintal":2477,"Actual Price(INR)/Quintal":2505,"Percentage Difference":"1.12%","YEAR":2021},
{ "Year : Month Week#":"AprWk3","Predicted Price(INR)/Quintal":2466,"Actual Price(INR)/Quintal":2426,"Percentage Difference":"-1.65%","YEAR":2021},
{ "Year : Month Week#":"AprWk4","Predicted Price(INR)/Quintal":2498,"Actual Price(INR)/Quintal":2493,"Percentage Difference":"-0.20%","YEAR":2021},
{ "Year : Month Week#":"Maywk1","Predicted Price(INR)/Quintal":2449,"Actual Price(INR)/Quintal":2377,"Percentage Difference":"-3.03%","YEAR":2021},
{ "Year : Month Week#":"Maywk2","Predicted Price(INR)/Quintal":2292,"Actual Price(INR)/Quintal":2250,"Percentage Difference":"-1.87%","YEAR":2021},
{ "Year : Month Week#":"Maywk3","Predicted Price(INR)/Quintal":2298,"Actual Price(INR)/Quintal":2330,"Percentage Difference":"1.37%","YEAR":2021},
{ "Year : Month Week#":"Maywk4","Predicted Price(INR)/Quintal":2286,"Actual Price(INR)/Quintal":2372,"Percentage Difference":"3.63%","YEAR":2021},
{ "Year : Month Week#":"Junwk1","Predicted Price(INR)/Quintal":2291,"Actual Price(INR)/Quintal":2325,"Percentage Difference":"1.46%","YEAR":2021},
{ "Year : Month Week#":"Junwk2","Predicted Price(INR)/Quintal":2094,"Actual Price(INR)/Quintal":2110,"Percentage Difference":"0.76%","YEAR":2021},
{ "Year : Month Week#":"Junwk3","Predicted Price(INR)/Quintal":2080,"Actual Price(INR)/Quintal":2141,"Percentage Difference":"2.85%","YEAR":2021},
{ "Year : Month Week#":"Junwk4","Predicted Price(INR)/Quintal":2078,"Actual Price(INR)/Quintal":2162,"Percentage Difference":"3.89%","YEAR":2021},
{ "Year : Month Week#":"Julwk1","Predicted Price(INR)/Quintal":2071,"Actual Price(INR)/Quintal":2150,"Percentage Difference":"3.67%","YEAR":2021},
{ "Year : Month Week#":"Julwk2","Predicted Price(INR)/Quintal":2090,"Actual Price(INR)/Quintal":2003,"Percentage Difference":"2.52%","YEAR":2021},
{ "Year : Month Week#":"Julwk3","Predicted Price(INR)/Quintal":2088,"Actual Price(INR)/Quintal":2060,"Percentage Difference":"-1.36%","YEAR":2021},
{ "Year : Month Week#":"Julwk4","Predicted Price(INR)/Quintal":2076,"Actual Price(INR)/Quintal":2100,"Percentage Difference":"1.14%","YEAR":2021},
{ "Year : Month Week#":"Augwk1","Predicted Price(INR)/Quintal":2131,"Actual Price(INR)/Quintal":2091,"Percentage Difference":"-1.91%","YEAR":2021},
{ "Year : Month Week#":"Augwk2","Predicted Price(INR)/Quintal":1969,"Actual Price(INR)/Quintal":2009,"Percentage Difference":"1.99%","YEAR":2021},
{ "Year : Month Week#":"Augwk3","Predicted Price(INR)/Quintal":2045,"Actual Price(INR)/Quintal":2077,"Percentage Difference":"1.54%","YEAR":2021},
{ "Year : Month Week#":"Augwk4","Predicted Price(INR)/Quintal":2072,"Actual Price(INR)/Quintal":2035,"Percentage Difference":"-1.82%","YEAR":2021},
{ "Year : Month Week#":"Sepwk1","Predicted Price(INR)/Quintal":2067,"Actual Price(INR)/Quintal":2086,"Percentage Difference":"0.91%","YEAR":2021},
{ "Year : Month Week#":"Sepwk2","Predicted Price(INR)/Quintal":2082,"Actual Price(INR)/Quintal":2028,"Percentage Difference":"-2.66%","YEAR":2021},
{ "Year : Month Week#":"Sepwk3","Predicted Price(INR)/Quintal":2090,"Actual Price(INR)/Quintal":2066,"Percentage Difference":"-1.16%","YEAR":2021},
{ "Year : Month Week#":"Sepwk4","Predicted Price(INR)/Quintal":2005,"Actual Price(INR)/Quintal":2045,"Percentage Difference":"1.96%","YEAR":2021},
{ "Year : Month Week#":"Octwk1","Predicted Price(INR)/Quintal":1996,"Actual Price(INR)/Quintal":2002,"Percentage Difference":"0.30%","YEAR":2021},
{ "Year : Month Week#":"Octwk2","Predicted Price(INR)/Quintal":2006,"Actual Price(INR)/Quintal":1998,"Percentage Difference":"-0.40%","YEAR":2021},
{ "Year : Month Week#":"Octwk3","Predicted Price(INR)/Quintal":2008,"Actual Price(INR)/Quintal":1993,"Percentage Difference":"-0.75%","YEAR":2021},
{ "Year : Month Week#":"Octwk4","Predicted Price(INR)/Quintal":2011,"Actual Price(INR)/Quintal":2012,"Percentage Difference":"0.05%","YEAR":2021},
{ "Year : Month Week#":"Novwk1","Predicted Price(INR)/Quintal":2012,"Actual Price(INR)/Quintal":1975,"Percentage Difference":"-1.87%","YEAR":2021},
{ "Year : Month Week#":"Novwk2","Predicted Price(INR)/Quintal":2019,"Actual Price(INR)/Quintal":1962,"Percentage Difference":"-2.91%","YEAR":2021},
{ "Year : Month Week#":"Novwk3","Predicted Price(INR)/Quintal":1978,"Actual Price(INR)/Quintal":1968,"Percentage Difference":"-0.51%","YEAR":2021},
{ "Year : Month Week#":"Novwk4","Predicted Price(INR)/Quintal":1987,"Actual Price(INR)/Quintal":1985,"Percentage Difference":"-0.10%","YEAR":2021},
{ "Year : Month Week#":"Decwk1","Predicted Price(INR)/Quintal":1995,"Actual Price(INR)/Quintal":1962,"Percentage Difference":"-1.68%","YEAR":2021},
{ "Year : Month Week#":"Decwk2","Predicted Price(INR)/Quintal":1943,"Actual Price(INR)/Quintal":2012,"Percentage Difference":"3.43%","YEAR":2021},
{ "Year : Month Week#":"Decwk3","Predicted Price(INR)/Quintal":1932,"Actual Price(INR)/Quintal":2009,"Percentage Difference":"3.83%","YEAR":2021},
{ "Year : Month Week#":"Decwk4","Predicted Price(INR)/Quintal":1981,"Actual Price(INR)/Quintal":1990,"Percentage Difference":"0.45%","YEAR":2021},
{ "Year : Month Week#":"JanWk1","Predicted Price(INR)/Quintal":2252,"Actual Price(INR)/Quintal":2220,"Percentage Difference":"-1.44%","YEAR":2020},
{ "Year : Month Week#":"JanWk2","Predicted Price(INR)/Quintal":2249,"Actual Price(INR)/Quintal":2321,"Percentage Difference":"3.10%","YEAR":2020},
{ "Year : Month Week#":"JanWk3","Predicted Price(INR)/Quintal":2185,"Actual Price(INR)/Quintal":2242,"Percentage Difference":"2.54%","YEAR":2020},
{ "Year : Month Week#":"JanWk4","Predicted Price(INR)/Quintal":2234,"Actual Price(INR)/Quintal":2180,"Percentage Difference":"-2.48%","YEAR":2020},
{ "Year : Month Week#":"FebWk1","Predicted Price(INR)/Quintal":2212,"Actual Price(INR)/Quintal":2190,"Percentage Difference":"-1.00%","YEAR":2020},
{ "Year : Month Week#":"FebWk2","Predicted Price(INR)/Quintal":2206,"Actual Price(INR)/Quintal":2175,"Percentage Difference":"-1.43%","YEAR":2020},
{ "Year : Month Week#":"FebWk3","Predicted Price(INR)/Quintal":2218,"Actual Price(INR)/Quintal":2310,"Percentage Difference":"3.98%","YEAR":2020},
{ "Year : Month Week#":"FebWk4","Predicted Price(INR)/Quintal":2299,"Actual Price(INR)/Quintal":2220,"Percentage Difference":"-3.56%","YEAR":2020},
{ "Year : Month Week#":"MarWk1","Predicted Price(INR)/Quintal":2238,"Actual Price(INR)/Quintal":2200,"Percentage Difference":"-1.73%","YEAR":2020},
{ "Year : Month Week#":"MarWk2","Predicted Price(INR)/Quintal":2212,"Actual Price(INR)/Quintal":2108,"Percentage Difference":"-4.93%","YEAR":2020},
{ "Year : Month Week#":"MarWk3","Predicted Price(INR)/Quintal":2076,"Actual Price(INR)/Quintal":2116,"Percentage Difference":"1.89%","YEAR":2020},
{ "Year : Month Week#":"MarWk4","Predicted Price(INR)/Quintal":2120,"Actual Price(INR)/Quintal":2215,"Percentage Difference":"4.29%","YEAR":2020},
{ "Year : Month Week#":"AprWk1","Predicted Price(INR)/Quintal":2245,"Actual Price(INR)/Quintal":2200,"Percentage Difference":"-2.05%","YEAR":2020},
{ "Year : Month Week#":"AprWk2","Predicted Price(INR)/Quintal":2246,"Actual Price(INR)/Quintal":2221,"Percentage Difference":"-1.13%","YEAR":2020},
{ "Year : Month Week#":"AprWk3","Predicted Price(INR)/Quintal":2241,"Actual Price(INR)/Quintal":2306,"Percentage Difference":"2.82%","YEAR":2020},
{ "Year : Month Week#":"AprWk4","Predicted Price(INR)/Quintal":2242,"Actual Price(INR)/Quintal":2318,"Percentage Difference":"3.28%","YEAR":2020},
{ "Year : Month Week#":"MayWk1","Predicted Price(INR)/Quintal":2296,"Actual Price(INR)/Quintal":2299,"Percentage Difference":"0.13%","YEAR":2020},
{ "Year : Month Week#":"MayWk2","Predicted Price(INR)/Quintal":2295,"Actual Price(INR)/Quintal":2296,"Percentage Difference":"0.04%","YEAR":2020},
{ "Year : Month Week#":"MayWk3","Predicted Price(INR)/Quintal":2256,"Actual Price(INR)/Quintal":2322,"Percentage Difference":"2.84%","YEAR":2020},
{ "Year : Month Week#":"MayWk4","Predicted Price(INR)/Quintal":2263,"Actual Price(INR)/Quintal":2307,"Percentage Difference":"1.91%","YEAR":2020},
{ "Year : Month Week#":"JunWk1","Predicted Price(INR)/Quintal":2361,"Actual Price(INR)/Quintal":2404,"Percentage Difference":"1.79%","YEAR":2020},
{ "Year : Month Week#":"JunWk2","Predicted Price(INR)/Quintal":2379,"Actual Price(INR)/Quintal":2450,"Percentage Difference":"2.90%","YEAR":2020},
{ "Year : Month Week#":"JunWk3","Predicted Price(INR)/Quintal":2428,"Actual Price(INR)/Quintal":2395,"Percentage Difference":"-1.38%","YEAR":2020},
{ "Year : Month Week#":"JunWk4","Predicted Price(INR)/Quintal":2338,"Actual Price(INR)/Quintal":2441,"Percentage Difference":"4.22%","YEAR":2020},
{ "Year : Month Week#":"JulWk1","Predicted Price(INR)/Quintal":2460,"Actual Price(INR)/Quintal":2425,"Percentage Difference":"-1.44%","YEAR":2020},
{ "Year : Month Week#":"JulWk2","Predicted Price(INR)/Quintal":2430,"Actual Price(INR)/Quintal":2339,"Percentage Difference":"-3.89%","YEAR":2020},
{ "Year : Month Week#":"JulWk3","Predicted Price(INR)/Quintal":2311,"Actual Price(INR)/Quintal":2208,"Percentage Difference":"-4.66%","YEAR":2020},
{ "Year : Month Week#":"JulWk4","Predicted Price(INR)/Quintal":2190,"Actual Price(INR)/Quintal":2310,"Percentage Difference":"5.19%","YEAR":2020},
{ "Year : Month Week#":"AugWk1","Predicted Price(INR)/Quintal":2255,"Actual Price(INR)/Quintal":2350,"Percentage Difference":"4.04%","YEAR":2020},
{ "Year : Month Week#":"AugWk2","Predicted Price(INR)/Quintal":2356,"Actual Price(INR)/Quintal":2300,"Percentage Difference":"-2.43%","YEAR":2020},
{ "Year : Month Week#":"AugWk3","Predicted Price(INR)/Quintal":2287,"Actual Price(INR)/Quintal":2320,"Percentage Difference":"1.42%","YEAR":2020},
{ "Year : Month Week#":"AugWk4","Predicted Price(INR)/Quintal":2119,"Actual Price(INR)/Quintal":2153,"Percentage Difference":"1.58%","YEAR":2020},
{ "Year : Month Week#":"SepWk1","Predicted Price(INR)/Quintal":2117,"Actual Price(INR)/Quintal":2088,"Percentage Difference":"-1.39%","YEAR":2020},
{ "Year : Month Week#":"SepWk2","Predicted Price(INR)/Quintal":1939,"Actual Price(INR)/Quintal":1990,"Percentage Difference":"2.56%","YEAR":2020},
{ "Year : Month Week#":"SepWk3","Predicted Price(INR)/Quintal":1962,"Actual Price(INR)/Quintal":1923,"Percentage Difference":"-2.03%","YEAR":2020},
{ "Year : Month Week#":"SepWk4","Predicted Price(INR)/Quintal":1955,"Actual Price(INR)/Quintal":1998,"Percentage Difference":"2.15%","YEAR":2020},
{ "Year : Month Week#":"OctWk1","Predicted Price(INR)/Quintal":1984,"Actual Price(INR)/Quintal":2039,"Percentage Difference":"2.70%","YEAR":2020},
{ "Year : Month Week#":"OctWk2","Predicted Price(INR)/Quintal":1983,"Actual Price(INR)/Quintal":2061,"Percentage Difference":"3.78%","YEAR":2020},
{ "Year : Month Week#":"OctWk3","Predicted Price(INR)/Quintal":2089,"Actual Price(INR)/Quintal":2050,"Percentage Difference":"-1.90%","YEAR":2020},
{ "Year : Month Week#":"OctWk4","Predicted Price(INR)/Quintal":2031,"Actual Price(INR)/Quintal":1998,"Percentage Difference":"-1.65%","YEAR":2020},
{ "Year : Month Week#":"NovWk1","Predicted Price(INR)/Quintal":1922,"Actual Price(INR)/Quintal":2002,"Percentage Difference":"4.00%","YEAR":2020},
{ "Year : Month Week#":"NovWk2","Predicted Price(INR)/Quintal":2014,"Actual Price(INR)/Quintal":1950,"Percentage Difference":"-3.28%","YEAR":2020},
{ "Year : Month Week#":"NovWk3","Predicted Price(INR)/Quintal":1930,"Actual Price(INR)/Quintal":2003,"Percentage Difference":"3.64%","YEAR":2020},
{ "Year : Month Week#":"NovWk4","Predicted Price(INR)/Quintal":1912,"Actual Price(INR)/Quintal":1955,"Percentage Difference":"2.20%","YEAR":2020},
{ "Year : Month Week#":"DecWk1","Predicted Price(INR)/Quintal":1923,"Actual Price(INR)/Quintal":1975,"Percentage Difference":"2.63%","YEAR":2020},
{ "Year : Month Week#":"DecWk2","Predicted Price(INR)/Quintal":1946,"Actual Price(INR)/Quintal":2005,"Percentage Difference":"2.94%","YEAR":2020},
{ "Year : Month Week#":"DecWk3","Predicted Price(INR)/Quintal":2014,"Actual Price(INR)/Quintal":2046,"Percentage Difference":"1.56%","YEAR":2020},
{ "Year : Month Week#":"DecWk4","Predicted Price(INR)/Quintal":2009,"Actual Price(INR)/Quintal":2010,"Percentage Difference":"0.05%","YEAR":2020},
  {
    "2019": "",
    "Year : Month Week#": "JanWk1",
    "Predicted Price(INR)/Quintal": 2352,
    "Actual Price(INR)/Quintal": 2323,
    "Percentage Difference": "-1.25%",
    "YEAR": 2019
  },
  {
    "2019": "",
    "Year : Month Week#": "JanWk2",
    "Predicted Price(INR)/Quintal": 2301,
    "Actual Price(INR)/Quintal": 2362,
    "Percentage Difference": "2.58%",
    "YEAR": 2019
  },
  {
    "2019": "",
    "Year : Month Week#": "JanWk3",
    "Predicted Price(INR)/Quintal": 2305,
    "Actual Price(INR)/Quintal": 2407,
    "Percentage Difference": "4.24%",
    "YEAR": 2019
  },
  {
    "2019": "",
    "Year : Month Week#": "JanWk4",
    "Predicted Price(INR)/Quintal": 2391,
    "Actual Price(INR)/Quintal": 2343,
    "Percentage Difference": "-2.05%",
    "YEAR": 2019
  },
  {
    "2019": "",
    "Year : Month Week#": "FebWk1",
    "Predicted Price(INR)/Quintal": 2385,
    "Actual Price(INR)/Quintal": 2407,
    "Percentage Difference": "0.91%",
    "YEAR": 2019
  },
  {
    "2019": "",
    "Year : Month Week#": "FebWk2",
    "Predicted Price(INR)/Quintal": 2361,
    "Actual Price(INR)/Quintal": 2294,
    "Percentage Difference": "-2.92%",
    "YEAR": 2019
  },
  {
    "2019": "",
    "Year : Month Week#": "FebWk3",
    "Predicted Price(INR)/Quintal": 2267,
    "Actual Price(INR)/Quintal": 2276,
    "Percentage Difference": "0.40%",
    "YEAR": 2019
  },
  {
    "2019": "",
    "Year : Month Week#": "FebWk4",
    "Predicted Price(INR)/Quintal": 2301,
    "Actual Price(INR)/Quintal": 2304,
    "Percentage Difference": "0.13%",
    "YEAR": 2019
  },
  {
    "2019": "",
    "Year : Month Week#": "MarWk1",
    "Predicted Price(INR)/Quintal": 2277,
    "Actual Price(INR)/Quintal": 2362,
    "Percentage Difference": "3.60%",
    "YEAR": 2019
  },
  {
    "2019": "",
    "Year : Month Week#": "MarWk2",
    "Predicted Price(INR)/Quintal": 2276,
    "Actual Price(INR)/Quintal": 2303,
    "Percentage Difference": "1.17%",
    "YEAR": 2019
  },
  {
    "2019": "",
    "Year : Month Week#": "MarWk3",
    "Predicted Price(INR)/Quintal": 2297,
    "Actual Price(INR)/Quintal": 2329,
    "Percentage Difference": "1.37%",
    "YEAR": 2019
  },
  {
    "2019": "",
    "Year : Month Week#": "MarWk4",
    "Predicted Price(INR)/Quintal": 2304,
    "Actual Price(INR)/Quintal": 2351,
    "Percentage Difference": "2.00%",
    "YEAR": 2019
  },
  {
    "2019": "",
    "Year : Month Week#": "AprWk1",
    "Predicted Price(INR)/Quintal": 2298,
    "Actual Price(INR)/Quintal": 2416,
    "Percentage Difference": "4.88%",
    "YEAR": 2019
  },
  {
    "2019": "",
    "Year : Month Week#": "AprWk2",
    "Predicted Price(INR)/Quintal": 2387,
    "Actual Price(INR)/Quintal": 2328,
    "Percentage Difference": "-2.53%",
    "YEAR": 2019
  },
  {
    "2019": "",
    "Year : Month Week#": "AprWk3",
    "Predicted Price(INR)/Quintal": 2318,
    "Actual Price(INR)/Quintal": 2320,
    "Percentage Difference": "0.09%",
    "YEAR": 2019
  },
  {
    "2019": "",
    "Year : Month Week#": "AprWk4",
    "Predicted Price(INR)/Quintal": 2311,
    "Actual Price(INR)/Quintal": 2324,
    "Percentage Difference": "0.56%",
    "YEAR": 2019
  },
  {
    "2019": "",
    "Year : Month Week#": "MayWk1",
    "Predicted Price(INR)/Quintal": 2346,
    "Actual Price(INR)/Quintal": 2386,
    "Percentage Difference": "1.68%",
    "YEAR": 2019
  },
  {
    "2019": "",
    "Year : Month Week#": "MayWk2",
    "Predicted Price(INR)/Quintal": 2362,
    "Actual Price(INR)/Quintal": 2351,
    "Percentage Difference": "-0.47%",
    "YEAR": 2019
  },
  {
    "2019": "",
    "Year : Month Week#": "MayWk3",
    "Predicted Price(INR)/Quintal": 2347,
    "Actual Price(INR)/Quintal": 2350,
    "Percentage Difference": "0.13%",
    "YEAR": 2019
  },
  {
    "2019": "",
    "Year : Month Week#": "MayWk4",
    "Predicted Price(INR)/Quintal": 2334,
    "Actual Price(INR)/Quintal": 2343,
    "Percentage Difference": "0.38%",
    "YEAR": 2019
  },
  {
    "2019": "",
    "Year : Month Week#": "JunWk1",
    "Predicted Price(INR)/Quintal": 2329,
    "Actual Price(INR)/Quintal": 2398,
    "Percentage Difference": "2.88%",
    "YEAR": 2019
  },
  {
    "2019": "",
    "Year : Month Week#": "JunWk2",
    "Predicted Price(INR)/Quintal": 2344,
    "Actual Price(INR)/Quintal": 2363,
    "Percentage Difference": "0.80%",
    "YEAR": 2019
  },
  {
    "2019": "",
    "Year : Month Week#": "JunWk3",
    "Predicted Price(INR)/Quintal": 2337,
    "Actual Price(INR)/Quintal": 2367,
    "Percentage Difference": "1.27%",
    "YEAR": 2019
  },
  {
    "2019": "",
    "Year : Month Week#": "JunWk4",
    "Predicted Price(INR)/Quintal": 2352,
    "Actual Price(INR)/Quintal": 2355,
    "Percentage Difference": "0.13%",
    "YEAR": 2019
  },
  {
    "2019": "",
    "Year : Month Week#": "JulWk1",
    "Predicted Price(INR)/Quintal": 2316,
    "Actual Price(INR)/Quintal": 2320,
    "Percentage Difference": "0.17%",
    "YEAR": 2019
  },
  {
    "2019": "",
    "Year : Month Week#": "JulWk2",
    "Predicted Price(INR)/Quintal": 2326,
    "Actual Price(INR)/Quintal": 2343,
    "Percentage Difference": "0.73%",
    "YEAR": 2019
  },
  {
    "2019": "",
    "Year : Month Week#": "JulWk3",
    "Predicted Price(INR)/Quintal": 2330,
    "Actual Price(INR)/Quintal": 2350,
    "Percentage Difference": "0.85%",
    "YEAR": 2019
  },
  {
    "2019": "",
    "Year : Month Week#": "JulWk4",
    "Predicted Price(INR)/Quintal": 2332,
    "Actual Price(INR)/Quintal": 2299,
    "Percentage Difference": "-1.44%",
    "YEAR": 2019
  },
  {
    "2019": "",
    "Year : Month Week#": "AugWk1",
    "Predicted Price(INR)/Quintal": 2309,
    "Actual Price(INR)/Quintal": 2316,
    "Percentage Difference": "0.30%",
    "YEAR": 2019
  },
  {
    "2019": "",
    "Year : Month Week#": "AugWk2",
    "Predicted Price(INR)/Quintal": 2310,
    "Actual Price(INR)/Quintal": 2331,
    "Percentage Difference": "0.90%",
    "YEAR": 2019
  },
  {
    "2019": "",
    "Year : Month Week#": "AugWk3",
    "Predicted Price(INR)/Quintal": 2305,
    "Actual Price(INR)/Quintal": 2311,
    "Percentage Difference": "0.26%",
    "YEAR": 2019
  },
  {
    "2019": "",
    "Year : Month Week#": "AugWk4",
    "Predicted Price(INR)/Quintal": 2302,
    "Actual Price(INR)/Quintal": 2394,
    "Percentage Difference": "3.84%",
    "YEAR": 2019
  },
  {
    "2019": "",
    "Year : Month Week#": "SepWk1",
    "Predicted Price(INR)/Quintal": 2337,
    "Actual Price(INR)/Quintal": 2370,
    "Percentage Difference": "1.39%",
    "YEAR": 2019
  },
  {
    "2019": "",
    "Year : Month Week#": "SepWk2",
    "Predicted Price(INR)/Quintal": 2324,
    "Actual Price(INR)/Quintal": 2375,
    "Percentage Difference": "2.15%",
    "YEAR": 2019
  },
  {
    "2019": "",
    "Year : Month Week#": "SepWk3",
    "Predicted Price(INR)/Quintal": 2328,
    "Actual Price(INR)/Quintal": 2350,
    "Percentage Difference": "0.94%",
    "YEAR": 2019
  },
  {
    "2019": "",
    "Year : Month Week#": "SepWk4",
    "Predicted Price(INR)/Quintal": 2338,
    "Actual Price(INR)/Quintal": 2347,
    "Percentage Difference": "0.38%",
    "YEAR": 2019
  },
  {
    "2019": "",
    "Year : Month Week#": "OctWk1",
    "Predicted Price(INR)/Quintal": 2325,
    "Actual Price(INR)/Quintal": 2287,
    "Percentage Difference": "-1.66%",
    "YEAR": 2019
  },
  {
    "2019": "",
    "Year : Month Week#": "OctWk2",
    "Predicted Price(INR)/Quintal": 2284,
    "Actual Price(INR)/Quintal": 2364,
    "Percentage Difference": "-1.66%",
    "YEAR": 2019
  },
  {
    "2019": "",
    "Year : Month Week#": "OctWk3",
    "Predicted Price(INR)/Quintal": 2277,
    "Actual Price(INR)/Quintal": 2290,
    "Percentage Difference": "3.38%",
    "YEAR": 2019
  },
  {
    "2019": "",
    "Year : Month Week#": "OctWk4",
    "Predicted Price(INR)/Quintal": 2262,
    "Actual Price(INR)/Quintal": 2191,
    "Percentage Difference": "-3.24%",
    "YEAR": 2019
  },
  {
    "2019": "",
    "Year : Month Week#": "NovWk1",
    "Predicted Price(INR)/Quintal": 2181,
    "Actual Price(INR)/Quintal": 2133,
    "Percentage Difference": "-2.25%",
    "YEAR": 2019
  },
  {
    "2019": "",
    "Year : Month Week#": "NovWk2",
    "Predicted Price(INR)/Quintal": 2187,
    "Actual Price(INR)/Quintal": 2135,
    "Percentage Difference": "-2.44%",
    "YEAR": 2019
  },
  {
    "2019": "",
    "Year : Month Week#": "NovWk3",
    "Predicted Price(INR)/Quintal": 2175,
    "Actual Price(INR)/Quintal": 2275,
    "Percentage Difference": "4.40%",
    "YEAR": 2019
  },
  {
    "2019": "",
    "Year : Month Week#": "NovWk4",
    "Predicted Price(INR)/Quintal": 2169,
    "Actual Price(INR)/Quintal": 2240,
    "Percentage Difference": "3.17%",
    "YEAR": 2019
  },
  {
    "2019": "",
    "Year : Month Week#": "DecWk1",
    "Predicted Price(INR)/Quintal": 2194,
    "Actual Price(INR)/Quintal": 2216,
    "Percentage Difference": "0.99%",
    "YEAR": 2019
  },
  {
    "2019": "",
    "Year : Month Week#": "DecWk2",
    "Predicted Price(INR)/Quintal": 2282,
    "Actual Price(INR)/Quintal": 2275,
    "Percentage Difference": "-0.31%",
    "YEAR": 2019
  },
  {
    "2019": "",
    "Year : Month Week#": "DecWk3",
    "Predicted Price(INR)/Quintal": 2217,
    "Actual Price(INR)/Quintal": 2230,
    "Percentage Difference": "0.58%",
    "YEAR": 2019
  },
  {
    "2019": "",
    "Year : Month Week#": "DecWk4",
    "Predicted Price(INR)/Quintal": 2178,
    "Actual Price(INR)/Quintal": 2165,
    "Percentage Difference": "-0.60%",
    "YEAR": 2019
  },
  {
    "2019": "",
    "Year : Month Week#": "JanWk1",
    "Predicted Price(INR)/Quintal": 2205,
    "Actual Price(INR)/Quintal": 2219,
    "Percentage Difference": "0.63%",
    "YEAR": 2018
  },
  {
    "2019": "",
    "Year : Month Week#": "JanWk2",
    "Predicted Price(INR)/Quintal": 2208,
    "Actual Price(INR)/Quintal": 2144,
    "Percentage Difference": "-2.99%",
    "YEAR": 2018
  },
  {
    "2019": "",
    "Year : Month Week#": "JanWk3",
    "Predicted Price(INR)/Quintal": 2104,
    "Actual Price(INR)/Quintal": 2057,
    "Percentage Difference": "-2.28%",
    "YEAR": 2018
  },
  {
    "2019": "",
    "Year : Month Week#": "JanWk4",
    "Predicted Price(INR)/Quintal": 2006,
    "Actual Price(INR)/Quintal": 2027,
    "Percentage Difference": "1.04%",
    "YEAR": 2018
  },
  {
    "2019": "",
    "Year : Month Week#": "FebWk1",
    "Predicted Price(INR)/Quintal": 2075,
    "Actual Price(INR)/Quintal": 2137,
    "Percentage Difference": "2.90%",
    "YEAR": 2018
  },
  {
    "2019": "",
    "Year : Month Week#": "FebWk2",
    "Predicted Price(INR)/Quintal": 2100,
    "Actual Price(INR)/Quintal": 2100,
    "Percentage Difference": "0.00%",
    "YEAR": 2018
  },
  {
    "2019": "",
    "Year : Month Week#": "FebWk3",
    "Predicted Price(INR)/Quintal": 2108,
    "Actual Price(INR)/Quintal": 2218,
    "Percentage Difference": "4.96%",
    "YEAR": 2018
  },
  {
    "2019": "",
    "Year : Month Week#": "FebWk4",
    "Predicted Price(INR)/Quintal": 2262,
    "Actual Price(INR)/Quintal": 2247,
    "Percentage Difference": "-0.67%",
    "YEAR": 2018
  },
  {
    "2019": "",
    "Year : Month Week#": "MarWk1",
    "Predicted Price(INR)/Quintal": 2046,
    "Actual Price(INR)/Quintal": 2041,
    "Percentage Difference": "-0.24%",
    "YEAR": 2018
  },
  {
    "2019": "",
    "Year : Month Week#": "MarWk2",
    "Predicted Price(INR)/Quintal": 2025,
    "Actual Price(INR)/Quintal": 2079,
    "Percentage Difference": "2.60%",
    "YEAR": 2018
  },
  {
    "2019": "",
    "Year : Month Week#": "MarWk3",
    "Predicted Price(INR)/Quintal": 2067,
    "Actual Price(INR)/Quintal": 2095,
    "Percentage Difference": "1.34%",
    "YEAR": 2018
  },
  {
    "2019": "",
    "Year : Month Week#": "MarWk4",
    "Predicted Price(INR)/Quintal": 2079,
    "Actual Price(INR)/Quintal": 2091,
    "Percentage Difference": "0.57%",
    "YEAR": 2018
  },
  {
    "2019": "",
    "Year : Month Week#": "AprWk1",
    "Predicted Price(INR)/Quintal": 1999,
    "Actual Price(INR)/Quintal": 2104,
    "Percentage Difference": "4.99%",
    "YEAR": 2018
  },
  {
    "2019": "",
    "Year : Month Week#": "AprWk2",
    "Predicted Price(INR)/Quintal": 2107,
    "Actual Price(INR)/Quintal": 2144,
    "Percentage Difference": "1.73%",
    "YEAR": 2018
  },
  {
    "2019": "",
    "Year : Month Week#": "AprWk3",
    "Predicted Price(INR)/Quintal": 2130,
    "Actual Price(INR)/Quintal": 2166,
    "Percentage Difference": "1.66%",
    "YEAR": 2018
  },
  {
    "2019": "",
    "Year : Month Week#": "AprWk4",
    "Predicted Price(INR)/Quintal": 2121,
    "Actual Price(INR)/Quintal": 2190,
    "Percentage Difference": "3.15%",
    "YEAR": 2018
  },
  {
    "2019": "",
    "Year : Month Week#": "MayWk1",
    "Predicted Price(INR)/Quintal": 2120,
    "Actual Price(INR)/Quintal": 2148,
    "Percentage Difference": "1.30%",
    "YEAR": 2018
  },
  {
    "2019": "",
    "Year : Month Week#": "MayWk2",
    "Predicted Price(INR)/Quintal": 2088,
    "Actual Price(INR)/Quintal": 2200,
    "Percentage Difference": "5.09%",
    "YEAR": 2018
  },
  {
    "2019": "",
    "Year : Month Week#": "MayWk3",
    "Predicted Price(INR)/Quintal": 2228,
    "Actual Price(INR)/Quintal": 2230,
    "Percentage Difference": "0.09%",
    "YEAR": 2018
  },
  {
    "2019": "",
    "Year : Month Week#": "MayWk4",
    "Predicted Price(INR)/Quintal": 2259,
    "Actual Price(INR)/Quintal": 2262,
    "Percentage Difference": "0.13%",
    "YEAR": 2018
  },
  {
    "2019": "",
    "Year : Month Week#": "JunWk1",
    "Predicted Price(INR)/Quintal": 2178,
    "Actual Price(INR)/Quintal": 2195,
    "Percentage Difference": "0.77%",
    "YEAR": 2018
  },
  {
    "2019": "",
    "Year : Month Week#": "JunWk2",
    "Predicted Price(INR)/Quintal": 2194,
    "Actual Price(INR)/Quintal": 2199,
    "Percentage Difference": "0.23%",
    "YEAR": 2018
  },
  {
    "2019": "",
    "Year : Month Week#": "JunWk3",
    "Predicted Price(INR)/Quintal": 2145,
    "Actual Price(INR)/Quintal": 2147,
    "Percentage Difference": "0.09%",
    "YEAR": 2018
  },
  {
    "2019": "",
    "Year : Month Week#": "JunWk4",
    "Predicted Price(INR)/Quintal": 2146,
    "Actual Price(INR)/Quintal": 2159,
    "Percentage Difference": "0.60%",
    "YEAR": 2018
  },
  {
    "2019": "",
    "Year : Month Week#": "JulWk1",
    "Predicted Price(INR)/Quintal": 2136,
    "Actual Price(INR)/Quintal": 2146,
    "Percentage Difference": "0.47%",
    "YEAR": 2018
  },
  {
    "2019": "",
    "Year : Month Week#": "JulWk2",
    "Predicted Price(INR)/Quintal": 2144,
    "Actual Price(INR)/Quintal": 2085,
    "Percentage Difference": "-2.83%",
    "YEAR": 2018
  },
  {
    "2019": "",
    "Year : Month Week#": "JulWk3",
    "Predicted Price(INR)/Quintal": 2045,
    "Actual Price(INR)/Quintal": 2181,
    "Percentage Difference": "6.24%",
    "YEAR": 2018
  },
  {
    "2019": "",
    "Year : Month Week#": "JulWk4",
    "Predicted Price(INR)/Quintal": 2147,
    "Actual Price(INR)/Quintal": 2222,
    "Percentage Difference": "3.38%",
    "YEAR": 2018
  },
  {
    "2019": "",
    "Year : Month Week#": "AugWk1",
    "Predicted Price(INR)/Quintal": 2138,
    "Actual Price(INR)/Quintal": 2236,
    "Percentage Difference": "4.38%",
    "YEAR": 2018
  },
  {
    "2019": "",
    "Year : Month Week#": "AugWk2",
    "Predicted Price(INR)/Quintal": 2230,
    "Actual Price(INR)/Quintal": 2288,
    "Percentage Difference": "2.53%",
    "YEAR": 2018
  },
  {
    "2019": "",
    "Year : Month Week#": "AugWk3",
    "Predicted Price(INR)/Quintal": 2228,
    "Actual Price(INR)/Quintal": 2174,
    "Percentage Difference": "-2.48%",
    "YEAR": 2018
  },
  {
    "2019": "",
    "Year : Month Week#": "AugWk4",
    "Predicted Price(INR)/Quintal": 2136,
    "Actual Price(INR)/Quintal": 2106,
    "Percentage Difference": "-1.42%",
    "YEAR": 2018
  },
  {
    "2019": "",
    "Year : Month Week#": "SepWk1",
    "Predicted Price(INR)/Quintal": 2013,
    "Actual Price(INR)/Quintal": 2089,
    "Percentage Difference": "3.64%",
    "YEAR": 2018
  },
  {
    "2019": "",
    "Year : Month Week#": "SepWk2",
    "Predicted Price(INR)/Quintal": 2119,
    "Actual Price(INR)/Quintal": 2230,
    "Percentage Difference": "4.98%",
    "YEAR": 2018
  },
  {
    "2019": "",
    "Year : Month Week#": "SepWk3",
    "Predicted Price(INR)/Quintal": 2213,
    "Actual Price(INR)/Quintal": 2223,
    "Percentage Difference": "0.45%",
    "YEAR": 2018
  },
  {
    "2019": "",
    "Year : Month Week#": "SepWk4",
    "Predicted Price(INR)/Quintal": 2242,
    "Actual Price(INR)/Quintal": 2301,
    "Percentage Difference": "2.56%",
    "YEAR": 2018
  },
  {
    "2019": "",
    "Year : Month Week#": "OctWk1",
    "Predicted Price(INR)/Quintal": 2252,
    "Actual Price(INR)/Quintal": 2320,
    "Percentage Difference": "2.93%",
    "YEAR": 2018
  },
  {
    "2019": "",
    "Year : Month Week#": "OctWk2",
    "Predicted Price(INR)/Quintal": 2301,
    "Actual Price(INR)/Quintal": 2310,
    "Percentage Difference": "0.39%",
    "YEAR": 2018
  },
  {
    "2019": "",
    "Year : Month Week#": "OctWk3",
    "Predicted Price(INR)/Quintal": 2305,
    "Actual Price(INR)/Quintal": 2209,
    "Percentage Difference": "-4.35%",
    "YEAR": 2018
  },
  {
    "2019": "",
    "Year : Month Week#": "OctWk4",
    "Predicted Price(INR)/Quintal": 2201,
    "Actual Price(INR)/Quintal": 2205,
    "Percentage Difference": "0.18%",
    "YEAR": 2018
  },
  {
    "2019": "",
    "Year : Month Week#": "NovWk1",
    "Predicted Price(INR)/Quintal": 2185,
    "Actual Price(INR)/Quintal": 2288,
    "Percentage Difference": "4.50%",
    "YEAR": 2018
  },
  {
    "2019": "",
    "Year : Month Week#": "NovWk2",
    "Predicted Price(INR)/Quintal": 2261,
    "Actual Price(INR)/Quintal": 2382,
    "Percentage Difference": "5.08%",
    "YEAR": 2018
  },
  {
    "2019": "",
    "Year : Month Week#": "NovWk3",
    "Predicted Price(INR)/Quintal": 2367,
    "Actual Price(INR)/Quintal": 2377,
    "Percentage Difference": "0.42%",
    "YEAR": 2018
  },
  {
    "2019": "",
    "Year : Month Week#": "NovWk4",
    "Predicted Price(INR)/Quintal": 2301,
    "Actual Price(INR)/Quintal": 2288,
    "Percentage Difference": "-0.57%",
    "YEAR": 2018
  },
  {
    "2019": "",
    "Year : Month Week#": "DecWk1",
    "Predicted Price(INR)/Quintal": 2277,
    "Actual Price(INR)/Quintal": 2308,
    "Percentage Difference": "1.34%",
    "YEAR": 2018
  },
  {
    "2019": "",
    "Year : Month Week#": "DecWk2",
    "Predicted Price(INR)/Quintal": 2256,
    "Actual Price(INR)/Quintal": 2328,
    "Percentage Difference": "3.09%",
    "YEAR": 2018
  },
  {
    "2019": "",
    "Year : Month Week#": "DecWk3",
    "Predicted Price(INR)/Quintal": 2307,
    "Actual Price(INR)/Quintal": 2328,
    "Percentage Difference": "0.90%",
    "YEAR": 2018
  },
  {
    "2019": "",
    "Year : Month Week#": "DecWk4",
    "Predicted Price(INR)/Quintal": 2314,
    "Actual Price(INR)/Quintal": 2350,
    "Percentage Difference": "1.53%",
    "YEAR": 2018
  },
  {
    "2019": "",
    "Year : Month Week#": "Jan Wk1",
    "Predicted Price(INR)/Quintal": 2090,
    "Actual Price(INR)/Quintal": 2116,
    "Percentage Difference": "1.22%",
    "YEAR": 2017
  },
  {
    "2019": "",
    "Year : Month Week#": "Jan Wk2",
    "Predicted Price(INR)/Quintal": 2166,
    "Actual Price(INR)/Quintal": 2290,
    "Percentage Difference": "5.41%",
    "YEAR": 2017
  },
  {
    "2019": "",
    "Year : Month Week#": "Jan Wk3",
    "Predicted Price(INR)/Quintal": 2113,
    "Actual Price(INR)/Quintal": 2148,
    "Percentage Difference": "1.63%",
    "YEAR": 2017
  },
  {
    "2019": "",
    "Year : Month Week#": "Jan Wk4",
    "Predicted Price(INR)/Quintal": 2186,
    "Actual Price(INR)/Quintal": 2317,
    "Percentage Difference": "5.65%",
    "YEAR": 2017
  },
  {
    "2019": "",
    "Year : Month Week#": "Feb Wk1",
    "Predicted Price(INR)/Quintal": 2368,
    "Actual Price(INR)/Quintal": 2537,
    "Percentage Difference": "6.67%",
    "YEAR": 2017
  },
  {
    "2019": "",
    "Year : Month Week#": "Feb Wk2",
    "Predicted Price(INR)/Quintal": 2579,
    "Actual Price(INR)/Quintal": 2569,
    "Percentage Difference": "-0.39%",
    "YEAR": 2017
  },
  {
    "2019": "",
    "Year : Month Week#": "Feb Wk3",
    "Predicted Price(INR)/Quintal": 2542,
    "Actual Price(INR)/Quintal": 2710,
    "Percentage Difference": "6.20%",
    "YEAR": 2017
  },
  {
    "2019": "",
    "Year : Month Week#": "Feb Wk4",
    "Predicted Price(INR)/Quintal": 2792,
    "Actual Price(INR)/Quintal": 2850,
    "Percentage Difference": "2.04%",
    "YEAR": 2017
  },
  {
    "2019": "",
    "Year : Month Week#": "Mar Wk1",
    "Predicted Price(INR)/Quintal": 2856,
    "Actual Price(INR)/Quintal": 2859,
    "Percentage Difference": "0.01%",
    "YEAR": 2017
  },
  {
    "2019": "",
    "Year : Month Week#": "Mar Wk2",
    "Predicted Price(INR)/Quintal": 2888,
    "Actual Price(INR)/Quintal": 2899,
    "Percentage Difference": "0.38%",
    "YEAR": 2017
  },
  {
    "2019": "",
    "Year : Month Week#": "Mar Wk3",
    "Predicted Price(INR)/Quintal": 2865,
    "Actual Price(INR)/Quintal": 2883,
    "Percentage Difference": "0.62%",
    "YEAR": 2017
  },
  {
    "2019": "",
    "Year : Month Week#": "Mar Wk4",
    "Predicted Price(INR)/Quintal": 2828,
    "Actual Price(INR)/Quintal": 2920,
    "Percentage Difference": "3.15%",
    "YEAR": 2017
  },
  {
    "2019": "",
    "Year : Month Week#": "Apr Wk1",
    "Predicted Price(INR)/Quintal": 2926,
    "Actual Price(INR)/Quintal": 2979,
    "Percentage Difference": "1.78%",
    "YEAR": 2017
  },
  {
    "2019": "",
    "Year : Month Week#": "Apr Wk2",
    "Predicted Price(INR)/Quintal": 2924,
    "Actual Price(INR)/Quintal": 2925,
    "Percentage Difference": "0.03%",
    "YEAR": 2017
  },
  {
    "2019": "",
    "Year : Month Week#": "Apr Wk3",
    "Predicted Price(INR)/Quintal": 2983,
    "Actual Price(INR)/Quintal": 2849,
    "Percentage Difference": "-4.70%",
    "YEAR": 2017
  },
  {
    "2019": "",
    "Year : Month Week#": "Apr Wk4",
    "Predicted Price(INR)/Quintal": 2861,
    "Actual Price(INR)/Quintal": 2748,
    "Percentage Difference": "-4.11%",
    "YEAR": 2017
  },
  {
    "2019": "",
    "Year : Month Week#": "May Wk1",
    "Predicted Price(INR)/Quintal": 2615,
    "Actual Price(INR)/Quintal": 2674,
    "Percentage Difference": "2.21%",
    "YEAR": 2017
  },
  {
    "2019": "",
    "Year : Month Week#": "May Wk2",
    "Predicted Price(INR)/Quintal": 2666,
    "Actual Price(INR)/Quintal": 2637,
    "Percentage Difference": "-1.10%",
    "YEAR": 2017
  },
  {
    "2019": "",
    "Year : Month Week#": "May Wk3",
    "Predicted Price(INR)/Quintal": 2467,
    "Actual Price(INR)/Quintal": 2469,
    "Percentage Difference": "0.08%",
    "YEAR": 2017
  },
  {
    "2019": "",
    "Year : Month Week#": "May Wk4",
    "Predicted Price(INR)/Quintal": 2477,
    "Actual Price(INR)/Quintal": 2294,
    "Percentage Difference": "-7.98%",
    "YEAR": 2017
  },
  {
    "2019": "",
    "Year : Month Week#": "Jun Wk1",
    "Predicted Price(INR)/Quintal": 2180,
    "Actual Price(INR)/Quintal": 2296,
    "Percentage Difference": "5.05%",
    "YEAR": 2017
  },
  {
    "2019": "",
    "Year : Month Week#": "Jun Wk2",
    "Predicted Price(INR)/Quintal": 2283,
    "Actual Price(INR)/Quintal": 2384,
    "Percentage Difference": "4.24%",
    "YEAR": 2017
  },
  {
    "2019": "",
    "Year : Month Week#": "Jun Wk3",
    "Predicted Price(INR)/Quintal": 2387,
    "Actual Price(INR)/Quintal": 2462,
    "Percentage Difference": "3.05%",
    "YEAR": 2017
  },
  {
    "2019": "",
    "Year : Month Week#": "Jun Wk4",
    "Predicted Price(INR)/Quintal": 2481,
    "Actual Price(INR)/Quintal": 2502,
    "Percentage Difference": "0.84%",
    "YEAR": 2017
  },
  {
    "2019": "",
    "Year : Month Week#": "Jul Wk1",
    "Predicted Price(INR)/Quintal": 2560,
    "Actual Price(INR)/Quintal": 2604,
    "Percentage Difference": "1.69%",
    "YEAR": 2017
  },
  {
    "2019": "",
    "Year : Month Week#": "Jul Wk2",
    "Predicted Price(INR)/Quintal": 2565,
    "Actual Price(INR)/Quintal": 2626,
    "Percentage Difference": "2.32%",
    "YEAR": 2017
  },
  {
    "2019": "",
    "Year : Month Week#": "Jul Wk3",
    "Predicted Price(INR)/Quintal": 2632,
    "Actual Price(INR)/Quintal": 2673,
    "Percentage Difference": "1.53%",
    "YEAR": 2017
  },
  {
    "2019": "",
    "Year : Month Week#": "Jul Wk4",
    "Predicted Price(INR)/Quintal": 2630,
    "Actual Price(INR)/Quintal": 2824,
    "Percentage Difference": "6.87%",
    "YEAR": 2017
  },
  {
    "2019": "",
    "Year : Month Week#": "Aug Wk1",
    "Predicted Price(INR)/Quintal": 2841,
    "Actual Price(INR)/Quintal": 2846,
    "Percentage Difference": "0.18%",
    "YEAR": 2017
  },
  {
    "2019": "",
    "Year : Month Week#": "Aug Wk2",
    "Predicted Price(INR)/Quintal": 2855,
    "Actual Price(INR)/Quintal": 2999,
    "Percentage Difference": "4.80%",
    "YEAR": 2017
  },
  {
    "2019": "",
    "Year : Month Week#": "Aug Wk3",
    "Predicted Price(INR)/Quintal": 2950,
    "Actual Price(INR)/Quintal": 2972,
    "Percentage Difference": "0.74%",
    "YEAR": 2017
  },
  {
    "2019": "",
    "Year : Month Week#": "Aug Wk4",
    "Predicted Price(INR)/Quintal": 2899,
    "Actual Price(INR)/Quintal": 2904,
    "Percentage Difference": "0.17%",
    "YEAR": 2017
  },
  {
    "2019": "",
    "Year : Month Week#": "Sep Wk1",
    "Predicted Price(INR)/Quintal": 2896,
    "Actual Price(INR)/Quintal": 2899,
    "Percentage Difference": "0.10%",
    "YEAR": 2017
  },
  {
    "2019": "",
    "Year : Month Week#": "Sep Wk2",
    "Predicted Price(INR)/Quintal": 2897,
    "Actual Price(INR)/Quintal": 2911,
    "Percentage Difference": "0.48%",
    "YEAR": 2017
  },
  {
    "2019": "",
    "Year : Month Week#": "Sep Wk3",
    "Predicted Price(INR)/Quintal": 2801,
    "Actual Price(INR)/Quintal": 2851,
    "Percentage Difference": "1.75%",
    "YEAR": 2017
  },
  {
    "2019": "",
    "Year : Month Week#": "Sep Wk4",
    "Predicted Price(INR)/Quintal": 2816,
    "Actual Price(INR)/Quintal": 2850,
    "Percentage Difference": "1.19%",
    "YEAR": 2017
  },
  {
    "2019": "",
    "Year : Month Week#": "Oct Wk1",
    "Predicted Price(INR)/Quintal": 2818,
    "Actual Price(INR)/Quintal": 2910,
    "Percentage Difference": "3.16%",
    "YEAR": 2017
  },
  {
    "2019": "",
    "Year : Month Week#": "Oct Wk2",
    "Predicted Price(INR)/Quintal": 2633,
    "Actual Price(INR)/Quintal": 2678,
    "Percentage Difference": "1.68%",
    "YEAR": 2017
  },
  {
    "2019": "",
    "Year : Month Week#": "Oct Wk3",
    "Predicted Price(INR)/Quintal": 2677,
    "Actual Price(INR)/Quintal": 2605,
    "Percentage Difference": "-2.76%",
    "YEAR": 2017
  },
  {
    "2019": "",
    "Year : Month Week#": "Oct Wk4",
    "Predicted Price(INR)/Quintal": 2349,
    "Actual Price(INR)/Quintal": 2458,
    "Percentage Difference": "4.43%",
    "YEAR": 2017
  },
  {
    "2019": "",
    "Year : Month Week#": "Nov Wk1",
    "Predicted Price(INR)/Quintal": 2455,
    "Actual Price(INR)/Quintal": 2468,
    "Percentage Difference": "0.53%",
    "YEAR": 2017
  },
  {
    "2019": "",
    "Year : Month Week#": "Nov Wk2",
    "Predicted Price(INR)/Quintal": 2512,
    "Actual Price(INR)/Quintal": 2382,
    "Percentage Difference": "-5.46%",
    "YEAR": 2017
  },
  {
    "2019": "",
    "Year : Month Week#": "Nov Wk3",
    "Predicted Price(INR)/Quintal": 2331,
    "Actual Price(INR)/Quintal": 2358,
    "Percentage Difference": "1.15%",
    "YEAR": 2017
  },
  {
    "2019": "",
    "Year : Month Week#": "Nov Wk4",
    "Predicted Price(INR)/Quintal": 2327,
    "Actual Price(INR)/Quintal": 2297,
    "Percentage Difference": "-1.31%",
    "YEAR": 2017
  },
  {
    "2019": "",
    "Year : Month Week#": "Dec Wk1",
    "Predicted Price(INR)/Quintal": 2216,
    "Actual Price(INR)/Quintal": 2236,
    "Percentage Difference": "0.89%",
    "YEAR": 2017
  },
  {
    "2019": "",
    "Year : Month Week#": "Dec Wk2",
    "Predicted Price(INR)/Quintal": 2225,
    "Actual Price(INR)/Quintal": 2269,
    "Percentage Difference": "1.94%",
    "YEAR": 2017
  },
  {
    "2019": "",
    "Year : Month Week#": "Dec Wk3",
    "Predicted Price(INR)/Quintal": 2227,
    "Actual Price(INR)/Quintal": 2194,
    "Percentage Difference": "-1.50%",
    "YEAR": 2017
  },
  {
    "2019": "",
    "Year : Month Week#": "Dec Wk4",
    "Predicted Price(INR)/Quintal": 2219,
    "Actual Price(INR)/Quintal": 2225,
    "Percentage Difference": "0.27%",
    "YEAR": 2017
  },
  {
    "2019": "",
    "Year : Month Week#": "Jan Wk1",
    "Predicted Price(INR)/Quintal": 1613,
    "Actual Price(INR)/Quintal": 1576,
    "Percentage Difference": "-2.35%",
    "YEAR": 2016
  },
  {
    "2019": "",
    "Year : Month Week#": "Jan Wk2",
    "Predicted Price(INR)/Quintal": 1572,
    "Actual Price(INR)/Quintal": 1635,
    "Percentage Difference": "3.85%",
    "YEAR": 2016
  },
  {
    "2019": "",
    "Year : Month Week#": "Jan Wk3",
    "Predicted Price(INR)/Quintal": 1646,
    "Actual Price(INR)/Quintal": 1550,
    "Percentage Difference": "-6.19%",
    "YEAR": 2016
  },
  {
    "2019": "",
    "Year : Month Week#": "Jan Wk4",
    "Predicted Price(INR)/Quintal": 1586,
    "Actual Price(INR)/Quintal": 1599,
    "Percentage Difference": "0.81%",
    "YEAR": 2016
  },
  {
    "2019": "",
    "Year : Month Week#": "Feb Wk1",
    "Predicted Price(INR)/Quintal": 1673,
    "Actual Price(INR)/Quintal": 1635,
    "Percentage Difference": "-2.32%",
    "YEAR": 2016
  },
  {
    "2019": "",
    "Year : Month Week#": "Feb Wk2",
    "Predicted Price(INR)/Quintal": 1607,
    "Actual Price(INR)/Quintal": 1665,
    "Percentage Difference": "3.48%",
    "YEAR": 2016
  },
  {
    "2019": "",
    "Year : Month Week#": "Feb Wk3",
    "Predicted Price(INR)/Quintal": 1508,
    "Actual Price(INR)/Quintal": 1561,
    "Percentage Difference": "3.40%",
    "YEAR": 2016
  },
  {
    "2019": "",
    "Year : Month Week#": "Feb Wk4",
    "Predicted Price(INR)/Quintal": 1609,
    "Actual Price(INR)/Quintal": 1657,
    "Percentage Difference": "2.90%",
    "YEAR": 2016
  },
  {
    "2019": "",
    "Year : Month Week#": "Mar Wk1",
    "Predicted Price(INR)/Quintal": 1652,
    "Actual Price(INR)/Quintal": 1649,
    "Percentage Difference": "-0.18%",
    "YEAR": 2016
  },
  {
    "2019": "",
    "Year : Month Week#": "Mar Wk2",
    "Predicted Price(INR)/Quintal": 1565,
    "Actual Price(INR)/Quintal": 1674,
    "Percentage Difference": "6.51%",
    "YEAR": 2016
  },
  {
    "2019": "",
    "Year : Month Week#": "Mar Wk3",
    "Predicted Price(INR)/Quintal": 1654,
    "Actual Price(INR)/Quintal": 1558,
    "Percentage Difference": "-6.16%",
    "YEAR": 2016
  },
  {
    "2019": "",
    "Year : Month Week#": "Mar Wk4",
    "Predicted Price(INR)/Quintal": 1564,
    "Actual Price(INR)/Quintal": 1638,
    "Percentage Difference": "4.52%",
    "YEAR": 2016
  },
  {
    "2019": "",
    "Year : Month Week#": "Apr Wk1",
    "Predicted Price(INR)/Quintal": 1558,
    "Actual Price(INR)/Quintal": 1584,
    "Percentage Difference": "1.64%",
    "YEAR": 2016
  },
  {
    "2019": "",
    "Year : Month Week#": "Apr Wk2",
    "Predicted Price(INR)/Quintal": 1572,
    "Actual Price(INR)/Quintal": 1639,
    "Percentage Difference": "4.09%",
    "YEAR": 2016
  },
  {
    "2019": "",
    "Year : Month Week#": "Apr Wk3",
    "Predicted Price(INR)/Quintal": 1637,
    "Actual Price(INR)/Quintal": 1691,
    "Percentage Difference": "3.19%",
    "YEAR": 2016
  },
  {
    "2019": "",
    "Year : Month Week#": "Apr Wk4",
    "Predicted Price(INR)/Quintal": 1667,
    "Actual Price(INR)/Quintal": 1753,
    "Percentage Difference": "4.91%",
    "YEAR": 2016
  },
  {
    "2019": "",
    "Year : Month Week#": "May Wk1",
    "Predicted Price(INR)/Quintal": 1768,
    "Actual Price(INR)/Quintal": 1662,
    "Percentage Difference": "-6.38%",
    "YEAR": 2016
  },
  {
    "2019": "",
    "Year : Month Week#": "May Wk2",
    "Predicted Price(INR)/Quintal": 1622,
    "Actual Price(INR)/Quintal": 1702,
    "Percentage Difference": "4.70%",
    "YEAR": 2016
  },
  {
    "2019": "",
    "Year : Month Week#": "May Wk3",
    "Predicted Price(INR)/Quintal": 1708,
    "Actual Price(INR)/Quintal": 1673,
    "Percentage Difference": "-2.09%",
    "YEAR": 2016
  },
  {
    "2019": "",
    "Year : Month Week#": "May Wk4",
    "Predicted Price(INR)/Quintal": 1664,
    "Actual Price(INR)/Quintal": 1620,
    "Percentage Difference": "-2.72%",
    "YEAR": 2016
  },
  {
    "2019": "",
    "Year : Month Week#": "Jun Wk1",
    "Predicted Price(INR)/Quintal": 1585,
    "Actual Price(INR)/Quintal": 1580,
    "Percentage Difference": "0.00%",
    "YEAR": 2016
  },
  {
    "2019": "",
    "Year : Month Week#": "Jun Wk2",
    "Predicted Price(INR)/Quintal": 1566,
    "Actual Price(INR)/Quintal": 1531,
    "Percentage Difference": "-2.29%",
    "YEAR": 2016
  },
  {
    "2019": "",
    "Year : Month Week#": "Jun Wk3",
    "Predicted Price(INR)/Quintal": 1563,
    "Actual Price(INR)/Quintal": 1581,
    "Percentage Difference": "1.14%",
    "YEAR": 2016
  },
  {
    "2019": "",
    "Year : Month Week#": "Jun Wk4",
    "Predicted Price(INR)/Quintal": 1581,
    "Actual Price(INR)/Quintal": 1591,
    "Percentage Difference": "0.63%",
    "YEAR": 2016
  },
  {
    "2019": "",
    "Year : Month Week#": "Jul Wk1",
    "Predicted Price(INR)/Quintal": 1582,
    "Actual Price(INR)/Quintal": 1543,
    "Percentage Difference": "-2.53%",
    "YEAR": 2016
  },
  {
    "2019": "",
    "Year : Month Week#": "Jul Wk2",
    "Predicted Price(INR)/Quintal": 1550,
    "Actual Price(INR)/Quintal": 1619,
    "Percentage Difference": "4.26%",
    "YEAR": 2016
  },
  {
    "2019": "",
    "Year : Month Week#": "Jul Wk3",
    "Predicted Price(INR)/Quintal": 1654,
    "Actual Price(INR)/Quintal": 1599,
    "Percentage Difference": "-3.44%",
    "YEAR": 2016
  },
  {
    "2019": "",
    "Year : Month Week#": "Jul Wk4",
    "Predicted Price(INR)/Quintal": 1603,
    "Actual Price(INR)/Quintal": 1587,
    "Percentage Difference": "-1.07%",
    "YEAR": 2016
  },
  {
    "2019": "",
    "Year : Month Week#": "Aug Wk1",
    "Predicted Price(INR)/Quintal": 1613,
    "Actual Price(INR)/Quintal": 1608,
    "Percentage Difference": "-0.33%",
    "YEAR": 2016
  },
  {
    "2019": "",
    "Year : Month Week#": "Aug Wk2",
    "Predicted Price(INR)/Quintal": 1621,
    "Actual Price(INR)/Quintal": 1564,
    "Percentage Difference": "-3.65%",
    "YEAR": 2016
  },
  {
    "2019": "",
    "Year : Month Week#": "Aug Wk3",
    "Predicted Price(INR)/Quintal": 1582,
    "Actual Price(INR)/Quintal": 1577,
    "Percentage Difference": "-0.32%",
    "YEAR": 2016
  },
  {
    "2019": "",
    "Year : Month Week#": "Aug Wk4",
    "Predicted Price(INR)/Quintal": 1571,
    "Actual Price(INR)/Quintal": 1571,
    "Percentage Difference": "0.00%",
    "YEAR": 2016
  },
  {
    "2019": "",
    "Year : Month Week#": "Sep Wk1",
    "Predicted Price(INR)/Quintal": 1569,
    "Actual Price(INR)/Quintal": 1660,
    "Percentage Difference": "5.48%",
    "YEAR": 2016
  },
  {
    "2019": "",
    "Year : Month Week#": "Sep Wk2",
    "Predicted Price(INR)/Quintal": 1511,
    "Actual Price(INR)/Quintal": 1491,
    "Percentage Difference": "-1.34%",
    "YEAR": 2016
  },
  {
    "2019": "",
    "Year : Month Week#": "Sep Wk3",
    "Predicted Price(INR)/Quintal": 1496,
    "Actual Price(INR)/Quintal": 1561,
    "Percentage Difference": "4.16%",
    "YEAR": 2016
  },
  {
    "2019": "",
    "Year : Month Week#": "Sep Wk4",
    "Predicted Price(INR)/Quintal": 1512,
    "Actual Price(INR)/Quintal": 1543,
    "Percentage Difference": "2.01%",
    "YEAR": 2016
  },
  {
    "2019": "",
    "Year : Month Week#": "Oct Wk1",
    "Predicted Price(INR)/Quintal": 1523,
    "Actual Price(INR)/Quintal": 1607,
    "Percentage Difference": "5.22%",
    "YEAR": 2016
  },
  {
    "2019": "",
    "Year : Month Week#": "Oct Wk2",
    "Predicted Price(INR)/Quintal": 1582,
    "Actual Price(INR)/Quintal": 1672,
    "Percentage Difference": "5.38%",
    "YEAR": 2016
  },
  {
    "2019": "",
    "Year : Month Week#": "Oct Wk3",
    "Predicted Price(INR)/Quintal": 1655,
    "Actual Price(INR)/Quintal": 1719,
    "Percentage Difference": "3.72%",
    "YEAR": 2016
  },
  {
    "2019": "",
    "Year : Month Week#": "Oct Wk4",
    "Predicted Price(INR)/Quintal": 1682,
    "Actual Price(INR)/Quintal": 1789,
    "Percentage Difference": "5.98%",
    "YEAR": 2016
  },
  {
    "2019": "",
    "Year : Month Week#": "Now Wk1",
    "Predicted Price(INR)/Quintal": 1797,
    "Actual Price(INR)/Quintal": 1637,
    "Percentage Difference": "-9.77%",
    "YEAR": 2016
  },
  {
    "2019": "",
    "Year : Month Week#": "Now Wk2",
    "Predicted Price(INR)/Quintal": 1603,
    "Actual Price(INR)/Quintal": 1647,
    "Percentage Difference": "2.67%",
    "YEAR": 2016
  },
  {
    "2019": "",
    "Year : Month Week#": "Now Wk3",
    "Predicted Price(INR)/Quintal": 1652,
    "Actual Price(INR)/Quintal": 1790,
    "Percentage Difference": "7.71%",
    "YEAR": 2016
  },
  {
    "2019": "",
    "Year : Month Week#": "Now Wk4",
    "Predicted Price(INR)/Quintal": 1969,
    "Actual Price(INR)/Quintal": 2160,
    "Percentage Difference": "8.84%",
    "YEAR": 2016
  },
  {
    "2019": "",
    "Year : Month Week#": "Dec Wk1",
    "Predicted Price(INR)/Quintal": 1981,
    "Actual Price(INR)/Quintal": 2216,
    "Percentage Difference": "10.60%",
    "YEAR": 2016
  },
  {
    "2019": "",
    "Year : Month Week#": "Dec Wk2",
    "Predicted Price(INR)/Quintal": 2277,
    "Actual Price(INR)/Quintal": 2420,
    "Percentage Difference": "5.91%",
    "YEAR": 2016
  },
  {
    "2019": "",
    "Year : Month Week#": "Dec Wk3",
    "Predicted Price(INR)/Quintal": 2269,
    "Actual Price(INR)/Quintal": 2383,
    "Percentage Difference": "4.78%",
    "YEAR": 2016
  },
  {
    "2019": "",
    "Year : Month Week#": "Dec Wk4",
    "Predicted Price(INR)/Quintal": 2358,
    "Actual Price(INR)/Quintal": 2145,
    "Percentage Difference": "-9.93%",
    "YEAR": 2016
  }
];
